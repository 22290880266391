import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// login
export const login = createAsyncThunk(
    'auth/login',
    async (data) => {
        const response = await post(`/auth/login`, data)

        return response
    }
)

// register
export const register = createAsyncThunk(
    'auth/register',
    async (data) => {
        const response = await post(`/auth/register`, data)

        return response
    }
)

// sendOtp
export const sendOtp = createAsyncThunk(
    'auth/sendOtp',
    async (data) => {
        const response = await post(`/auth/sendotp`, data)

        return response
    }
)

// verifyOtp
export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async (data) => {
        const response = await post(`/auth/verifyotp`, data)

        return response
    }
)

// resendOtp
export const resendOtp = createAsyncThunk(
    'auth/resendOtp',
    async (data) => {
        const response = await post(`/auth/resendotp`, data)

        return response
    }
)

// reset Password
export const resetPassword = createAsyncThunk(
    'auth/resetPass',
    async (data) => {
        const response = await post(`/auth/resetpassword`, data)

        return response
    }
)

// get gender
export const getGenders = createAsyncThunk(
    'auth/getgender',
    async () => {
        const response = await get(`/auth/gender`)

        return response
    }
)

// get infor user
export const getInforUser = createAsyncThunk(
    'auth/getinforuser',
    async () => {
        const response = await get(`auth/getuserinfor`)

        return response
    }
)

// get infor user update
export const getInforUserUpdate = createAsyncThunk(
    'auth/getinforuserUpdate',
    async () => {
        const response = await get(`auth/update-userinfor`)

        return response
    }
)

// update infor user
export const updateInforUser = createAsyncThunk(
    'auth/updateInforuser',
    async (data) => {
        const response = await post(`auth/update-userinfor`, data)

        return response
    }
)

// get my page infor
export const getMypageInfor = createAsyncThunk(
    'auth/getMypage',
    async () => {
        const response = await get(`userorder/mypage`)

        return response
    }
)

// delete account 
export const deleteAccount = createAsyncThunk(
    'auth/deleteAccount',
    async () => {
        const response = await del(`auth/user`)

        return response
    }
)
