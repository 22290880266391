import React, { useEffect, useRef, useState } from 'react'
import { URL_SERVER } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailMaterial } from '../../reduxs/actions/product.action'
import ButtonSecon from '../ButtonSecon'
import ButtonPrimary from '../ButtonPrimary'
import { formatMoney } from '../../utils/renderText'

// onClick={() => handleChoose(id)}

export default function LettersItemPopup({ id, frontThumbnail, name, item, price, isCheck = false, handleChoose }) {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <>

            <div className={`cursor-pointer flex flex-col gap-4 rounded-20 border-[2px] ${isCheck ? 'border-logoColor' : 'border-transparent'}`} onClick={handleShow}>
                <div>
                    <img src={`${URL_SERVER}${frontThumbnail}`} alt='letter' className='w-full rounded-20 object-contain aspect-[7.08/10]' />
                </div>

                <div className='flex flex-col gap-1 items-center px-1'>
                    <button className='font-semibold hover:text-logoColor duration-300 line-clamp-1'>{name}</button>
                    <p className='font-bold text-logoColor'>₩{formatMoney(price)}</p>
                </div>
            </div>

            {
                isShow
                &&
                <PopupDetailMaterial productId={id} handleChoose={() => handleChoose(item)} handleShow={handleShow} contentRef={contentRef} isCheck={isCheck} />
            }
        </>
    )
}

const PopupDetailMaterial = ({ productId, handleChoose, handleShow, contentRef, isCheck }) => {
    const dispatch = useDispatch()

    const { loadingGetDetailMaterial, dataDetailMaterial } = useSelector(state => state.product)

    useEffect(() => {
        dispatch(getDetailMaterial(productId))
    }, [productId])

    if (loadingGetDetailMaterial || !dataDetailMaterial) {
        return
    }

    const { frontThumbnail, name, price, page_count, target, summary_description } = dataDetailMaterial

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
            <div className='rounded-20 p-5 bg-white flex flex-col gap-5 w-[600px]' ref={contentRef}>
                <h5 className='text-center text-24-30 font-semibold'>상세정보</h5>

                <div className='grid justify-items-center gap-4'>
                    <img src={`${URL_SERVER}${frontThumbnail}`} alt={`${name}`} className='w-[200px] aspect-[7.08/10] object-cover' />
                    <p className='line-clamp-1 px-4'>{name}</p>
                </div>

                <div className='rounded-20 border border-strock p-5 grid gap-5'>
                    <div className='font-semibold flex gap-2.5'>
                        <p className='w-[120px]'>판매가격</p>
                        <p className='text-logoColor'>{formatMoney(price)}</p>
                    </div>
                    <div className='flex gap-2.5'>
                        <p className='font-semibold w-[120px]'>페이지수</p>
                        <p className=''>{page_count}</p>
                    </div>
                    <div className='flex gap-2.5'>
                        <p className='font-semibold w-[120px]'>대상</p>
                        <p className=''>{target}</p>
                    </div>
                    <div className='flex gap-2.5'>
                        <p className='font-semibold w-[120px]'>상품요약설명</p>
                        <p className=''>{summary_description}</p>
                    </div>
                </div>

                <div className='flex gap-2.5 w-full justify-center'>
                    <ButtonSecon title='취소' className='max-w-[160px] w-full justify-center' onClick={handleShow} />
                    <ButtonPrimary text={isCheck ? '선택 취소' : '확인'} className='max-w-[160px] w-full justify-center' onClick={() => handleChoose(productId)} />
                </div>
            </div>
        </div>
    )
}
