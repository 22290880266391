import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkPaymentOrderHandmake } from "../reduxs/actions/order.action";
import { useParams } from "react-router-dom";

export const UploadLetterHmContext = createContext()

export default function UploadLetterHmProvider({ children }) {
    const dispatch = useDispatch()

    const [stepUpload, setStepUpload] = useState(1)
    const [formUpload, setFormUpload] = useState({})

    const { dataCheckOrderHandmake } = useSelector(state => state.order)

    const { methodCheckout } = useSelector(state => state.orderLetter)

    const { letterId } = useParams()

    const handleStepUpload = (method) => {
        switch (method) {
            case 'next':
                return setStepUpload(pre => pre + 1)

            default:
                if (methodCheckout === 1) return false
                return setStepUpload(pre => pre - 1)
        }
    }

    useEffect(() => {
        if (methodCheckout) {
            setStepUpload(methodCheckout === 1 ? 2 : 1)
        }
    }, [methodCheckout])

    useEffect(() => {
        if (stepUpload === 2) {
            dispatch(checkPaymentOrderHandmake(letterId))
        }
    }, [stepUpload, letterId])

    useEffect(() => {
        if (dataCheckOrderHandmake) {
            setFormUpload({
                ...formUpload,
                product_id: letterId,
                purchase_type_id: methodCheckout,
                ship_fee: dataCheckOrderHandmake.ship_fee,
                product_total: dataCheckOrderHandmake.product_total,
                money_total: dataCheckOrderHandmake.money_total,
            })
        }
    }, [dataCheckOrderHandmake])

    return (
        <UploadLetterHmContext.Provider value={{
            stepUpload,
            handleStepUpload,
            formUpload,
            setFormUpload
        }}>
            {children}
        </UploadLetterHmContext.Provider>
    )
}