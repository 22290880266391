import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import circle1 from '../../assets/images/circle1.png'
import circle2 from '../../assets/images/circle2.png'
import success from '../../assets/images/success.png'
import { useDispatch, useSelector } from "react-redux";
import { addEventOrderVisapay } from "../../reduxs/actions/order.action";
import { deleteSaveDataUpload } from "../../reduxs/reducers/order-letter.reducer";

export function SuccessPageEvent() {
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { saveDataUpload } = useSelector(state => state.orderLetter)

    useEffect(() => {
        const requestData = {
            orderId: searchParams.get("orderId"),
            amount: searchParams.get("amount"),
            paymentKey: searchParams.get("paymentKey"),
            ...saveDataUpload
        }

        async function confirm() {
            const response = await dispatch(addEventOrderVisapay({ data: JSON.stringify(requestData) }))

            const res = response.payload

            if (!res.status) {
                navigate(`/fail?message=${res.message}&code=${res.code}`)
                return;
            }

            dispatch(deleteSaveDataUpload())

            setTimeout(() => {
                navigate('/my-page')
            }, 3000);
        }

        confirm()
    }, []);

    return (
        <div className="result wrapper">
            <div className="relative w-full h-screen">
                <img src={circle2} alt="" className="absolute top-[10%] left-[25%] w-[550px] aspect-square" />
                <img src={circle1} alt="" className="absolute bottom-[5%] right-[25%] w-[720px] aspect-square" />
            </div>

            <div className="box_section text-logoColor fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white w-[600px] aspect-[6/4] flex flex-col gap-2.5 items-center justify-center p-10 rounded-20 shadow-payment">
                <img src={success} alt="success payment" className="w-[100px]" />

                <p className="text-24-30">결제 성공</p>
            </div>
        </div>
    );
}