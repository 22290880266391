import React, { useEffect } from 'react'
import TitlePage from '../../components/user/TitlePage'
import SaveAutoSection from '../../sections/user/SavedPage/SaveAutoSection'
import SaveManuallySection from '../../sections/user/SavedPage/SaveManuallySection'
import { useDispatch, useSelector } from 'react-redux'
import { getDeleteLetter, getSavedLetter } from '../../reduxs/actions/product.action'
import LoadingUser from './LoadingUser'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

export default function SavedPage() {
    const dispatch = useDispatch()

    const { letter } = useParams()

    const [tab, setTab] = useState(1)

    const { dataSavedLetter, loadingGetSavedLetter } = useSelector(state => state.product)

    const handleChangeTab = (tabId) => {
        setTab(tabId)
    }

    useEffect(() => {
        if (tab === 1) {
            dispatch(getSavedLetter(letter))
        }
        if (tab === 2) {
            dispatch(getDeleteLetter(letter))
        }
    }, [letter, tab])

    if (loadingGetSavedLetter || !dataSavedLetter) {
        return <LoadingUser />
    }

    const { autoSaveLetter, manualSaveLetter } = dataSavedLetter

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-5 container'>
            <TitlePage title='저장된 편지지' />

            <div className='flex justify-center gap-y-4 font-semibold flex-wrap'>
                <button onClick={() => handleChangeTab(1)} className={`lg:px-6 px-2 max-lg:text-14_20 ${tab === 1 ? 'text-logoColor' : ''}`}>저장된 편지지</button>
                <span>|</span>
                <button onClick={() => handleChangeTab(2)} className={`lg:px-6 px-2 max-lg:text-14_20 ${tab === 2 ? 'text-logoColor' : ''}`}>삭제된 편지지</button>
            </div>

            <article className='grid gap-5'>
                <SaveAutoSection data={autoSaveLetter} />
                <SaveManuallySection data={manualSaveLetter} />
            </article>
        </main>
    )
}
