import React, { memo, useEffect, useRef, useState } from 'react'

import 'react-quill/dist/quill.snow.css';

import ToolbarQuill from './ToolbarQuill';
import { URL_SERVER } from '../../utils/apis';
import ReactQuill from 'react-quill';
import { Trash } from 'iconsax-react';
import ButtonPrimary from '../ButtonPrimary';
import Delta from 'quill-delta';

const MAX_LINES = 20;

const LetterQuill = ({ id, editorState, setEditorState, endThumbnail, page, deleteEditor, widthElm, number_character }) => {
    const quillRef = useRef(null)
    const viewRef = useRef()

    const numberPageRef = useRef()
    const settingTextRef = useRef()

    const [isLimitReached, setIsLimitReached] = useState(false)
    const [isLimitCharact, setIsLimitCharact] = useState(false);

    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

    // const [fontSize, setFontSize] = useState();
    const [fontFamily, setFontFamily] = useState('온리캐모마일');
    const [fontFamilyEn, setFontFamilyEn] = useState('Chamomile');
    const [color, setColor] = useState();
    const [onBold, setOnBold] = useState(false);

    // font size
    // const handleFontSizeChange = (value) => {
    //     setFontSize(value.name);
    //     const quill = quillRef.current.getEditor();
    //     quill.format('size', value.value || false)
    // };

    const handleBoldChange = () => {
        setOnBold(!onBold);
        const quill = quillRef.current.getEditor()
        quill.format('bold', !onBold)
    }

    // font family
    const handleFontFamilyChange = (value) => {
        setFontFamily(value.name);
        setFontFamilyEn(value.value);
        const quill = quillRef.current.getEditor()
        quill.format('font', value.value || false)
    };

    // color
    const handleColorChange = (value) => {
        setColor(value.name);
        const quill = quillRef.current.getEditor()
        quill.format('color', value.value || false); // Chỉ áp dụng khi có giá trị
    };

    // handle change
    const onKeyDownCapture = (e) => {
        const numberOfLines = getNumberOfLines();

        if ((isLimitReached || numberOfLines > 20) && e.key !== 'Backspace' && e.key !== 'Delete') {
            setIsLimitReached(true)
            return e.preventDefault()
        }

        if ((numberOfLines > MAX_LINES) && (e.key === 'Enter')) {
            e.preventDefault()
            setIsLimitReached(true);
        }
    }

    // get number line current
    const getNumberOfLines = () => {
        const editor = quillRef.current.getEditor()
        const editorElement = editor.root;

        const paragraphs = editorElement?.querySelectorAll(':scope > *')

        const totalHeight = Array.from(paragraphs).reduce((total, p) => {
            return total + p.offsetHeight;
        }, 0);

        const numberOfLines = Math.floor(totalHeight / 45)

        return numberOfLines;
    };

    // Áp dụng style mặc định
    const applyDefaultStyles = () => {
        const quill = quillRef.current?.getEditor()

        const currentColor = color.toLowerCase()

        quill.format("size", `20px`)
        quill.format("font", `${fontFamilyEn}`)
        quill.format("color", `${currentColor}`)
        quill.format("bold", onBold)
    };

    // handle change 
    const updateEditor = (content, delta, source, editor) => {
        // if (source !== "user") return 

        const quill = quillRef.current?.getEditor();
        if (quill) {
            const selection = quill.getSelection();

            if (selection) {
                const [block] = quill.getLeaf(selection.index);
                const blockText = block?.text || "";

                // Kiểm tra nếu Backspace và dòng hiện tại trống
                const isBackspace =
                    delta.ops.some((op) => op.delete) &&
                    blockText.trim() === ""; // Kiểm tra nếu dòng trống

                if (isBackspace) {
                    applyDefaultStyles();
                }
            }
        }

        const numberCharacterLetter = editor.getLength()

        if (numberCharacterLetter <= number_character) {
            // applyDefaultStyles() 
            setEditorState(id, content)
            return
        }

        setIsLimitCharact(true)
    }

    // set style default
    useEffect(() => {
        const heightNumberPage = numberPageRef.current?.offsetHeight
        const heightSettingText = settingTextRef.current?.offsetHeight

        const adjustContainerSize = () => {
            const scaleFactor = widthElm / 800;
            setContainerSize({
                width: 800 * scaleFactor,
                height: (800 * scaleFactor) * (10 / 7.08) + (heightNumberPage * scaleFactor + heightSettingText * scaleFactor + (widthElm >= 800 ? 40 : (widthElm >= 680 ? 20 : 0)))
            });
        };

        adjustContainerSize();
        window.addEventListener('resize', adjustContainerSize)

        return () => {
            window.removeEventListener('resize', adjustContainerSize)
        };
    }, [widthElm, numberPageRef.current, settingTextRef.current])

    // set default font
    useEffect(() => {
        // Áp dụng font mặc định sau khi Quill được khởi tạo
        const quill = quillRef.current?.getEditor();
        if (quill) {
            quill.format('font', 'Chamomile'); // Font
            quill.format('size', '20px'); // Size
            quill.format('font', 'black'); // Color

            setColor('Black')
            setFontFamily('온리캐모마일')
            // setFontSize('13')
        }
    }, []);

    // on text 
    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                return new Delta(delta.ops.map((op) => {
                    if (typeof op.insert === "string") {
                        return {
                            ...op,
                            attributes: {
                                ...op.attributes,
                                size: `20px`,
                                font: `Chamomile`
                            },
                        };
                    }
                    return op
                }))
            })
        }
    }, [])

    // handle check character
    useEffect(() => {
        const quill = quillRef.current?.getEditor()

        if (quill) {
            const numberLine = quill.getText().split('\n')
            const numberText = quill.getLength

            if (numberText > number_character) {
                quill.deleteText(number_character, numberText - number_character, 'api')
            }

            if (getNumberOfLines() > 20 || numberLine > 20) {
                setIsLimitReached(true)
                quill.deleteText(quill.getLength() - 1, 1)
            }
        }
    }, [editorState])

    const modules = {
        toolbar: {
            container: '#custom-toolbar',
        },
        clipboard: {
            matchers: []
        }
    };

    return (
        <>
            <div className='flex flex-col gap-5 max-w-[800px] w-full' style={{ transform: `scale(${widthElm / 800})`, transformOrigin: 'top left', height: `${containerSize.height}px` }}>
                <div className='flex justify-between items-center' ref={numberPageRef}>
                    <div className='flex gap-10'>
                        <p>{page}페이지</p>
                        <p>{quillRef.current && getNumberOfLines()}/20라인</p>
                    </div>

                    {
                        page !== 1 && <button type='button' className='flex items-center gap-2 hover:text-logoColor duration-300' onClick={deleteEditor}>삭제 <Trash size={16} /></button>
                    }
                </div>

                <div className='shadow-shadow rounded-t-20' ref={viewRef}>
                    <ToolbarQuill
                        // currentFontsize={fontSize} // font size 
                        // onChangeFontsize={handleFontSizeChange}
                        currentFontFamily={fontFamily} // font family
                        onChangeFontFamily={handleFontFamilyChange}
                        currentColor={color}   /// color
                        onChangeColor={handleColorChange}
                        ref={settingTextRef}
                        onBold={handleBoldChange}
                        currentBold={onBold}
                    />

                    <div
                        onKeyDownCapture={onKeyDownCapture}
                        className='w-[800px] h-[1130px] pt-[115px] px-[65px] pb-[120px] bg-cover bg-center bg-no-repeat flex justify-start items-start'
                        style={{ backgroundImage: `url("${URL_SERVER}${endThumbnail.filename}")` }}
                    >
                        <ReactQuill
                            ref={quillRef}
                            onChange={updateEditor}
                            value={editorState}
                            modules={modules}
                            onKeyDown={onKeyDownCapture}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>
                </div>
            </div>

            {
                isLimitReached &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5 mx-5 max-w-[600px] w-full'>
                        <h3 className='lg:text-24-30 font-semibold'>알림</h3>

                        <p className='max-lg:text-14_20'>20줄을 초과했습니다.</p>

                        <ButtonPrimary text='확인' onClick={() => setIsLimitReached(false)} className='w-[120px]' />
                    </div>
                </div>
            }

            {
                isLimitCharact &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5 mx-5 max-w-[600px] w-full'>
                        <h3 className='lg:text-24-30 font-semibold'>알림</h3>

                        <p className='max-lg:text-14_20'>허용된 문자 수를 초과했습니다.</p>

                        <ButtonPrimary text='확인' onClick={() => setIsLimitCharact(false)} className='w-[120px]' />
                    </div>
                </div>
            }
        </>
    )
}

export default memo(LetterQuill)
