import { useFormik } from 'formik'
import { Edit } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useBirthdayFormik, usePhoneNumberFormik } from '../../../hooks/formatNumber'
import * as Yup from 'yup'
import { REGEX_BIRTHDAY_NUMBER, REGEX_PASWORD, REGEX_PHONE_NUMBER } from '../../../utils/regex'
import { ERROR_PASSWORD_CONFIRM, ERROR_PASWORD_FORMAT, ERROR_PHONE_FORMAT, ERROR_REQUIRED } from '../../../utils/error'
import { useDispatch, useSelector } from 'react-redux'
import { getInforUserUpdate, updateInforUser } from '../../../reduxs/actions/auth.action'
import moment from 'moment/moment'
import PopupShowWarningUser from '../../../components/user/PopupShowWarningUser'

export default function EditInforAccount() {
    const dispatch = useDispatch()

    const { dataInforUserUpdate } = useSelector(state => state.auth)

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isNotifycation, setIsNotifycation] = useState(false)

    const handleShowPopup = () => {
        // if (isShowPopup) formik.resetForm()
        setIsShowPopup(!isShowPopup)
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            name: '',
            phone_number: '',
            dob: '',
            address: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRED),
            // address: Yup.string().required(ERROR_REQUIRED),
            phone_number: Yup.string().matches(REGEX_PHONE_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED),
            dob: Yup.string().matches(REGEX_BIRTHDAY_NUMBER, ERROR_PHONE_FORMAT),
            password: Yup.string().matches(REGEX_PASWORD, ERROR_PASWORD_FORMAT),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], ERROR_PASSWORD_CONFIRM)
                .when('password', {
                    is: value => !!value,
                    then: schema => schema.required(ERROR_REQUIRED)
                }),
        }),
        onSubmit: async values => {
            const result = await dispatch(updateInforUser(values))

            const res = result.payload

            if (res.status) {
                setIsNotifycation('정보가 업데이트되었습니다.')

                setTimeout(() => {
                    setIsNotifycation()
                }, 3000);

                handleShowPopup()
            } else {
                if (res.error === 'e_phone') formik.setFieldError('phone_number', '전화번호가 존재했습니다')
            }
        }
    })

    const [phoneNumber, handlePhoneNumberChange] = usePhoneNumberFormik(formik, 'phone_number')
    const [dob, handleDob] = useBirthdayFormik(formik, 'dob')

    useEffect(() => {
        dispatch(getInforUserUpdate())
    }, [])

    useEffect(() => {
        if (dataInforUserUpdate) {
            formik.setValues({
                ...formik.values,
                name: dataInforUserUpdate.name,
                address: dataInforUserUpdate.address,
                phone_number: dataInforUserUpdate.phone_number,
                dob: moment(dataInforUserUpdate.dob).format('YYYY-MM-DD')
            })
        }
    }, [dataInforUserUpdate])

    return (
        <>
            <button onClick={handleShowPopup}><Edit size={24} /></button>

            {
                isShowPopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50 text-black'>
                    <form onSubmit={formik.handleSubmit} className='p-5 rounded-20 bg-white max-w-[600px] w-full aspect-square overscroll-y-auto hiddenScroll flex flex-col items-center gap-5' >
                        <h3 className='text-24-30 font-semibold'>정보 편집</h3>

                        <div className='w-full'>
                            <LabelFormThin label='이름' />
                            <InputFormThin
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                placehoder='이름을 입력 하세요'
                                indexTab={1}
                                error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                            />
                        </div>

                        {/* <div className='w-full'>
                            <LabelFormThin label='주소' />
                            <InputFormThin
                                name='address'
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                placehoder='가격을 입력하세요'
                                indexTab={2}
                                error={(formik.touched.address && formik.errors.address) && formik.errors.address}
                            />
                        </div> */}

                        <div className='w-full'>
                            <LabelFormThin label='휴대폰번호(필수)' />
                            <InputFormThin
                                name='phone_number'
                                onChange={handlePhoneNumberChange}
                                value={phoneNumber || formik.values.phone_number}
                                placehoder='숫자만입력 (예: 01012345678)'
                                indexTab={3}
                                error={(formik.touched.phone_number && formik.errors.phone_number) && formik.errors.phone_number}
                            />
                        </div>

                        <div className='w-full'>
                            <LabelFormThin label='생년월일(선택)' />
                            <InputFormThin
                                name='dob'
                                onChange={handleDob}
                                value={dob || formik.values.dob}
                                placehoder='예) 19901225'
                                indexTab={4}
                                error={(formik.touched.dob && formik.errors.dob) && formik.errors.dob}
                            />
                        </div>

                        <div className='w-full'>
                            <LabelFormThin label='비밀번호' />
                            <InputFormThin
                                type='password'
                                name='password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                placehoder='영문+숫자 6~20자리'
                                indexTab={5}
                                error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                            />
                        </div>

                        <div className='w-full'>
                            <LabelFormThin label='비밀번호 확인' />
                            <InputFormThin
                                type='password'
                                name='confirmPassword'
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                placehoder='비밀번호 확인을 위해 재입력'
                                indexTab={6}
                                error={(formik.touched.confirmPassword && formik.errors.confirmPassword) && formik.errors.confirmPassword}
                            />
                        </div>

                        <div className='flex gap-2.5'>
                            <PopupShowWarningUser />
                            <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }

            <div className={`${isNotifycation ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotifycation}
            </div>
        </>
    )
}
