import React from 'react'
import Dropdown from '../../../components/admin/Dropdown'
import InputFormThin from '../../../components/InputFormThin'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { filterByAccount } from '../../../utils/filterArray'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { searchUserBlacklist } from '../../../reduxs/actions/user.action'

export default function FilterBlacklist() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const search = searchParams.get('search') || ''
    const option = searchParams.get('option') || ''

    const handleChooseCondition = (optionId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, option: optionId })
    }

    const handleSearch = () => {
        const dataSearch = { page: 1, option, search }

        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, page: 1 })

        dispatch(searchUserBlacklist(dataSearch))
    }

    const handleChangeInput = (e) => {
        const value = e.target.value

        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, search: value })
    }

    return (
        <div className='flex gap-5 items-center'>
            <Dropdown
                data={filterByAccount}
                title='검색'
                className='w-[160px] justify-between'
                name='name'
                handleChoose={handleChooseCondition}
                isChoose={Number(option)}
            />

            <div className='flex gap-2 h-full flex-grow max-w-[396px] w-full'>
                <InputFormThin
                    placehoder='검색어를 입력하세요'
                    classNameAll='w-full h-full'
                    className='h-full'
                    onChange={handleChangeInput}
                    value={search}
                />

                <ButtonPrimary text='검색' className='flex-shrink-0' onClick={handleSearch} />
            </div>
        </div>
    )
}
