import { ArrowRight2 } from 'iconsax-react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function TitleSection({ title, more, small = false, className, mt = true }) {
    return (
        <div className={`${mt && 'lg:mb-5 mb-4'} flex justify-between items-center`}>
            <h2 className={`${small ? 'max-lg:text-14_20' : 'lg:text-24-30 text-14_20'} font-semibold ${className}`}>{title}</h2>

            {
                more
                &&
                <Link to={more} className='flex items-center gap-2 max-lg:text-14_20'>더 보기 <ArrowRight2 size={16} /></Link>
            }
        </div>
    )
}
