import React from 'react'

export default function ToggleStatus({ status, onClick }) {
    return (
        <button onClick={onClick} className={`rounded-8 ${status ? 'bg-blue' : 'bg-logoColor'} py-1.5 px-3 text-white text-12 duration-300 w-[65px]`}>
            {
                status ? '노출' : '노출됨'
            }
        </button>
    )
}
