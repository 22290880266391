import React from 'react'
import { Link } from 'react-router-dom'
import { URL_SERVER } from '../../utils/apis'
import { formatMoney } from '../../utils/renderText'

export default function LettersItem({ id, link = '/products', frontThumbnail, name, price, sold_out }) {
    return (
        <div className='flex flex-col lg:gap-4 gap-2.5 w-full'>
            <Link to={`${link}/${id}`} className='relative'>
                {
                    sold_out
                        ?
                        <div className='absolute inset-0 bg-popup flex justify-center items-center lg:rounded-20 rounded-12'>
                            <p className='text-24-30 font-bold text-white'>재고 없음</p>
                        </div>
                        : ''
                }
                <img loading='lazy' src={`${URL_SERVER}${frontThumbnail}`} alt='letter' className='w-full lg:rounded-20 rounded-12 object-cover aspect-[7.08/10]' />
            </Link>

            <div className='flex flex-col gap-1 items-center'>
                <Link to={`${link}/${id}`} className='font-semibold hover:text-logoColor max-lg:line-clamp-1 duration-300 max-lg:text-14_20 text-center'>{name}</Link>
                <p className='font-bold text-logoColor max-lg:text-14_20'>₩{formatMoney(price)}</p>
            </div>
        </div>
    )
}
