import React, { useCallback, useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import EvaluateItem from '../../../components/user/EvaluateItem'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react'

export default function BestCommentSection({ dataComment }) {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <section className='flex flex-col w-full'>
            <TitleSection title='Best 리뷰어⭐' />

            <div className='border border-strock rounded-20 p-5 flex-grow relative w-full'>
                <Swiper
                    className='h-full'
                    ref={sliderRef}
                    spaceBetween={10}
                >
                    {
                        dataComment.map(item =>
                            <SwiperSlide key={item.id}>
                                <EvaluateItem content={item.content} rating={item.mark} frontThumbnail={item.frontThumbnail} />
                            </SwiperSlide>
                        )
                    }
                </Swiper>

                {
                    dataComment.length > 1
                    &&
                    <>
                        <div className='flex justify-center lg:-translate-y-8 translate-y-3 gap-2.5 relative z-10'>
                            <button
                                className="rounded-full flex justify-center items-center text-24-30 icon14"
                                onClick={handlePrev}
                            >
                                <ArrowLeft2 color='#404040' />
                            </button>

                            <button
                                className="rounded-full flex justify-center items-center text-24-30 icon14"
                                onClick={handleNext}
                            >
                                <ArrowRight2 color='#404040' />
                            </button>
                        </div>
                    </>
                }
            </div>
        </section>
    )
}
