import React, { useEffect, useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import UpdateImages from '../../sections/admin/createproduct/UpdateImages'
import UpdateInfoProduct from '../../sections/admin/createproduct/UpdateInfoProduct'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailPostOfficeUpdate, updateDetailPostOfficeUpdate } from '../../reduxs/actions/admin.action'
import LoadingUser from '../user/LoadingUser'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'

export default function EditProductAdmin() {
    const dispatch = useDispatch()

    const { id } = useParams()

    const { loadingGetDetailPostOfficeUpdate, dataDetailPostOfficeUpdate } = useSelector(state => state.admin)

    const [isNotify, setIsNotify] = useState(false)

    const formik = useFormik({
        initialValues: {
            frontThumbnail: '',
            endThumbnail: '',
            normals: [],
            descriptions: [],
            name: '',
            basic_provision: '',
            addition_offer: '',
            number_line: 20,
            number_character: '',
            price: '',
            discount: 0,
            quantity: '',
            category_id: '',
            delete_picture: [],
        },
        validationSchema: Yup.object({
            frontThumbnail: Yup.object().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            basic_provision: Yup.string().required(ERROR_REQUIRED),
            addition_offer: Yup.string().required(ERROR_REQUIRED),
            number_line: Yup.string().required(ERROR_REQUIRED),
            number_character: Yup.string().required(ERROR_REQUIRED),
            price: Yup.string().required(ERROR_REQUIRED),
            discount: Yup.string().required(ERROR_REQUIRED),
            quantity: Yup.string().required(ERROR_REQUIRED),
            category_id: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('frontThumbnail', values.frontThumbnail.filename ? '' : values.frontThumbnail.currentFile)
            formData.append('endThumbnail', values.endThumbnail.filename ? '' : values.endThumbnail.currentFile)

            for (let i = 0; i < values.delete_picture.length; i++) {
                const item = values.delete_picture[i];

                formData.append(`delete_picture[${i}][type]`, item.type)
                formData.append(`delete_picture[${i}][picture_name]`, item.filename)
            }

            for (let i = 0; i < values.descriptions.length; i++) {
                const item = values.descriptions[i];

                if (item.file) {
                    formData.append(`descriptions`, item.currentFile)
                }
            }

            for (let i = 0; i < values.normals.length; i++) {
                const item = values.normals[i];

                if (item.file) {
                    formData.append(`normals`, item.currentFile)
                }
            }

            formData.append('name', values.name)
            formData.append('basic_provision', values.basic_provision)
            formData.append('addition_offer', values.addition_offer)
            formData.append('number_line', values.number_line)
            formData.append('number_character', values.number_character)
            formData.append('price', values.price)
            formData.append('discount', values.discount)
            formData.append('quantity', values.quantity)
            formData.append('category_id', values.category_id)

            const result = await dispatch(updateDetailPostOfficeUpdate({ formData, productId: id }))
            const res = result.payload

            if (res.status) {
                setIsNotify('성공적으로 저장되었습니다')
            } else {
                setIsNotify(res.message)
            }

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }
    })

    useEffect(() => {
        dispatch(getDetailPostOfficeUpdate(id))
    }, [id])

    useEffect(() => {
        if (dataDetailPostOfficeUpdate) {
            formik.setValues({
                ...formik.values,
                name: dataDetailPostOfficeUpdate.name,
                quantity: dataDetailPostOfficeUpdate.quantity,
                price: dataDetailPostOfficeUpdate.price,
                discount: dataDetailPostOfficeUpdate.discount,
                basic_provision: dataDetailPostOfficeUpdate.basic_provision,
                addition_offer: dataDetailPostOfficeUpdate.addition_offer,
                number_line: dataDetailPostOfficeUpdate.number_line,
                number_character: dataDetailPostOfficeUpdate.number_character,
                category_id: dataDetailPostOfficeUpdate.category_id,
                descriptions: dataDetailPostOfficeUpdate.descriptions,
                frontThumbnail: dataDetailPostOfficeUpdate.frontThumbnail,
                endThumbnail: dataDetailPostOfficeUpdate.endThumbnail,
                normals: dataDetailPostOfficeUpdate.normals,
            })
        }
    }, [dataDetailPostOfficeUpdate])

    if (loadingGetDetailPostOfficeUpdate || !dataDetailPostOfficeUpdate) {
        return <LoadingUser />
    }

    return (
        <>
            <div className='flex justify-between'>
                <TitleSection title='수정' mt={false} />
                <ButtonPrimary text='저장' onClick={formik.handleSubmit} />
            </div>

            <form className='flex gap-5' onSubmit={formik.handleSubmit}>
                <UpdateImages
                    formik={formik}
                    frontImage={formik.values.frontThumbnail}
                    endImage={formik.values.endThumbnail}
                    normalImage={formik.values.normals}
                />
                <UpdateInfoProduct formik={formik} descriptionImage={formik.values.descriptions} />
            </form>

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}
