import { Add } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import TitleSection from '../user/TitleSection'
import LabelFormThin from '../LabelFormThin'
import InputFormThin from '../InputFormThin'
import { useFormik } from 'formik'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecon from '../ButtonSecon'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { addOrderLetter } from '../../reduxs/reducers/order-letter.reducer'

export default function PopupAddOrderLetter() {
    const dispatch = useDispatch()

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            price: '',
            quantity: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRED),
            price: Yup.string().required(ERROR_REQUIRED),
            quantity: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: values => {
            dispatch(addOrderLetter(values))

            formik.resetForm()
        }
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div>
            <button type='button' onClick={handleShow} className='flex items-center gap-1'><Add size={20} /> 만들기</button>

            <div className={`${isShow ? 'bg-popup' : 'invisible'} duration-300 z-50 fixed inset-0 flex justify-center items-center`}>
                <form
                    ref={contentRef}
                    className={`w-popup bg-white p-5 rounded-20 grid place-items-center gap-5 duration-100 ${isShow ? '' : 'invisible'}`}
                    onSubmit={formik.handleSubmit}
                >
                    <TitleSection title='만들기' mt={false} />

                    <div className='w-full grid gap-5'>
                        <div>
                            <LabelFormThin label='상품명' />
                            <InputFormThin
                                name='name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                placehoder='상품명을 입력하세요'
                                error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='가격' />
                            <InputFormThin
                                name='price'
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                placehoder='가격을 입력하세요'
                                error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='수량' />
                            <InputFormThin
                                name='quantity'
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.quantity}
                                placehoder='수량을 입력하세요'
                                error={(formik.touched.quantity && formik.errors.quantity) && formik.errors.quantity}
                            />
                        </div>
                    </div>

                    <div className='grid grid-cols-2 gap-2.5 w-full'>
                        <ButtonSecon title='취소' className='w-full' onClick={handleShow} />
                        <ButtonPrimary text='확인' className='w-full !duration-0' type='submit' onClick={formik.handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    )
}
