import { createSlice } from "@reduxjs/toolkit";
import { changeStatusOrder, downloadPostOfficeOrder, getDashboardAdmin, getDashboardOrder, getDetailPostOffice, getDetailPostOfficeUpdate, getEventOrder, getHandmakeOrder, getPdfOrder, getPostOfficeOrder, getStatusOrder, getStatusOrderVoucher, searchPostOffice, updateDetailPostOfficeUpdate, updateDetailProductEvent, updateDetailProductHandmake, updateShipmentCode } from "../actions/admin.action";
import { deleteOrderAdmin } from "../actions/order.action";

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        loadingGetDashboard: false,
        dataDashboard: undefined,

        loadingGetDashboardOrder: false,
        dataDashboardOrder: undefined,
        orderCount: 0,

        loadingGetPostOffice: false,
        dataPostOffice: undefined,
        postOfficeCount: 0,

        loadingGetDetailPostOffice: false,
        dataDetailPostOffice: undefined,

        loadingSearchPostOffice: false,

        loadingGetStatusOrder: false,
        dataStatusOrder: undefined,

        loadingChangeStatusOrder: false,

        loadingGetDetailPostOfficeUpdate: false,
        dataDetailPostOfficeUpdate: undefined,

        loadingGetStatusOrderVoucher: false,
        dataStatusOrderVoucher: undefined,

        loadingUpdateProduct: false,

        loadingDeleteOrder: false,

        loadingDownloadPostOffice: false
    },
    extraReducers: builder => {
        // get dashboard admin
        builder.addCase(getDashboardAdmin.pending, (state, action) => {
            state.loadingGetDashboard = true
        })
        builder.addCase(getDashboardAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDashboard = res.data
            }

            state.loadingGetDashboard = false
        })

        // get status order
        builder.addCase(getStatusOrder.pending, (state, action) => {
            state.loadingGetStatusOrder = true
        })
        builder.addCase(getStatusOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataStatusOrder = res.data
            }

            state.loadingGetStatusOrder = false
        })

        // get status order voucher
        builder.addCase(getStatusOrderVoucher.pending, (state, action) => {
            state.loadingGetStatusOrderVoucher = true
        })
        builder.addCase(getStatusOrderVoucher.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataStatusOrderVoucher = res.data
            }

            state.loadingGetStatusOrderVoucher = false
        })

        // get dashboard order
        builder.addCase(getDashboardOrder.pending, (state, action) => {
            state.loadingGetDashboardOrder = true
        })
        builder.addCase(getDashboardOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDashboardOrder = res.data.orders
                state.orderCount = res.data.orderCount
            }

            state.loadingGetDashboardOrder = false
        })

        // get post office order
        builder.addCase(getPostOfficeOrder.pending, (state, action) => {
            state.loadingGetPostOffice = true
        })
        builder.addCase(getPostOfficeOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPostOffice = res.data.orders
                state.postOfficeCount = res.data.orderCount
            }

            state.loadingGetPostOffice = false
        })

        // download letter post office order
        builder.addCase(downloadPostOfficeOrder.pending, (state, action) => {
            state.loadingDownloadPostOffice = true
        })
        builder.addCase(downloadPostOfficeOrder.fulfilled, (state, action) => {
            state.loadingDownloadPostOffice = false
        })

        // get pdf order
        builder.addCase(getPdfOrder.pending, (state, action) => {
            state.loadingGetPostOffice = true
        })
        builder.addCase(getPdfOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPostOffice = res.data.orders
                state.postOfficeCount = res.data.orderCount
            }

            state.loadingGetPostOffice = false
        })

        // get event order
        builder.addCase(getEventOrder.pending, (state, action) => {
            state.loadingGetPostOffice = true
        })
        builder.addCase(getEventOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPostOffice = res.data.orders
                state.postOfficeCount = res.data.orderCount
            }

            state.loadingGetPostOffice = false
        })

        // get handmake order
        builder.addCase(getHandmakeOrder.pending, (state, action) => {
            state.loadingGetPostOffice = true
        })
        builder.addCase(getHandmakeOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPostOffice = res.data.orders
                state.postOfficeCount = res.data.orderCount
            }

            state.loadingGetPostOffice = false
        })


        // delete order admin
        builder.addCase(deleteOrderAdmin.pending, (state, action) => {
            state.loadingDeleteOrder = true
        })
        builder.addCase(deleteOrderAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataPostOffice.filter(item => item.order_id !== Number(res.data.order_id))

                if (res.data.replaceOrder) {
                    state.dataPostOffice = [
                        ...updateData,
                        res.data.replaceOrder
                    ]

                    return
                }

                state.dataPostOffice = updateData
            }

            state.postOfficeCount -= 1
            state.loadingDeleteOrder = false
        })

        // update shipment code
        builder.addCase(updateShipmentCode.pending, (state, action) => {
            state.loadingUpdateShipmentCode = true
        })
        builder.addCase(updateShipmentCode.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = {
                    ...state.dataDetailPostOffice,
                    shipment_code: res.data
                }

                state.dataDetailPostOffice = updateData
            }

            state.loadingUpdateShipmentCode = false
        })

        // get detail order
        builder.addCase(getDetailPostOffice.pending, (state, action) => {
            state.loadingGetDetailPostOffice = true
        })
        builder.addCase(getDetailPostOffice.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDetailPostOffice = res.data
            }

            state.loadingGetDetailPostOffice = false
        })

        // get detail order update
        builder.addCase(getDetailPostOfficeUpdate.pending, (state, action) => {
            state.loadingGetDetailPostOfficeUpdate = true
        })
        builder.addCase(getDetailPostOfficeUpdate.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDetailPostOfficeUpdate = res.data
            }

            state.loadingGetDetailPostOfficeUpdate = false
        })

        // update detail order update
        builder.addCase(updateDetailPostOfficeUpdate.pending, (state, action) => {
            state.loadingUpdateProduct = true
        })
        builder.addCase(updateDetailPostOfficeUpdate.fulfilled, (state, action) => {
            state.loadingUpdateProduct = false
        })

        // update detail product event
        builder.addCase(updateDetailProductEvent.pending, (state, action) => {
            state.loadingUpdateProduct = true
        })
        builder.addCase(updateDetailProductEvent.fulfilled, (state, action) => {
            state.loadingUpdateProduct = false
        })

        // update detail product event
        builder.addCase(updateDetailProductHandmake.pending, (state, action) => {
            state.loadingUpdateProduct = true
        })
        builder.addCase(updateDetailProductHandmake.fulfilled, (state, action) => {
            state.loadingUpdateProduct = false
        })

        // search post office order
        builder.addCase(searchPostOffice.pending, (state, action) => {
            state.loadingSearchPostOffice = true
        })
        builder.addCase(searchPostOffice.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPostOffice = res.data.orders
                state.postOfficeCount = res.data.orderCount
            }

            state.loadingSearchPostOffice = false
        })

        // change status order
        builder.addCase(changeStatusOrder.pending, (state, action) => {
            state.loadingChangeStatusOrder = true
        })
        builder.addCase(changeStatusOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataPostOffice.map(item => {
                    if (item.order_id === Number(res.data.order_id)) {
                        return {
                            ...item,
                            order_status_id: res.data.status_id
                        }
                    }

                    return item
                })

                state.dataPostOffice = updateData
            }

            state.loadingChangeStatusOrder = false
        })
    }
})

export default adminSlice.reducer