import React, { forwardRef } from 'react'
import { DropdownQuill } from './DropdownQuill'
import { Quill } from 'react-quill';

const FontSizeStyle = Quill.import('attributors/style/size');
FontSizeStyle.whitelist = ['13px', '14px', '15px', '16px', '20px'];
Quill.register(FontSizeStyle, true);

const FontFamilyStyle = Quill.import('attributors/style/font');
FontFamilyStyle.whitelist = ['Gowunbatang', 'Handwriting', 'Ongeulip', 'Chamomile'];
Quill.register(FontFamilyStyle, true);

const ColorClass = Quill.import('attributors/style/color');
ColorClass.whitelist = ['red', 'green', 'blue', 'yellow', 'black'];
Quill.register(ColorClass, true);

// export const ListFont = [
//     { id: 1, name: '13', value: '13px' },
//     { id: 3, name: '14', value: '14px' },
//     { id: 2, name: '15', value: '15px' },
//     { id: 4, name: '16', value: '16px' },
// ];

export const ListFontFamily = [
    // { id: 0, name: 'Roboto', value: 'Roboto' },
    // { id: 0, name: '바탕', value: 'Gowunbatang' },
    { id: 2, name: '나눔손글씨 곰신체', value: 'Handwriting' },
    { id: 3, name: '온글잎 언즈체', value: 'Ongeulip' },
    { id: 4, name: '온리캐모마일', value: 'Chamomile' },
];

export const ListColors = [
    { id: 1, name: 'Red', value: 'red' },
    { id: 2, name: 'Green', value: 'green' },
    { id: 3, name: 'Blue', value: 'blue' },
    { id: 4, name: 'Yellow', value: 'yellow' },
    { id: 5, name: 'Black', value: 'black' },
];

const ToolbarQuill = forwardRef((props, ref) => {
    const {
        // currentFontsize,
        // onChangeFontsize,
        currentFontFamily,
        onChangeFontFamily,
        currentColor,
        onChangeColor,
        currentBold,
        onBold
    } = props

    return (
        <div id="custom-toolbar" className='flex gap-2 items-stretch !p-5 justify-between max-w-[800px] after:!hidden rounded-t-12' ref={ref}>
            <DropdownQuill
                data={ListFontFamily}
                onChange={onChangeFontFamily}
                name='name'
                isChoose={currentFontFamily}
                title='Font family'
                style='font'
            />

            {/* <div className='border border-strock grid grid-cols-4 rounded-12 h-full overflow-hidden'>
                {
                    ListFont.map(item =>
                        <button
                            key={item.id}
                            className={`!py-2.5 !px-3 !float-none !w-auto !h-auto hover:!bg-logoColor hover:!text-white duration-200 ${currentFontsize === item.name ? '!bg-logoColor text-white' : ''}`}
                            onClick={() => onChangeFontsize(item)}
                        >
                            {item.name}
                        </button>
                    )
                }
            </div> */}

            <div className='flex gap-5'>
                <DropdownQuill
                    data={ListColors}
                    onChange={onChangeColor}
                    name='name'
                    isChoose={currentColor}
                    title='Colors'
                />

                <button
                    onClick={onBold}
                    className={`!border-solid !border-[1px] !flex justify-center items-center !aspect-square min-w-[46px] flex-shrink-0 !h-full !p-0 !float-none font-bold !border-strock rounded-12 hover:!text-white hover:!bg-logoColor duration-200 ${currentBold && '!bg-logoColor text-white'}`}
                >
                    B
                </button>
            </div>
        </div>
    )
})

export default ToolbarQuill