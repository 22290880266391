import React, { useContext } from 'react'
import UploadLetterHmProvider, { UploadLetterHmContext } from '../../contexts/uploadLetterHmContext'
import AddressHandmadeStep from '../../sections/user/CheckoutHmLetter/AddressHandmadeStep'
import CheckoutHandmakeStep from '../../sections/user/CheckoutHmLetter/CheckoutHandmakeStep'

export default function CheckoutLetterHandmade() {
    return (
        <UploadLetterHmProvider>
            <CheckoutUploadContent />
        </UploadLetterHmProvider>
    )
}

const CheckoutUploadContent = () => {
    const { stepUpload } = useContext(UploadLetterHmContext)

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col items-center lg:gap-10 gap-6 container max-lg:px-6'>
            {
                stepUpload === 1 && <AddressHandmadeStep />
            }
            {
                stepUpload === 2 && <CheckoutHandmakeStep />
            }
        </main>
    )
}
