import { DocumentCopy } from 'iconsax-react'
import React from 'react'

export default function CopyButton({ value, title }) {
    const handleCopy = () => {
        try {
            navigator.clipboard.writeText(value)

            alert('Copy successfully !!!')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='flex gap-1 text-blue items-start'>
            <p className='max-lg:text-14_20' >{title}</p>
            <button onClick={handleCopy} type='button'><DocumentCopy size={20} /></button>
        </div>
    )
}
