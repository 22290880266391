import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './routers/routers';
import 'vuesax/dist/vuesax.css'
import { Provider } from 'react-redux';
import { persistor, store } from './reduxs/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingUser from './pages/user/LoadingUser';
import { ErrorBoundary } from 'react-error-boundary';
import { fallbackRender } from './utils/error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ErrorBoundary fallbackRender={fallbackRender} >
    <Provider store={store}>
      <PersistGate loading={<LoadingUser />} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
