import React, { useState } from 'react'
import moment from 'moment/moment'
import Pagination from '../../../components/Pagination'
import DropdownTable from '../../../components/admin/DropdownTable'
import { useDispatch } from 'react-redux'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { formatMoney } from '../../../utils/renderText'
import { deleteProductAdmin } from '../../../reduxs/actions/product.action'

export default function ListProduct({ data, countProductAdmin, handlePage, page }) {
    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 min-w-[]'>상품명</th>
                        <th className='py-5 px-2 max-lg:w-[100px]'>타입</th>
                        <th className='py-5 px-2 max-lg:w-[140px]'>카테고리</th>
                        <th className='py-5 px-2 max-lg:w-[100px]'>판매수량</th>
                        <th className='py-5 px-2 max-lg:w-[140px]'>추가된 날짜</th>
                        <th className='py-5 px-2 max-lg:w-[120px]'>가격</th>
                        <th className='py-5 px-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map(item =>
                            <RowProductItem
                                key={item.id}
                                id={item.id}
                                product_name={item.product_name}
                                type_name={item.type_name}
                                category_name={item.category_name}
                                quantity={item.quantity}
                                created_at={item.created_at}
                                price={item.price}
                                page={page}
                            />)
                    }
                </tbody>
            </table>

            <Pagination totalCount={countProductAdmin} currentPage={page} handlePage={handlePage} />
        </section>
    )
}

const RowProductItem = ({ id, product_name, type_name, category_name, quantity, created_at, price, page }) => {
    const dispatch = useDispatch()

    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isNotify, setIsNotify] = useState(false)

    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(deleteProductAdmin({ productId: id, page: page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <p>{product_name}</p>
            </td>
            <td className='py-5'>{type_name}</td>
            <td className='py-5'>{category_name}</td>
            <td className='py-5'>{formatMoney(quantity)}</td>
            <td className='py-5'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5'>₩{formatMoney(price)}원</td>
            <td className='py-5'>
                <DropdownTable onclickDelete={handleShowDelete} linkEdit={`edit/${id}`} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 상품을 삭제하시겠습니까?</p>
                                <p>삭제된 이 상품은 복구할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowDelete} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleConfirmDelete} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}
