import React, { useEffect } from 'react'
import TotalLetter from './TotalLetter'
import TotalFileMasterial from './TotalFileMasterial'
import TotalFilePdf from './TotalFilePdf'
import TotalShipingFee from './TotalShipingFee'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderCheckInfor } from '../../../reduxs/actions/order.action'
import { useSearchParams } from 'react-router-dom'
import LoadingUser from '../../../pages/user/LoadingUser'

export default function CheckingStep({ handleNavigateStep }) {
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    const letterSaved = searchParams.get('savedLetter')
    const letterId = searchParams.get('letter')

    const { loadingCheckInforOrder, dataCheckInforOrder } = useSelector(state => state.order)

    useEffect(() => {
        dispatch(getOrderCheckInfor(letterSaved || letterId))
    }, [letterSaved])

    if (loadingCheckInforOrder || !dataCheckInforOrder) {
        return <LoadingUser />
    }

    const { letterDetail, feeLetter, print_type, materials } = dataCheckInforOrder

    return (
        <article className='max-w-[800px] w-full mx-auto flex flex-col lg:gap-10 gap-6'>
            <TotalLetter
                data={letterDetail.content}
                frontThumbnail={letterDetail.frontThumbnail}
                endThumbnail={letterDetail.endThumbnail}
                price={letterDetail.price}
                feeLetter={feeLetter}
                totalPage={letterDetail.page_total}
            />

            <TotalFileMasterial materials={materials} />
            <TotalFilePdf pdfFiles={print_type} />
            <TotalShipingFee />

            <div className='flex gap-2.5 w-full justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleNavigateStep} />
                <ButtonPrimary text='결제하기' className='w-[160px] justify-center' onClick={() => handleNavigateStep('next')} />
            </div>
        </article>
    )
}
