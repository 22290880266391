import { createSlice } from "@reduxjs/toolkit";
import { addBannerAdmin, deleteBannerAdmin, getBannerAdmin, getBannerHomepage } from "../actions/banner.action";

const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        loadingGetBannerHomePage: false,
        dataBannerHome: undefined,

        loadingGetBannerAdmin: false,
        dataBannerAdmin: undefined,

        loadingDeleteBannerAdmin: false,

        loadingAddBannerAdmin: false,
    },
    extraReducers: builder => {
        // get banner home page
        builder.addCase(getBannerHomepage.pending, (state, action) => {
            state.loadingGetBannerHomePage = true
        })
        builder.addCase(getBannerHomepage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataBannerHome = res.data
            }

            state.loadingGetBannerHomePage = false
        })

        // get banner admin
        builder.addCase(getBannerAdmin.pending, (state, action) => {
            state.loadingGetBannerAdmin = true
        })
        builder.addCase(getBannerAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataBannerAdmin = res.data
            }

            state.loadingGetBannerAdmin = false
        })

        // delete banner admin
        builder.addCase(deleteBannerAdmin.pending, (state, action) => {
            state.loadingDeleteBannerAdmin = true
        })
        builder.addCase(deleteBannerAdmin.fulfilled, (state, action) => {
            state.loadingDeleteBannerAdmin = false
        })

        // add banner admin
        builder.addCase(addBannerAdmin.pending, (state, action) => {
            state.loadingAddBannerAdmin = true
        })
        builder.addCase(addBannerAdmin.fulfilled, (state, action) => {
            state.loadingAddBannerAdmin = false
        })
    }
})

export const { } = bannerSlice.actions
export default bannerSlice.reducer