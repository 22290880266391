import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { URL_SERVER } from '../../../utils/apis'

export default function LetterDetail({ data }) {
    return (
        <section>
            <TitleSection title='편지지 세부 사항' small />

            <div className='grid gap-5'>
                {
                    data.map(item =>
                        <img
                            key={item.id}
                            loading='lazy'
                            src={`${URL_SERVER}${item.filename}`}
                            alt='desPicture'
                            className='aspect-video rounded-20 w-full object-contain'
                        />)
                }
            </div>
        </section>
    )
}
