import { createContext, useState } from "react";

export const UploadPdfContext = createContext()

const UploadPdfProvider = ({ children }) => {
    const [stepUpload, setStepUpload] = useState(1)
    const [formUpload, setFormUpload] = useState({
        recipient_address_id: '',
        pay_method_id: 0,
        coupon_point: 0,
        coupon_percent: 0,
        used_point: 0,
        bonus_point: 0,
        bankcard_number: ''
    })

    const handleNavigateStep = (navigate) => {
        switch (navigate) {
            case 'next':
                return stepUpload <= 5 && setStepUpload(stepUpload + 1)

            default:
                return (stepUpload > 1) && setStepUpload(stepUpload - 1)
        }
    }

    return (
        <UploadPdfContext.Provider
            value={{
                stepUpload,
                handleNavigateStep,
                formUpload,
                setFormUpload
            }}>
            {children}
        </UploadPdfContext.Provider>
    )
}

export default UploadPdfProvider