import React, { useContext } from 'react'
import UploadPdfStep from '../../sections/user/PdfuploadPage/UploadPdfStep'
import UploadPdfProvider, { UploadPdfContext } from '../../contexts/UploadPdfContext'
import CheckPdfFileStep from '../../sections/user/PdfuploadPage/CheckPdfFileStep'
import SelectAdrUserPdfStep from '../../sections/user/PdfuploadPage/SelectAdrUserPdfStep'
import CheckoutPdfStep from '../../sections/user/PdfuploadPage/CheckoutPdfStep'

export default function PdfuploadPage() {
    return (
        <UploadPdfProvider>
            <PdfUploadContent />
        </UploadPdfProvider>
    )
}

const PdfUploadContent = () => {
    const { stepUpload } = useContext(UploadPdfContext)

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col items-center lg:gap-10 gap-6 container max-lg:px-6'>
            {
                stepUpload === 1 && <UploadPdfStep />
            }

            {
                stepUpload === 2 && <CheckPdfFileStep />
            }

            {
                stepUpload === 3 && <SelectAdrUserPdfStep />
            }

            {
                stepUpload === 4 && <CheckoutPdfStep />
            }
        </main>
    )
}
