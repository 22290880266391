import React, { useEffect } from 'react'
import TitlePage from '../../components/user/TitlePage'
import { ProfileCircle, SmsTracking, Ticket2, Wallet1 } from 'iconsax-react'
import HistoryOrder from '../../sections/user/MyAccount/HistoryOrder'
import HistoryDownload from '../../sections/user/MyAccount/HistoryDownload'
import ListVoucher from '../../sections/user/MyAccount/ListVoucher'
import { useDispatch, useSelector } from 'react-redux'
import { getMypageInfor } from '../../reduxs/actions/auth.action'
import LoadingUser from './LoadingUser'
import ListCoupon from '../../sections/user/MyAccount/ListCoupon'
import ListPdfOrder from '../../sections/user/MyAccount/ListPdfOrder'
import EditInforAccount from '../../sections/user/MyAccount/EditInforAccount'

export default function MyAccount() {
    const dispatch = useDispatch()

    const { loadingGetMypage, dataGetMypage } = useSelector(state => state.auth)
    const { dataGetAccount } = useSelector(state => state.auth)

    const name = dataGetAccount?.name

    useEffect(() => {
        dispatch(getMypageInfor())
    }, [])

    if (loadingGetMypage || !dataGetMypage) {
        return <LoadingUser />
    }

    const { user_point, completedOrder_total, coupon_total, coupon } = dataGetMypage

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='마이페이지' />

            <section className='bg-logoColor p-5 rounded-20 grid lg:grid-cols-2 gap-6 text-white'>
                <div className='flex items-center gap-2.5'>
                    <div className='w-[54px] aspect-square rounded-full bg-white flex-shrink strock1'>
                        <ProfileCircle size={54} />
                    </div>

                    <p className='text-24-30'>{name}</p>

                    <EditInforAccount />
                </div>

                <div className='grid lg:grid-cols-3 gap-5 text-black font-bold'>
                    <div className='bg-white lg:p-5 p-4 rounded-20 flex flex-col items-center gap-1'>
                        <div className='flex gap-1 items-center'>
                            <Wallet1 />
                            <span className='translate-y-[2px] max-lg:text-14_20'>보유포인트</span>
                        </div>

                        <p className='lg:text-24-30'>{user_point}점</p>
                    </div>
                    <div className='bg-white lg:p-5 p-4 rounded-20 flex flex-col items-center gap-1'>
                        <div className='flex gap-1 items-center'>
                            <Ticket2 />
                            <span className='translate-y-[2px] max-lg:text-14_20'>쿠폰함</span>
                        </div>

                        <p className='lg:text-24-30'>{coupon_total}권</p>
                    </div>
                    <div className='bg-white lg:p-5 p-4 rounded-20 flex flex-col items-center gap-1'>
                        <div className='flex gap-1 items-center'>
                            <SmsTracking />
                            <span className='translate-y-[2px] max-lg:text-14_20'>보낸편지내역</span>
                        </div>

                        <p className='lg:text-24-30'>{completedOrder_total}건</p>
                    </div>
                </div>
            </section>

            <HistoryOrder />
            <ListPdfOrder />
            <HistoryDownload />
            <ListCoupon data={coupon} />
            <ListVoucher />
        </main>
    )
}
