import { createSlice } from "@reduxjs/toolkit";
import { getGaoladdress, searchGaolAddress } from "../actions/gaoIaddress.action";

const gaolSlice = createSlice({
    name: 'gaol',
    initialState: {
        loadingGetGaol: false,
        dataGaol: undefined
    },
    extraReducers: builder => {
        // get gaol address
        builder.addCase(getGaoladdress.pending, (state, action) => {
            state.loadingGetGaol = true
        })
        builder.addCase(getGaoladdress.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataGaol = res.data
            }

            state.loadingGetGaol = false
        })

        // search gaol address
        builder.addCase(searchGaolAddress.pending, (state, action) => {
            state.loadingGetGaol = true
        })
        builder.addCase(searchGaolAddress.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataGaol = res.data
            }

            state.loadingGetGaol = false
        })
    }
})

export const { } = gaolSlice.actions
export default gaolSlice.reducer
