import React from 'react'
import HeroSection from '../../sections/user/HomePage/HeroSection'
import ProductsSection from '../../sections/user/HomePage/ProductsSection'
import DataDetailSection from '../../sections/user/HomePage/DataDetailSection'

export default function HomePageUser() {
    return (
        <main className='flex flex-col w-full'>
            <HeroSection />

            <article className='flex flex-col lg:gap-10 gap-6 container lg:py-10 py-5 max-lg:px-6'>
                <ProductsSection />
                <DataDetailSection />
            </article>
        </main>
    )
}
