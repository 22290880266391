import React, { useEffect, useRef, useState } from 'react'
import { formatMoney } from '../../../utils/renderText'
import LetterQuillRead from '../../../components/user/LetterQuillRead'

export default function TotalLetter({ data, frontThumbnail, endThumbnail, price, feeLetter, totalPage }) {
    const ref = useRef()

    const [editors, setEditors] = useState([])
    const [widthView, setWidthView] = useState(0)

    useEffect(() => {
        if (data) {
            const contentParse = JSON.parse(data)

            const restoredEditors = contentParse.map(editor => {
                return {
                    id: editor.id,
                    editorState: editor.editorState
                };
            });

            setEditors(restoredEditors)
        }
    }, [data])

    useEffect(() => {
        setWidthView(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    return (
        <section className='w-full flex flex-col items-center lg:gap-5 gap-4' ref={ref}>
            <div className='flex justify-between items-start w-full max-lg:text-14_20'>
                <div>
                    <div className='flex gap-2 items-center'>
                        <p>편지내용:</p>
                        <span className='text-logoColor font-bold'>{editors.length}장</span>
                    </div>

                    <p className='mt-2.5'>원입니다 *4번째 장부터는 1장당 {formatMoney(feeLetter.price)}원씩 추가됩니다.</p>
                </div>

                <div className='flex gap-2 items-center'>
                    <p>총액:</p>
                    <span className='text-logoColor font-bold'>{formatMoney(Number(totalPage) <= 3 ? price : (price + ((Number(totalPage) - 3) * feeLetter.price)))} 원</span>
                </div>
            </div>

            {
                editors.map(item =>
                    <LetterQuillRead
                        widthElm={widthView}
                        key={item.id}
                        editorState={item.editorState}
                        frontThumbnail={frontThumbnail}
                        endThumbnail={endThumbnail}
                    />)
            }
        </section>
    )
}
