import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getNoticeDetail, updateNoticeDetail } from '../../reduxs/actions/notice.action'
import TitleSection from '../../components/user/TitleSection'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import LabelFormThin from '../../components/LabelFormThin'
import InputFormThin from '../../components/InputFormThin'
import ContentForm from '../../components/admin/ContentForm'
import { ImageUploadItem } from '../../sections/admin/createproduct/FormUpload'
import ButtonPrimary from '../../components/ButtonPrimary'
import { URL_SERVER } from '../../utils/apis'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'

export default function EditNotice() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id } = useParams()

    const { detailNotice } = useSelector(state => state.notice)

    const imageRef = useRef()

    const [postImage, setPostImage] = useState([])
    const [deletePictures, setDeletePictures] = useState([])

    const onEditorStateChange = (editorState) => {
        formik.setFieldValue('content', editorState);
    };

    const handleClickImage = () => {
        imageRef.current.click()
    }

    const handleChooseImage = (e) => {
        const files = e.currentTarget.files
        const fileArray = Array.from(files)

        const handleFile = fileArray.map((item, index) => {
            return {
                id: index + Date.now(),
                file: URL.createObjectURL(item),
                currentFile: item
            }
        }).filter(item => item !== undefined)

        const handleSliceFile = [...formik.values.pictures, ...handleFile].slice(0, 4)

        formik.setFieldValue('pictures', handleSliceFile)

        e.target.value = null
    }

    const handleDeletepostImage = (file) => {
        const isCheckFile = postImage.find(item => item.id === Number(file));

        const handleFile = formik.values.pictures.filter(item => item.id !== Number(file))

        formik.setFieldValue('pictures', handleFile)

        if (isCheckFile) {
            setDeletePictures([
                ...deletePictures,
                isCheckFile.picture_name
            ])
        }
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            pictures: '',
            content: '',
        },
        validationSchema: Yup.object({
            // pictures: Yup.string().required(ERROR_REQUIRED), 
            title: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            const contentState = values.content.getCurrentContent();
            const rawContent = convertToRaw(contentState);

            formData.append('title', values.title)
            formData.append('content', JSON.stringify(rawContent))

            for (let i = 0; i < values.pictures.length; i++) {
                const file = values.pictures[i].currentFile;

                if (file) formData.append('posts', file)
            }

            for (let i = 0; i < deletePictures.length; i++) {
                const file = deletePictures[i];

                formData.append(`delete_file[${i}]`, file)
            }

            const result = await dispatch(updateNoticeDetail({ postId: id, formData: formData }))

            const res = result.payload

            if (res.status) {
                navigate('/admin/notice')
            }
        }
    })

    useEffect(() => {
        dispatch(getNoticeDetail(id))
    }, [])

    useEffect(() => {
        if (detailNotice) {
            formik.setFieldValue('title', detailNotice.title)
            formik.setFieldValue('pictures', detailNotice.pictures)

            setPostImage(detailNotice.pictures)

            const contentState = convertFromRaw(JSON.parse(detailNotice.content))
            const newEditor = EditorState.createWithContent(contentState)

            formik.setFieldValue('content', newEditor)
        }
    }, [detailNotice])

    return (
        <>
            <TitleSection title='수정' mt={false} />

            <form className='grid gap-5 p-5 rounded-24 bg-white justify-items-center' onSubmit={formik.handleSubmit}>
                <div className='w-full'>
                    <LabelFormThin label='제목' className='font-bold' />
                    <InputFormThin
                        name='title'
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        placehoder='제목을 입력하세요'
                        error={(formik.touched.title && formik.errors.title) && formik.errors.title}
                    />
                </div>

                <div className='w-full'>
                    <LabelFormThin label='내용' className='font-bold' />
                    <ContentForm editorState={formik.values.content} setEditorState={onEditorStateChange} />
                </div>

                <div className='w-full'>
                    <div className='flex justify-between items-center gap-5'>
                        <LabelFormThin label='첨부파일 (.jpg)' />
                        <span>4/4 사진</span>
                    </div>

                    <div className='grid grid-cols-4 gap-5'>
                        <input type='file' accept='image/jpg, image/jpeg' multiple hidden ref={imageRef} onChange={handleChooseImage} />

                        {
                            formik.values.pictures
                                ?
                                <>
                                    {
                                        formik.values.pictures.map(item =>
                                            <ImageUploadItem
                                                key={item.id}
                                                id={item.id}
                                                image={item.file ? item.file : `${URL_SERVER}${item.filename}`}
                                                handleDelete={handleDeletepostImage}
                                            />)
                                    }

                                    {formik.values.pictures.length < 4 &&
                                        Array(4 - formik.values.pictures.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickImage}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickImage} handleDelete={handleDeletepostImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} handleDelete={handleDeletepostImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} handleDelete={handleDeletepostImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} handleDelete={handleDeletepostImage} />
                                </>
                        }
                    </div>
                </div>
            </form>

            <div className='text-center'>
                <ButtonPrimary type='button' text='저장' className='w-[160px]' onClick={formik.handleSubmit} />
            </div>
        </>
    )
}
