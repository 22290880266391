import React, { useContext, useEffect } from 'react'
import { UploadLetterContext } from '../../../contexts/uploadLetterContext'
import { getOrderStamp } from '../../../reduxs/actions/order.action'
import { useDispatch, useSelector } from 'react-redux'
import ShippingFeeItem from '../../../components/user/ShippingFeeItem'
import { formatMoney } from '../../../utils/renderText'

export default function TotalShipingFee() {
    const dispatch = useDispatch()

    const { formUpload } = useContext(UploadLetterContext)

    const { dataOrderStamp } = useSelector(state => state.order)

    const stamp = formUpload?.stamp_id
    const stampPrice = formUpload?.ship_fee
    const nameStamp = formUpload?.nameStamp

    useEffect(() => {
        if (!dataOrderStamp) {
            dispatch(getOrderStamp(1))
        }
    }, [dataOrderStamp])

    return (
        <section className='w-full flex flex-col items-center gap-5'>
            <div className='flex justify-between items-center w-full max-lg:text-14_20'>
                <div className='flex gap-2 items-center'>
                    <p>발송방법:</p>
                    <span className='text-logoColor font-bold'>{nameStamp}</span>
                </div>

                <div className='flex gap-2 items-center'>
                    <p>총액:</p>
                    <span className='text-logoColor font-bold'>{formatMoney(stampPrice)} 원</span>
                </div>
            </div>

            <div className='grid lg:grid-cols-3 grid-cols-2 lg:gap-3 gap-2.5 w-full'>
                {
                    dataOrderStamp
                    &&
                    dataOrderStamp.map(item => {
                        if (item.id === stamp) {
                            return <ShippingFeeItem
                                key={item.id}
                                id={item.id}
                                image={item.filename}
                            />
                        }
                    }
                    )
                }
            </div>
        </section>
    )
}
