import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/apis";

export const getInforDataHomePage = createAsyncThunk(
    'home/getinfor',
    async () => {
        const response = await get(`home/infordetail`)

        return response
    }
)