import React, { useEffect } from 'react'
import TitleSection from '../../components/user/TitleSection'
import LabelFormThin from '../../components/LabelFormThin'
import TextareaForm from '../../components/TextareaForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useNavigate, useParams } from 'react-router-dom'
import { getDetailFaq, updateDetailFaq } from '../../reduxs/actions/faq.action'
import LoadingUser from '../user/LoadingUser'

export default function EditFaq() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { id } = useParams()

    const { loadingGetDetailFaq, dataFaqDetail } = useSelector(state => state.faq)

    const formik = useFormik({
        initialValues: {
            id: dataFaqDetail?.id,
            question: dataFaqDetail?.question,
            answer: dataFaqDetail?.answer
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            question: Yup.string().required(ERROR_REQUIRED),
            answer: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(updateDetailFaq({ faqId: values.id, body: values }))

            const res = result.payload

            if (res.status) {
                navigate('/admin/faq-admin')
            }
        }
    })

    useEffect(() => {
        dispatch(getDetailFaq(id))
    }, [id])

    if (loadingGetDetailFaq || !dataFaqDetail) {
        return <LoadingUser />
    }

    return (
        <>
            <TitleSection title='수정' mt={false} />

            <form className='grid gap-5 justify-items-center' onSubmit={formik.handleSubmit}>
                <div className='w-full'>
                    <LabelFormThin label='질문' className='font-bold' />
                    <TextareaForm
                        placeholder='답변을 입력해 주세요....'
                        name='question'
                        onChange={formik.handleChange}
                        value={formik.values.question}
                        error={(formik.touched.question && formik.errors.question) && formik.errors.question}
                    />
                </div>
                <div className='w-full'>
                    <LabelFormThin label='답변' className='font-bold' />
                    <TextareaForm
                        placeholder='질문을 입력해 주세요....'
                        name='answer'
                        onChange={formik.handleChange}
                        value={formik.values.answer}
                        error={(formik.touched.answer && formik.errors.answer) && formik.errors.answer}
                    />
                </div>

                <ButtonPrimary text='저장' className='w-[160px]' type='submit' onClick={formik.handleSubmit} />
            </form>
        </>
    )
}
