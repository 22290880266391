import React, { useEffect, useState } from 'react'
import statusOrder from '../../../components/user/StatusOrder'
import { Link } from 'react-router-dom'
import { ArrowRight2 } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderMypage } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { URL_SERVER } from '../../../utils/apis'
import moment from 'moment/moment'
import Pagination from '../../../components/Pagination'

export default function HistoryOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetOrderMypage, dataOrderMypage, countOrders } = useSelector(state => state.order)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countOrders / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getOrderMypage(page))
    }, [page])

    if (loadingGetOrderMypage || !dataOrderMypage) {
        return <LoadingUser />
    }

    return (
        <section className='px-5 rounded-20 border border-strock'>
            <div className='overflow-x-auto'>
                <table className='w-full text-center'>
                    <thead>
                        <tr className='border-b border-strock'>
                            <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 w-1/12 max-lg:min-w-[60px]'>영상</th>
                            <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[200px]'>제품명</th>
                            <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 w-2/12 max-lg:min-w-[120px]'>생성 날짜</th>
                            <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 w-2/12 max-lg:min-w-[100px]'>주문 상태</th>
                            <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 w-[120px]'></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataOrderMypage.map(item =>
                                <RowOrderItem
                                    key={item.order_id}
                                    id={item.order_id}
                                    name={item.product_name}
                                    picture={item.product_picture}
                                    created_at={item.created_at}
                                    status_id={item.order_status_id}
                                    status_name={item.status_name}
                                />
                            )
                        }
                    </tbody>
                </table>
            </div>

            <Pagination totalCount={countOrders} currentPage={page} handlePage={handlePage} />
        </section>
    )
}

const RowOrderItem = ({ id, name, picture, created_at, status_id, status_name }) => {
    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <div className='flex justify-center'>
                    <img src={`${URL_SERVER}${picture}`} alt='product' className='aspect-[7.08/10] object-cover lg:max-w-[100px] max-lg:w-[60px]' />
                </div>
            </td>

            <td className='py-5 px-2'>
                <Link to={`/order/${id}`} className='line-clamp-1 max-lg:text-12 hover:text-logoColor duration-200'>{name}</Link>
            </td>

            <td className='py-5 px-2 max-lg:text-12'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5 px-2 max-lg:text-12'>
                <div className='flex justify-center max-lg:text-12'>
                    {statusOrder(status_id, status_name)}
                </div>
            </td>
            <td>
                {
                    status_id === 5
                    &&
                    <Link to={`/comment/${id}`} className='hover:text-logoColor duration-300 max-lg:text-12 flex gap-2.5 items-center'>
                        리뷰작성하기
                        <ArrowRight2 size={16} />
                    </Link>
                }
            </td>
        </tr>
    )
}
