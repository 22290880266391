import React, { useEffect, useRef } from 'react'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import { ImageUploadItem } from '../createproduct/FormUpload'
import { getAllCategories } from '../../../reduxs/actions/category.action'
import { useDispatch, useSelector } from 'react-redux'
import TitleSection from '../../../components/user/TitleSection'
import Dropdown from '../../../components/admin/Dropdown'
import DateInputForm from '../../../components/admin/DateInputForm'
import OrderLetter from './OrderLetter'

export default function FormUploadEvent({ formik, descriptionImage, setDescriptionImage }) {
    const dispatch = useDispatch()

    const descriptionImageRef = useRef()

    const { dataCategories } = useSelector(state => state.categories)

    // normal file thimbnail
    const handleClickNormalImage = () => {
        descriptionImageRef.current.click()
    }

    const handleChooseNormalImage = (e) => {
        const filesArray = Array.from(e.currentTarget.files)

        const handleFiles = filesArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const files = filesArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const customImage = [
            ...descriptionImage,
            ...handleFiles
        ].slice(0, 4)

        const customImageForm = [
            ...formik.values.descriptions,
            ...files
        ].slice(0, 4)

        setDescriptionImage(customImage)
        formik.setFieldValue('descriptions', customImageForm)

        e.target.value = null;
    }

    const handleDeleteNormal = (file) => {
        const handleFiles = descriptionImage.filter(item => item.id !== file)
        const handleDeleteFormik = formik.values.descriptions.filter(item => item.id !== file)

        setDescriptionImage(handleFiles)
        formik.setFieldValue('descriptions', handleDeleteFormik)
    }

    // handle choose date create
    const handleChooseDateStart = (date) => {
        formik.setFieldValue('explres', date)
    }

    // handle choose category
    const handleChooseCategory = (categoryId) => {
        formik.setFieldValue('category_id', categoryId)
    }

    useEffect(() => {
        dispatch(getAllCategories(2))
    }, [])

    return (
        <section className='w-7/12 grid gap-5 h-fit'>
            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title='정보' small mt={false} />

                <div>
                    <LabelFormThin label='상품명 (필수)' />
                    <InputFormThin
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placehoder='텍스트입력'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>

                <div className='grid gap-2.5'>
                    <div className='flex justify-between items-center'>
                        <LabelFormThin label='편지지 세부 사항' mt={false} className='mb-0' />
                        <p>{formik.values.descriptions.length}/4 사진</p>
                    </div>

                    <div className='grid grid-cols-4 gap-2.5'>
                        <input type='file' accept='image/*' multiple ref={descriptionImageRef} hidden onChange={handleChooseNormalImage} />

                        {
                            (descriptionImage && (descriptionImage.length > 0)) ?
                                <>
                                    {
                                        descriptionImage.map(item => <ImageUploadItem key={item.id} id={item.id} image={item.file} handleDelete={handleDeleteNormal} />)
                                    }

                                    {descriptionImage.length < 4 &&
                                        Array(4 - descriptionImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickNormalImage}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title=' 가격 및 재고' small mt={false} />

                <div className='grid grid-cols-2 gap-5'>
                    <div>
                        <LabelFormThin label='가격 (필수)' />
                        <InputFormThin
                            placehoder='가격을 입력하세요'
                            name='price'
                            onChange={formik.handleChange}
                            value={formik.values.price}
                            error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='할인' />

                        <div className='relative'>
                            <InputFormThin
                                placehoder='할인을 입력하세요'
                                name='discount'
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.discount}
                                error={(formik.touched.discount && formik.errors.discount) && formik.errors.discount}
                            />

                            <span className='absolute top-1/2 -translate-y-1/2 right-5'>%</span>
                        </div>
                    </div>

                    <div>
                        <LabelFormThin label='범주 (필수)' />
                        <Dropdown
                            title='편지지'
                            data={dataCategories}
                            name='name'
                            className='w-full'
                            handleChoose={handleChooseCategory}
                            isChoose={formik.values.category_id}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='재고 (필수)' />
                        <InputFormThin
                            name='quantity'
                            type='number'
                            onChange={formik.handleChange}
                            value={formik.values.quantity}
                            placehoder='100개'
                            error={(formik.touched.quantity && formik.errors.quantity) && formik.errors.quantity}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='재고 (필수)' />
                        <DateInputForm date={formik.values.explres} handleSelectDay={handleChooseDateStart} />
                    </div>
                </div>
            </div>

            <OrderLetter />
        </section>
    )
}
