import { Star1 } from 'iconsax-react'
import React, { useState } from 'react'

export default function RatingReview({ numberStar = 10, numberChoose, handleChoose, defaultStar = 0, onlyView = true }) {
    const [numberSelected, setNumberSelected] = useState(defaultStar)

    const paginationNumbers = [];

    for (let i = 1; i <= numberStar; i++) {
        paginationNumbers.push(i);
    }

    const handleChooseNumber = (number) => {
        if (!onlyView) handleChoose(number)
    }

    const handleMouseEnter = (number) => {
        if (!onlyView) setNumberSelected(number)
    }

    const handleMouseDown = () => {
        if (!onlyView) setNumberSelected(numberChoose)
    }

    return (
        <div className='flex flex-wrap items-center justify-center gap-1' onMouseLeave={handleMouseDown}>
            {
                paginationNumbers.map(item =>
                    <div
                        key={item}
                        className='text-[#FFEB37] cursor-pointer'
                        onMouseEnter={() => handleMouseEnter(item)}
                        onClick={() => handleChooseNumber(item)}
                    >
                        {
                            (item <= numberSelected)
                                ?
                                <Star1 size={20} variant='Bold' />
                                :
                                <Star1 size={20} />
                        }
                    </div>
                )
            }
        </div>
    )
}
