import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterOrders from '../../sections/admin/orders/FilterOrders'
import ListHandmakeOrder from '../../sections/admin/orders/ListHandmakeOrder'

export default function OrderHandmakeAdmin() {
    return (
        <>
            <TitleSection title='주문내역' mt={false} />

            <FilterOrders type='makebyme-normal-order' />

            <ListHandmakeOrder />
        </>
    )
}
