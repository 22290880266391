import React from 'react'
import TitlePage from '../../components/user/TitlePage'

export default function TermsOfUse() {
    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='이용약관' />

            <div className='whitespace-pre-line'>
                <p>
                    표준 이용약관 개정 2024. 08.30
                </p>
                <br />

                <p className='font-bold'>
                    제1조 목적
                </p>
                <br />

                <p >
                    본 이용약관은 “메이드바이미”(이하 “사이트”)의 서비스의 이용조건과 운영에 관한 제반 사항 규정을 목적으로 합니다.
                </p>
                <br />

                <p className='font-bold'>
                    제2조 용어의 정의
                </p>
                <br />

                <p>
                    본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.
                </p>
                <br />

                <p>
                    ① 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여 회원등록을 한 자로서, 사이트와의 이용계약을 체결하고 사이트를 이용하는 이용자를 말합니다.
                    ② 이용계약 : 사이트 이용과 관련하여 사이트와 회원간에 체결 하는 계약을 말합니다.
                    ③ 회원 아이디(이하 “ID”) : 회원의 식별과 회원의 서비스 이용을 위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.
                    ④ 비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고 회원의 권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.
                    ⑤ 운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를 말합니다.
                    ⑥ 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
                </p>
                <br />

                <p className='font-bold'>
                    제3조 약관 외 준칙
                </p>
                <br />

                <p>
                    운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본 약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.
                </p>
                <br />

                <p className='font-bold'>
                    제4조 이용계약 체결
                </p>
                <br />

                <p>
                    ① 이용계약은 회원으로 등록하여 사이트를 이용하려는 자의 본 약관 내용에 대한 동의와 가입신청에 대하여 운영자의 이용승낙으로 성립합니다.
                    ② 회원으로 등록하여 서비스를 이용하려는 자는 사이트 가입신청 시 본 약관을 읽고 아래에 있는 “동의합니다”를 선택하는 것으로 본 약관에 대한 동의 의사 표시를 합니다.
                    ③ “사이트”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                </p>
                <br />

                <p>
                    1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
                    2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
                    3. 기타 구매신청에 승낙하는 것이 “사이트” 기술상 현저히 지장이 있다고 판단하는 경우
                </p>
                <br />

                <p>
                    ④ “사이트”의 승낙이 제12조제1항의 수신확인 통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                    ⑤ “사이트”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
                </p>
                <br />

                <p>
                    제11조(지급방법) “사이트”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “사이트”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                </p>
                <br />

                <p>
                    1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
                    2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                    3. 온라인무통장입금
                    4. 전자화폐에 의한 결제
                    5. 수령 시 대금지급
                    6. 마일리지 등 “사이트”이 지급한 포인트에 의한 결제
                    7. “사이트”과 계약을 맺었거나 “사이트”이 인정한 상품권에 의한 결제
                    8. 기타 전자적 지급 방법에 의한 대금 지급 등
                </p>
                <br />

                <p className='font-bold'>
                    제5조 서비스 이용 신청
                </p>
                <br />

                <p>
                    ① 회원으로 등록하여 사이트를 이용하려는 이용자는 사이트에서 요청하는 제반정보(이용자ID,비밀번호, 닉네임, 휴대전화번호 등)를 제공해야 합니다.
                    ② 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 진정한 정보를 등록하지 않은 회원은 사이트 이용과 관련하여 아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌받을 수 있습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제6조 개인정보처리방침
                </p>
                <br />

                <p>
                    사이트 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를 가지고 있지 않으며 이와 관련된 부분은 사이트의 개인정보처리방침을 따릅니다.
                    운영자는 관계 법령이 정하는 바에 따라 회원등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다.
                </p>
                <br />

                <p>
                    회원의 개인정보보호에 관하여 관계법령 및 사이트가 정하는 개인정보처리방침에 정한 바에 따릅니다.
                </p>
                <br />

                <p>
                    단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는 일체의 책임을 지지 않습니다.
                    운영자는 회원이 미풍양속에 저해되거나 국가안보에 위배되는 게시물 등 위법한 게시물을 등록 · 배포할 경우 관련 기관의 요청이 있을 시 회원의 자료를 열람 및 해당 자료를 관련 기관에 제출할 수 있습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제7조 운영자의 의무
                </p>
                <br />

                <p>
                    ① 운영자는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는 이용회원에게 쪽지, 전자우편 등을 보내는 등 최선을 다합니다.
                    ② 운영자는 계속적이고 안정적인 사이트 제공을 위하여 설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할 수 있도록 사이트에 요구할 수 있습니다. 다만, 천재지변 또는 사이트나 운영자에 부득이한 사유가 있는 경우, 사이트 운영을 일시 정지할 수 있습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제8조 회원의 의무
                </p>
                <br />

                <p>
                    ① 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반 규정, 공지사항 및 운영정책 등 사이트가 공지하는 사항 및 관계 법령을 준수하여야 하며, 기타 사이트의 업무에 방해가 되는 행위, 사이트의 명예를 손상하는 행위를 해서는 안 됩니다.
                    ② 회원은 사이트의 명시적 동의가 없는 한 서비스의 이용 권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                    ③ 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야 하며, 운영자나 사이트의 동의 없이 제3자에게 아이디를 제공하여 이용하게 할 수 없습니다.
                    ④ 회원은 운영자와 사이트 및 제3자의 지적 재산권을 침해해서는 안 됩니다.
                </p>
                <br />

                <p className='font-bold'>
                    제9조 서비스 이용 시간
                </p>
                <br />

                <p>
                    ① 서비스 이용 시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 단, 사이트는 시스템 정기점검, 증설 및 교체를 위해 사이트가 정한 날이나 시간에 서비스를 일시중단 할 수 있으며 예정된 작업으로 인한 서비스 일시 중단은 사이트의 홈페이지에 사전에 공지하오니 수시로 참고하시길 바랍니다.
                    ② 단, 사이트는 다음 경우에 대하여 사전 공지나 예고 없이 서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.
                    – 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을 일으키는 경우
                    – 국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우
                    – 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
                    – 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
                    ③ 전항에 의한 서비스 중단의 경우 사이트는 사전에 공지사항 등을 통하여 회원에게 통지합니다. 단, 사이트가 통제할 수 없는 사유로 발생한 서비스의 중단에 대하여 사전공지가 불가능한 경우에는 사후공지로 대신합니다.
                </p>
                <br />

                <p className='font-bold'>
                    제10조 서비스 이용 해지 (회원 탈퇴 및 자격 상실 등)
                </p>
                <br />

                <p>
                    ① 회원은 “사이트”에 언제든지 탈퇴를 요청할 수 있으며 “사이트”는 즉시 회원탈퇴를 처리합니다. 단 회원탈퇴 후 90일간 동일 계정 / 휴대전화 등으로 재가입이 불가합니다.
                    ② 회원이 다음 각 호의 사유에 해당하는 경우, “사이트”는 회원자격을 제한 및 정지시킬 수 있습니다.
                </p>
                <br />

                <p>
                    1. 가입 신청 시에 허위 내용을 등록한 경우
                    2. “사이트”를 이용하여 구입한 재화 등의 대금, 기타 “사이트” 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
                    3. 다른 사람의 “사이트” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 (구매 신청은 하지만 구매금액 미입금이 지속적/ 빈번하게 발생할 때 등)
                    4. “사이트”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
                </p>
                <br />

                <p>
                    ③ “사이트”가 회원 자격을 제한/정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “사이트”는 회원자격을 상실 시킬 수 있습니다.
                    ④ “사이트”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
                </p>
                <br />

                <p className='font-bold' >
                    제11조 서비스 이용 제한
                </p>
                <br />

                <p>
                    회원은 다음 각호에 해당하는 행위를 하여서는 아니 되며 해당 행위를 한 경우에 사이트는 회원의 서비스 이용 제한 및 적법한 조치를 할 수 있으며 이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수 있습니다.
                    ① 회원 가입시 혹은 가입 후 정보 변경 시 허위 내용을 등록하는 행위
                    ② 타인의 사이트 이용을 방해하거나 정보를 도용하는 행위
                    ③ 사이트의 운영진, 직원 또는 관계자를 사칭하는 행위
                    ④ 사이트, 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위
                    ⑤ 다른 회원의 ID를 부정하게 사용하는 행위
                    ⑥ 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
                    ⑦ 범죄와 결부된다고 객관적으로 판단되는 행위
                    ⑧ 기타 관련 법령에 위배되는 행위
                </p>
                <br />

                <p className='font-bold'>
                    제12조 게시물의 관리
                </p>
                <br />

                <p>
                    ① 사이트의 게시물과 자료의 관리 및 운영의 책임은 운영자에게 있습니다. 운영자는 항상 불량 게시물 및 자료에 대하여 모니터링을 하여야 하며, 불량 게시물 및 자료를 발견하거나 신고를 받으면 해당 게시물 및 자료를 삭제하고 이를 등록한 회원에게 주의를 주어야 합니다.
                    한편, 이용회원이 올린 게시물에 대해서는 게시자 본인에게 책임이 있으니 회원 스스로 본 이용약관에서 위배되는 게시물은 게재해서는 안 됩니다.
                    ② 정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우 운영자는 회원의 사전동의 없이 게시물을 삭제하거나 이동 할 수 있습니다.
                    ③ 불량게시물의 판단기준은 다음과 같습니다.
                    – 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 손상하는 내용인 경우
                    – 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크 시키는 경우
                    – 불법 복제 또는 해킹을 조장하는 내용인 경우
                    – 영리를 목적으로 하는 광고일 경우
                    – 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
                    – 다른 이용자 또는 제3자와 저작권 등 기타 권리를 침해하는 경우
                    – 기타 관계 법령에 위배된다고 판단되는 경우
                    ④ 사이트 및 운영자는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로 게시 중단(전송중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에 소송, 합의 기타 이에 준하는 관련 기관의 결정 등이 이루어져 사이트에 접수된 경우 이에 따릅니다.
                </p>
                <br />

                <p className='font-bold'>
                    제13조 게시물의 보관
                </p>
                <br />

                <p>
                    사이트 운영자가 불가피한 사정으로 본 사이트를 중단하게 될 경우, 회원에게 사전 공지를 하고 게시물의 이전이 쉽도록 모든 조치를 하기 위해 노력합니다.
                </p>
                <br />

                <p className='font-bold'>
                    제14조 게시물에 대한 저작권
                </p>
                <br />

                <p>
                    ① 회원이 사이트 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 사이트는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만 비영리 목적인 경우는 그러하지 아니하며, 또한 서비스 내의 게재권을 갖습니다.
                    ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
                    ③ 운영자는 회원이 게시하거나 등록하는 사이트 내의 내용물, 게시 내용에 대해 제12조 각호에 해당한다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제15조 손해배상
                </p>
                <br />

                <p>
                    ① 본 사이트의 발생한 모든 민, 형법상 책임은 회원 본인에게 1차적으로 있습니다.
                    ② 본 사이트로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나 회원의 고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지 않습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제16조 면책
                </p>
                <br />

                <p>
                    ① 운영자는 회원이 사이트의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
                    ② 운영자는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는 전기통신 서비스의 장애로 인한 경우에는 책임이 면제되며 본 사이트의 서비스 기반과 관련되어 발생한 손해에 대해서는 사이트의 이용약관에 준합니다
                    ③ 운영자는 회원이 저장, 게시 또는 전송한 자료와 관련하여 일체의 책임을 지지 않습니다.
                    ④ 운영자는 회원의 귀책 사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임지지 아니합니다.
                    ⑤ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간, 기타 회원의 본 서비스 내외를 불문한 일체의 활동(데이터 전송, 기타 커뮤니티 활동 포함)에 대하여 책임을 지지 않습니다.
                    ⑥ 운영자는 회원이 게시 또는 전송한 자료 및 본 사이트로 회원이 제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성 등 그 내용에 대해서는 책임지지 아니합니다.
                    ⑦ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간에 서비스를 매개로 하여 물품거래 등을 한 경우에 그로부터 발생하는 일체의 손해에 대하여 책임지지 아니합니다.
                    ⑧ 운영자는 운영자의 귀책 사유 없이 회원간 또는 회원과 제3자간에 발생한 일체의 분쟁에 대하여 책임지지 아니합니다.
                    ⑨ 운영자는 서버 등 설비의 관리, 점검, 보수, 교체 과정 또는 소프트웨어의 운용 과정에서 고의 또는 고의에 준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애, 제3자의 공격으로 인한 시스템의 장애, 국내외의 저명한 연구기관이나 보안 관련 업체에 의해 대응 방법이 개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타 운영자가 통제할 수 없는 불가항력적 사유로 인한 회원의 손해에 대하여 책임지지 않습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제17조(분쟁해결)
                </p>
                <br />

                <p>
                    ① “사이트”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치/운영합니다.
                    ② “사이트”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
                    ③ “사이트”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
                </p>
                <br />

                <p className='font-bold'>
                    제18조(재판권 및 준거법)
                </p>
                <br />

                <p>
                    ① “사이트”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
                    ② “사이트”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
                </p>
                <br />

                <p>
                    부칙
                </p>
                <br />

                <p>
                    이 약관은 2023년 12월 8일 부터 시행합니다.
                </p>
                <br />
            </div>
        </main>
    )
}
