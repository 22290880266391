import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function FAQSection({ dataFaq }) {
    return (
        <section>
            <TitleSection title='FAQ 게시판' more='fqa' />

            <div className='border-strock px-5 border rounded-20'>
                <table className='text-center w-full'>
                    <thead>
                        <tr>
                            <th className='w-4/5 py-5 text-start'>제목</th>
                            <th className='w-1/5 py-5'></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataFaq.map(item => <RowFaqItem key={item.id} question={item.question} createdAt={item.created_at} />)
                        }
                    </tbody>
                </table>
            </div>
        </section>
    )
}

const RowFaqItem = ({ id, question, createdAt }) => {
    return (
        <tr className='border-t border-strock'>
            <td className='py-5 pr-5'>
                <Link to='/fqa' className='line-clamp-1 text-start'>{question}</Link>
            </td>
            <td className='py-5'>{moment(createdAt).format('YYYY.MM.DD')}</td>
        </tr>
    )
}
