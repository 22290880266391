import React, { useState } from 'react'
import ShippingItem from '../../../components/admin/ShippingItem'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import { ERROR_REQUIRED } from '../../../utils/error'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { updateFeeletterAdmin } from '../../../reduxs/actions/management.action'

export default function LetterOption({ data }) {
    const dispatch = useDispatch()
    const [isShowPopup, setIsShowPopup] = useState(false)

    const handleShowPopup = (id) => {
        if (isShowPopup) formik.resetForm()

        setIsShowPopup(!isShowPopup)
        formik.setFieldValue('pdfId', formik.values.pdfId ? '' : id)
    }

    const formik = useFormik({
        initialValues: {
            pdfId: '',
            price: ''
        },
        validationSchema: Yup.object({
            price: Yup.string().required(ERROR_REQUIRED),
            pdfId: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(updateFeeletterAdmin(values))

            const res = result.payload

            if (res.status) {
                handleShowPopup()
            } else {
                formik.setFieldError('price', res.message)
            }
        }
    })

    return (
        <>
            {
                data.map(item =>
                    <ShippingItem
                        key={item.id}
                        name={item.name}
                        title='편지지 장당 가격수정'
                        price={item.price}
                        onClick={() => handleShowPopup(item.id)}
                    />)
            }

            {
                (isShowPopup && formik.values.pdfId)
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                    <form onSubmit={formik.handleSubmit} className='w-[600px] bg-white rounded-20 flex flex-col items-center gap-5 p-5'>
                        <h3 className='text-24-30 font-semibold'>편집</h3>

                        <div className='w-full'>
                            <LabelFormThin label='가격' />
                            <InputFormThin
                                name='price'
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                placehoder='가격을 입력하세요'
                                error={(formik.touched.price && formik.errors.price) && formik.errors.price} />
                        </div>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }
        </>
    )
}