import htmlToPdfmake from 'html-to-pdfmake'
import React from 'react'

export default function TestPage() {
    const html = `
    <div>
        <p style="fontSize: 20px">hello text 20</p>
    </div>
    `

    const handleDownload = () => {
        const pdfContent = htmlToPdfmake(html)

        console.log(pdfContent)
        // pdfMake.createPdf(docDefinition).download('test font.pdf')
    }

    return (
        <>
            <button onClick={handleDownload}>Download</button>
        </>
    )
}

