import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import SearchVoucher from '../../sections/admin/orders/SearchVoucher'
import ListVoucherOrder from '../../sections/admin/orders/ListVoucherOrder'

export default function OrderVoucher() {
    return (
        <>
            <TitleSection title='주문내역' mt={false} />

            <SearchVoucher />

            <ListVoucherOrder />
        </>
    )
}
