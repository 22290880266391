import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import SaveLetterItem from '../../../components/user/SaveLetterItem'

export default function SaveAutoSection({ data }) {
    return (
        <section className='max-lg:px-2.5'>
            <TitleSection title='자동으로 저장' small />

            <div className='grid lg:grid-cols-6 grid-cols-2 gap-x-5 gap-y-8'>
                {
                    data.map(item =>
                        <SaveLetterItem
                            key={item.save_id}
                            save_id={item.save_id}
                            thumbnail={item.front_thumbnail}
                            letter_name={item.letter_name}
                        />)
                }
            </div>
        </section>
    )
}
