import React, { useEffect } from 'react'
import InputFormThin from '../../../components/InputFormThin'
import { useDispatch, useSelector } from 'react-redux'
import { getPointUser, updatePointUser } from '../../../reduxs/actions/user.action'
import Pagination from '../../../components/Pagination'
import LoadingUser from '../../../pages/user/LoadingUser'
import { useSearchParams } from 'react-router-dom'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { formatMoney } from '../../../utils/renderText'

export default function ListEditPoint() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page') || 1

    const { loadingGetPointUser, dataPointUser, countPointUser } = useSelector(state => state.user)

    const handlePage = (method) => {
        const currentParams = Object.fromEntries([...searchParams]);

        if (method === 'next') {
            const maxPage = Math.ceil(countPointUser / 10)
            setSearchParams({ ...currentParams, page: Number(page) < maxPage ? Number(page) + 1 : Number(page) })
        }
        else if (method === 'pre') {
            setSearchParams({ ...currentParams, page: Number(page) <= 1 ? Number(page) : Number(page) - 1 })
        }
        else if (typeof method === 'number') {
            setSearchParams({ ...currentParams, page: method })
        }
    }

    useEffect(() => {
        dispatch(getPointUser(page))
    }, [page])

    if (loadingGetPointUser || !dataPointUser) {
        return <LoadingUser />
    }

    return (
        <div className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[500px]'>이메일</th>
                        <th className='py-5 px-2 w-[300px]'>성함</th>
                        <th className='py-5 px-2 w-[300px]'>포인트</th>
                        <th className='py-5 px-2 w-[240px]'>추가할 적립금</th>
                        <th className='py-5 px-2 w-[240px]'>차감할 적립금</th>
                        <th className='py-5 px-2 w-[100px]'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataPointUser.map(item =>
                            <RowPointItem
                                key={item.user_id}
                                id={item.user_id}
                                name={item.name}
                                email={item.email}
                                point_total={item.point_total}
                            />)
                    }
                </tbody>
            </table>

            <Pagination totalCount={countPointUser} currentPage={Number(page)} handlePage={handlePage} />
        </div>
    )
}

const RowPointItem = ({ id, name, email, point_total }) => {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            user_id: id,
            user_point: point_total,
            plus_point: 0,
            minus_point: 0
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            user_id: Yup.string().required(ERROR_REQUIRED),
            user_point: Yup.string().required(ERROR_REQUIRED),
            plus_point: Yup.number(),
            minus_point: Yup.number(),
        }),
        onSubmit: async values => {
            const result = point_total + values.plus_point - values.minus_point

            if (result < 0) return alert('총점은 0보다 작을 수 없습니다')

            const response = await dispatch(updatePointUser(values))

            if (!response.payload.status) {
                alert(response.payload.message)
            }
        }
    })

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>{email}</td>
            <td className='py-5'>{name}</td>
            <td className='py-5'>{formatMoney(point_total)}</td>
            <td className='py-5'>
                <div className='flex justify-center'>
                    <InputFormThin
                        classNameAll='w-[195px]'
                        className='text-center !py-1 !px-3 !text-14_20 !rounded-8'
                        placehoder='차감할 적립금을 입력하세요'
                        name='plus_point'
                        type='number'
                        value={formik.values.plus_point}
                        onChange={formik.handleChange}
                    />
                </div>
            </td>
            <td className='py-5'>
                <div className='flex justify-center'>
                    <InputFormThin
                        classNameAll='w-[195px]'
                        className='text-center !py-1 !px-3 !text-14_20 !rounded-8'
                        placehoder='차감할 적립금을 입력하세요'
                        name='minus_point'
                        type='number'
                        value={formik.values.minus_point}
                        onChange={formik.handleChange}
                    />
                </div>
            </td>
            <td>
                <ButtonPrimary text='저장' className='!py-1 !px-3 !text-14_20' onClick={formik.handleSubmit} />
            </td>
        </tr>
    )
}
