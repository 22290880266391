import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get all coupon admin
export const getAllCouponAdmin = createAsyncThunk(
    'coupon/getallAdmin',
    async (page) => {
        const response = await get(`management/usercoupon?page=${page}`)

        return response
    }
)

// search coupon admin
export const searchCouponAdmin = createAsyncThunk(
    'coupon/search',
    async (data) => {
        const { page, condition, text } = data

        const response = await get(`management/usercoupon/search?page=${page}&condition=${condition}&text=${text}`)

        return response
    }
)

// create coupon admin
export const createCouponAdmin = createAsyncThunk(
    'coupon/create',
    async (data) => {
        const response = await post(`management/usercoupon/create-coupon`, data)

        return response
    }
)

// get detail coupon of user admin
export const getCouponByUser = createAsyncThunk(
    'coupon/byUser',
    async (userId) => {
        const response = await get(`management/usercoupon/${userId}`)

        return response
    }
)

// delete coupon of user admin
export const deleteCouponByUser = createAsyncThunk(
    'coupon/delete',
    async (couponId) => {
        const response = await del(`management/usercoupon/delete-coupon/${couponId}`)

        return response
    }
)