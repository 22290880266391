import React, { useEffect, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import RadioForm from '../../../components/RadioForm'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCommentByProduct } from '../../../reduxs/actions/comment.action'
import CommentItem from '../../../components/user/CommentItem'
import LoadingUser from '../../../pages/user/LoadingUser'

export default function CommentSectionProduct() {
    const dispatch = useDispatch()

    const [typeComment, setTypeComment] = useState('comment')

    const { loadingGetCommentProduct, dataCommentProduct } = useSelector(state => state.comment)

    const { id } = useParams()

    const handleChoosetype = (e) => {
        setTypeComment(e.target.value)
    }

    useEffect(() => {
        const dataSubmit = {
            type: typeComment,
            letterId: id
        }

        dispatch(getCommentByProduct(dataSubmit))
    }, [typeComment, id])

    if (loadingGetCommentProduct || !dataCommentProduct) {
        return <LoadingUser />
    }

    return (
        <section className='flex flex-col'>
            <TitleSection title='리뷰' small />

            <div className='flex gap-5 lg:mb-5 mb-4'>
                <RadioForm content='모든 댓글' value='comment' onChange={handleChoosetype} isCheck={typeComment === 'comment'} />
                <RadioForm content='이미지' value='picture' onChange={handleChoosetype} isCheck={typeComment === 'picture'} />
            </div>

            <div>
                {
                    dataCommentProduct.map(item =>
                        <CommentItem
                            key={item.id}
                            commentId={item.id}
                            name={item.name}
                            content={item.content}
                            createdAt={item.created_at}
                            isReply={false}
                            mark={item.mark}
                            picture={item.pictures}
                        />)
                }
            </div>
        </section>
    )
}
