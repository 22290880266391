import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import ListReviewProduct from '../../sections/admin/reviewProduct/ListReviewProduct'

export default function ReviewProductAdmin() {
    return (
        <>
            <TitleSection title='리뷰 목록' mt={false} />

            <ListReviewProduct />
        </>
    )
}
