import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/apis";

// get all post
export const getPosts = createAsyncThunk(
    'post/getAll',
    async () => {
        const response = await get(`home/getallpost?page=1`)

        return response
    }
)

// get post detail
export const getDetailpost = createAsyncThunk(
    'post/getdetail',
    async (postId) => {
        const response = await get(`home/post/${postId}`)

        return response
    }
)

// get post comment
export const getPostComment = createAsyncThunk(
    'post/getcomment',
    async (postId) => {
        const response = await get(`home/postcomment/${postId}`)

        return response
    }
)

// comment post
export const commentPost = createAsyncThunk(
    'post/commentPost',
    async (data) => {
        const response = await post(`home/postcomment`, data)

        return response
    }
)
