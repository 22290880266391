export const filterByUser = [
    {
        id: 1,
        name: 'ID'
    },
    {
        id: 2,
        name: '보내는분'
    },
    {
        id: 3,
        name: '받는분'
    },
    {
        id: 4,
        name: '연락처'
    },
]

export const filterByUserPdf = [
    {
        id: 1,
        name: 'ID'
    },
    {
        id: 3,
        name: '받는분'
    },
    {
        id: 4,
        name: '연락처'
    },
]

export const filterByStatus = [
    {
        id: 1,
        name: '접수확인 중'
    },
    {
        id: 2,
        name: '제작중'
    },
    {
        id: 3,
        name: '발송대기중'
    },
    {
        id: 4,
        name: '발송중'
    },
    {
        id: 5,
        name: '배송완료'
    },
    {
        id: 6,
        name: '취소'
    },
    {
        id: 7,
        name: '환불'
    },
]

export const filterByAccount = [
    {
        id: 1,
        name: '이메일'
    },
    {
        id: 2,
        name: '성함'
    },
    {
        id: 3,
        name: '연락처'
    },
]

export const filterEditPoint = [
    {
        id: 1,
        name: '이메일'
    },
    {
        id: 2,
        name: '성함'
    },
]