import { Eye, EyeSlash } from 'iconsax-react'
import React, { useState } from 'react'

export default function InputForm({ tabIndex, type = 'text', className, classNameAll, name, onChange, value, placehoder, error }) {
    const [isChangeText, setIsChangeText] = useState(false)

    const handleChangeTypeText = () => {
        setIsChangeText(!isChangeText)
    }

    return (
        <div className={`${classNameAll}`}>
            <div className='relative'>
                <input
                    type={isChangeText ? 'text' : type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placehoder}
                    tabIndex={tabIndex}
                    // autoComplete='off'
                    className={`${className} shadow-input rounded-12 w-full lg:py-2 lg:px-4 py-1.5 px-3 max-lg:text-14_20 placeholder:text-deactive ${type === 'password' && 'pr-[52px]'}`}
                />

                {
                    type === 'password'
                    &&
                    <button className='absolute top-1/2 -translate-y-1/2 right-4 w-fit' type='button' onClick={handleChangeTypeText}>
                        {
                            isChangeText
                                ?
                                <Eye size={16} color='#bababa' />
                                :
                                <EyeSlash size={16} color='#bababa' />
                        }
                    </button>
                }
            </div>

            {
                error
                &&
                <span className='lg:text-12 text-red text-10 line-clamp-1 mt-2.5'>{error}</span>
            }
        </div>
    )
}
