import React, { useContext } from 'react'
import TitlePage from '../../components/user/TitlePage'
import StepLetters from '../../components/user/StepLetters'
import WritingStep from '../../sections/user/WritingLetter.js/WritingStep'
import UploadLetterProvider, { UploadLetterContext } from '../../contexts/uploadLetterContext'
import UploadPdf from '../../sections/user/WritingLetter.js/UploadPdf'
import SelectAddress from '../../sections/user/WritingLetter.js/SelectAddress'
import CheckingStep from '../../sections/user/WritingLetter.js/CheckingStep'
import PaymentStep from '../../sections/user/WritingLetter.js/PaymentStep'

export default function WritingLetter() {
    return (
        <UploadLetterProvider>
            <WritingLetterContent />
        </UploadLetterProvider>
    )
}

const WritingLetterContent = () => {
    const { stepUpload, handleNavigateStep } = useContext(UploadLetterContext)

    return (
        <main className='flex-grow lg:py-10 pt-2 pb-4 flex flex-col lg:gap-10 gap-6 max-lg:px-6 container'>
            <TitlePage title='편지작성' />
            <StepLetters stepUpload={stepUpload} />

            {
                stepUpload === 1 && <WritingStep />
            }

            {
                stepUpload === 2 && <UploadPdf handleNavigateStep={handleNavigateStep} />
            }

            {
                stepUpload === 3 && <SelectAddress handleNavigateStep={handleNavigateStep} />
            }

            {
                stepUpload === 4 && <CheckingStep handleNavigateStep={handleNavigateStep} />
            }

            {
                stepUpload === 5 && <PaymentStep handleNavigateStep={handleNavigateStep} />
            }
        </main>
    )
}
