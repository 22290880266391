import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get all categories
export const getAllCategories = createAsyncThunk(
    'category/getall',
    async (productType) => {
        const response = await get(`/category/product/${productType}`)

        return response
    }
)

// get all categories admin
export const getAllCategoriesAdmin = createAsyncThunk(
    'category/getalladmin',
    async () => {
        const response = await get(`/category/allcategory`)

        return response
    }
)

// create categories admin
export const createCategoriesAdmin = createAsyncThunk(
    'category/create',
    async (data) => {
        const response = await post(`/category/create`, data)

        return response
    }
)

// update categories admin
export const updateCategoriesAdmin = createAsyncThunk(
    'category/update',
    async (data) => {
        const response = await post(`/category/update`, data)

        return response
    }
)

// delete categories admin
export const deleteCategoriesAdmin = createAsyncThunk(
    'category/delete',
    async (categoryId) => {
        const response = await del(`/category/deletecategory/${categoryId}`)

        return response
    }
)

export const getEmojis = createAsyncThunk(
    'category/getEmoji',
    async () => {
        const response = await get(`category/icons`)

        return response
    }
)