import React, { useEffect, useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import InputFormThin from '../../../components/InputFormThin'
import LabelFormThin from '../../../components/LabelFormThin'
import { AddCircle, CloseSquare } from 'iconsax-react'
import TextareaForm from '../../../components/TextareaForm'
import Dropdown from '../../../components/admin/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategories } from '../../../reduxs/actions/category.action'

export default function FormUpload({ formik, descriptionImage, setDescriptionImage }) {
    const dispatch = useDispatch()

    const descriptionImageRef = useRef()

    const { dataCategories } = useSelector(state => state.categories)

    const handleClickNormalImage = () => {
        descriptionImageRef.current.click()
    }

    const handleChooseDescriptionImage = (e) => {
        const filesArray = Array.from(e.currentTarget.files)

        const handleFiles = filesArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const files = filesArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const customImage = [
            ...descriptionImage,
            ...handleFiles
        ].slice(0, 4)

        const customImageForm = [
            ...formik.values.descriptions,
            ...files
        ].slice(0, 4)

        setDescriptionImage(customImage)
        formik.setFieldValue('descriptions', customImageForm)

        e.target.value = null;
    }

    const handleDeleteDescription = (file) => {
        const handleFiles = descriptionImage.filter(item => item.id !== file)
        const handleDeleteFormik = formik.values.descriptions.filter(item => item.id !== file)

        setDescriptionImage(handleFiles)
        formik.setFieldValue('descriptions', handleDeleteFormik)
    }

    useEffect(() => {
        dispatch(getAllCategories(1))
    }, [])

    return (
        <section className='w-7/12 grid gap-5 h-fit'>
            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title='정보' small mt={false} />

                <div>
                    <LabelFormThin label='상품명 (필수)' />
                    <InputFormThin
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placehoder='텍스트입력'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>

                <div className='grid gap-2.5'>
                    <div className='flex justify-between items-center'>
                        <LabelFormThin label='편지지 세부 사항' mt={false} className='mb-0' />
                        <p>{formik.values.descriptions.length}/4 사진</p>
                    </div>

                    <div className='grid grid-cols-4 gap-2.5'>
                        <input type='file' accept='image/*' multiple ref={descriptionImageRef} hidden onChange={handleChooseDescriptionImage} />

                        {
                            (descriptionImage && (descriptionImage.length > 0)) ?
                                <>
                                    {
                                        descriptionImage.map(item => <ImageUploadItem key={item.id} id={item.id} image={item.file} handleDelete={handleDeleteDescription} />)
                                    }

                                    {descriptionImage.length < 4 &&
                                        Array(4 - descriptionImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickNormalImage}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title='상품 세부정보' small mt={false} />

                <div>
                    <LabelFormThin label='기본제공 (필수)' />
                    <InputFormThin
                        placehoder='내용을 입력하세요'
                        name='basic_provision'
                        height={false}
                        onChange={formik.handleChange}
                        value={formik.values.basic_provision}
                        error={(formik.touched.basic_provision && formik.errors.basic_provision) && formik.errors.basic_provision}
                    />
                </div>

                <div>
                    <LabelFormThin label='추가제공' />
                    <TextareaForm
                        name='addition_offer'
                        onChange={formik.handleChange}
                        value={formik.values.addition_offer}
                        placeholder="내용을 입력하세요"
                    />
                </div>

                {/* <div>
                    <LabelFormThin label='장당 한 장당 (필수)' />
                    <InputFormThin
                        placehoder='내용을 입력하세요'
                        name='number_line'
                        type='number'
                        onChange={formik.handleChange}
                        value={formik.values.number_line}
                    />
                </div> */}

                <div>
                    <LabelFormThin label='장당 글자수' />
                    <InputFormThin
                        name='number_character'
                        type='number'
                        onChange={formik.handleChange}
                        value={formik.values.number_character}
                        placehoder='내용을 입력하세요'
                    />
                </div>
            </div>

            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title='가격 및 재고' small mt={false} />

                <div className='grid grid-cols-2 gap-5'>
                    <div className='flex flex-col'>
                        <LabelFormThin label='가격 (필수)' />
                        <InputFormThin
                            height={false}
                            name='price'
                            type='number'
                            onChange={formik.handleChange}
                            value={formik.values.price}
                            placehoder='가격을 입력하세요'
                            className=''
                            classNameAll='flex-grow'
                            error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <LabelFormThin label='할인' />

                        <div className='relative'>
                            <InputFormThin
                                height={false}
                                placehoder='할인을 입력하세요'
                                name='discount'
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.discount}
                                error={(formik.touched.discount && formik.errors.discount) && formik.errors.discount}
                            />

                            <span className='absolute top-1/2 -translate-y-1/2 right-5'>%</span>
                        </div>
                    </div>
                    <div>
                        <LabelFormThin label='범주 (필수)' />
                        <Dropdown
                            title='편지지'
                            className='w-full'
                            data={dataCategories}
                            name='name'
                            isChoose={formik.values.category_id}
                            handleChoose={(id) => formik.setFieldValue('category_id', id)}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <LabelFormThin label='재고 (필수)' />
                        <InputFormThin
                            height={false}
                            name='quantity'
                            type='number'
                            onChange={formik.handleChange}
                            value={formik.values.quantity}
                            placehoder='수량을 입력하세요'
                            className=''
                            classNameAll='flex-grow'
                            error={(formik.touched.quantity && formik.errors.quantity) && formik.errors.quantity}
                        />
                    </div>
                    {
                        /*
                        <div className='flex flex-col'>
                        <LabelFormThin label='추가된 날짜' />
                        <InputFormThin placehoder='YYYY/MM/DD' className='h-full' classNameAll='flex-grow' />
                        </div> 
                        */
                    }

                </div>
            </div>
        </section>
    )
}

export const ImageUploadItem = ({ id, image, handleDelete, handleSelect }) => {
    return (
        <div className={`relative ${image ? '' : 'border-dashed border-[2px] rounded-20 aspect-video overflow-hidden'}`}>
            {
                image
                    ?
                    <>
                        <img src={image} alt='' className='w-full aspect-video rounded-20 object-cover' />
                        <button type='button' className='absolute top-0 right-0 p-2.5' onClick={() => handleDelete(id)}><CloseSquare size={16} className=' hover:text-logoColor duration-300 text-deactive' /></button>
                    </>
                    :
                    <div onClick={handleSelect} className='cursor-pointer flex justify-center items-center h-full'>
                        <AddCircle size={20} className='hover:text-logoColor' />
                    </div>
            }
        </div>
    )
}
