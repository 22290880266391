import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import { ArrowDown2 } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllFaqUser } from '../../reduxs/actions/faq.action'
import moment from 'moment/moment'
import LoadingUser from './LoadingUser'

export default function FQAPage() {
    const dispatch = useDispatch()

    const { loadingGetAllFaqUser, dataFaqUser } = useSelector(state => state.faq)

    const [isShowItem, setIsIsShowItem] = useState(0)

    const handleShow = (id) => {
        if (id === isShowItem) return setIsIsShowItem(0)
        setIsIsShowItem(id)
    }

    useEffect(() => {
        dispatch(getAllFaqUser())
    }, [])

    if (loadingGetAllFaqUser || !dataFaqUser) {
        return <LoadingUser />
    }

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col gap-10 container max-lg:px-5'>
            <TitlePage title='FAQ 게시판' />

            <article>
                <div className='flex justify-between pb-2.5 border-b border-strock'>
                    <p>제목</p>
                    <p></p>
                </div>

                {
                    dataFaqUser.map(item =>
                        <RowFaqItem
                            key={item.id}
                            question={item.question}
                            answer={item.answer}
                            created_at={item.created_at}
                            isShow={isShowItem === item.id}
                            handleShow={() => handleShow(item.id)}
                        />)
                }
            </article>
        </main>
    )
}

const RowFaqItem = ({ question, answer, created_at, handleShow, isShow }) => {
    return (
        <div className='border-b border-strock py-5 flex gap-2 justify-between'>
            <div className='flex items-start gap-2'>
                <ArrowDown2 size={20} className={`${isShow ? 'rotate-180' : ''} duration-300 flex-shrink-0`} />

                <div className='flex flex-col gap-5'>
                    <button className='flex items-center gap-2' onClick={handleShow}>
                        <p className='line-clamp-1 hover:text-logoColor duration-300 max-lg:text-14_20'>{question}</p>
                    </button>
                    <div className={`max-lg:text-14_20 ${isShow ? '' : 'hidden'}`}>{answer}</div>
                </div>
            </div>

            <span className='max-lg:text-14_20'>{moment(created_at).format('YYYY.MM.DD')}</span>
        </div>
    )
}
