import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDetailpost } from '../../../reduxs/actions/posts.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import { URL_SERVER } from '../../../utils/apis'
import { convertFromRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

export default function ContentPostSection() {
    const dispatch = useDispatch()

    const { post } = useParams()

    const { loadingGetDetailPost, dataDetailPost } = useSelector(state => state.post)

    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const content = dataDetailPost?.content

    useEffect(() => {
        dispatch(getDetailpost(post))
    }, [post])

    useEffect(() => {
        if (content) {
            const contentEditor = convertFromRaw(JSON.parse(content))
            const newEditor = EditorState.createWithContent(contentEditor)

            setEditorState(newEditor)
        }
    }, [content])

    if (loadingGetDetailPost || !dataDetailPost) {
        return <LoadingUser />
    }

    const { title, name, created_at, interact_count, picture } = dataDetailPost

    return (
        <section className='flex flex-col lg:gap-5 gap-4'>
            <h3 className='text-blue font-semibold lg:text-24-30 ' >{title}</h3>

            <div className='flex lg:gap-5 gap-4 max-lg:flex-col max-lg:text-14_20'>
                <div className='flex gap-2'>
                    <p>작성자:</p>
                    <span className='font-semibold text-logoColor'>{name}</span>
                </div>
                <div className='flex gap-2'>
                    <p>작성일:</p>
                    <span className=''>{moment(created_at).format('YYYY-MM-DD hh:mm')}</span>
                </div>
                <div className='flex gap-2'>
                    <p>조회:</p>
                    <span className=''>{interact_count}</span>
                </div>
            </div>

            <div className='w-full'>
                <Editor
                    editorState={editorState}
                    readOnly
                    toolbarHidden
                />
            </div>

            {
                picture.map(item =>
                    <img
                        key={item.id}
                        src={`${URL_SERVER}${item.filename}`}
                        alt='make by me post detail'
                        className='aspect-video rounded-20 object-cover'
                    />)
            }
        </section>
    )
}
