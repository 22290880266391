import React, { useEffect, useRef, useState } from 'react'
import logo from '../../assets/images/logo.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ArrowDown2, LoginCurve, ProfileCircle } from 'iconsax-react'
import NavbarHidden from './NavbarHidden'
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowNotifyNotLogin } from '../../reduxs/reducers/auth.reducer'

export default function HeaderUser() {
    const { dataGetAccount, isAuthentication } = useSelector(state => state.auth)

    const name = dataGetAccount?.name

    const { pathname } = useLocation()

    return (
        <>
            <header className={`${pathname === '/' ? 'lg:fixed sticky' : 'sticky'} top-0 left-0 w-full px-layout flex items-center justify-between text-black max-lg:px-6 max-lg:py-3 bg-white z-50`}>
                <Link to='/'>
                    <img src={logo} alt='make by me' className='inline-block lg:w-[60px] w-10 aspect-square object-contain' />
                </Link>

                <nav className='max-lg:hidden'>
                    <ul className='flex'>
                        <NavItem nav='홈' link='/' />
                        <NavItem nav='편지지' link='products' />
                        <NavItem nav='PDF 접수' link='pdf' isAuthentication={isAuthentication} />
                        <NavDropdownItem nav='MADEBYME ONLY' />
                    </ul>
                </nav>

                <div className='flex'>
                    {
                        !isAuthentication
                            ?
                            <Link to='/auth' type='button' className='px-2.5'>
                                <LoginCurve />
                            </Link>
                            :
                            <Link to='my-page' className='lg:flex items-center gap-1 hidden strock1'>
                                <ProfileCircle />
                                <p className='flex-grow max-lg:text-14_20 translate-y-[1px] line-clamp-1 max-w-[100px]'>{name}</p>
                            </Link>
                    }


                    <NavbarHidden />
                </div>
            </header>

        </>
    )
}

export const NavItem = ({ nav, link = '#', isAuthentication = true }) => {
    const styleDefault = 'px-6'

    const dispatch = useDispatch()

    const handleNavigate = (e) => {
        if (!isAuthentication) {
            e.preventDefault(); // Ngăn điều hướng nếu chưa xác thực
            dispatch(setIsShowNotifyNotLogin());
        }
    }

    return (
        <li>
            <NavLink to={isAuthentication ? link : ''} onClick={handleNavigate} className={({ isActive }) =>
                (isActive && isAuthentication) ? `${styleDefault} text-logoColor` : `${styleDefault}`
            }>{nav}</NavLink>
        </li>
    )
}

export const NavDropdownItem = ({ nav }) => {
    const [isShow, setIsShow] = useState(false)

    const styleDefault = 'py-2.5 px-4 block text-center'

    const { pathname } = useLocation()

    const contentRef = useRef()

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <li className='relative' ref={contentRef}>
            <button className={`${(pathname === '/product-handmade' || pathname === '/product-event') ? 'text-logoColor' : ''} flex items-center gap-2.5 px-4`} onClick={handleShow}>{nav} <ArrowDown2 size={20} className={`-translate-y-[2px] duration-300 ${isShow ? 'rotate-180' : ''}`} /></button>

            {
                isShow
                &&
                <ul className='absolute top-full mt-2 right-0 bg-white shadow-dropdown rounded-20 w-[213px]'>
                    <li onClick={handleShow}>
                        <NavLink to='product-event' className={({ isActive, isPending }) =>
                            isActive ? `${styleDefault} text-logoColor` : `${styleDefault}`
                        }>공구</NavLink>
                    </li>

                    <li onClick={handleShow}>
                        <NavLink to='product-handmade' className={({ isActive, isPending }) =>
                            isActive ? `${styleDefault} text-logoColor` : `${styleDefault}`
                        }>자체제작 상품</NavLink>
                    </li>
                </ul>
            }
        </li>
    )
}
