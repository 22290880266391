import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get new product 
export const getNewProduct = createAsyncThunk(
    'product/newproduct',
    async () => {
        const response = await get(`home/product/new-popular`)

        return response
    }
)

// get all product
export const getAllProducts = createAsyncThunk(
    'product/getall',
    async (data) => {
        const { productId, categoryId, page } = data

        const response = await get(`/product/${productId}?category=${categoryId}&page=${page}`)

        return response
    }
)

// get product by category
export const getProductsByCategory = createAsyncThunk(
    'product/getproductbycategory',
    async (data) => {
        const { categoryId, productId, page } = data

        const response = await get(`/product/${productId}?category=${categoryId}&page=${page}`)

        return response
    }
)

// get product by type
export const getProductsByType = createAsyncThunk(
    'product/getproductbytype',
    async (productType) => {
        const response = await get(`/product/type/${productType}`)

        return response
    }
)

// get product detail
export const getProductDetail = createAsyncThunk(
    'product/getproductdetail',
    async (productId) => {
        const response = await get(`/product/detail/${productId}`)

        return response
    }
)

// get form letter
export const getFormLetter = createAsyncThunk(
    'product/getFormLetter',
    async (letterId) => {
        const response = await get(`product/getformletter/${letterId}`)

        return response
    }
)

// auto save letter
export const autoSaveLetter = createAsyncThunk(
    'product/autoSaveLetter',
    async (data) => {
        const response = await post(`product/autosave`, data)

        return response
    }
)

// manualsave letter
export const manualSaveLetter = createAsyncThunk(
    'product/manualSaveLetter',
    async (data) => {
        const response = await post(`product/manualsave`, data)

        return response
    }
)

// manual save letter
export const getSavedLetter = createAsyncThunk(
    'product/getSavedLetter',
    async (letterId) => {
        const response = await get(`product/savedletter/${letterId}`)

        return response
    }
)

// get delete letter
export const getDeleteLetter = createAsyncThunk(
    'product/getDeleteLetter',
    async (letterId) => {
        const response = await get(`product/deletedletter/${letterId}`)

        return response
    }
)

// get saved letter by id
export const getSavedLetterById = createAsyncThunk(
    'product/getSavedLetterById',
    async (letterId) => {
        const response = await get(`product/savedletter/detail/${letterId}`)

        return response
    }
)

// delete saved letter
export const deleteSavedLetter = createAsyncThunk(
    'product/deleteSavedLetter',
    async (letterId) => {
        const response = await del(`product/deletedletter/${letterId}`)

        return response
    }
)

export const getDetailMaterial = createAsyncThunk(
    'product/materialdetail',
    async (productId) => {
        const response = await get(`product/detailmaterial/${productId}`)

        return response
    }
)

/// =================== admin ===================
export const getAllProductAdmin = createAsyncThunk(
    'product/getAllAdmin',
    async (data) => {
        const { productType, page, category } = data

        const response = await get(`/productmanage/adminsearchproduct/${productType}?page=${page}&category=${category || ''}`)

        return response
    }
)

// create product normal
export const createProductNormal = createAsyncThunk(
    'product/createNormal',
    async (data) => {
        const response = await post(`/productmanage/postoffice`, data)

        return response
    }
)

// create product event
export const createProductEvent = createAsyncThunk(
    'product/createEvent',
    async (data) => {
        const response = await post(`/productmanage/eventproduct`, data)

        return response
    }
)

// create product handmade
export const createProductHandmade = createAsyncThunk(
    'product/createhandmade',
    async (data) => {
        const response = await post(`/productmanage/makebymeproduct`, data)

        return response
    }
)

// delete product 
export const deleteProductAdmin = createAsyncThunk(
    'product/delete',
    async (data) => {
        const { productId, page } = data

        const response = await del(`productmanage/${productId}?page=${page}`)

        return response
    }
)
