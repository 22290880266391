import React from 'react'
import FilterOrders from '../../sections/admin/orders/FilterOrders'
import TitleSection from '../../components/user/TitleSection'
import ListPdfOrder from '../../sections/admin/orders/ListPdfOrder'

export default function OrderPdfAdmin() {
    return (
        <>
            <TitleSection title='주문내역' mt={false} />

            <FilterOrders type='pdf-order' />

            <ListPdfOrder />
        </>
    )
}
