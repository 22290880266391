import React, { useRef, useState } from 'react'
import ShippingItem from '../../../components/admin/ShippingItem'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { AttachSquare } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { updateStampAdmin } from '../../../reduxs/actions/management.action'

export default function ShippingOption({ data }) {
    const inputRef = useRef()
    const dispatch = useDispatch()

    const [isShowPopup, setIsShowPopup] = useState(false)

    const formik = useFormik({
        initialValues: {
            newPicture: '',
            name: '',
            price: '',
            pdfId: ''
        },
        validationSchema: Yup.object({
            price: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('newPicture', values.newPicture)
            formData.append('name', values.name)
            formData.append('price', values.price)

            const result = await dispatch(updateStampAdmin({ id: values.pdfId, formData }))

            const res = result.payload

            if (res.status) {
                handleShowPopup()
            } else {
                formik.setFieldError('name', res.message)
            }
        }
    })

    const handleClickImage = () => {
        inputRef.current.click()
    }

    const handleShowPopup = (id) => {
        if (isShowPopup) formik.resetForm()

        setIsShowPopup(!isShowPopup)
        formik.setFieldValue('pdfId', formik.values.pdfId ? '' : id)
    }

    return (
        <div className='grid grid-cols-4 gap-5'>
            {
                data.map(item =>
                    <ShippingItem
                        key={item.id}
                        name={item.name}
                        title='배송옵션 수정'
                        price={item.price}
                        image={item.filename}
                        onClick={() => handleShowPopup(item.id)}
                    />)
            }

            {
                (isShowPopup && formik.values.pdfId)
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                    <form onSubmit={formik.handleSubmit} className='w-[600px] bg-white rounded-20 flex flex-col items-center gap-5 p-5'>
                        <h3 className='text-24-30 font-semibold'>편집</h3>

                        <div className='w-full grid gap-5'>
                            {
                                formik.values.pdfId !== 5
                                &&
                                <div>
                                    <div className='flex justify-between items-center'>
                                        <LabelFormThin label='첨부파일 (.jpg)' />
                                        <button type='button' onClick={handleClickImage}><AttachSquare size={20} className='strock1' /></button>
                                    </div>

                                    <input type='file' accept='/image' name='newPicture' hidden ref={inputRef} onChange={(e) => formik.setFieldValue('newPicture', e.currentTarget.files[0])} />
                                    <InputFormThin
                                        name='newPicture'
                                        value={formik.values.newPicture.name || ''}
                                        onChange={formik.handleChange}
                                        placehoder='첨부파일'
                                        error={(formik.touched.newPicture && formik.errors.newPicture) && formik.errors.newPicture}
                                        onlyView
                                    />
                                </div>
                            }
                            <div>
                                <LabelFormThin label='상품명' />
                                <InputFormThin
                                    name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    placehoder='첨부파일'
                                    error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='가격' />
                                <InputFormThin
                                    name='price'
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                    placehoder='첨부파일'
                                    error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                                />
                            </div>
                        </div>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
