import React, { useEffect, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { formatMoney } from '../../../utils/renderText'

export default function TotalFilePdf({ pdfFiles }) {
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        if (pdfFiles) {
            const totalprice = pdfFiles.reduce((totalprice, item) => totalprice + (item.price * item.pdf_page), 0)

            setTotalPrice(totalprice)
        }
    }, [pdfFiles])

    return (
        <section className='w-full flex flex-col items-center gap-5'>
            <div className='flex justify-between items-center w-full max-lg:text-14_20'>
                <div className='flex gap-2 items-center'>
                    <p>자료:</p>
                    <span className='text-logoColor font-bold'>{pdfFiles.length}장</span>
                </div>

                <div className='flex gap-2 items-center'>
                    <p>총액:</p>
                    <span className='text-logoColor font-bold'>{formatMoney(totalPrice)} 원</span>
                </div>
            </div>

            <div className='grid gap-2.5 w-full'>
                {
                    pdfFiles.map(item => <FilePdfTotalItem key={item.id} file={item.pdf_name} page_count={item.pdf_page} />)
                }
            </div>
        </section>
    )
}

const FilePdfTotalItem = ({ file, page_count }) => {
    const { name } = file

    const [totalPage, setTotalPage] = useState(0)

    useEffect(() => {
        if (name) {
            const fileReader = new FileReader();

            fileReader.onload = async () => {
                const arrayBuffer = fileReader.result;

                const pdfDoc = await PDFDocument.load(arrayBuffer);

                // get page number
                const numPages = pdfDoc.getPageCount();

                setTotalPage(numPages); // update page number
            };

            fileReader.readAsArrayBuffer(file)
        }
    }, [name])

    return (
        <div className='max-lg:text-14_20'>
            <p className='brak-all line-clamp-1'>{name || file}</p>
            <span className='text-logoColor'>(PDF: {totalPage || page_count}장 컬러)</span>
        </div>
    )
}
