import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { TruckFast } from 'iconsax-react'
import { uploadLetterEventContext } from '../../../contexts/uploadLetterEventContext'
import { Table } from '../WritingLetter.js/PaymentStep'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUser from '../../../pages/user/LoadingUser'
import PopupFailPayment from '../../../pages/user/PopupFailPayment'
import { checkoutOrderEvent } from '../../../reduxs/actions/order.action'
import { addSaveDataUpload, clearOrderLetterSelected } from '../../../reduxs/reducers/order-letter.reducer'
import { loadTossPayments } from '@tosspayments/tosspayments-sdk'
import PopupShowTimeNotify from '../../../components/user/PopupShowTimeNotify'
import PopupShowNotifyBanking from '../../../components/user/PopupShowNotifyBanking'
import { formatMoney } from '../../../utils/renderText'

export default function CheckoutStep() {
    const paymentRef = useRef()

    const dispatch = useDispatch()

    const { formUpload, setFormUpload, handleStepOrder } = useContext(uploadLetterEventContext)

    const { dataCheckPaymentEventLlt } = useSelector(state => state.order)

    const money_total = dataCheckPaymentEventLlt?.money_total
    const ship_fee = dataCheckPaymentEventLlt?.ship_fee

    const [widgets, setWidgets] = useState(null)
    const [paymentMethodWidget, setPaymentMethodWidget] = useState(null);
    const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false)

    const [typePayment, setTypePayment] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const [coupon, setCoupon] = useState(0)

    // notify 
    const [isShowNotifyPayByPoint, setIsShowNotifyPayBypoint] = useState(false)
    const [isShowNotifySuccessPayment, setIsShowNotifySuccessPayment] = useState(false)

    const [isShowTimeNotify, setIsShowTimeNotify] = useState(false)
    const [isShowBankingNotify, setIsShowBankingNotify] = useState(false)

    const handleChooseTypePayment = (e) => {
        const type = Number(e.target.value)

        if (type === 1) {
            if (user_point < 1000) return setIsShowNotifyPayBypoint('현재 점수가 1000점 이하이므로 사용할 수 없습니다.')

            setCoupon(user_point)
            setFormUpload({
                ...formUpload,
                pay_method_id: 1
            })
        } else {
            setCoupon(0)

            setFormUpload({
                ...formUpload,
                pay_method_id: type,

            })
        }
        setTypePayment(type)
    }

    const handlePaymentByPoint = async () => {
        if (coupon < totalPayment || user_point < 1000) {
            return setIsShowNotifyPayBypoint(true)
        }

        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            money_total: money_total,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            bonus_point: (totalPayment * bonus_percent) / 100,
        }

        const result = await dispatch(checkoutOrderEvent(customDataUpload))

        const res = result.payload

        if (res.status) {
            setIsShowTimeNotify(true)
            dispatch(clearOrderLetterSelected())
        } else {
            setIsShowNotifySuccessPayment(res.message)
        }

        setTimeout(() => {
            setIsShowNotifySuccessPayment(false)
        }, 3000);
    }

    const handleBanking = () => {
        setIsShowBankingNotify(true)
    }

    const handlePaymentByBanking = async () => {
        setIsShowBankingNotify(false)

        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            money_total: money_total,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            bonus_point: (totalPayment * bonus_percent) / 100,
        }

        const result = await dispatch(checkoutOrderEvent(customDataUpload))

        const res = result.payload

        if (res.status) {
            setIsShowTimeNotify(true)
        } else {
            setIsShowNotifySuccessPayment(res.message)
        }
    }

    const handlePaymentByCard = async () => {
        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            money_total: money_total,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            bonus_point: (totalPayment * bonus_percent) / 100,
            visapay_id: visapay_id
        }

        dispatch(addSaveDataUpload(customDataUpload))

        try {
            if (paymentMethodWidget && typeof paymentMethodWidget.destroy === "function") {
                await paymentMethodWidget.destroy();
                setPaymentMethodWidget(null);  // Xóa widget khỏi trạng thái
            }

            setIsShowPaymentMethod(true);

            // Đặt số tiền thanh toán
            await widgets.setAmount({
                currency: "KRW",
                value: totalPayment, // totalPayment
            });

            // Render widget phương thức thanh toán
            const paymentMethod = await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: "DEFAULT",
            });

            setPaymentMethodWidget(paymentMethod);  // Lưu widget vào state
        } catch (error) {
            console.error("Lỗi trong quá trình xử lý thanh toán:", error);
            await paymentMethodWidget.destroy();
            setPaymentMethodWidget(null);
        }
    }

    // confirm payment card
    const confirmPaymentCard = async () => {
        try {
            await widgets.requestPayment({
                orderId: visapay_id,
                orderName: product_name,
                successUrl: window.location.origin + "/successEvent",
                failUrl: window.location.origin + "/fail",
                customerEmail: email,
                customerName: name,
                customerMobilePhone: phone_number,
            });
        } catch (error) {
            console.log(error)
        }
    }

    // config widgets
    useEffect(() => {
        const initializeToss = async () => {
            const clientKey = process.env.REACT_APP_CLIENT_KEY_PAYMENT;
            const tossPayments = await loadTossPayments(clientKey);
            const customerKey = process.env.REACT_APP_CUSTOMER_KEY_PAYMENT;

            const widgetsInstance = tossPayments.widgets({
                customerKey,
            });
            setWidgets(widgetsInstance);
        };

        initializeToss();
    }, [])

    // handle show
    useEffect(() => {
        async function handleClickOutside(event) {
            if (paymentRef.current && !paymentRef.current.contains(event.target)) {
                setIsShowPaymentMethod(false)
            }
        }

        if (isShowPaymentMethod) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [paymentRef, isShowPaymentMethod]);

    // total payment
    useEffect(() => {
        if (money_total) {
            const totalPayments = formUpload?.coupon_percent ? money_total - ((money_total * formUpload?.coupon_percent) / 100) : money_total - (formUpload?.coupon_point || 0)
            setTotalPayment(totalPayments)
        }
    }, [formUpload, coupon, money_total, typePayment])

    if (!dataCheckPaymentEventLlt) {
        return <LoadingUser />
    }

    const { product_name, user_point, bonus_percent, phone_number, visapay_id, email, name } = dataCheckPaymentEventLlt

    return (
        <>
            <TitleSection title='결제방법' mt={false} />

            <section className='flex flex-col lg:gap-5 gap-4 w-full'>
                <div className='flex justify-between items-start w-full max-lg:flex-col gap-4 max-lg:text-14_20'>
                    <div className='flex gap-2 items-center'>
                        <TruckFast variant="Bold" size={20} />
                        <p>배송비:</p>
                        <span className='text-logoColor font-bold'>{formatMoney(ship_fee)}원</span>
                    </div>

                    <div className='flex flex-col gap-2.5'>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>총액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(money_total)}원</span>
                        </div>

                        {
                            coupon
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>사용 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(coupon)}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            coupon
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>남은 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(coupon - totalPayment)}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            (formUpload.coupon_percent || formUpload.coupon_point)
                            &&
                            <div className='flex gap-2 items-center justify-between'>
                                <p className='min-w-20'>쿠폰:</p>
                                <span className='text-logoColor font-bold w-[150px] text-end'>{formUpload.coupon_percent && `${formUpload.coupon_percent}%`} {formUpload.coupon_point && `${formatMoney(formUpload.coupon_point)}원`}</span>
                            </div>
                        }

                        <div className='flex gap-2 items-center justify-between'>
                            <p className='font-bold min-w-20'>총 구매금액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{(coupon - totalPayment) > 0 ? 0 : formatMoney(totalPayment)}원</span>
                        </div>
                    </div>
                </div>

                <Table
                    handleChooseTypePayment={handleChooseTypePayment}
                    typePayment={typePayment}
                    bonusPercent={bonus_percent}
                    userPoint={user_point}
                    isCheck={!!coupon}
                    totalPayment={totalPayment}
                    formUpload={formUpload}
                    setFormUpload={setFormUpload}
                />
            </section>

            <div className='flex gap-2.5 justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleStepOrder} />
                <ButtonPrimary text='구매하기' className='w-[160px] justify-center' onClick={typePayment === 1 ? handlePaymentByPoint : typePayment === 2 ? handleBanking : handlePaymentByCard} />
            </div>

            {
                isShowNotifyPayByPoint
                &&
                <PopupFailPayment content={isShowNotifyPayByPoint} handleShow={() => setIsShowNotifyPayBypoint(false)} />
            }

            {
                isShowPaymentMethod
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='w-[600px] bg-white rounded-20 p-5' ref={paymentRef}>
                        <div className='flex flex-col gap-5 items-center justify-center'>
                            <div id='payment-method' className='w-full'></div>
                            <ButtonSecon title='확인' onClick={confirmPaymentCard} />
                        </div>
                    </div>
                </div>
            }

            {
                isShowTimeNotify
                    ?
                    <PopupShowTimeNotify />
                    :
                    ''
            }


            {
                isShowBankingNotify
                    ?
                    <PopupShowNotifyBanking handleShow={() => setIsShowBankingNotify(false)} handleConfirm={handlePaymentByBanking} />
                    :
                    ''
            }

            <div className={`${isShowNotifySuccessPayment ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isShowNotifySuccessPayment}
            </div>
        </>
    )
}
