import { AttachSquare } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import PdfFileItem from '../../../components/user/PdfFileItem'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderFree } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { formatMoney } from '../../../utils/renderText'
import * as pdfjsLib from 'pdfjs-dist/webpack';

export default function ListPdfUpload(props) {
    const { pdfData, setPDfData, setPdfDataDelete, pdfDataUpload, setPdfDataUpload } = props

    const dispatch = useDispatch()

    const { dataOrderFee, loadingGetFee } = useSelector(state => state.order)

    const inputRef = useRef()

    const [pageCount, setPageCount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)

    const handleClickFile = () => {
        inputRef.current.click()
    }

    const onChangePdfFile = async (e) => {
        const files = e.currentTarget.files
        const filesArray = Array.from(files)

        const validFiles = filesArray.filter((file) => {
            const isPdf = file.type === 'application/pdf';
            const isUnder10MB = file.size < 10 * 1024 * 1024;
            if (!isPdf) {
                alert(`${file.name} is not a valid PDF file.`);
            }
            if (!isUnder10MB) {
                alert(`${file.name} exceeds the size limit of 10MB.`);
            }
            return isPdf && isUnder10MB;
        });

        const handleFile = validFiles.map((item, index) => {
            return {
                id: index + Date.now(),
                pdf_name: item,
                type: 1,
                price: dataOrderFee?.feePrint[0].price,
                pdf_page: 0
            }
        })

        const limitedFiles = [...pdfData, ...handleFile]

        const pageCountsArray = await Promise.all(
            handleFile.map(async (file) => {
                const fileReader = new FileReader()
                return new Promise((resolve, reject) => {
                    fileReader.onload = async (e) => {
                        try {
                            const typedArray = new Uint8Array(e.target.result)
                            const pdf = await pdfjsLib.getDocument(typedArray).promise
                            const numPages = pdf.numPages;

                            if (numPages > 60) reject(file.pdf_name)

                            resolve({ id: file.id, numPages: pdf.numPages })
                        } catch (error) {
                            alert(`${file.pdf_name} could not be processed.`)
                            reject('Error reading PDF file')
                        }
                    };
                    fileReader.onerror = () => reject('File reading failed')
                    fileReader.readAsArrayBuffer(file.pdf_name)
                });
            })
        );

        const updatedFiles = limitedFiles.map((file) => {
            const matchedPageCount = pageCountsArray.find((page) => page.id === file.id);

            if (matchedPageCount) {
                return {
                    ...file,
                    pdf_page: matchedPageCount.numPages
                };
            }

            return file
        });

        const fileUpload = handleFile.map((file) => {
            const matchedPageCount = pageCountsArray.find((page) => page.id === file.id);
            return {
                ...file,
                pdf_page: matchedPageCount ? matchedPageCount.numPages : 0 // Gán số trang
            };
        });

        setPDfData(updatedFiles)

        setPdfDataUpload(fileUpload)

        const totalPageCount = pageCountsArray.reduce((total, page) => total + page.numPages, 0);

        setPageCount(totalPageCount);

        e.target.value = null;
    }

    const handleDeleteFile = (id) => {
        const newArray = pdfData.filter(item => item.id !== id)
        const file = pdfData.find(item => item.id === id)

        if (!file.pdf_name.name) {
            setPdfDataDelete(pre => [
                ...pre,
                file.pdf_name
            ])
        } else {
            const handlePdfArray = pdfDataUpload.filter(item => item.id !== id)
            setPdfDataUpload(handlePdfArray)
        }

        setPageCount(pre => pre - file.pdf_page)
        setPDfData(newArray)
    }

    const handleChangeType = (e, id, price) => {
        const value = e.target.value

        const updateFilesArray = pdfData.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    type: Number(value),
                    price
                }
            }

            return item
        })

        setPDfData(updateFilesArray)
    }

    useEffect(() => {
        dispatch(getOrderFree())
    }, [])

    useEffect(() => {
        if (pdfData.length) {
            const total = pdfData.reduce((total, item) => total + (Number(item.price) * Number(item.pdf_page)), 0)
            const totalPage = pdfData.reduce((total, item) => total + item.pdf_page, 0)

            setTotalPrice(total)
            setPageCount(totalPage)
        } else {
            setTotalPrice(0)
            setPageCount(0)
        }
    }, [pdfData, pageCount])

    if (loadingGetFee || !dataOrderFee) {
        return <LoadingUser />
    }

    const { feePrint, limitPdf } = dataOrderFee

    return (
        <>
            <section className='flex flex-col items-center lg:gap-5 gap-4 w-full' >
                <div className='rounded-20 border-[2px] border-deactive border-dashed max-w-[536px] max-lg:text-14_20 w-full aspect-video text-center flex flex-col gap-2.5 items-center justify-center cursor-pointer' onClick={handleClickFile}>
                    <input type='file' accept='.pdf' multiple hidden ref={inputRef} onChange={onChangePdfFile} />
                    <AttachSquare size={60} className='strock1' />

                    <div>
                        <p>파일을 여기에 드래그하세요 또는 여기 클릭 </p>
                        <p>{limitPdf.limited}장까지 업로드 가능합니다</p>
                        <p>첨부되는 형식: .PDF</p>
                    </div>
                </div>

                <div className='w-full'>
                    {
                        pdfData
                        &&
                        pdfData.map(file =>
                            <PdfFileItem
                                key={file.id}
                                id={file.id}
                                file={file.pdf_name}
                                handleDeleteFile={handleDeleteFile}
                                typeDownload={file.type}
                                handleChangeType={handleChangeType}
                                dataColor={feePrint}
                                pageCount={file.pdf_page}
                            />)
                    }
                </div>
            </section>

            <div className='flex gap-5 justify-end w-full text-logoColor max-lg:text-14_20'>
                <p>총 {pageCount}페이지</p>
                <p>금액: <span className='font-semibold'>{formatMoney(totalPrice)}원</span></p>
            </div>
        </>
    )
}
