import React from 'react'
import circle1 from '../assets/images/circle1.png'
import circle2 from '../assets/images/circle2.png'
import { ButtonPrimaryLink } from '../components/ButtonPrimary'

export default function NotFoundPage() {
    return (
        <div className="h-screen flex justify-center items-center w-full relative">
            <div className="absolute inset-0">
                <img src={circle2} alt="" className="absolute top-[10%] left-[25%] w-[550px] aspect-square" />
                <img src={circle1} alt="" className="absolute bottom-[5%] right-[25%] w-[720px] aspect-square" />
            </div>

            <div className="z-20 box_section text-logoColor bg-white max-w-[600px] w-full aspect-[6/4] flex flex-col lg:gap-10 gap-8 items-center justify-center p-10 rounded-20 shadow-payment">
                <div className='text-center flex flex-col gap-1'>
                    <h1 className='font-bold lg:text-[140px] text-[80px] lg:leading-[140px] leading-[80px]'>404</h1>
                    <p className="lg:text-24-30 text-14_20">페이지를 찾을 수 없습니다</p>
                </div>

                <ButtonPrimaryLink link='/' text='HOME으로 돌아가기' />
            </div>
        </div>
    )
}
