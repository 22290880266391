import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonPrimary from '../../components/ButtonPrimary'
// import Dropdown from '../../components/admin/Dropdown'
import UploadImage from '../../sections/admin/createproduct/UploadImage'
import FormUpload from '../../sections/admin/createproduct/FormUpload'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { createProductNormal } from '../../reduxs/actions/product.action'

export default function Createproduct() {
    const dispatch = useDispatch()

    const [frontImage, setFrontImage] = useState()
    const [endImage, setEndImage] = useState()

    const [descriptionImage, setDescriptionImage] = useState([])

    const [normalImage, setNarmalImage] = useState([])
    const [normalImageFirst, setNarmalImageFirst] = useState()

    const formik = useFormik({
        initialValues: {
            frontThumbnail: '',
            endThumbnail: '',
            normals: [],
            normalFirst: '',
            descriptions: [],
            name: '',
            basic_provision: '',
            addition_offer: '',
            number_line: 20,
            number_character: '',
            price: '',
            discount: 0,
            quantity: '',
            category_id: '',
        },
        validationSchema: Yup.object({
            frontThumbnail: Yup.string().required(ERROR_REQUIRED),
            // endThumbnail: Yup.string().required(ERROR_REQUIRED),
            // normals: Yup.string().required(ERROR_REQUIRED),
            // descriptions: Yup.string().required(ERROR_REQUIRED),
            // normalFirst: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            basic_provision: Yup.string().required(ERROR_REQUIRED),
            addition_offer: Yup.string().required(ERROR_REQUIRED),
            number_line: Yup.string().required(ERROR_REQUIRED),
            number_character: Yup.string().required(ERROR_REQUIRED),
            price: Yup.string().required(ERROR_REQUIRED),
            discount: Yup.string().required(ERROR_REQUIRED),
            quantity: Yup.string().required(ERROR_REQUIRED),
            category_id: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('frontThumbnail', values.frontThumbnail)
            formData.append('endThumbnail', values.endThumbnail)

            for (let i = 0; i < values.descriptions.length; i++) {
                const element = values.descriptions[i].file;

                formData.append('descriptions', element)
            }

            formData.append('normals', values.normalFirst)

            for (let i = 0; i < values.normals.length; i++) {
                const element = values.normals[i].file;

                formData.append('normals', element)
            }

            formData.append('name', values.name)
            formData.append('basic_provision', values.basic_provision)
            formData.append('addition_offer', values.addition_offer)
            formData.append('number_line', values.number_line)
            formData.append('number_character', values.number_character)
            formData.append('price', values.price)
            formData.append('discount', values.discount)
            formData.append('quantity', values.quantity)
            formData.append('category_id', values.category_id)

            const upload = await dispatch(createProductNormal(formData))

            const res = upload.payload

            if (res.status) {
                formik.resetForm()
                setDescriptionImage()
                setEndImage()
                setFrontImage()
                setNarmalImage()
                setNarmalImageFirst()
            }
        }
    })

    return (
        <>
            <div className='flex justify-between'>
                <TitleSection title='상품 추가하기' mt={false} />
                <ButtonPrimary text='상품 추가하기' onClick={formik.handleSubmit} />
            </div>

            <form className='flex gap-5' onSubmit={formik.handleSubmit}>
                <UploadImage
                    formik={formik}
                    frontImage={frontImage}
                    setFrontImage={setFrontImage}
                    endImage={endImage}
                    setEndImage={setEndImage}
                    normalImage={normalImage}
                    setNormalImage={setNarmalImage}
                    normalImageFirst={normalImageFirst}
                    setNormalImageFirst={setNarmalImageFirst}
                />

                <FormUpload formik={formik} descriptionImage={descriptionImage} setDescriptionImage={setDescriptionImage} />
            </form>
        </>
    )
}
