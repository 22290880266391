import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/apis";

export const getManagementAdmin = createAsyncThunk(
    'management/getAdmin',
    async () => {
        const response = await get(`management`)

        return response
    }
)

export const updateFeeprintAdmin = createAsyncThunk(
    'management/updateFeeprint',
    async (data) => {
        const { price, pdfId } = data

        const response = await post(`management/feeprint/${pdfId}`, { price })

        return response
    }
)

export const updateStampAdmin = createAsyncThunk(
    'management/updateStamp',
    async (data) => {
        const { formData, id } = data

        const response = await post(`management/stamp/${id}`, formData)

        return response
    }
)

export const updateLimitPdfAdmin = createAsyncThunk(
    'management/updateLimitPdf',
    async (data) => {
        const { limited, pdfId } = data

        const response = await post(`management/limitpdf/${pdfId}`, { limited })

        return response
    }
)

export const updateFeeletterAdmin = createAsyncThunk(
    'management/updateFeeletter',
    async (data) => {
        const { price, pdfId } = data

        const response = await post(`management/feeletter/${pdfId}`, { price })

        return response
    }
)