import { AddCircle, CloseSquare } from 'iconsax-react'
import React, { useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { URL_SERVER } from '../../../utils/apis'
import { ImageUploadItem } from './FormUpload'

export default function UpdateImages({
    formik,
    frontImage,
    endImage,
    normalImage,
}) {
    const frontThumbnail = useRef()
    const endThumbnail = useRef()
    const normalThumbnail = useRef()

    // ========== front thumbnail ========== //
    const handleClickFrontThumbnail = () => {
        frontThumbnail.current.click()
    }

    const handleChooseFrontThumbnail = (e) => {
        const file = e.currentTarget.files

        if (file.length) {
            formik.setFieldValue('frontThumbnail', { file: URL.createObjectURL(file[0]), currentFile: file[0] })
        }

        e.target.value = null;
    }

    const deleteFrontImage = () => {
        formik.setValues({
            ...formik.values,
            frontThumbnail: '',
            delete_picture: [
                ...formik.values.delete_picture,
                {
                    type: 'thumbnails',
                    filename: frontImage.picture_name
                }
            ]
        })
    }

    // ========== end thumbnail ========== //
    const handleClickEndThumbnail = () => {
        endThumbnail.current.click()
    }

    const handleChooseEndThumbnail = (e) => {
        const file = e.currentTarget.files

        if (file.length) {
            formik.setFieldValue('endThumbnail', { file: URL.createObjectURL(file[0]), currentFile: file[0] })
        }

        e.target.value = null;
    }

    const deleteEndImage = () => {
        formik.setValues({
            ...formik.values,
            endThumbnail: '',
            delete_picture: [
                ...formik.values.delete_picture,
                {
                    type: 'thumbnails',
                    filename: endImage.picture_name
                }
            ]
        })
    }

    // ========== normal thumbnail ========== //
    const handleClickNormalThumbnail = () => {
        normalThumbnail.current.click()
    }

    const handleChooseNormalThumbnail = (e) => {
        const fileArray = Array.from(e.currentTarget.files)

        const handleFile = fileArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item),
                currentFile: item
            };
        });

        const customImage = [
            ...normalImage,
            ...handleFile
        ].slice(0, 4)

        formik.setFieldValue('normals', customImage)

        e.target.value = null
    }

    const deleteNormalImage = (file) => {
        const handleDeleteFormik = formik.values.normals.filter(item => item.id !== file.id)

        if (file.file) {
            formik.setFieldValue('normals', handleDeleteFormik)
        } else {
            const updateArray = [
                ...formik.values.delete_picture,
                {
                    type: 'normal',
                    filename: file.picture_name
                }
            ]

            formik.setValues({
                ...formik.values,
                delete_picture: updateArray,
                normals: handleDeleteFormik
            })
        }
    }

    return (
        <section className='p-5 rounded-20 bg-white w-5/12 grid gap-[30px]'>
            <div className='grid gap-5'>
                <div className='flex justify-between gap-5 items-center'>
                    <TitleSection title='상품 썸네일' small mt={false} />

                    {
                        (formik.touched.frontThumbnail && formik.errors.frontThumbnail)
                        &&
                        <p className='text-12 text-red'>{formik.errors.frontThumbnail}</p>
                    }
                </div>

                <div className='grid gap-2.5'>
                    {/* /// front thumbnail */}
                    <div className={`aspect-[7.08/10] border-dashed ${frontImage ? '' : 'border-[2px]'} rounded-20 relative overflow-hidden`}>
                        <input
                            type='file'
                            accept="image/*"
                            ref={frontThumbnail}
                            onChange={(e) => handleChooseFrontThumbnail(e)}
                            hidden
                        />

                        {
                            frontImage
                                ?
                                <>
                                    <button type='button' className='absolute top-5 right-5 text-deactive' onClick={deleteFrontImage}><CloseSquare size={20} /></button>
                                    <img src={frontImage.filename ? `${URL_SERVER}${frontImage.filename}` : frontImage.file} alt='front thumbnail' className='w-full h-full object-contain' />
                                </>
                                :
                                <div className='flex flex-col gap-2.5 items-center justify-center w-full h-full cursor-pointer' onClick={handleClickFrontThumbnail}>
                                    <AddCircle size={40} variant="Outline" />

                                    <div className='text-center'>
                                        <p>이미지 비율 3x4</p>
                                        <p>앞 (최대 5MB)</p>
                                    </div>
                                </div>
                        }
                    </div>


                    {/* /// end thumbnail */}
                    <div className={`w-1/2 aspect-[7.08/10] border-dashed ${endImage ? '' : 'border-[2px]'} rounded-20 relative overflow-hidden`}>
                        <input
                            type='file'
                            accept="image/*"
                            hidden
                            ref={endThumbnail}
                            onChange={(e) => handleChooseEndThumbnail(e)}
                        />

                        {
                            endImage
                                ?
                                <>
                                    <button type='button' className='absolute top-2.5 right-2.5 text-deactive' onClick={deleteEndImage}><CloseSquare size={20} /></button>
                                    <img src={endImage.filename ? `${URL_SERVER}${endImage.filename}` : endImage.file} alt='front thumbnail' className='w-full h-full object-contain' />
                                </>
                                :
                                <div className='flex flex-col gap-2.5 items-center justify-center h-full w-full cursor-pointer' onClick={handleClickEndThumbnail}>
                                    <AddCircle size={40} variant="Outline" />

                                    <div className='text-center'>
                                        <p>이미지 비율 3x4</p>
                                        <p>앞 (최대 5MB)</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className='grid gap-5'>
                <div className='flex justify-between items-center'>
                    <TitleSection title='상품 상세 이미지' small mt={false} />
                    <p>{normalImage.length}/4 사진</p>
                </div>

                <div>
                    <div className='grid grid-cols-3 gap-2.5'>
                        <input ref={normalThumbnail} accept="image/*" hidden multiple type='file' onChange={handleChooseNormalThumbnail} />
                        <div className={`relative col-span-3 aspect-video rounded-20 overflow-hidden ${normalImage.length !== 0 ? '' : 'border-dashed border-[2px]'}`}>
                            {
                                normalImage.length === 0
                                    ?
                                    <div className='flex flex-col gap-2.5 items-center justify-center h-full cursor-pointer' onClick={handleClickNormalThumbnail}>
                                        <AddCircle size={40} variant="Outline" />

                                        <div className='text-center'>
                                            <p>이미지 비율 16x9</p>
                                            <p>앞 (최대 5MB)</p>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <button type='button' className='absolute top-5 right-5' onClick={() => deleteNormalImage(normalImage[0])}>
                                            <CloseSquare size={20} className=' hover:text-logoColor duration-300' />
                                        </button>

                                        <img src={normalImage[0].filename ? `${URL_SERVER}${normalImage[0].filename}` : normalImage[0].file} alt='front thumbnail' className='w-full h-full object-cover' />
                                    </>
                            }
                        </div>

                        {
                            normalImage.length === 0
                                ?
                                <>
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                </>
                                :
                                <>
                                    {
                                        normalImage.map((item, index) =>
                                            index >= 1
                                            &&
                                            <ImageUploadItem
                                                key={item.id}
                                                id={item.id}
                                                image={item.file ? item.file : `${URL_SERVER}${item.filename}`}
                                                handleDelete={() => deleteNormalImage(item)}
                                            />)
                                    }

                                    {normalImage.length < 4 &&
                                        Array(4 - normalImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickNormalThumbnail}
                                            />
                                        ))
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
