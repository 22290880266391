import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import HomePageUser from './HomePageUser'
import HeaderUser from '../../components/user/HeaderUser'
import FooterUser from '../../components/user/FooterUser'
import PopupSection from '../../sections/user/PopupSection'
import PopupNotLogin from '../../components/user/PopupNotLogin'

export default function User() {
    const { pathname } = useLocation()

    return (
        <>
            <div className='h-screen overflow-y-scroll hiddenScroll relative'>
                <HeaderUser />
                <PopupSection />
                <PopupNotLogin />

                {
                    pathname === '/' ? <HomePageUser /> : <Outlet />
                }

                <FooterUser />
            </div>
        </>
    )
}
