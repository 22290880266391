import { createSlice } from "@reduxjs/toolkit";
import { createAdvert, deleteAdvert, getAdvertHomepage, getAllAdvert, toggleStatusAdvert } from "../actions/adverts.action";

const adVertSlice = createSlice({
    name: 'advert',
    initialState: {
        dataAdvert: undefined,
        loadingGetAddAdvert: false,
        countAdvert: 0,

        dataAdvertHome: undefined,
        loadingGetAddAdvertHome: false,

        loadingCreateAdvert: false,

        loadingDeleteAdvert: false,

        loadingToggleStatusAdvert: false,
    },
    extraReducers: builder => {
        // get add advert
        builder.addCase(getAllAdvert.pending, (state, action) => {
            state.loadingGetAddAdvert = true
        })
        builder.addCase(getAllAdvert.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAdvert = res.data.adverts
                state.countAdvert = res.data.countAdvert
            }

            state.loadingGetAddAdvert = false
        })

        // get advert homepage
        builder.addCase(getAdvertHomepage.pending, (state, action) => {
            state.loadingGetAddAdvertHome = true
        })
        builder.addCase(getAdvertHomepage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAdvertHome = res.data
            }

            state.loadingGetAddAdvertHome = false
        })

        // create advert
        builder.addCase(createAdvert.pending, (state, action) => {
            state.loadingCreateAdvert = true
        })
        builder.addCase(createAdvert.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAdvert = [
                    res.data,
                    ...state.dataAdvert,
                ]
                state.countAdvert += 1
            }

            state.loadingCreateAdvert = false
        })

        // delete advert
        builder.addCase(deleteAdvert.pending, (state, action) => {
            state.loadingDeleteAdvert = true
        })
        builder.addCase(deleteAdvert.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataAdvert.filter(item => Number(item.id) !== Number(res.data))

                state.dataAdvert = updateData
                state.countAdvert -= 1
            }

            state.loadingDeleteAdvert = false
        })

        // toggle status advert
        builder.addCase(toggleStatusAdvert.pending, (state, action) => {
            state.loadingToggleStatusAdvert = true
        })
        builder.addCase(toggleStatusAdvert.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataAdvert.map(item => {
                    if (item.id === res.data.id) {
                        return res.data
                    }

                    return item
                })

                state.dataAdvert = updateData
            }

            state.loadingToggleStatusAdvert = false
        })
    }
})

export default adVertSlice.reducer
