import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailPostOffice } from '../../../reduxs/actions/admin.action'
import { URL_SERVER } from '../../../utils/apis'
import ButtonSecon from '../../../components/ButtonSecon'
import { ButtonPrimaryLink } from '../../../components/ButtonPrimary'
import UpdateDeliveriOrder from '../../../pages/admin/UpdateDeliveriOrder'

export default function DetailOrderPopup({ orderId, handleShow }) {
    const dispatch = useDispatch()

    const { loadingGetDetailPostOffice, dataDetailPostOffice } = useSelector(state => state.admin)

    useEffect(() => {
        dispatch(getDetailPostOffice(orderId))
    }, [orderId])

    const product_picture = dataDetailPostOffice?.product_picture

    return (
        <div className='fixed inset-0 bg-popup z-50 flex justify-center items-center'>
            {
                (loadingGetDetailPostOffice || !dataDetailPostOffice)
                    ?
                    <p className='text-white'>Loading...</p>
                    :
                    <div className='w-[600px] max-h-[600px] overflow-y-auto hiddenScroll bg-white p-5 rounded-20 flex flex-col items-center gap-5'>
                        <h3 className='font-semibold text-24-30'>주문 정보</h3>

                        {
                            product_picture
                            &&
                            <div className='flex flex-col items-center gap-4'>
                                <img
                                    src={`${URL_SERVER}${product_picture}`}
                                    alt={dataDetailPostOffice.product_name}
                                    className='w-[180px] aspect-[7.08/10] rounded-20 object-cover'
                                />

                                <p className='font-semibold'>{dataDetailPostOffice.product_name}</p>
                            </div>}

                        <div className='rounded-20 border border-strock p-5 grid gap-2.5 w-full'>
                            {
                                dataDetailPostOffice.product_price
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>결제금액:</p>
                                    <span className='text-logoColor font-bold'>{dataDetailPostOffice.product_price}원</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.money_pay
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>결제금액:</p>
                                    <span className='text-logoColor font-bold'>{dataDetailPostOffice.money_pay}원</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.sender_name
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>보내는 사람:</p>
                                    <span className=''>{dataDetailPostOffice.sender_name}</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.sender_address
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>보내는 사람 주소:</p>
                                    <span className=''>{dataDetailPostOffice.sender_address}</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.sender_phone
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>보내는 사람 전화번호:</p>
                                    <span className=''>{dataDetailPostOffice.sender_phone}</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.recipient_name
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>받는 사람:</p>
                                    <span className=''>{dataDetailPostOffice.recipient_name}</span>
                                </div>
                            }


                            {
                                dataDetailPostOffice.recipient_address
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>받는 사람 주소:</p>
                                    <span className=''>{dataDetailPostOffice.recipient_address}</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.recipient_phone
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>보내는 사람 전화번호:</p>
                                    <span className=''>{dataDetailPostOffice.recipient_phone}</span>
                                </div>
                            }

                            {
                                dataDetailPostOffice.account_number
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>계좌번호:</p>
                                    <span className=''>{dataDetailPostOffice.account_number}</span>
                                </div>
                            }

                            <UpdateDeliveriOrder orderId={orderId} currentCode={dataDetailPostOffice.shipment_code} />

                            {
                                dataDetailPostOffice.shipment_code
                                &&
                                <div className='flex gap-5 items-center'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>주문 상태:</p>
                                    <ButtonPrimaryLink
                                        target='_blank'
                                        className='!py-2'
                                        link={`https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dataDetailPostOffice.shipment_code}`}
                                        text='배송 추적'
                                    />
                                </div>
                            }

                            {
                                dataDetailPostOffice.pdf_filename
                                &&
                                <div className='flex gap-5'>
                                    <p className='w-[130px] flex-shrink-0 text-start font-semibold'>PDF 파일 업로드:</p>
                                    <span className='line-clamp-1'>{dataDetailPostOffice.pdf_filename}</span>
                                </div>
                            }
                        </div>

                        {
                            (dataDetailPostOffice.pdf?.length || dataDetailPostOffice.materials?.length)
                                ?
                                <div className='rounded-20 border border-strock p-5 grid gap-2.5 w-full'>
                                    {
                                        (dataDetailPostOffice.pdf && dataDetailPostOffice.pdf.length)
                                            ?
                                            <div className='flex gap-5'>
                                                <p className='w-[130px] flex-shrink-0 text-start font-semibold'>PDF 첨부:</p>
                                                <div className='text-start grid gap-2.5'>
                                                    {
                                                        dataDetailPostOffice.pdf.map(item => <PdfItem key={item.id} name={item.filename} color={item.print_name} />)
                                                    }
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    {
                                        (dataDetailPostOffice.materials && dataDetailPostOffice.materials.length)
                                            ?
                                            <div className='flex gap-5'>
                                                <p className='w-[130px] flex-shrink-0 text-start font-semibold'>자체제작 자료:</p>
                                                <div className='grid grid-cols-4 gap-2.5 '>
                                                    {
                                                        dataDetailPostOffice.materials.map(item =>
                                                            <img
                                                                key={item.id}
                                                                src={`${URL_SERVER}${item.product_picture}`}
                                                                alt={item.name}
                                                                className='aspect-[7.08/10] object-cover rounded-20'
                                                            />)
                                                    }
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                :
                                ''
                        }

                        <ButtonSecon onClick={handleShow} title='취소' className='w-[160px]' />
                    </div>
            }
        </div>
    )
}

const PdfItem = ({ name, color }) => {
    return (
        <div>
            <p>{name}</p>
            <span className='text-pink'>{color}</span>
        </div>
    )
}
