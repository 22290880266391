import React from 'react'
import { URL_SERVER } from '../../utils/apis'

export default function MaterialItem({ image, name }) {
    return (
        <div>
            <img src={`${URL_SERVER}${image}`} alt={`${name}`} className='aspect-[9/16] object-cover rounded-12' />
        </div>
    )
}
