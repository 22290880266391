import React, { useCallback, useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import LettersItem from '../../../components/user/LettersItem'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css';

import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';

export default function NewProduct({ dataNewProducts }) {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <section>
            <TitleSection title='신상편지지 라인' />

            <div className='relative'>
                <Swiper
                    className="w-full"
                    spaceBetween={20}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        400: {
                            slidesPerView: 3,
                        },
                        865: {
                            slidesPerView: 4
                        },
                        1000: {
                            slidesPerView: 5
                        },
                        1500: {
                            slidesPerView: 6
                        },
                    }}
                    ref={sliderRef}
                >
                    {
                        dataNewProducts.map(item =>
                            <SwiperSlide key={item.id} className='w-full'>
                                <LettersItem
                                    id={item.id}
                                    frontThumbnail={item.frontThumbnail}
                                    name={item.name}
                                    price={item.price}
                                />
                            </SwiperSlide>
                        )
                    }

                </Swiper>

                {
                    dataNewProducts.length > 6
                    &&
                    <>
                        <div
                            className="shadow-setting cursor-pointer absolute top-1/2 left-0 -translate-x-1/2 -translate-y-14 bg-white w-8 aspect-square rounded-full flex justify-center items-center z-10"
                            onClick={handlePrev}
                        >
                            <ArrowLeft2 size={24} color='#FA0071' />
                        </div>

                        <div
                            className="shadow-setting cursor-pointer absolute top-1/2 right-0 translate-x-1/2 -translate-y-14 bg-white w-8 aspect-square rounded-full flex justify-center items-center z-10"
                            onClick={handleNext}
                        >
                            <ArrowRight2 size={24} color='#FA0071' />
                        </div>
                    </>
                }
            </div>
        </section>
    )
}
