import { ArrowDown2 } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'

export default function statusOrder(status, status_name) {
    switch (status) {
        case 1:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#FF9E67] text-white text-12 w-fit'>{status_name}</div>

        case 2:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#009E9E] text-white text-12 w-fit'>{status_name}</div>

        case 3:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#AB2DB6] text-white text-12 w-fit'>{status_name}</div>

        case 4:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#50A6FF] text-white text-12 w-fit'>{status_name}</div>

        case 5:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#3562FF] text-white text-12 w-fit'>{status_name}</div>

        case 6:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#EE0707] text-white text-12 w-fit'>{status_name}</div>

        case 7:
            return <div className='py-1.5 px-2.5 rounded-4 bg-[#3B6C14] text-white text-12 w-fit'>{status_name}</div>

        default:
            break;
    }
}

export const StatusOrderSelect = ({ status, listStatus, handleChange }) => {
    const contentRef = useRef()

    const [isShowDropdown, setIsShowDropdown] = useState(false)

    const handleShow = () => {
        setIsShowDropdown(!isShowDropdown)
    }

    const currentStatus = listStatus ? listStatus?.filter(item => item.id === status)[0].name : 0

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShowDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShowDropdown]);

    const renderStyle = () => {
        switch (status) {
            case 1:
                return 'py-1.5 px-2.5 rounded-4 bg-[#FF9E67] text-white text-12 w-fit'

            case 2:
                return 'py-1.5 px-2.5 rounded-4 bg-[#009E9E] text-white text-12 w-fit'

            case 3:
                return 'py-1.5 px-2.5 rounded-4 bg-[#AB2DB6] text-white text-12 w-fit'

            case 4:
                return 'py-1.5 px-2.5 rounded-4 bg-[#50A6FF] text-white text-12 w-fit'

            case 5:
                return 'py-1.5 px-2.5 rounded-4 bg-[#3562FF] text-white text-12 w-fit'

            case 6:
                return 'py-1.5 px-2.5 rounded-4 bg-[#EE0707] text-white text-12 w-fit'

            case 7:
                return 'py-1.5 px-2.5 rounded-4 bg-[#3B6C14] text-white text-12 w-fit'

            default:
                break;
        }
    }

    return (
        <div className='flex justify-center'>
            <div className='relative' ref={contentRef}>
                <button className={`${renderStyle()} w-[100px] flex gap-1.5 items-center`} onClick={handleShow}>{currentStatus} <ArrowDown2 size={16} /></button>

                {
                    isShowDropdown
                    &&
                    <div className='absolute top-full right-0 z-10 grid text-center w-full bg-white shadow-dropdown rounded-b-12'>
                        {
                            listStatus.map(item =>
                                <button
                                    key={item.id}
                                    onClick={() => {
                                        handleChange(item.id)
                                        handleShow()
                                    }}
                                    className={`py-1.5 text-12 hover:text-logoColor duration-200 ${status === item.id && 'text-logoColor'}`}
                                >
                                    {item.name}
                                </button>)
                        }
                    </div>
                }
            </div>
        </div>
    )
}
