import React from 'react'
import { URL_SERVER } from '../../utils/apis'
import { formatMoney } from '../../utils/renderText'

export default function ShippingFeeItem({ id, isChecked = false, image, price, name, handleSelectStamp }) {
    return (
        <div className={`flex flex-col gap-2.5 items-center p-5 rounded-20 cursor-pointer ${isChecked ? 'border-logoColor' : 'border-transparent'} border-[3px] `} onClick={() => handleSelectStamp({ id, price, name })}>
            <div>
                <img src={`${URL_SERVER}${image}`} alt='shipping' className='w-[248px] aspect-square object-contain' />
            </div>

            {
                price
                &&
                <p className='text-logoColor font-semibold'>{name} {formatMoney(price)}원</p>
            }
        </div>
    )
}
