import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get all notice
export const getAllNotice = createAsyncThunk(
    'notice/getall',
    async (page) => {
        const response = await get(`/post/admingetall?page=${page}`)

        return response
    }
)

// get notice detail
export const getNoticeDetail = createAsyncThunk(
    'notice/getdetail',
    async (noticeId) => {
        const response = await get(`/post/detail/${noticeId}`)

        return response
    }
)

// update notice detail
export const updateNoticeDetail = createAsyncThunk(
    'notice/updateDetail',
    async (data) => {
        const { postId, formData } = data

        const response = await post(`/post/detail/${postId}`, formData)

        return response
    }
)

// create notice 
export const createNotice = createAsyncThunk(
    'notice/create',
    async (data) => {
        const response = await post(`/post`, data)

        return response
    }
)

// delete notice 
export const deleteNotice = createAsyncThunk(
    'notice/delete',
    async (postId) => {
        const response = await del(`post/${postId}`)

        return response
    }
)