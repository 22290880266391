import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeUserToNormal, getUserBlacklist, searchUserBlacklist } from '../../../reduxs/actions/user.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import ToggleStatus from '../../../components/admin/ToggleStatus'
import Pagination from '../../../components/Pagination'
import { formatMoney } from '../../../utils/renderText'
import { useSearchParams } from 'react-router-dom'

export default function ListBlacklistUser() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const { loadingGetUserBlacklist, dataUserBlacklist, countUserBlacklist } = useSelector(state => state.user)

    const page = searchParams.get('page') || 1
    const search = searchParams.get('search') || ''
    const option = searchParams.get('option') || ''

    const handlePage = (method) => {
        const currentParams = Object.fromEntries([...searchParams]);
        const numberPage = Number(page)

        if (method === 'next') {
            const maxPage = Math.ceil(countUserBlacklist / 10)
            setSearchParams({ ...currentParams, page: numberPage < maxPage ? numberPage + 1 : numberPage })
        }
        else if (method === 'pre') {
            setSearchParams({ ...currentParams, page: numberPage <= 1 ? numberPage : numberPage - 1 })
        }
        else if (typeof method === 'number') {
            setSearchParams({ ...currentParams, page: method })
        }
    }

    useEffect(() => {
        if (!search) {
            dispatch(getUserBlacklist(page))
        }
    }, [page])

    useEffect(() => {
        if (search) {
            const dataSearch = { page: page, option, search }

            dispatch(searchUserBlacklist(dataSearch))
        }
    }, [])

    if (loadingGetUserBlacklist || !dataUserBlacklist) {
        return <LoadingUser />
    }

    return (
        <div className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[350px]'>이메일</th>
                        <th className='py-5 px-2 w-[200px]'>성함</th>
                        <th className='py-5 px-2'>주소</th>
                        <th className='py-5 px-2 w-[150px]'>포인트</th>
                        <th className='py-5 px-2 w-[220px]'>연락처</th>
                        <th className='py-5 px-2 w-[200px]'>블랙리스트</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataUserBlacklist.map(item =>
                            <RowBlacklistItem
                                key={item.account_id}
                                id={item.account_id}
                                email={item.email}
                                name={item.name}
                                phone={item.phone_number}
                                address={item.address}
                                created_at={item.created_at}
                                last_login={item.last_login}
                                point={item.point_total}
                                status={false}
                                page={Number(page)}
                            />
                        )
                    }
                </tbody>
            </table>
            <Pagination totalCount={countUserBlacklist} currentPage={Number(page)} handlePage={handlePage} />
        </div>
    )
}

const RowBlacklistItem = ({ email, name, phone, address, point, id, status, page }) => {
    const dispatch = useDispatch()

    const handleChangeStatusUser = () => {
        dispatch(changeUserToNormal({ account_id: id, page }))
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{email}</td>
            <td className='py-5 px-2'>{name}</td>
            <td className='py-5 px-2'>
                <p className=''>{address}</p>
            </td>
            <td className='py-5 px-2'>{formatMoney(point)}</td>
            <td className='py-5 px-2 whitespace-nowrap'>{phone}</td>
            <td className='py-5 px-2'><ToggleStatus status={status} onClick={handleChangeStatusUser} /></td>
        </tr>
    )
}

