import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { Trash } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOrderLetter } from '../../../reduxs/reducers/order-letter.reducer'
import PopupAddOrderLetter from '../../../components/admin/PopupAddOrderLetter'

export default function OrderLetter() {
    const dispatch = useDispatch()

    const { dataOrderLetter } = useSelector(state => state.orderLetter)

    const handleDeleteOrder = (orderId) => {
        dispatch(deleteOrderLetter(orderId))
    }

    return (
        <div className='p-5 rounded-[20px] bg-white grid gap-5'>
            <div className='flex justify-between items-center'>
                <TitleSection title=' 주문하실 봉투종류' small mt={false} />
                <PopupAddOrderLetter />
            </div>

            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='pb-2 min-w-[150px]'>상품명</th>
                        <th className='pb-2 min-w-[150px]'>가격</th>
                        <th className='pb-2 min-w-[150px]'>수량</th>
                        <th className='pb-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataOrderLetter.map(item =>
                            <RowOrderItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                price={item.price}
                                quantity={item.quantity}
                                handleDeleteOrder={handleDeleteOrder}
                            />
                        )
                    }
                </tbody>
            </table>

            {
                dataOrderLetter.length === 0 && <p className='text-center'>아직 상품이 없습니다</p>
            }
        </div>
    )
}

const RowOrderItem = ({ id, name, price, quantity, handleDeleteOrder }) => {
    return (
        <tr className='border-b border-strock last:border-none'>
            <td className='py-2'>
                <p className='line-clamp-2'>{name}</p>
            </td>
            <td className='py-2'>{price}</td>
            <td className='py-2'>{quantity}</td>
            <td className='py-2'>
                <button type='button' onClick={() => handleDeleteOrder(id)}><Trash size={16} className='text-deactive' /></button>
            </td>
        </tr>
    )
}
