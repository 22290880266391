import { createSlice } from "@reduxjs/toolkit";
import { getAllColors } from "../actions/color.action";

const colorSlice = createSlice({
    name: 'colors',
    initialState: {
        loadingGetAllColor: false,
        dataColor: undefined,
    },
    extraReducers: builder => {
        builder.addCase(getAllColors.pending, (state, action) => {
            state.loadingGetAllColor = true
        })
        builder.addCase(getAllColors.fulfilled, (state, action) => {
            const res = action.payload

            if (res?.status) {
                state.dataColor = res.data
            }

            state.loadingGetAllColor = false
        })
    }
})

export const { } = colorSlice.actions
export default colorSlice.reducer