import React, { useEffect } from 'react'
import InputForm from '../../components/InputForm'
import LabelForm from '../../components/LabelForm'
import { Link, useNavigate } from 'react-router-dom'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_EMAIL_FORMAT, ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { login } from '../../reduxs/actions/auth.action'
import { REGEX_EMAIL } from '../../utils/regex'
import { clearLocalStorage, setLocalStorage } from '../../utils/storage'
import { renderError } from '../../utils/renderText'

export default function LoginPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().matches(REGEX_EMAIL, ERROR_EMAIL_FORMAT).required(ERROR_REQUIRED),
            password: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(login(values))

            const res = result.payload

            if (res.status) {
                const data = res.data

                setLocalStorage('token', data.accessToken)
                setLocalStorage('refreshToken', data.refreshToken)
                setLocalStorage('role', data.role_id)

                if (data.role_id === 1) navigate('/admin')
                else navigate('/')
            } else {
                const message = renderError(res.error)

                if (res.error === '404_email') {
                    formik.setFieldError('email', message)
                }

                else if (res.error === 'f_pw') {
                    formik.setFieldError('password', message)
                }
            }
        }
    })

    useEffect(() => {
        clearLocalStorage()
    }, [])

    return (
        <>
            <form className='w-full flex flex-col lg:gap-10 gap-[30px]' onSubmit={formik.handleSubmit}>
                <div className='grid gap-4'>
                    <div>
                        <LabelForm label='이메일' />
                        <InputForm
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            placehoder='이메일 주소를 입력하세요'
                            error={((formik.touched.email && formik.errors.email) && formik.errors.email)}
                        />
                    </div>

                    <div>
                        <LabelForm label='비밀번호' />
                        <InputForm
                            placehoder='비밀번호를 입력 하세요'
                            type='password'
                            name='password'
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={((formik.touched.password && formik.errors.password) && formik.errors.password)}
                        />

                        <Link to='forgot' className='mt-2.5 lf:text-12 block font-semibold text-blue text-10'>비밀번호를 분실하셨나요?</Link>
                    </div>
                </div>

                <div className='grid justify-center w-full gap-2.5 justify-items-center'>
                    <ButtonPrimary type='submit' onClick={formik.handleSubmit} text='로그인' className='w-[200px]' />

                    <div className='flex justify-center gap-1.5 w-full text-14_20'>
                        <p className='max-lg:text-10'>아직 비회원 이신가요?</p>
                        <Link to='register' className='text-blue font-semibold max-lg:text-10'>회원가입하기</Link>
                    </div>
                </div>
            </form>
        </>
    )
}
