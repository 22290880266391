import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUser from '../../../pages/user/LoadingUser'
import { changeStatusOrder, getHandmakeOrder } from '../../../reduxs/actions/admin.action'
import Pagination from '../../../components/Pagination'
import { StatusOrderSelect } from '../../../components/user/StatusOrder'
import DetailOrderPopup from './DetailOrderPopup'
import moment from 'moment/moment'
import ButtonStatus from '../../../components/admin/ButtonStatus'
import { URL_FILE, URL_SERVER } from '../../../utils/apis'
import axios from 'axios'
import { formatMoney } from '../../../utils/renderText'
import DropdownTable from '../../../components/admin/DropdownTable'
import { deleteOrderAdmin } from '../../../reduxs/actions/order.action'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'

export default function ListHandmakeOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { dataPostOffice, loadingGetPostOffice, postOfficeCount, dataStatusOrder } = useSelector(state => state.admin)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(postOfficeCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getHandmakeOrder(page))
    }, [page])

    if (loadingGetPostOffice || !dataPostOffice || !dataStatusOrder) {
        return <LoadingUser />
    }

    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[300px]'>접수일</th>
                        <th className='py-5 px-2 w-[300px]'>받는분</th>
                        <th className='py-5 px-2 w-[300px]'>결제방법</th>
                        <th className='py-5 px-2 w-[200px]'>상태</th>
                        <th className='py-5 px-2 w-[300px]'>결제금액</th>
                        <th className='py-5 px-2 w-[160px]'>PDF 인쇄</th>
                        <th className='py-5 px-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataPostOffice.map(item =>
                            <RowOrderItem
                                key={item.order_id}
                                id={item.order_id}
                                sender={item.sender_name}
                                receiver={item.recipient_name}
                                status={item.order_status_id}
                                created_at={item.created_at}
                                money={item.money_pay}
                                methodPay={item.pay_method_name}
                                listStatus={dataStatusOrder}
                                material={item.materials}
                                page={page}
                            />
                        )
                    }
                </tbody>
            </table>

            <Pagination totalCount={postOfficeCount} currentPage={page} handlePage={handlePage} />
        </section>
    )
}

const RowOrderItem = ({ id, receiver, status, created_at, money, methodPay, material, listStatus, page }) => {
    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)

    // download materials
    const handleDownloadPdf = async () => {
        try {
            setIsLoadingPdf(true)

            const downloadPromises = material.map(file =>
                axios.get(`${URL_FILE}/${file.filename}`)
            );

            const responses = await Promise.all(downloadPromises);

            responses.forEach((response, index) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${material[index].pdf_name}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingPdf(false)
        }
    }

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const handleChangeStatus = (statusId) => {
        if (Number(status) !== Number(statusId)) return dispatch(changeStatusOrder({ order_id: id, status_id: statusId }))
    }

    const handleShowpopup = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleDeleteOrder = async () => {
        const result = await dispatch(deleteOrderAdmin({ orderId: id, page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
            handleShowpopup()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{moment(created_at).format('YYYY.MM.DD hh:mm')}</td>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{receiver}</td>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{methodPay}</td>
            <td className='py-5 px-2'>
                <StatusOrderSelect status={status} listStatus={listStatus} handleChange={handleChangeStatus} />

                {
                    isShow
                    &&
                    <DetailOrderPopup orderId={id} handleShow={handleShow} />
                }
            </td>
            <td className='py-5 px-2'>₩{formatMoney(money)}원</td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    <ButtonStatus status={true} isLoading={isLoadingPdf} onClick={handleDownloadPdf} />
                </div>
            </td>
            <td>
                <DropdownTable onclickDelete={handleShowpopup} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 주문을 삭제하시겠습니까?</p>
                                <p>이 주문이 삭제되면 복원할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowpopup} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleDeleteOrder} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}
