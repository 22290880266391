import React, { useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import CategoryItem from '../../../components/admin/CategoryItem'
import { Add } from 'iconsax-react'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import InputFormThin from '../../../components/InputFormThin'
import LabelFormThin from '../../../components/LabelFormThin'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { useDispatch } from 'react-redux'
import { createCategoriesAdmin, deleteCategoriesAdmin } from '../../../reduxs/actions/category.action'

export default function ListCategories({ typeId, title, data }) {
    const dispatch = useDispatch()

    const [addNewCategory, setAddNewCategory] = useState(false)

    const [isNotify, setIsNotify] = useState(false)

    const handleShowPopup = () => {
        setAddNewCategory(!addNewCategory)
    }

    const handleDelete = async (categoryId) => {
        const result = await dispatch(deleteCategoriesAdmin(categoryId))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    const formik = useFormik({
        initialValues: {
            product_type_id: typeId,
            name: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(createCategoriesAdmin(values))

            const res = result.payload

            if (res.status) {
                handleShowPopup()
            }
        }
    })

    return (
        <section>
            <div className='flex justify-between items-center'>
                <TitleSection title={title} small />
                <button className='flex items-center gap-1' onClick={handleShowPopup}><Add size={20} /> <span>만들다</span></button>
            </div>

            <div className='grid grid-cols-5 gap-5'>
                {
                    data.map(item => <CategoryItem key={item.id} id={item.id} content={item.name} handleDelete={handleDelete} />)
                }
            </div>

            {
                addNewCategory
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <form className='w-[600px] p-5 bg-white rounded-20 flex flex-col items-center gap-5' onSubmit={formik.handleSubmit}>
                        <h3 className='text-24-30 font-semibold'>카테고리 생성</h3>

                        <div className='w-full'>
                            <LabelFormThin label='카테고리 이름' />
                            <InputFormThin
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                placehoder='텍스트 입력'
                                error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                            />
                        </div>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleShowPopup} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </section>
    )
}
