import { createSlice } from "@reduxjs/toolkit";
import { createFaq, deleteFaq, getAllFaq, getAllFaqUser, getDetailFaq, updateDetailFaq } from "../actions/faq.action";

const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        loadingGetAllFaq: false,
        dataFaq: undefined,
        faqCount: 0,

        loadingCreateFaq: false,

        loadingGetAllFaqUser: false,
        dataFaqUser: undefined,

        loadingGetDetailFaq: false,
        dataFaqDetail: undefined,

        loadingUpdateDetailFaq: false,

        loadingDeleteFaq: false
    },
    extraReducers: builder => {
        // get faq user
        builder.addCase(getAllFaqUser.pending, (state, action) => {
            state.loadingGetAllFaqUser = true
        })
        builder.addCase(getAllFaqUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataFaqUser = res.data
            }

            state.loadingGetAllFaqUser = false
        })

        // get all faq admin
        builder.addCase(getAllFaq.pending, (state, action) => {
            state.loadingGetAllFaq = true
        })
        builder.addCase(getAllFaq.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataFaq = res.data.faq
                state.faqCount = res.data.faqCount
            }

            state.loadingGetAllFaq = false
        })

        // create faq
        builder.addCase(createFaq.pending, (state, action) => {
            state.loadingCreateFaq = true
        })
        builder.addCase(createFaq.fulfilled, (state, action) => {
            state.loadingCreateFaq = false
        })

        // get detail faq
        builder.addCase(getDetailFaq.pending, (state, action) => {
            state.loadingGetDetailFaq = true
        })
        builder.addCase(getDetailFaq.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataFaqDetail = res.data
            }

            state.loadingGetDetailFaq = false
        })

        // update detail faq
        builder.addCase(updateDetailFaq.pending, (state, action) => {
            state.loadingUpdateDetailFaq = true
        })
        builder.addCase(updateDetailFaq.fulfilled, (state, action) => {
            state.loadingUpdateDetailFaq = false
        })

        // delete faq
        builder.addCase(deleteFaq.pending, (state, action) => {
            state.loadingDeleteFaq = true
        })
        builder.addCase(deleteFaq.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataFaq.filter(item => item.id !== Number(res.data.id))

                if (res.data.replaceFaq) {
                    state.dataFaq = [
                        ...updateData,
                        res.data.replaceFaq
                    ]

                    return
                }

                state.faqCount -= 1
                state.dataFaq = updateData
            }

            state.loadingDeleteFaq = false
        })
    }
})

export const { } = faqSlice.actions
export default faqSlice.reducer
