import moment from 'moment/moment'
import React from 'react'
import RatingReview from '../admin/RatingReview'
import { URL_SERVER } from '../../utils/apis'

export default function CommentItem({ commentId, name, content, createdAt, reply = [], border = true, handleReply, isReply = true, mark, picture }) {
    return (
        <div className={`py-5 ${border ? 'border-b' : ''} border-strock`}>
            <div className='flex flex-col gap-1 items-start w-full first:pt-0'>
                <div className='flex justify-between items-center w-full'>
                    <div>
                        <h5 className='lg:font-semibold font-bold text-logoColor mb-1 max-lg:text-14_20'>{name}</h5>
                        {mark && <RatingReview defaultStar={mark} />}
                    </div>

                    <p className='lg:text-12 text-10 leading-normal'>{moment(createdAt).format('YYYY.MM.DD hh:mm')}</p>
                </div>

                <div className='max-lg:text-14_20'>
                    {content}
                </div>
                {isReply && <button type='button' className='lg:text-14_20 text-12 lg:font-bold font-semibold hover:text-logoColor duration-300' onClick={handleReply}>Reply</button>}
                {
                    picture
                    &&
                    <div className='grid lg:grid-cols-8 grid-cols-3 gap-2.5'>
                        {
                            picture.map(item => <img src={`${URL_SERVER}${item.filename}`} alt={`${name}`} className='aspect-square rounded-20 object-cover' />)
                        }
                    </div>
                }
            </div>

            <div className='pl-10'>
                {
                    reply.map(item =>
                        <CommentItem
                            key={item.id}
                            content={item.content}
                            createdAt={item.created_at}
                            name={item.name}
                            border={false}
                            handleReply={handleReply}
                        />)
                }
            </div>
        </div>
    )
}
