import { ArrowDown2, LogoutCurve, ProfileCircle, Star1, TextalignJustifycenter } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { logoutAuth } from '../../reduxs/reducers/auth.reducer.js'
import { useDispatch, useSelector } from 'react-redux'

export default function NavbarHidden() {
    const { dataGetAccount, isAuthentication } = useSelector(state => state.auth)

    const name = dataGetAccount?.name
    const point = dataGetAccount?.point

    const dispatch = useDispatch()

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const handleLogout = () => {
        handleShow()
        dispatch(logoutAuth())
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <>
            <button type='button' className='px-2.5' onClick={handleShow}>
                <TextalignJustifycenter className='max-lg:text-base' />
            </button>

            <aside className={`fixed inset-0 z-50 duration-300 ${isShow ? 'bg-popup' : 'invisible'}`}>
                <nav className={`w-[300px] h-full flex flex-col gap-4 p-6 rounded-r-24 bg-[#FFE1ED] duration-300 ${isShow ? '' : '-translate-x-full'}`} ref={contentRef}>
                    {
                        isAuthentication
                        &&
                        <div className='p-4 bg-logoColor rounded-12 text-white grid gap-2.5'>
                            <div className='flex gap-2.5 items-center'>
                                <ProfileCircle size={24} />
                                <span>{name}</span>
                            </div>

                            <div className='flex items-center justify-between text-14_20'>
                                <div className='flex gap-2.5 items-center'>
                                    <Star1 size={14} />
                                    <span>{point}P</span>
                                </div>
                            </div>

                            {
                                name
                                &&
                                <button type='button' className='flex gap-1 items-center rounded-12 border border-white w-fit py-1.5 px-3' onClick={handleLogout}>
                                    <LogoutCurve size={14} />
                                    <span className='text-12'>로그아웃</span>
                                </button>
                            }
                        </div>
                    }

                    <ul className='flex flex-col gap-2.5'>
                        <LinkItem title='편지지' link='products' mobile handleShowPopup={handleShow} />
                        <LinkItem title='PDF 접수' link='pdf' mobile handleShowPopup={handleShow} />
                        <DropdownLink title='MADEBYME ONLY' mobile handleShowPopup={handleShow} />
                        <LinkItem handleShowPopup={handleShow} title='공지사항' link='posts' />
                        {
                            isAuthentication
                            &&
                            <LinkItem handleShowPopup={handleShow} title='내 정보' link='my-page' />
                        }
                        <LinkItem handleShowPopup={handleShow} title='Kakao cs상담연결' link='https://pf.kakao.com/_iFxaXG' target='_blank' />
                        <LinkItem handleShowPopup={handleShow} title='이용약관' link='terms_of_use' />
                        <LinkItem handleShowPopup={handleShow} title='개인정보처리방침' link='privacy_policy' />
                    </ul>
                </nav>
            </aside>
        </>
    )
}

const LinkItem = ({ link = '#', title, mobile = false, handleShowPopup, target }) => {
    return (
        <li className={`${mobile && 'lg:hidden'}`}>
            <Link onClick={handleShowPopup} to={link} target={target} className={`text-14_20 text-logoColor`}>{title}</Link>
        </li>
    )
}

const DropdownLink = ({ title, mobile, handleShowPopup }) => {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <li className={`${mobile && 'lg:hidden'}`}>
            <button className='text-14_20 text-logoColor flex items-center gap-2.5' onClick={handleShow}>{title} <ArrowDown2 size={16} className={`${isShow && 'rotate-180'}`} /></button>

            {
                isShow
                &&
                <div className='grid gap-2.5 text-14_20 text-logoColor my-2.5 border-l border-logoColor pl-5'>
                    <Link onClick={handleShowPopup} to='/product-event'>공구</Link>
                    <Link onClick={handleShowPopup} to='/product-handmade'>자체제작 상품</Link>
                </div>
            }
        </li>
    )
}
