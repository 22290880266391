import React, { useEffect, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderPdfMypage } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import statusOrder from '../../../components/user/StatusOrder'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../../components/Pagination'

export default function ListPdfOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetOrderPdfMypage, dataOrderPdfMypage, countOrderPdf } = useSelector(state => state.order)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countOrderPdf / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getOrderPdfMypage(page))
    }, [page])

    if (loadingGetOrderPdfMypage || !dataOrderPdfMypage) {
        return <LoadingUser />
    }

    return (
        <section>
            <TitleSection title='PDF 주문서' small />

            <div className='px-5 rounded-20 border border-strock'>
                <div className='overflow-x-auto'>
                    <table className='w-full text-center'>
                        <thead>
                            <tr className='border-b border-strock'>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20'>PDF 파일 이름</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[200px]'>파일 수</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[120px] w-2/12'>생성 날짜</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[120px] w-2/12'>주문 상태</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dataOrderPdfMypage.map(item =>
                                    <RowPdfOrderItem
                                        key={item.order_id}
                                        id={item.order_id}
                                        pdf={item.pdf}
                                        created_at={item.created_at}
                                        status_id={item.order_status_id}
                                        status_name={item.status_name}
                                    />)
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination totalCount={countOrderPdf} currentPage={page} handlePage={handlePage} />
            </div>
        </section>
    )
}

const RowPdfOrderItem = ({ pdf, id, created_at, status_id, status_name }) => {
    const navigate = useNavigate()

    return (
        <tr className='border-b border-strock' onClick={() => navigate(`/order/${id}`)}>
            <td className='py-5'>
                <div className='grid gap-2.5 justify-start text-start'>
                    {
                        pdf.map(item => <p key={item.order_detailpdf_id}>{item.pdf_name}</p>)
                    }
                </div>
            </td>
            <td className='py-5'>{pdf.length}</td>
            <td className='py-5'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5'>
                <div className='flex justify-center max-lg:text-12'>
                    {statusOrder(status_id, status_name)}
                </div>
            </td>
        </tr>
    )
}
