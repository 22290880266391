import React, { useEffect, useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonSecon, { ButtonLinkSecon } from '../../components/ButtonSecon'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNotice, getAllNotice } from '../../reduxs/actions/notice.action'
import LoadingUser from '../user/LoadingUser'
import moment from 'moment/moment'
import DropdownTable from '../../components/admin/DropdownTable'
import ButtonPrimary from '../../components/ButtonPrimary'
import Pagination from '../../components/Pagination'

export default function NoticeAdmin() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetNoice, dataNotice, postCount } = useSelector(state => state.notice)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(postCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isNotify, setIsNotify] = useState(false)

    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleConfirmDelete = async (id) => {
        const result = await dispatch(deleteNotice(id))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    useEffect(() => {
        dispatch(getAllNotice(page))
    }, [dispatch, page])

    return (
        <>
            <TitleSection title='Notice' mt={false} />

            {
                (loadingGetNoice || !dataNotice)
                    ?
                    <LoadingUser className='m-0 py-0' />
                    :
                    <section className='grid gap-5'>
                        <ButtonLinkSecon link='add' title='Notice 추가하기' className='w-fit' />

                        <div className='bg-white rounded-24 px-5'>
                            <table className='w-full text-center'>
                                <thead>
                                    <tr className='border-b border-strock'>
                                        <th className='py-5 px-2 w-[100px]'></th>
                                        <th className='py-5 px-2'>게시물 이름</th>
                                        <th className='py-5 px-2'>추가된 날짜</th>
                                        <th className='py-5 px-2'></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        dataNotice.map((item, index) =>
                                            <RowListNoticeItem
                                                key={item.id}
                                                id={item.id}
                                                index={index + 1}
                                                title={item.title}
                                                createdAt={item.created_at}
                                                handleConfirmDelete={handleConfirmDelete}
                                                handleShowDelete={handleShowDelete}
                                                isShowDelete={isShowDelete}
                                            />)
                                    }
                                </tbody>
                            </table>

                            <Pagination totalCount={postCount} currentPage={page} handlePage={handlePage} />
                        </div>
                    </section>
            }

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}

const RowListNoticeItem = ({ id, index, title, createdAt, isShowDelete, handleConfirmDelete, handleShowDelete }) => {
    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{index}</td>
            <td className='py-5 px-2'>
                <p>{title}</p>
            </td>
            <td className='py-5 px-2'>{moment(createdAt).format('YYYY.MM.DD')}</td>
            <td className='py-5 px-2 w-5'>
                <DropdownTable linkEdit={`edit/${id}`} onclickDelete={handleShowDelete} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>배너를 삭제하시겠습니까?</p>
                                <p>삭제된 배너는 복구할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowDelete} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={() => handleConfirmDelete(id)} />
                            </div>
                        </div>
                    </div>
                }

            </td>
        </tr>
    )
}
