import React, { useEffect, useState } from 'react'
import Pagination from '../../../components/Pagination'
import DropdownTable from '../../../components/admin/DropdownTable'
import { useDispatch, useSelector } from 'react-redux'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { deleteFaq, getAllFaq } from '../../../reduxs/actions/faq.action'
import LoadingUser from '../../../pages/user/LoadingUser'

export default function ListFaq() {
    const dispatch = useDispatch()

    const { loadingGetAllFaq, dataFaq, faqCount } = useSelector(state => state.faq)

    const [page, setPage] = useState(1)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(faqCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getAllFaq(page))
    }, [page])

    if (loadingGetAllFaq || !dataFaq) {
        return <LoadingUser />
    }

    return (
        <div className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[100px]'></th>
                        <th className='py-5 px-2 min-w-[400px]'>질문</th>
                        <th className='py-5 px-2 min-w-[400px]'>답변</th>
                        <th className='py-5 px-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataFaq.map((item, index) =>
                            <RowFaqItem
                                key={item.id}
                                id={item.id}
                                index={page > 1 ? page * 10 + index + 1 : index + 1}
                                answer={item.answer}
                                question={item.question}
                                page={page}
                            />)
                    }
                </tbody>
            </table>

            <Pagination totalCount={faqCount} currentPage={page} handlePage={handlePage} />
        </div>
    )
}

const RowFaqItem = ({ index, id, answer, question, page }) => {
    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)
    const [isShowDelete, setIsShowDelete] = useState(false)

    const handleShowpopup = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleDeleteOrder = async () => {
        const result = await dispatch(deleteFaq({ faqId: id, page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
            handleShowpopup()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>{index}</td>
            <td className='py-5'>
                <p className='line-clamp-1'>{question}</p>
            </td>
            <td className='py-5'>
                <p className='line-clamp-1'>{answer}</p>
            </td>
            <td className='py-5'>
                <DropdownTable linkEdit={`edit/${id}`} onclickDelete={handleShowpopup} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 주문을 삭제하시겠습니까?</p>
                                <p>이 주문이 삭제되면 복원할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowpopup} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleDeleteOrder} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}
