import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterEditPoint from '../../sections/admin/users/FilterEditPoint'
import ListEditPoint from '../../sections/admin/users/ListEditPoint'

export default function EditPointsAdmin() {
    return (
        <>
            <TitleSection title='적립금 수정' mt={false} />

            <section className='grid gap-5'>
                <FilterEditPoint />
                <ListEditPoint />
            </section>
        </>
    )
}
