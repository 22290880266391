import { Trash } from 'iconsax-react'
import React from 'react'
import RadioForm from '../RadioForm'

export default function PdfFileItem({ id, file, handleDeleteFile, typeDownload, handleChangeType, dataColor, pageCount }) {
    const { name } = file

    return (
        <div className='flex items-start justify-between gap-10 lg:py-5 py-4 border-b border-strock'>
            <div>
                <div className='flex gap-2.5'>
                    <p className='line-clamp-1 break-all'>{name || file}</p>

                    <button type='button' onClick={() => handleDeleteFile(id)}>
                        <Trash size={16} />
                    </button>
                </div>

                <span className='lg:text-14_20 text-12 mt-1'>{pageCount}장</span>
            </div>

            <div className='flex gap-5 flex-shrink-0'>
                {
                    dataColor.map(item =>
                        <RadioForm key={item.id} content={item.name} isCheck={typeDownload === item.id} name='type' onChange={(e) => handleChangeType(e, id, item.price)} value={item.id} />
                    )
                }
            </div>
        </div>
    )
}
