import { useFormik } from 'formik'
import React, { useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import * as Yup from 'yup'
import { ERROR_PASSWORD_CONFIRM, ERROR_PASWORD_FORMAT, ERROR_REQUIRED } from '../../utils/error'
import { renderError } from '../../utils/renderText'
import { useDispatch, useSelector } from 'react-redux'
import { resendOtp, resetPassword } from '../../reduxs/actions/auth.action'
import { Navigate, useNavigate } from 'react-router-dom'
import { REGEX_PASWORD } from '../../utils/regex'
import { clearAccountId } from '../../reduxs/reducers/auth.reducer'

export default function ConfirmNewPassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isError, setIsError] = useState()

    const { accountId, name, email } = useSelector(state => state.auth)

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmNewPassword: '',
            userOtp: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().matches(REGEX_PASWORD, ERROR_PASWORD_FORMAT).required(ERROR_REQUIRED),
            confirmNewPassword: Yup.string().oneOf([Yup.ref('password'), null], ERROR_PASSWORD_CONFIRM).required(ERROR_REQUIRED),
            userOtp: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            if (!accountId) return formik.setFieldError('password', 'ERROR NOT FOUND ACCOUNT ID !!!')

            const result = await dispatch(resetPassword({ ...values, account_id: accountId }))

            const res = result.payload

            if (res.status) {
                dispatch(clearAccountId())

                setTimeout(() => {
                    navigate('/auth')
                }, 100);
            } else {
                const error = renderError(res.error)

                formik.setFieldError('userOtp', error)
            }
        }
    })

    const handleResendOtp = async () => {
        const result = await dispatch(resendOtp({ name, email, account_id: accountId }))

        const res = result.payload

        if (res.status) {
            formik.setTouched({ ...formik.touched, userOtp: true })
            formik.setFieldError('userOtp', 'Resend Success !!')
        } else {
            const error = renderError(res.error)

            formik.setTouched({ ...formik.touched, userOtp: true })
            formik.setFieldError('userOtp', error)
        }
    }

    if (!name || !email || !accountId) {
        return <Navigate to='/auth/forgot' />
    }

    return (
        <form className='w-full flex flex-col items-center gap-10' onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 w-full'>
                <div>
                    <LabelForm label='새 비밀번호' />
                    <InputForm
                        type='password'
                        placehoder='새 비밀번호 입력해주세요'
                        name='password'
                        onChange={(e) => {
                            formik.handleChange(e)
                            return isError && setIsError()
                        }}
                        tabIndex={1}
                        value={formik.values.password}
                        error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                    />
                </div>
                <div>
                    <LabelForm label='비빌번호 확인' />
                    <InputForm
                        type='password'
                        placehoder='비빌번호 입력해주세요'
                        name='confirmNewPassword'
                        onChange={(e) => {
                            formik.handleChange(e)
                            return isError && setIsError()
                        }}
                        tabIndex={2}
                        value={formik.values.confirmNewPassword}
                        error={(formik.touched.confirmNewPassword && formik.errors.confirmNewPassword) && formik.errors.confirmNewPassword}
                    />
                </div>
                <div>
                    <LabelForm label='증번호 입력' />
                    <InputForm
                        placehoder=' OTP 입력해주세요'
                        name='userOtp'
                        onChange={(e) => {
                            formik.handleChange(e)
                            return isError && setIsError()
                        }}
                        tabIndex={4}
                        value={formik.values.userOtp}
                        error={(formik.touched.userOtp && formik.errors.userOtp) && formik.errors.userOtp}
                    />
                </div>

                <button onClick={handleResendOtp} type='button' className='text-start text-12 font-semibold text-blue'>OTP 재전송</button>
            </div>

            {
                isError
                &&
                <p className='text-12 text-red'>{renderError(isError)}</p>
            }

            <ButtonPrimary text='가입완료' className='w-[200px]' type='submit' onClick={formik.handleSubmit} />
        </form>
    )
}
