import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterUser from '../../sections/admin/users/FilterUser'
import ListUser from '../../sections/admin/users/Listuser'
import CreateUserNormal from '../../sections/admin/users/CreateUserNormal'
import { useSelector } from 'react-redux'

export default function ListUserAdmin() {
    const { countUserNormal } = useSelector(state => state.user)

    return (
        <>
            <div className='flex gap-10 items-center'>
                <TitleSection title='사용자' mt={false} />

                <p>사용자 {countUserNormal}명</p>
            </div>

            <div className='flex flex-col gap-5'>
                <CreateUserNormal />
                <FilterUser />

                <ListUser />
            </div>
        </>
    )
}
