import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkAuth, logoutAuth } from '../../reduxs/reducers/auth.reducer'
import { getInforUser } from '../../reduxs/actions/auth.action'

export default function ProtectRouterAdmin({ children }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isAuthentication, loading, loadingGetInforUser, dataGetAccount } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(getInforUser())
        dispatch(checkAuth())
    }, [dispatch])

    if (loadingGetInforUser || loading) {
        return <div className='h-screen flex items-center justify-center'>Loading...</div>
    }

    if (!isAuthentication || dataGetAccount.role_id !== 1) {
        dispatch(logoutAuth())
        navigate('/auth')
        return null
    }

    return children
}
