export const ERROR_REQUIRED = '필수 입력 항목입니다'

export const ERROR_EMAIL_FORMAT = '올바른 이메일 형식이 아닙니다'

export const ERROR_PASWORD_FORMAT = '영문+숫자 6~20자리'
export const ERROR_PASSWORD_CONFIRM = '다시 입력한 비밀번호가 올바르지 않습니다'

export const ERROR_PHONE_FORMAT = '전화번호 형식이 올바르지 않습니다'

export const ERROR_FORMAT_IMAGE = 'png 및 jpg 파일만 허용됩니다'
export const ERROR_MAX_IMAGE = '이미지가 용량을 초과하였습니다'

export const ERROR_NOTMATCH_TYPE = '파일 형식이 지원되지 않습니다'
export const ERROR_5_FILE = '최대 5개 파일'
export const ERROR_SIZE_TOO_LARGE = '파일 용량이 너무 큽니다'


export const fallbackRender = ({ error, resetErrorBoundary }) => {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre className="text-red">{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}