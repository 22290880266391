import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCouponByUser, getCouponByUser } from '../../../reduxs/actions/coupon.action'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { Trash } from 'iconsax-react'
import moment from 'moment/moment'

export default function CouponUser({ user_id, handleShow }) {
    const dispatch = useDispatch()

    const { loadingGetCouponUser, dataCouponUser } = useSelector(state => state.coupon)

    const handleDeleteCoupon = (couponId) => {
        dispatch(deleteCouponByUser(couponId))
    }

    useEffect(() => {
        dispatch(getCouponByUser(user_id))
    }, [user_id])

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
            {
                (loadingGetCouponUser || !dataCouponUser)
                    ?
                    <p>Loading...</p>
                    :
                    <div className='p-5 rounded-20 bg-white w-[600px] max-h-[600px] flex flex-col gap-5 items-center overflow-y-scroll hiddenScroll'>
                        <h3 className='text-24-30 font-semibold'>쿠폰 세부정보</h3>

                        <div className='w-full grid grid-cols-2 gap-2.5'>
                            {
                                dataCouponUser.map(item =>
                                    <CouponItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        mark={item.mark}
                                        expire_at={item.expire_at}
                                        type={item.mark_type_id}
                                        handleDeleteCoupon={handleDeleteCoupon}
                                    />
                                )
                            }
                        </div>

                        <ButtonPrimary text='확인' onClick={handleShow} className='w-[160px]' />
                    </div>
            }
        </div>
    )
}

const CouponItem = ({ id, name, mark, type, expire_at, handleDeleteCoupon }) => {
    return (
        <div className='border-[3px] border-strock p-5 rounded-20 grid gap-2.5'>
            <div className='flex justify-between items-center gap-5'>
                <p className='line-clamp-1 break-all font-semibold'>{name}</p>
                <button className='text-deactive' onClick={() => handleDeleteCoupon(id)}><Trash size={20} /></button>
            </div>

            <div className='flex justify-between items-end'>
                <p className='text-24-30 font-semibold text-logoColor'>{type === 1 ? `${mark}원` : `${mark}%`}</p>
                <p className='text-14_20'>{moment(expire_at).format('YYYY/MM/DD')}</p>
            </div>
        </div>
    )
}
