import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/apis";

// get all color
export const getAllColors = createAsyncThunk(
    'color/getAll',
    async () => {
        const response = await get(`category/textcolor`)

        return response
    }
)