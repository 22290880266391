import { Editor } from 'react-draft-wysiwyg'
import React from 'react'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function ContentForm({ editorState, setEditorState }) {
    return (
        <div className='min-h-[300px] rounded-12 border border-strock p-5'>
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                placeholder='내용 입력'
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: [
                        { text: "APPLE", value: "apple" },
                        { text: "BANANA", value: "banana", url: "banana" },
                        { text: "CHERRY", value: "cherry", url: "cherry" },
                        { text: "DURIAN", value: "durian", url: "durian" },
                        { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
                        { text: "FIG", value: "fig", url: "fig" },
                        { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
                        { text: "HONEYDEW", value: "honeydew", url: "honeydew" }
                    ]
                }}
            />
        </div>
    )
}
