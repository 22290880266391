import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get all advert
export const getAllAdvert = createAsyncThunk(
    'advert/getall',
    async (page) => {
        const response = await get(`advert/?page=${page}`)

        return response
    }
)

// create advert
export const createAdvert = createAsyncThunk(
    'advert/create',
    async (data) => {
        const response = await post(`advert`, data)

        return response
    }
)

// delete advert
export const deleteAdvert = createAsyncThunk(
    'advert/delete',
    async (advertId) => {
        const response = await del(`advert/${advertId}`)

        return response
    }
)

// toggle advert
export const toggleStatusAdvert = createAsyncThunk(
    'advert/toggleStatus',
    async (data) => {
        const response = await post(`advert/toggle-hidden`, data)

        return response
    }
)

// get advert homepage
export const getAdvertHomepage = createAsyncThunk(
    'advert/getAdvertHomepage',
    async (data) => {
        const response = await get(`advert/home-advert`)

        return response
    }
)