import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/apis";

// get comment by orderId
export const getCommentByOrder = createAsyncThunk(
    'comment/get',
    async (orderId) => {
        const response = await get(`productevaluate/infor/${orderId}`)

        return response
    }
)

// post comment order
export const postCommentOrder = createAsyncThunk(
    'comment/post',
    async (data) => {
        const response = await post(`productevaluate`, data)

        return response
    }
)

// get comment by product
export const getCommentByProduct = createAsyncThunk(
    'comment/product',
    async (data) => {
        const { type, letterId } = data

        const response = await get(`/product/evaluate/${type}/${letterId}`)

        return response
    }
)

// get comment admin
export const getCommentAdmin = createAsyncThunk(
    'comment/getAdmin',
    async (page) => {
        const response = await get(`/productevaluate/admin-allevaluate?page=${page}`)

        return response
    }
)

// get comment admin
export const toggleStatusComment = createAsyncThunk(
    'comment/toggleStatus',
    async (data) => {
        const response = await post(`/productevaluate/toggle/evaluate-home`, data)

        return response
    }
)