import { AttachSquare, CloseSquare } from 'iconsax-react'
import React, { useRef } from 'react'

export default function PopupImage({ onChange, image, handleDelete, error }) {
    const inputRef = useRef()

    const handleClickInput = () => {
        inputRef.current.click()
    }

    return (
        <div className='flex flex-col gap-2.5 items-center'>
            <div className='aspect-video w-full border-dashed border-[2px] rounded-20 overflow-hidden'>
                <input
                    type='file'
                    accept='.jpg , .png'
                    hidden
                    ref={inputRef}
                    onChange={(e) => {
                        onChange(e.currentTarget.files[0])
                        e.target.value = null
                    }}
                />

                <div className='relative h-full'>
                    {
                        image ?
                            <>
                                <img src={URL.createObjectURL(image)} alt='' className='w-full h-full object-contain' />
                                <button type='button' className='absolute top-0 right-0 p-5 text-deactive' onClick={handleDelete}>
                                    <CloseSquare size={20} />
                                </button>
                            </>
                            :
                            <div className='w-full h-full flex flex-col justify-center items-center cursor-pointer' onClick={handleClickInput}>
                                <AttachSquare size={55} className='mb-2.5 strock1' />
                                {/* <p>이미지 비율 1x1</p> */}
                                <p>(최대 10MB)</p>
                            </div>
                    }
                </div>
            </div>

            {
                error
                &&
                <p className='text-12 text-red w-full'>{error}</p>
            }
        </div>
    )
}
