import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import { ButtonLinkSecon } from '../../components/ButtonSecon'
import ListFaq from '../../sections/admin/faq/ListFaq'

export default function FaqAdmin() {
    return (
        <>
            <TitleSection title='FAQ' mt={false} />

            <section className='grid gap-5'>
                <ButtonLinkSecon link='add' title='FAQ 추가하기' className='w-fit' />

                <ListFaq />
            </section>
        </>
    )
}
