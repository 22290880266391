import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/apis";

// get all font
export const getAllFont = createAsyncThunk(
    'font/getall',
    async () => {
        const response = await get(`category/fontfamily`)

        return response
    }
)