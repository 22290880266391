import React from 'react'
import ButtonPrimary from '../ButtonPrimary'
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import { useDaumPostcodePopup } from 'react-daum-postcode';

export default function SelectAddressUser({ buttonTitle, formik, target, zipcode }) {
    const open = useDaumPostcodePopup(postcodeScriptUrl);

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        // console.log(fullAddress)

        // console.log(fullAddress);
        formik.setValues({
            ...formik.values,
            [target]: fullAddress,
            [zipcode]: data.zonecode
        })
    };

    const handleClick = () => {
        open({ onComplete: handleComplete });
    };

    return (

        <>
            <ButtonPrimary text={buttonTitle} className='flex-shrink-0' onClick={handleClick} />
        </>
    )
}
