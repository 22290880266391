import moment from 'moment/moment'
import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { formatMoney } from '../../../utils/renderText'

export default function ListCoupon({ data }) {
    return (
        <section>
            <TitleSection small title='쿠폰' />
            <div className='grid lg:grid-cols-4 gap-5'>
                {
                    data.map(item => <CouponItem key={item.id} name={item.name} price={item.mark} type={item.mark_type_id} />)
                }
            </div>
        </section>
    )
}

const CouponItem = ({ name, price, created_at, type }) => {
    return (
        <div className='p-5 rounded-20 border-[3px] border-strock grid gap-2.5'>
            <span className='font-bold'>{name}</span>

            <div className='flex justify-between items-end gap-2.5'>
                <span className='text-logoColor text-24-30 font-bold'>{type === 1 ? `${price}%` : `₩${formatMoney(price)}`}</span>

                <span className='text-14_20'>{moment(created_at).format('YYYY.MM.DD')}</span>
            </div>
        </div>
    )
}
