import React, { useEffect, useState } from 'react'
import RatingReview from '../../../components/admin/RatingReview'
import ToggleStatus from '../../../components/admin/ToggleStatus'
import { useDispatch, useSelector } from 'react-redux'
import { getCommentAdmin, toggleStatusComment } from '../../../reduxs/actions/comment.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import Pagination from '../../../components/Pagination'
import moment from 'moment/moment'

export default function ListReviewProduct() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetCommentAdmin, dataCommentAdmin, countComment } = useSelector(state => state.comment)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countComment / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getCommentAdmin(page))
    }, [page])

    if (loadingGetCommentAdmin || !dataCommentAdmin) {
        return <LoadingUser />
    }

    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[300px]'>상품명</th>
                        <th className='py-5 px-2 w-[200px]'>작성자</th>
                        <th className='py-5 px-2'>리뷰내용</th>
                        <th className='py-5 px-2 w-[200px]'>리뷰내용</th>
                        <th className='py-5 px-2 w-[200px]'>작성일자</th>
                        <th className='py-5 px-2 w-[150px]'>메인노출</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataCommentAdmin.map(item =>
                            <RowReviewProductItem
                                key={item.id}
                                id={item.id}
                                product_name={item.product_name}
                                user_name={item.user_name}
                                content={item.content}
                                mark={item.mark}
                                show_home={item.show_home}
                                created_at={item.created_at}
                            />)
                    }
                </tbody>
            </table>

            <Pagination currentPage={page} totalCount={countComment} handlePage={handlePage} />
        </section>
    )
}

const RowReviewProductItem = ({ id, product_name, user_name, content, mark, show_home, created_at }) => {
    const dispatch = useDispatch()

    const handleToggleStatus = () => {
        dispatch(toggleStatusComment({ evaluate_id: id }))
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{product_name}</td>
            <td className='py-5 px-2'>{user_name}</td>
            <td className='py-5 px-2'>
                <p className='break-all line-clamp-2 text-center'>{content}</p>
            </td>
            <td className='py-5 px-2'><RatingReview onlyView defaultStar={mark} /></td>
            <td className='py-5 px-2'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5 px-2'><ToggleStatus status={!!show_home} onClick={handleToggleStatus} /></td>
        </tr>
    )
}

