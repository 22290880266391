import React, { useEffect } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ListCategories from '../../sections/admin/categories/ListCategories'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategoriesAdmin } from '../../reduxs/actions/category.action'
import LoadingUser from '../user/LoadingUser'

export default function CategoriesAdmin() {
    const dispatch = useDispatch()

    const { dataCategoriesAdmin, loadingGetAllCategoriesAdmin } = useSelector(state => state.categories)

    useEffect(() => {
        dispatch(getAllCategoriesAdmin())
    }, [])

    if (loadingGetAllCategoriesAdmin || !dataCategoriesAdmin) {
        return <LoadingUser />
    }

    const { } = dataCategoriesAdmin

    return (
        <>
            <TitleSection title='카테고리' mt={false} />

            {
                dataCategoriesAdmin.map(item =>
                    <ListCategories
                        key={item.product_type_id}
                        typeId={item.product_type_id}
                        title={item.name}
                        data={item.category}
                    />
                )
            }
        </>
    )
}
