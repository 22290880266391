import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import { ButtonPrimaryLink } from '../../components/ButtonPrimary'
import { TruckFast } from 'iconsax-react'
import product from '../../assets/images/letters.png'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetail } from '../../reduxs/actions/order.action'
import { useParams } from 'react-router-dom'
import LoadingUser from './LoadingUser'
import { URL_SERVER } from '../../utils/apis'
import NotFoundPage from '../404'
import { getAllFont } from '../../reduxs/actions/fontfamily.action'
import { getAllColors } from '../../reduxs/actions/color.action'
import LetterQuillRead from '../../components/user/LetterQuillRead'

export default function OrderdetailPage() {
    const dispatch = useDispatch()

    const { orderId } = useParams()

    const { loadingGetOrderDetail, dataOrderDetail, isError } = useSelector(state => state.order)

    const order_type_id = dataOrderDetail?.order_type_id

    const letterDetail = dataOrderDetail?.letterDetail

    const frontLetter = letterDetail?.frontThumbnail
    const endLetter = letterDetail?.endThumbnail

    const [editors, setEditors] = useState()

    useEffect(() => {
        if (letterDetail) {
            const contentParse = JSON.parse(letterDetail.content)

            const restoredEditors = contentParse.map(editor => {
                return {
                    id: editor.id,
                    editorState: editor.editorState
                };
            });

            setEditors(restoredEditors)
        } else {
            setEditors()
        }
    }, [letterDetail, orderId])

    useEffect(() => {
        dispatch(getOrderDetail(orderId))
    }, [orderId])

    useEffect(() => {
        if (order_type_id !== 4) {
            dispatch(getAllFont())
            dispatch(getAllColors())
        }
    }, [order_type_id])

    if (loadingGetOrderDetail) {
        return <LoadingUser />
    }

    if (isError) {
        return <NotFoundPage />
    }

    const {
        created_at,
        money_pay,
        ship_fee,
        used_point,
        paymethod_name,
        stamp_name,
        product_name,
        pdf,
        materials,
        frontThumbnail,
        endThumbnail,
        shipment_code
    } = dataOrderDetail

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='주문 세부 정보' />

            {
                editors
                &&
                <section className='grid lg:grid-cols-3 gap-5'>
                    {
                        editors.map(item =>
                            <LetterQuillRead
                                key={item.id}
                                editorState={item.editorState}
                                frontThumbnail={frontLetter}
                                endThumbnail={endLetter}
                                widthElm='397.3'
                            />)
                    }
                </section>
            }

            {
                (frontThumbnail && endThumbnail)
                &&
                <section className='flex justify-center max-lg:flex-col gap-5'>
                    <img src={`${URL_SERVER}${frontThumbnail}`} className='aspect-[7.08/10] w-[397px] object-cover' />
                    <img src={`${URL_SERVER}${endThumbnail}`} className='aspect-[7.08/10] w-[397px] object-cover' />
                </section>
            }

            <section className='border border-strock rounded-20 p-5'>
                <h3 className='lg:text-30 font-semibold' >생성 날짜 {moment(created_at).format('YYYY-MM-DD hh:mm:ss')}</h3>

                <div className='grid gap-5 py-5 border-b border-strock'>
                    <div className='flex gap-10 items-center'>
                        <p className='font-bold w-[100px]'>결제금액:</p>
                        <span className='text-logoColor font-bold w-[150px] text-start'>{money_pay - ship_fee}원</span>
                    </div>

                    {
                        stamp_name
                        &&
                        <div className='flex gap-10 items-center'>
                            <p className='font-bold w-[100px]'>우편종류:</p>
                            <span className='w-[150px] text-start'>{stamp_name} = {ship_fee}원</span>
                        </div>
                    }

                    {
                        (pdf && order_type_id === 4)
                        &&
                        <div className='flex gap-10 items-start'>
                            <p className='font-bold w-[100px]'>PDF 주문서:</p>
                            <div className='grid gap-2.5'>
                                {
                                    pdf.map(item => <PdfView key={item.id} name={item.pdf_name} filename={item.filename} />)
                                }
                            </div>
                        </div>
                    }

                    {
                        product_name
                        &&
                        <div className='flex gap-10 items-center'>
                            <p className='font-bold w-[100px]'>자료이름:</p>
                            <span className='text-start'>{product_name}</span>
                        </div>
                    }

                    <div className='flex gap-10 items-center'>
                        <p className='font-bold w-[100px]'>사용 포인트:</p>
                        <span className='w-[150px] text-start'>{used_point}포인트</span>
                    </div>

                    {
                        paymethod_name &&
                        <div className='flex gap-10 items-center'>
                            <p className='font-bold w-[100px]'>결제방법:</p>
                            <span className='w-[150px] text-start'>{paymethod_name}</span>
                        </div>
                    }

                    {
                        shipment_code
                        &&
                        <div className='flex gap-10 items-center'>
                            <p className='font-bold w-[100px]'>주문 상태:</p>
                            <span className='w-[150px] text-start'><ButtonPrimaryLink target='_blank' link={`https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${shipment_code}`} text='배송 추적' /></span>
                        </div>
                    }
                </div>

                {
                    ((pdf && pdf.length) || (materials && materials.length))
                        ?
                        <div className='grid gap-5 py-5 border-b border-strock'>
                            {
                                (pdf && pdf.length)
                                    ?
                                    <div className='flex gap-10 items-start'>
                                        <p className='font-bold w-[100px]'>PDF 첨부:</p>
                                        <div className='grid gap-2.5'>
                                            {
                                                pdf.map(item => <PdfDownload key={item.id} name={item.pdf_name} filename={item.filename} />)
                                            }
                                        </div>
                                    </div>
                                    : ''
                            }

                            {
                                (materials && materials.length)
                                    ?
                                    <div className='flex gap-10 items-start'>
                                        <p className='font-bold w-[100px] flex-shrink-0'>자체제작 자료:</p>
                                        <div className='grid lg:grid-cols-6 grid-cols-2 gap-2.5'>
                                            {
                                                materials.map(item => <img key={item.id} src={product} alt='product' className='aspect-[7.08/10] object-cover rounded-20' />)
                                            }
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                        : ''
                }

                <div className='flex justify-between items-start w-full max-lg:flex-col gap-4 max-lg:text-14_20 py-5'>
                    <div className='flex gap-2 items-center'>
                        <TruckFast variant="Bold" size={20} />
                        <p>등기/우편 비용:</p>
                        <span className='text-logoColor font-bold'>{ship_fee}원</span>
                    </div>

                    <div className='flex flex-col gap-2.5'>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>총액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{money_pay}원</span>
                        </div>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>사용 포인트:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{used_point}포인트</span>
                        </div>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20 font-bold'>총 구매금액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{money_pay}원</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

const PdfDownload = ({ name, filename }) => {
    return (
        <div className='flex items-center gap-5'>
            <p className='line-clamp-1 break-all'>{name}</p>
            {/* <button type='button'><ReceiveSquare size={20} className='strock1' /></button> */}
        </div>
    )
}

const PdfView = ({ name, filename, page }) => {
    return (
        <div>
            <p className='line-clamp-1 break-all mb-1'>{name}</p>
            <span className='text-lightPink'>(PDF: {page}장 컬러)</span>
        </div>
    )
}