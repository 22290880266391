import { createSlice } from "@reduxjs/toolkit";
import { getCommentAdmin, getCommentByOrder, getCommentByProduct, postCommentOrder, toggleStatusComment } from "../actions/comment.action";

const commentSlice = createSlice({
    name: 'comment',
    initialState: {
        loadingGetComment: false,
        dataComment: undefined,

        loadingPostComment: false,

        loadingGetCommentProduct: false,
        dataCommentProduct: undefined,

        loadingGetCommentAdmin: false,
        dataCommentAdmin: undefined,
        countComment: 0,

        loadingToggleStatusComment: false
    },
    extraReducers: builder => {
        // get comment by order
        builder.addCase(getCommentByOrder.pending, (state, action) => {
            state.loadingGetComment = true
        })
        builder.addCase(getCommentByOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataComment = res.data
            }

            state.loadingGetComment = false
        })

        // post comment order
        builder.addCase(postCommentOrder.pending, (state, action) => {
            state.loadingPostComment = true
        })
        builder.addCase(postCommentOrder.fulfilled, (state, action) => {
            state.loadingPostComment = false
        })

        // get comment by product
        builder.addCase(getCommentByProduct.pending, (state, action) => {
            state.loadingGetCommentProduct = true
        })
        builder.addCase(getCommentByProduct.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCommentProduct = res.data
            }

            state.loadingGetCommentProduct = false
        })

        // get comment admin
        builder.addCase(getCommentAdmin.pending, (state, action) => {
            state.loadingGetCommentAdmin = true
        })
        builder.addCase(getCommentAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCommentAdmin = res.data.evaluates
                state.countComment = res.data.evaluateCount
            }

            state.loadingGetCommentAdmin = false
        })

        // toggle status comment
        builder.addCase(toggleStatusComment.pending, (state, action) => {
            state.loadingToggleStatusComment = true
        })
        builder.addCase(toggleStatusComment.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataCommentAdmin.map(item => {
                    if (item.id === res.data.id) {
                        return res.data
                    }

                    return item
                })

                state.dataCommentAdmin = updateData
            }

            state.loadingToggleStatusComment = false
        })
    }
})

export default commentSlice.reducer
