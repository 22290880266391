import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import BannerImage from '../../sections/admin/managementImage/BannerImage'
import EventAdmin from '../../sections/admin/managementImage/EventAdmin'

export default function ManagementImage() {
    return (
        <>
            <TitleSection title='배너/ Mabyme Calendar' mt={false} />

            <div className='bg-white rounded-20 p-5 flex flex-col gap-[30px]'>
                <BannerImage />
                <EventAdmin />
            </div>
        </>
    )
}
