import React, { useEffect, useState } from 'react'
import ListPdfUpload from './ListPdfUpload'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ListMaterial from './ListMaterial'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSavedPdfMaterialOrder, savedPdfMaterialOrder, updatePdfMaterialOrder } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { encodeFilename } from '../../../utils/renderText'
import { ERROR_5_FILE } from '../../../utils/error'

export default function UploadPdf({ handleNavigateStep }) {
    const dispatch = useDispatch()

    const [pdfData, setPDfData] = useState([])
    const [pdfDataDelete, setPdfDataDelete] = useState([])
    const [pdfDataUpload, setPdfDataUpload] = useState([])
    const [materialData, setMaterialdata] = useState([])
    const [materialDataDelete, setMaterialDataDelete] = useState([])
    const [materialDataUpdate, setMaterialDataUpdate] = useState([])

    const [errorPdf, setErrorPdf] = useState()

    const [searchParams] = useSearchParams()

    const { loadingGetSavedPdfMaterial, dataSavedPdfMaterial } = useSelector(state => state.order)

    const letterId = searchParams.get('savedLetter')

    const [currenttab, setCurrentTab] = useState(1)

    const handleChooseCurrentTab = (tab) => {
        setCurrentTab(tab)
    }

    const handleConfirmNextStep = async () => {
        if (pdfData.length > 5) {
            setErrorPdf(ERROR_5_FILE)

            setTimeout(() => {
                setErrorPdf(false)
            }, 3000);

            return
        }

        const formData = new FormData()

        for (let i = 0; i < materialDataUpdate.length; i++) {
            const element = materialDataUpdate[i];

            formData.append(`materials[${i}][product_id]`, element.id)
            formData.append(`materials[${i}][price]`, element.price)
            formData.append(`materials[${i}][quantity]`, 1)
        }

        for (let i = 0; i < pdfDataUpload.length; i++) {
            const element = pdfDataUpload[i];

            const encodedFilename = encodeFilename(element.pdf_name.name)

            const file = new File([element.pdf_name], encodedFilename, { type: element.pdf_name.type })

            formData.append(`print_type[${i}][type]`, element.type)
            formData.append(`print_type[${i}][price]`, element.price)
            formData.append(`print_type[${i}][pdf]`, file)
            formData.append(`print_type[${i}][pdf_page]`, element.pdf_page)
        }

        if (dataSavedPdfMaterial.print_type.length && dataSavedPdfMaterial.materials.length) {
            for (let i = 0; i < pdfDataDelete.length; i++) {
                const pdf = pdfDataDelete[i];

                formData.append(`deletePdf[${i}]`, pdf)
            }

            for (let i = 0; i < materialDataDelete.length; i++) {
                const material = materialDataDelete[i];

                formData.append(`deleteMaterials[${i}]`, material)
            }

            const result = await dispatch(updatePdfMaterialOrder({ letterId, data: formData }))

            const res = result.payload

            if (res.status) {
                handleNavigateStep('next')
            }
        } else {
            const result = await dispatch(savedPdfMaterialOrder({ letterId, data: formData }))

            const res = result.payload

            if (res.status) {
                handleNavigateStep('next')
            }
        }
    }

    useEffect(() => {
        dispatch(getSavedPdfMaterialOrder(letterId))
    }, [letterId])

    useEffect(() => {
        if (dataSavedPdfMaterial) {
            const pdf = dataSavedPdfMaterial.print_type

            setPDfData(pdf)
        }
    }, [dataSavedPdfMaterial])

    if (loadingGetSavedPdfMaterial || !dataSavedPdfMaterial) {
        return <LoadingUser />
    }

    return (
        <>
            <article className='flex flex-col gap-5 items-center justify-center w-full'>
                <section className='flex flex-col gap-5 items-center justify-center w-full'>
                    <div className='font-semibold max-lg:text-14_20'>
                        <button type='button' onClick={() => handleChooseCurrentTab(1)} className={`lg:px-8 px-2 duration-200 ${currenttab === 1 ? 'text-logoColor' : ''}`}>PDF 첨부</button>
                        <button type='button' onClick={() => handleChooseCurrentTab(2)} className={`lg:px-8 px-2 duration-200 ${currenttab === 2 ? 'text-logoColor' : ''}`}>자체제작 자료</button>
                    </div>

                    {
                        currenttab === 1
                        &&
                        <ListPdfUpload
                            pdfData={pdfData}
                            setPDfData={setPDfData}
                            setPdfDataDelete={setPdfDataDelete}
                            pdfDataUpload={pdfDataUpload}
                            setPdfDataUpload={setPdfDataUpload}
                        />
                    }

                    {
                        currenttab === 2
                        &&
                        <ListMaterial
                            oldMaterial={dataSavedPdfMaterial.materials}
                            materialData={materialData}
                            setMaterialdata={setMaterialdata}
                            setMaterialDataDelete={setMaterialDataDelete}
                            materialDataDelete={materialDataDelete}
                            materialDataUpdate={materialDataUpdate}
                            setMaterialDataUpdate={setMaterialDataUpdate}
                        />
                    }
                </section>

                <div className='flex gap-2.5 w-full justify-center'>
                    <ButtonSecon title='이전' className='max-w-[160px] w-full justify-center' onClick={handleNavigateStep} />
                    <ButtonPrimary text='건너뛰기' className='max-w-[160px] w-full justify-center' onClick={() => handleNavigateStep('next')} />
                    <ButtonSecon title='다음' className='max-w-[160px] w-full justify-center' onClick={handleConfirmNextStep} />
                </div>
            </article>

            <div className={`${errorPdf ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {errorPdf}
            </div>
        </>
    )
}
