import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatMoney } from '../../../utils/renderText'
import ButtonDeactive from '../../../components/ButtonDeactive'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import CountdownTimer from '../../../components/user/CountdownTimer'
import { setIsShowNotifyNotLogin } from '../../../reduxs/reducers/auth.reducer'

export default function ProductInforNormal({ detail }) {
    const dispatch = useDispatch()

    const nowInKorea = moment.tz(moment.now(), 'Asia/Seoul');

    const resultDate = nowInKorea.hour() <= 3 ? nowInKorea.add(1, 'day').format('YYYY-MM-DD') : nowInKorea.format('YYYY-MM-DD');

    const { isAuthentication } = useSelector(state => state.auth)
    const navigate = useNavigate()

    const { id, name, price, sold_out, basic_provision, addition_offer, number_line, number_character } = detail

    const handleNavigate = () => {
        return isAuthentication ? navigate(`/writing/${id}`) : dispatch(setIsShowNotifyNotLogin())
    }

    return (
        <div className='flex flex-col lg:gap-5 gap-4'>
            <div className='flex items-center gap-2.5'>
                <h4 className='lg:text-24-30 font-semibold'>{name}</h4>
                <span className='py-1.5 px-2.5 rounded-4 bg-logoColor text-12 font-semibold text-white'>New</span>
            </div>

            <div>
                <div className='pb-5 border-b border-strock flex flex-col lg:gap-5 gap-4'>
                    <ContentItem title='판매가격' content={`${formatMoney(price)} 원`} color />
                    <ContentItem title='기본제공' content={basic_provision} />
                    <ContentItem title='추가제공' content={addition_offer} />
                    <ContentItem title='기본제공' content={<>
                        <p>발송 예정: <span className='text-logoColor'>{resultDate}</span></p>
                        {<CountdownTimer detail={true} />}
                    </>} />
                </div>

                <div className='pt-5 flex flex-col gap-5'>
                    <ContentItem title='장당 한 장당' content={`한 장당 ${20 || number_line}줄`} />
                    <ContentItem title='장당 글자수' content={`한 장당 ${number_character} (폰트에 따라 다름)`} />
                </div>
            </div>

            {
                sold_out
                    ?
                    <ButtonDeactive text='편지작성' />
                    :
                    <button onClick={handleNavigate} className='bg-logoColor rounded-12 lg:py-2.5 py-2 lg:px-4 px-[14px] text-white w-[160px] text-center max-lg:text-14_20'>편지작성</button>
            }
        </div>
    )
}


const ContentItem = ({ title, content, color = false }) => {
    return (
        <div className='flex items-start '>
            <p className='lg:w-[100px] w-[90px] font-semibold flex-shrink-0 max-lg:text-14_20'>{title}</p>
            <div className={`${color && 'text-logoColor font-bold'} max-lg:text-14_20`}>{content}</div>
        </div>
    )
}