import React, { useContext, useEffect } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import SelectInforUser from '../../../components/user/SelectInforUser'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import RadioForm from '../../../components/RadioForm'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { UploadPdfContext } from '../../../contexts/UploadPdfContext'
import SelectAddressUser from '../../../components/user/SelectAddressUser'
import ShippingFeeItem from '../../../components/user/ShippingFeeItem'
import LoadingUser from '../../../pages/user/LoadingUser'
import { getOrderStamp } from '../../../reduxs/actions/order.action'
import { useDispatch, useSelector } from 'react-redux'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import SearchOrganization from '../../../components/user/SearchOrganization'

export default function SelectAdrUserPdfStep() {
    const dispatch = useDispatch()

    const { loadingGetStamp, dataOrderStamp } = useSelector(state => state.order)

    const { handleNavigateStep, setFormUpload, formUpload } = useContext(UploadPdfContext)

    const formik = useFormik({
        initialValues: {
            sender_name: '',
            sender_phone: '',
            sender_address: '',
            sender_zip: '',
            receiver_name: '',
            receiver_phone: '',
            receiver_address: '',
            receiver_zip: '',
            stamp_id: '',
            ship_fee: '',
            nameStamp: '',
        },
        validationSchema: Yup.object({
            sender_name: Yup.string().required(ERROR_REQUIRED),
            sender_phone: Yup.string().required(ERROR_REQUIRED),
            sender_address: Yup.string().required(ERROR_REQUIRED),
            sender_zip: Yup.string().required(ERROR_REQUIRED),
            receiver_name: Yup.string().required(ERROR_REQUIRED),
            receiver_phone: Yup.string().required(ERROR_REQUIRED),
            receiver_address: Yup.string().required(ERROR_REQUIRED),
            receiver_zip: Yup.string().required(ERROR_REQUIRED),
            stamp_id: Yup.number().required(ERROR_REQUIRED),
            ship_fee: Yup.number().required(ERROR_REQUIRED)
        }),
        onSubmit: values => {
            setFormUpload({
                ...formUpload,
                sender_name: values.sender_name,
                sender_phone: values.sender_phone,
                sender_address: values.sender_address,
                sender_zip_code: values.sender_zip,
                recipient_name: values.receiver_name,
                recipient_phone: values.receiver_phone,
                recipient_address: values.receiver_address,
                recipient_zip_code: values.receiver_phone,
                stamp_id: values.stamp_id,
                ship_fee: values.ship_fee,
                nameStamp: values.nameStamp,
            })

            handleNavigateStep('next')
        }
    })

    const handleChangeReceiver = (e) => {
        formik.handleChange(e)
        setFormUpload({
            ...formUpload,
            recipient_address_id: '',
        })
    }

    const handleChangeSender = (e) => {
        formik.handleChange(e)
        setFormUpload({
            ...formUpload,
            sender_address_id: ''
        })
    }

    const handleSaveSender = () => {
        setFormUpload({
            ...formUpload,
            save_sender: !(formUpload?.save_sender),
            sender_address_id: ''
        })
    }

    const handleSaveReceiver = () => {
        setFormUpload({
            ...formUpload,
            save_recipient: !(formUpload?.save_recipient),
            recipient_address_id: ''
        })
    }

    const handleSelectStamp = (stamp) => {
        formik.setValues({
            ...formik.values,
            stamp_id: stamp.id,
            ship_fee: stamp.price,
            nameStamp: stamp.name
        })
    }

    useEffect(() => {
        dispatch(getOrderStamp(1))
    }, [])

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            sender_name: formUpload.sender_name,
            sender_phone: formUpload.sender_phone,
            sender_address: formUpload.sender_address,
            sender_zip: formUpload.sender_zip_code,
            receiver_name: formUpload.recipient_name,
            receiver_phone: formUpload.recipient_phone,
            receiver_address: formUpload.recipient_address,
            receiver_zip: formUpload.recipient_zip_code,
            stamp_id: formUpload.stamp_id,
            ship_fee: formUpload.ship_fee,
            nameStamp: formUpload.nameStamp
        })
    }, [])

    useEffect(() => {
        if (formUpload.organization) {
            formik.setValues({
                ...formik.values,
                receiver_name: formUpload.receiver_name,
                receiver_phone: '',
                receiver_address: formUpload.receiver_address,
                receiver_zip: formUpload.receiver_zip
            });
        }
    }, [formUpload])

    const sender_id = formUpload?.sender_address_id
    const receiver_id = formUpload?.recipient_address_id

    useEffect(() => {
        if (sender_id || receiver_id) {
            formik.setValues({
                ...formik.values,
                sender_name: formUpload.sender_name ? formUpload.sender_name : '',
                sender_phone: formUpload.sender_phone ? formUpload.sender_phone : '',
                sender_address: formUpload.sender_address ? formUpload.sender_address : '',
                sender_zip: formUpload.sender_zip_code ? formUpload.sender_zip_code : '',
                receiver_name: formUpload.recipient_name ? formUpload.recipient_name : '',
                receiver_phone: formUpload.recipient_phone ? formUpload.recipient_phone : '',
                receiver_address: formUpload.recipient_address ? formUpload.recipient_address : '',
                receiver_zip: formUpload.recipient_zip_code ? formUpload.recipient_zip_code : '',
                stamp_id: formUpload.stamp_id,
                ship_fee: formUpload.ship_fee,
                nameStamp: formUpload.nameStamp
            })
        }
    }, [sender_id, receiver_id])

    if (loadingGetStamp || !dataOrderStamp) {
        return <LoadingUser />
    }

    return (
        <>
            <TitleSection title='주소 입력' mt={false} />

            <section className='grid lg:grid-cols-2 lg:gap-10 gap-4 w-full'>
                <div className='p-5 rounded-20 border border-strock flex flex-col items-center gap-5 w-full'>
                    <div className='flex justify-between items-center w-full'>
                        <TitleSection title='보내는 사람' small mt={false} />

                        <SelectInforUser
                            titleButton='저장된 주소선택'
                            title='저장된 보낸 사람 주소'
                            formUpload={formUpload}
                            setFormUpload={setFormUpload}
                        />
                    </div>

                    <div className='grid lg:gap-5 gap-4 w-full'>
                        <div>
                            <LabelFormThin label='보내는 사람 이름' />
                            <InputFormThin
                                name='sender_name'
                                value={formik.values.sender_name}
                                onChange={handleChangeSender}
                                placehoder='내용을 입력하세요'
                                error={(formik.touched.sender_name && formik.errors.sender_name) && formik.errors.sender_name}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='휴대전화번호' />
                            <InputFormThin
                                name='sender_phone'
                                value={formik.values.sender_phone}
                                onChange={handleChangeSender}
                                placehoder='내용을 입력하세요'
                                error={(formik.touched.sender_phone && formik.errors.sender_phone) && formik.errors.sender_phone}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='보내는사람주소' />

                            <div className='flex gap-2.5 items-start'>
                                <InputFormThin
                                    name='sender_address'
                                    value={formik.values.sender_address}
                                    onChange={handleChangeSender}
                                    placehoder='내용을 입력하세요'
                                    classNameAll='w-full'
                                    error={(formik.touched.sender_address && formik.errors.sender_address) && formik.errors.sender_address}
                                />

                                <SelectAddressUser buttonTitle='주소검색' formik={formik} target='sender_address' zipcode='sender_zip' />
                            </div>
                        </div>
                        <div>
                            <LabelFormThin label='우편번호' />
                            <InputFormThin
                                name='sender_zip'
                                value={formik.values.sender_zip}
                                onChange={handleChangeSender}
                                placehoder='내용을 입력하세요'
                                error={(formik.touched.sender_zip && formik.errors.sender_zip) && formik.errors.sender_zip}
                            />
                        </div>
                    </div>

                    <RadioForm
                        content='저장 [저장시 자동으로 주소를 불러옴]'
                        onChange={handleSaveSender}
                        isCheck={formUpload?.save_sender}
                        type='checkbox'
                    />
                </div>

                <div className='p-5 rounded-20 border border-strock flex flex-col items-center gap-5 w-full'>
                    <div className='flex justify-end items-center w-full'>
                        <SelectInforUser
                            titleButton='저장된 수신자 주소'
                            title='저장된 수신자 주소'
                            typeId={2}
                            formUpload={formUpload}
                            setFormUpload={setFormUpload}
                        />
                    </div>

                    <div className='grid gap-5 w-full'>
                        <div>
                            <LabelFormThin label='받는 사람 이름' />
                            <InputFormThin
                                placehoder='내용을 입력하세요'
                                name='receiver_name'
                                value={formik.values.receiver_name}
                                onChange={handleChangeReceiver}
                                error={(formik.touched.receiver_name && formik.errors.receiver_name) && formik.errors.receiver_name}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='휴대전화번호' />
                            <InputFormThin
                                placehoder='내용을 입력하세요'
                                name='receiver_phone'
                                value={formik.values.receiver_phone}
                                onChange={handleChangeReceiver}
                                error={(formik.touched.receiver_phone && formik.errors.receiver_phone) && formik.errors.receiver_phone}
                            />
                        </div>
                        <div>
                            <LabelFormThin label='받는 사람 주소' />

                            <div className='flex gap-2.5 items-start'>
                                <InputFormThin
                                    placehoder='내용을 입력하세요'
                                    name='receiver_address'
                                    value={formik.values.receiver_address}
                                    onChange={handleChangeReceiver}
                                    classNameAll='w-full'
                                    error={(formik.touched.receiver_address && formik.errors.receiver_address) && formik.errors.receiver_address}
                                />
                                <SelectAddressUser buttonTitle='일반주소검색' formik={formik} target='receiver_address' zipcode='receiver_zip' />
                                <SearchOrganization formUpload={formUpload} setFormUpload={setFormUpload} />
                            </div>
                        </div>
                        <div>
                            <LabelFormThin label='우편번호' />
                            <InputFormThin
                                placehoder='내용을 입력하세요'
                                name='receiver_zip'
                                value={formik.values.receiver_zip}
                                onChange={handleChangeReceiver}
                                error={(formik.touched.receiver_zip && formik.errors.receiver_zip) && formik.errors.receiver_zip}
                            />
                        </div>
                    </div>

                    <RadioForm
                        content='저장 [저장시 자동으로 주소를 불러옴]'
                        onChange={handleSaveReceiver}
                        isCheck={formUpload?.save_recipient}
                        type='checkbox'
                    />
                </div>
            </section>

            <section>
                <div className='flex justify-between items-center'>
                    <TitleSection title='우편선택' small />

                    {
                        (formik.touched.stamp_id && formik.errors.stamp_id)
                        &&
                        <p className='text-red text-12'>{formik.errors.stamp_id}</p>
                    }
                </div>

                <div className='grid lg:grid-cols-4 lg:gap-5'>
                    {
                        dataOrderStamp.map(item =>
                            <ShippingFeeItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                image={item.filename}
                                price={item.price}
                                handleSelectStamp={handleSelectStamp}
                                isChecked={item.id === formik.values.stamp_id}
                            />
                        )
                    }
                </div>
            </section>

            <div className='flex gap-2.5 w-full justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleNavigateStep} />
                <ButtonPrimary text='결제하기' className='w-[160px] justify-center' onClick={formik.handleSubmit} />
            </div>
        </>
    )
}
