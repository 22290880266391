import React, { useRef } from 'react'

export default function PdfUploadForm({ onChange, title, placehoder }) {
    const inputRef = useRef()

    const handleCLick = () => {
        inputRef.current.click()
    }

    return (
        <div>
            <input type='file' accept='.pdf' ref={inputRef} onChange={(e) => onChange(e.currentTarget.files)} hidden />

            <div onClick={handleCLick} className={`w-full border border-strock rounded-12 py-2 px-4 cursor-pointer ${title ? '' : 'text-deactive'}`}>{title || placehoder}</div>
        </div>
    )
}
