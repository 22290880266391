import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCouponAdmin } from '../../../reduxs/actions/coupon.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import Pagination from '../../../components/Pagination'
import RadioForm from '../../../components/RadioForm'
import CouponUser from './CouponUser'
import { formatMoney } from '../../../utils/renderText'

export default function ListCoupon({ formik, page, handlePage }) {
    const dispatch = useDispatch()

    const { loadingGetCouponAdmin, dataCouponAdmin, couponCount } = useSelector(state => state.coupon)

    useEffect(() => {
        dispatch(getAllCouponAdmin(page))
    }, [page])

    if (loadingGetCouponAdmin || !dataCouponAdmin) {
        return <LoadingUser />
    }

    return (
        <div className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[120px]'></th>
                        <th className='py-5 px-2 w-[400px]'>이름</th>
                        <th className='py-5 px-2 w-[400px]'>이메일</th>
                        <th className='py-5 px-2 w-[400px]'>쿠폰 수</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataCouponAdmin.map((item, index) =>
                            <RowCouponItem
                                key={item.user_id}
                                user_id={item.user_id}
                                name={item.name}
                                email={item.email}
                                totalCoupon={item.coupon_total}
                                formik={formik}
                            />)
                    }
                </tbody>
            </table>

            <Pagination totalCount={couponCount} currentPage={page} handlePage={handlePage} />
        </div>
    )
}

const RowCouponItem = ({ user_id, name, email, totalCoupon, formik }) => {
    const [isShowCoupon, setIsShowCoupon] = useState(false)

    const handleChooseUser = () => {
        formik.setFieldValue('user_id', user_id)
    }

    const handleShowCoupons = () => {
        setIsShowCoupon(!isShowCoupon)
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <RadioForm onChange={handleChooseUser} isCheck={formik.values.user_id === user_id} name='name' />
            </td>
            <td className='py-5 '>
                <p className='line-clamp-1 cursor-pointer' onClick={handleShowCoupons}>
                    {name}
                </p>

                {
                    isShowCoupon
                    &&
                    <CouponUser user_id={user_id} handleShow={handleShowCoupons} />
                }
            </td>
            <td className='py-5 cursor-pointer' onClick={handleShowCoupons}>
                <p className='line-clamp-1'>{email}</p>
            </td>
            <td className='py-5 cursor-pointer' onClick={handleShowCoupons}>{formatMoney(totalCoupon)}</td>
        </tr>
    )
}

