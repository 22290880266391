import React, { useState } from 'react'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { URL_SERVER } from '../../../utils/apis';

export default function ReviewImage({ data }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className='w-full overflow-hidden'>
            <Swiper
                loop={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Thumbs]}
                breakpoints={{
                    1: {
                        spaceBetween: 5
                    },
                    1024: {
                        spaceBetween: 10
                    }
                }}
                className="mySwiper2 aspect-video lg:mb-5 mb-2.5"
            >
                {
                    data?.map(item =>
                        <SwiperSlide key={item.id}>
                            <img src={`${URL_SERVER}${item.filename}`} alt='imagess' className='w-full lg:rounded-20 rounded-12 aspect-video object-cover' />
                        </SwiperSlide>
                    )
                }
            </Swiper>

            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                slidesPerView={3}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                breakpoints={{
                    1: {
                        spaceBetween: 5
                    },
                    1024: {
                        spaceBetween: 10
                    }
                }}
                className="mySwiper w-full"
            >
                {
                    data?.map(item =>
                        <SwiperSlide key={item.id}>
                            <img loading='lazy' src={`${URL_SERVER}${item.filename}`} alt='imagess' className='w-full lg:rounded-20 rounded-12 aspect-video object-cover cursor-pointer' />
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    )
}
