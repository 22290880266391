import React, { useEffect, useRef, useState } from 'react'
import { URL_SERVER } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSavedLetter, getSavedLetterById } from '../../reduxs/actions/product.action'
import ButtonSecon from '../ButtonSecon'
import ButtonPrimary from '../ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import { CloseCircle } from 'iconsax-react'
import LetterQuillRead from './LetterQuillRead'

export default function SaveLetterItem({ thumbnail, letter_name, save_id }) {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <>
            <div className='flex flex-col lg:gap-5 gap-2.5 items-center' >
                <button type='button' className='aspect-[7.08/10] w-full' onClick={handleShow}>
                    <img src={`${URL_SERVER}${thumbnail}`} alt={thumbnail} className='w-full lg:rounded-20 rounded-12 object-cover' />
                </button>

                <button type='button' className='px-5 break-all line-clamp-1 font-bold w-full max-lg:text-12' onClick={handleShow}>{letter_name}</button>
            </div>

            {
                isShow
                &&
                <LetterDetailPopup
                    save_id={save_id}
                    handleShow={handleShow}
                />
            }
        </>
    )
}

const LetterDetailPopup = ({ save_id, handleShow }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const ref = useRef()

    const [widthElm, setWidthElm] = useState(0)

    const [editors, setEditors] = useState([])

    const { dataSavedLetterById, loadingGetSavedLetterById } = useSelector(state => state.product)

    const handleClick = () => {
        navigate(`/writing/${product_id}?savedLetter=${save_id}`)
    }

    const handleDelete = async () => {
        const result = await dispatch(deleteSavedLetter(save_id))

        const res = result.payload

        if (res.status) {
            handleShow()
        }
    }

    useEffect(() => {
        setWidthElm(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    useEffect(() => {
        dispatch(getSavedLetterById(save_id))
    }, [])

    useEffect(() => {
        if (dataSavedLetterById) {
            const { content } = dataSavedLetterById

            const contentParse = JSON.parse(content)

            const restoredEditors = contentParse.map(editor => {
                return {
                    id: editor.id,
                    editorState: editor.editorState,
                    delta: editor.delta,
                    soure: editor.source,
                    editor: editor.editor
                };
            });

            setEditors(restoredEditors)
        }
    }, [dataSavedLetterById])

    if (loadingGetSavedLetterById || !dataSavedLetterById) {
        return
    }

    const { endThumbnail, product_id } = dataSavedLetterById

    return (
        <div className='fixed inset-0 bg-popup z-50 flex justify-center items-center px-2.5'>
            {
                !(loadingGetSavedLetterById || !dataSavedLetterById)
                &&
                <div className='relative bg-white p-5 flex flex-col gap-5 rounded-20 w-[600px] items-center max-h-[800px] overflow-y-auto hiddenScroll'>
                    <button className='absolute top-5 right-5' onClick={handleShow}><CloseCircle size={24} /></button>
                    <h5 className='lg:text-24-30 font-semibold'>상세정보</h5>

                    <div className='w-full grid gap-5' ref={ref}>
                        {
                            editors.length
                            &&
                            editors.map(item =>
                                <LetterQuillRead
                                    key={item.id}
                                    editorState={item.editorState}
                                    delta={item.delta}
                                    soure={item.soure}
                                    editor={item.editor}
                                    endThumbnail={endThumbnail}
                                    widthElm={widthElm}
                                />
                            )
                        }
                    </div>

                    <div className='flex justify-center gap-5'>
                        <ButtonSecon title='삭제' className='w-[160px]' onClick={handleDelete} />
                        <ButtonPrimary text='사용' className='w-[160px]' onClick={handleClick} />
                    </div>
                </div>
            }
        </div>
    )
}
