import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get point admin
export const getPointsAdmin = createAsyncThunk(
    'point/getalladmin',
    async (page) => {
        const response = await get(`conversionpoint/admin/allvoucher?page=${page}`)

        return response
    }
)

// create point admin
export const createPointsAdmin = createAsyncThunk(
    'point/create',
    async (data) => {
        const response = await post(`conversionpoint/create`, data)

        return response
    }
)

// delete point admin
export const deletePointsAdmin = createAsyncThunk(
    'point/delete',
    async (pointId) => {
        const response = await del(`conversionpoint/voucher/${pointId}`)

        return response
    }
)

// get point user
export const getPointsUser = createAsyncThunk(
    'point/getalluser',
    async () => {
        const response = await get(`conversionpoint/user/allvoucher`)

        return response
    }
)

// check point user
export const checkPointsUser = createAsyncThunk(
    'point/check',
    async (voucherId) => {
        const response = await get(`conversionpoint/user/checkorder/${voucherId}`)

        return response
    }
)

// checkout point
export const checkoutPointUser = createAsyncThunk(
    'point/checkout',
    async (data) => {
        const response = await post(`conversionpoint/user/addorder-voucherpoint`, data)

        return response
    }
)

// get all voucher admin
export const getAllVoucher = createAsyncThunk(
    'voucher/getall',
    async (page) => {
        const response = await get(`ordermanagement/voucherpoint-order?page=${page}`)

        return response
    }
)


// delete order admin
export const deleteOrderVoucher = createAsyncThunk(
    'voucher/deleteOrder',
    async (data) => {
        const { page, orderId } = data

        const response = await del(`ordermanagement/order/${orderId}?page=${page}`)

        return response
    }
)

// search voucher admin
export const searchVoucher = createAsyncThunk(
    'voucher/search',
    async (data) => {
        const { page, status_id, stamp_id, condition, text } = data

        const response = await get(`ordermanagement/voucherpoint-order/search/?page=${page || ''}&status_id=${status_id || ''}&stamp_id=${stamp_id || ''}&condition=${condition || ''}&text=${text || ''}`)

        return response
    }
)


// change status order voucher 
export const changeStatusOrderVoucher = createAsyncThunk(
    'order/changeStatusVoucher',
    async (data) => {
        const response = await post(`ordermanagement/update-ordervoucher/status`, data)

        return response
    }
)
