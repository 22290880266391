import React, { useEffect, useState } from 'react'
import { getSessionStorage, setSessionStorage } from '../utils/storage'
import ButtonPrimary from './ButtonPrimary'

export default function NotifyChangePassword() {
    const [isShow, setIsShow] = useState()

    const handleShow = () => {
        setSessionStorage('isNotifyPassword', true)
        setIsShow(true)
    }

    useEffect(() => {
        const isShowPopup = getSessionStorage('isNotifyPassword')
        setIsShow(isShowPopup)
    }, [])

    if (isShow) {
        return null
    }

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-[100]'>
            <div className='p-5 rounded-20 bg-white flex flex-col items-center gap-5 max-w-[600px] w-full'>
                <h3 className='text-24-30 font-semibold'>알림</h3>
                <p>최초 로그인시 비밀번호 찾기 기능을 통해 비밀번호를 재설정 해주시기 바랍니다.</p>

                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleShow} />
            </div>
        </div>
    )
}
