import React, { useEffect, useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonSecon from '../../components/ButtonSecon'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAdvert, getAllAdvert, toggleStatusAdvert } from '../../reduxs/actions/adverts.action'
import LoadingUser from '../user/LoadingUser'
import CreatePopup from '../../sections/admin/popup/CreatePopup'
import Pagination from '../../components/Pagination'
import DropdownTable from '../../components/admin/DropdownTable'
import ButtonPrimary from '../../components/ButtonPrimary'
import ToggleStatus from '../../components/admin/ToggleStatus'

export default function PopupAdmin() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetAddAdvert, dataAdvert, countAdvert } = useSelector(state => state.advert)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countAdvert / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    const [isNotify, setIsNotify] = useState(false)

    const handleConfirmDelete = async (id) => {
        const result = await dispatch(deleteAdvert(id))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    useEffect(() => {
        dispatch(getAllAdvert(page))
    }, [page])

    return (
        <>
            <TitleSection title='팝업' mt={false} />

            {
                (loadingGetAddAdvert || !dataAdvert)
                    ?
                    <LoadingUser className='m-0 py-0' />
                    :
                    <section className='grid gap-5'>
                        <CreatePopup />

                        <div className='bg-white rounded-24 px-5'>
                            <table className='w-full text-center'>
                                <thead>
                                    <tr className='border-b border-strock'>
                                        <th className='py-5 px-2 w-[50px]'></th>
                                        <th className='py-5 px-2 w-[300px]'>팝업명</th>
                                        <th className='py-5 px-2 w-[300px]'>파일명</th>
                                        <th className='py-5 px-2 w-[100px]'></th>
                                        <th className='py-5 px-2 w-[50px]'></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {dataAdvert.map((item, index) =>
                                        <RowPopupItem
                                            key={item.id}
                                            id={item.id}
                                            index={index + 1}
                                            name={item.name}
                                            filename={item.name_picture}
                                            status={item.hidden}
                                            handleConfirmDelete={handleConfirmDelete}
                                            isNotify={isNotify}
                                        />
                                    )}
                                </tbody>
                            </table>

                            <Pagination totalCount={countAdvert} currentPage={page} handlePage={handlePage} />
                        </div>
                    </section>
            }
        </>
    )
}

const RowPopupItem = ({ index, id, name, filename, status, handleConfirmDelete, isNotify }) => {
    const dispatch = useDispatch()

    const [isShow, setIsShow] = useState(false)

    const handleShowpopup = () => {
        setIsShow(!isShow)
    }

    const handletoggleStatus = () => {
        dispatch(toggleStatusAdvert({ advert_id: id }))
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{index}</td>
            <td className='py-5 px-2'>
                <p className='breal-all line-clamp-1'>{name}</p>
            </td>
            <td className='py-5 px-2'>{filename}</td>
            <td className='py-5 px-2'><ToggleStatus status={!!status} onClick={handletoggleStatus} /></td>
            <td className='py-5 px-2'>
                <div className='flex justify-center items-center relative'>
                    <DropdownTable onclickDelete={handleShowpopup} />

                    {
                        isShow
                        &&
                        <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                            <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                                <div className='text-center'>
                                    <p>배너를 삭제하시겠습니까?</p>
                                    <p>삭제된 배너는 복구할 수 없습니다.</p>
                                </div>

                                <div className='grid grid-cols-2 gap-2.5'>
                                    <ButtonSecon title='취소' onClick={handleShowpopup} className='w-[160px]' />
                                    <ButtonPrimary text='확인' className='w-[160px]' onClick={() => handleConfirmDelete(id)} />
                                </div>
                            </div>
                        </div>
                    }

                    <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                        {isNotify}
                    </div>
                </div>
            </td>
        </tr>
    )
}
