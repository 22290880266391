import React from 'react'
import ButtonPrimary from '../ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowNotifyNotLogin } from '../../reduxs/reducers/auth.reducer'

export default function PopupNotLogin() {
    const dispatch = useDispatch()

    const { showNotifyNotLogin } = useSelector(state => state.auth)

    const handleConfirm = () => {
        dispatch(setIsShowNotifyNotLogin())
    }

    if (!showNotifyNotLogin) {
        return
    }

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
            <div className='p-5 bg-white rounded-20 flex flex-col items-center gap-5 max-w-[600px] w-full'>
                <h3 className='text-24-30 font-semibold'>알림</h3>

                <p className='max-w-[384px] w-full text-center'>로그인 후에 이용 해주세요</p>

                <div className='flex gap-2.5'>
                    <ButtonPrimary link='/my-page' text='확인' className='w-[160px]' onClick={handleConfirm} />
                </div>
            </div>
        </div>
    )
}
