import { createSlice } from "@reduxjs/toolkit";
import { createEventAdmin, deleteEventAdmin, getEventAdmin } from "../actions/event.action";

const eventSlice = createSlice({
    name: 'event',
    initialState: {
        loadingGetEventAdmin: false,
        dataEventAdmin: undefined,

        loadingDeleteEventAdmin: false,

        loadingCreateEventAdmin: false,
    },
    extraReducers: builder => {
        // get event admin
        builder.addCase(getEventAdmin.pending, (state, action) => {
            state.loadingGetEventAdmin = true
        })
        builder.addCase(getEventAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataEventAdmin = res.data
            }

            state.loadingGetEventAdmin = false
        })

        // delete event
        builder.addCase(deleteEventAdmin.pending, (state, action) => {
            state.loadingDeleteEventAdmin = true
        })
        builder.addCase(deleteEventAdmin.fulfilled, (state, action) => {
            state.loadingDeleteEventAdmin = false
        })

        // create event
        builder.addCase(createEventAdmin.pending, (state, action) => {
            state.loadingCreateEventAdmin = true
        })
        builder.addCase(createEventAdmin.fulfilled, (state, action) => {
            state.loadingCreateEventAdmin = false
        })
    }
})

export default eventSlice.reducer
