import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import LabelFormThin from '../../components/LabelFormThin'
import TextareaForm from '../../components/TextareaForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { createFaq } from '../../reduxs/actions/faq.action'

export default function CreateFaq() {
    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)

    const formik = useFormik({
        initialValues: {
            question: '',
            answer: ''
        },
        validationSchema: Yup.object({
            question: Yup.string().required(ERROR_REQUIRED),
            answer: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(createFaq(values))

            const res = result.payload

            if (res.status) {
                setIsNotify('성공적으로 저장되었습니다')
                formik.resetForm()
            } else {
                setIsNotify(res.message)
            }

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }
    })

    return (
        <>
            <TitleSection title='FAQ 추가하기' mt={false} />

            <form className='grid gap-5 justify-items-center' onSubmit={formik.handleSubmit}>
                <div className='w-full'>
                    <LabelFormThin label='질문' className='font-bold' />
                    <TextareaForm
                        placeholder='답변을 입력해 주세요....'
                        name='question'
                        onChange={formik.handleChange}
                        value={formik.values.question}
                        error={(formik.touched.question && formik.errors.question) && formik.errors.question}
                    />
                </div>
                <div className='w-full'>
                    <LabelFormThin label='답변' className='font-bold' />
                    <TextareaForm
                        placeholder='질문을 입력해 주세요....'
                        name='answer'
                        onChange={formik.handleChange}
                        value={formik.values.answer}
                        error={(formik.touched.answer && formik.errors.answer) && formik.errors.answer}
                    />
                </div>

                <ButtonPrimary text='저장' className='w-[160px]' type='submit' onClick={formik.handleSubmit} />
            </form>

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}
