import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterCoupon from '../../sections/admin/coupon/FilterCoupon'
import ListCoupon from '../../sections/admin/coupon/ListCoupon'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch, useSelector } from 'react-redux'
import { createCouponAdmin } from '../../reduxs/actions/coupon.action'

export default function CouponAdminPage() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { couponCount } = useSelector(state => state.coupon)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(couponCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    const formik = useFormik({
        initialValues: {
            user_id: '',
            name: '',
            mark1: '',
            mark2: '',
            mark_type_id: '',
            expire_at: ''
        },
        validationSchema: Yup.object().shape({
            user_id: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            mark1: Yup.string().when('mark_type_id', {
                is: '1',
                then: schema => schema.required(ERROR_REQUIRED)
            }),
            mark2: Yup.string().when('mark_type_id', {
                is: '2',
                then: schema => schema.required(ERROR_REQUIRED)
            }),
            mark_type_id: Yup.string().required(ERROR_REQUIRED),
            expire_at: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(createCouponAdmin({ ...values, mark: values.mark_type_id === 1 ? values.mark1 : values.mark2 }))

            const res = result.payload

            if (res.status) {
                formik.resetForm()
            }
        }
    })

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitleSection title='쿠폰 관리' mt={false} />
                <ButtonPrimary text='저장' className='w-[160px]' onClick={formik.handleSubmit} />
            </div>

            <FilterCoupon formik={formik} page={page} />

            <ListCoupon formik={formik} page={page} handlePage={handlePage} />
        </>
    )
}
