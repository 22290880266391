import React, { useEffect } from 'react'
import TitlePage from '../../components/user/TitlePage'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPosts } from '../../reduxs/actions/posts.action'
import LoadingUser from './LoadingUser'
import moment from 'moment/moment'

export default function PostPage() {
    const dispatch = useDispatch()

    const { loadingGetAllPost, dataPosts } = useSelector(state => state.post)

    useEffect(() => {
        dispatch(getPosts())
    }, [])

    if (loadingGetAllPost || !dataPosts) {
        return <LoadingUser />
    }

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-5 container'>
            <TitlePage title='필독/ 공지사항' />

            <article className='max-lg:px-6'>
                <table className='text-center w-full'>
                    <thead>
                        <tr className='border-b border-strock'>
                            <th className='w-2/5 pb-5'>제목</th>
                            <th className='w-2/5 pb-5'>작성자</th>
                            <th className='w-1/5 pb-5'>작성일</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataPosts.map(item =>
                                <RowPostItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    title={item.title}
                                    createdAt={item.created_at}
                                />)
                        }
                    </tbody>
                </table>

                {/* <div className='flex justify-center items-center gap-2 lg:mt-5 mt-4'>
                    <button type='button'><ArrowLeft2 size={16} /></button>

                    <span>1</span>
                    <span>2</span>
                    <span>3</span>

                    <button type='button'><ArrowRight2 size={16} /></button>
                </div> */}
            </article>
        </main>
    )
}

const RowPostItem = ({ id, name, title, createdAt }) => {
    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <Link to={`${id}`} className='line-clamp-1 hover:text-logoColor duration-300'>{title}</Link>
            </td>
            <td className='py-5'>{name}</td>
            <td className='py-5'>{moment(createdAt).format('YYYY.MM.DD')}</td>
        </tr>
    )
}
