import { CloseSquare, Edit2 } from 'iconsax-react'
import React, { useState } from 'react'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecon from '../ButtonSecon'
import { useFormik } from 'formik'
import LabelFormThin from '../LabelFormThin'
import InputFormThin from '../InputFormThin'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { updateCategoriesAdmin } from '../../reduxs/actions/category.action'

export default function CategoryItem({ id, content, handleDelete }) {
    const dispatch = useDispatch()

    const [showConfirmDel, setShowConfirmDel] = useState()
    const [isEditCategory, setIsEditCategory] = useState()

    const handleShowPopup = () => {
        setShowConfirmDel(!showConfirmDel)
    }

    const handlEditCategory = () => {
        setIsEditCategory(!isEditCategory)
    }

    const formik = useFormik({
        initialValues: {
            id: id,
            name: content
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(updateCategoriesAdmin(values))

            const res = result.payload

            if (res.status) {
                handlEditCategory()
            }
        }
    })

    return (
        <div className='h-[120px] rounded-20 bg-white p-5 relative flex justify-center items-center'>
            <div className='flex gap-2.5 absolute top-2.5 right-2.5 w-fit'>
                <button className='text-[#BABABA] hover:text-logoColor duration-200' onClick={handlEditCategory}><Edit2 size={20} /></button>
                {
                    handleDelete
                    &&
                    <button className='text-[#BABABA] hover:text-logoColor duration-200' onClick={handleShowPopup}><CloseSquare size={20} /></button>
                }
            </div>

            {content}

            {
                showConfirmDel
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='w-[370px] p-5 bg-white rounded-20 flex flex-col gap-5'>
                        <div className='text-center'>
                            <p>카테고리를 삭제하시겠습니까?</p>
                            <p>삭제된 카테고리는 복구할 수 없습니다.</p>
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' onClick={() => handleDelete(id)} />
                        </div>
                    </div>
                </div>
            }

            {
                isEditCategory
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <form className='w-[600px] p-5 bg-white rounded-20 flex flex-col items-center gap-5' onSubmit={formik.handleSubmit}>
                        <h3 className='text-24-30 font-semibold'>카테고리 편집</h3>

                        <div className='w-full'>
                            <LabelFormThin label='카테고리 이름' />
                            <InputFormThin
                                name='name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                placehoder='텍스트 입력'
                                error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                            />
                        </div>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' onClick={handlEditCategory} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' onClick={formik.handleSubmit} />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
