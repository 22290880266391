import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get event
export const getEventAdmin = createAsyncThunk(
    'event/getadmin',
    async () => {
        const response = await get(`event`)

        return response
    }
)

// delete event
export const deleteEventAdmin = createAsyncThunk(
    'event/delete',
    async (eventId) => {
        const response = await del(`event/${eventId}`)

        return response
    }
)

// create event
export const createEventAdmin = createAsyncThunk(
    'event/create',
    async (data) => {
        const response = await post(`event`, data)

        return response
    }
)
