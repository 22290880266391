import React, { useEffect } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ShippingOption from '../../sections/admin/options/ShippingOption'
import LetterOption from '../../sections/admin/options/LetterOption'
import PdfPriceOption from '../../sections/admin/options/PdfPriceOption'
import PdfPageOption from '../../sections/admin/options/PdfPageOption'
import { useDispatch, useSelector } from 'react-redux'
import { getManagementAdmin } from '../../reduxs/actions/management.action'
import LoadingUser from '../user/LoadingUser'

export default function DeliveryOption() {
    const dispatch = useDispatch()

    const { loadingGetManagement, dataManagement } = useSelector(state => state.management)

    useEffect(() => {
        dispatch(getManagementAdmin())
    }, [])

    if (loadingGetManagement || !dataManagement) {
        return <LoadingUser />
    }

    const { stamps, feeLetter, limitPdf, feePrint } = dataManagement

    return (
        <>
            <TitleSection title='고정값 변경' mt={false} />

            <ShippingOption data={stamps} />

            <section className='grid grid-cols-4 gap-5'>
                <LetterOption data={feeLetter} />

                <PdfPriceOption data={feePrint} />

                <PdfPageOption data={limitPdf} />
            </section>
        </>
    )
}
