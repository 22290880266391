import { createSlice } from "@reduxjs/toolkit";

const orderLetterSlice = createSlice({
    name: 'orderletter',
    initialState: {
        dataOrderLetter: [],
        saveDataUpload: undefined,

        dataOrderSelected: [],
        methodCheckout: undefined,
    },
    reducers: {
        setDataOrderLetter: (state, action) => {
            const result = action.payload

            state.dataOrderLetter = result
        },
        addOrderLetter: (state, action) => {
            const response = action.payload

            state.dataOrderLetter = [
                ...state.dataOrderLetter,
                {
                    id: state.dataOrderLetter.length,
                    ...response
                }
            ]
        },
        deleteOrderLetter: (state, action) => {
            const orderId = action.payload

            const updateDataOrder = state.dataOrderLetter.filter(item => item.id !== orderId)

            state.dataOrderLetter = updateDataOrder
        },
        clearOrderLetter: (state, action) => {
            state.dataOrderLetter = []
        },
        addSaveDataUpload: (state, action) => {
            const data = action.payload

            state.saveDataUpload = data
        },
        deleteSaveDataUpload: (state, action) => {
            state.saveDataUpload = undefined
        },
        selectOrderLetter: (state, action) => {
            const response = action.payload

            const checkItem = state.dataOrderSelected.find(item => item.id === response.id)

            state.isLetterIdSelected = response.letterId
            // console.log(response)

            if (checkItem) return

            state.dataOrderSelected = [
                { ...response, totalQuantity: 1 },
                ...state.dataOrderSelected
            ]
        },
        handleQuantityOrderSelect: (state, action) => {
            const response = action.payload

            const { id, quantity } = response

            const updateData = state.dataOrderSelected.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        totalQuantity: quantity
                    }
                }

                return item
            })

            state.dataOrderSelected = updateData
        },
        deleteOrderSelectedItem: (state, action) => {
            const response = action.payload
            console.log(response)

            const updateData = state.dataOrderSelected.filter(item => item.id !== response)

            state.dataOrderSelected = updateData
        },
        clearOrderLetterSelected: (state, action) => {
            state.dataOrderSelected = []
        },

        setMethodCheckout: (state, action) => {
            const res = action.payload

            state.methodCheckout = res.method
        }
    }
})

export const {
    setDataOrderLetter,
    addOrderLetter,
    deleteOrderLetter,
    clearOrderLetter,
    addSaveDataUpload,
    selectOrderLetter,
    handleQuantityOrderSelect,
    deleteOrderSelectedItem,
    clearOrderLetterSelected,
    setMethodCheckout,
    deleteSaveDataUpload
} = orderLetterSlice.actions
export default orderLetterSlice.reducer
