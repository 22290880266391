import { ArrowDown2 } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'

export default function Dropdown({ title, className, data, name, handleChoose, isChoose, classnameAll }) {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div className={`relative flex-shrink-0 ${classnameAll}`} ref={contentRef}>
            <button
                type='button'
                className={`flex gap-2.5 items-center justify-between bg-white py-2 px-4 rounded-12 ${className} border-strock border relative z-20`}
                onClick={handleShow}
            >
                {isChoose ? data.filter(item => item.id === isChoose)[0][name] : title}

                <ArrowDown2 size={20} className={`${isShow ? 'rotate-180' : ''} duration-100`} />
            </button>

            {
                isShow
                &&
                <div className='absolute top-full mt-1 right-0 inset-x-0 bg-white border-strock border pt-2.5 -translate-y-3 z-10 rounded-b-12'>
                    <ul>
                        {
                            data.map(item =>
                                <li
                                    key={item.id}
                                    className={`py-2.5 px-4 w-full text-center cursor-pointer last:rounded-b-12 hover:text-logoColor duration-300 ${(isChoose === item.id) && 'text-logoColor'}`}
                                    onClick={() => {
                                        handleChoose(item.id)
                                        handleShow()
                                    }}
                                >
                                    {item[name]}
                                </li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    )
}
