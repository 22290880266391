import { createSlice } from "@reduxjs/toolkit";
import { createNotice, deleteNotice, getAllNotice, getNoticeDetail, updateNoticeDetail } from "../actions/notice.action";

const noticeSlice = createSlice({
    name: 'notice',
    initialState: {
        loadingGetNoice: false,
        dataNotice: undefined,
        postCount: 0,

        detailNotice: undefined,
        loadingGetNoiceDetail: false,

        loadingCreateNotice: false,

        loadingUpdateNoiceDetail: false,

        loadingDeleteNotice: false
    },
    extraReducers: builder => {
        // get all notice
        builder.addCase(getAllNotice.pending, (state, action) => {
            state.loadingGetNoice = true
        })
        builder.addCase(getAllNotice.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataNotice = res.data.posts
                state.postCount = res.data.postCount
            }

            state.loadingGetNoice = false
        })

        // get notice detail
        builder.addCase(getNoticeDetail.pending, (state, action) => {
            state.loadingGetNoiceDetail = true
        })
        builder.addCase(getNoticeDetail.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.detailNotice = res.data
            }

            state.loadingGetNoiceDetail = false
        })

        // update notice detail
        builder.addCase(updateNoticeDetail.pending, (state, action) => {
            state.loadingUpdateNoiceDetail = true
        })
        builder.addCase(updateNoticeDetail.fulfilled, (state, action) => {
            state.loadingUpdateNoiceDetail = false
        })

        // create notice
        builder.addCase(createNotice.pending, (state, action) => {
            state.loadingCreateNotice = true
        })
        builder.addCase(createNotice.fulfilled, (state, action) => {
            state.loadingCreateNotice = false
        })

        // delete notice
        builder.addCase(deleteNotice.pending, (state, action) => {
            state.loadingDeleteNotice = true
        })
        builder.addCase(deleteNotice.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataNotice.filter(item => item.id !== Number(res.data))

                state.dataNotice = updateData
            }

            state.loadingDeleteNotice = false
        })
    }
})

export const { } = noticeSlice.actions
export default noticeSlice.reducer
