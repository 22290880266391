import React from 'react'
import { Link } from 'react-router-dom'

export default function ButtonPrimary({ className, text, onClick, type = 'button' }) {
    return (
        <button type={type} className={`bg-pink hover:bg-lightPink duration-300 lg:rounded-12 rounded-8 lg:py-2.5 lg:px-4 py-2 px-4 max-lg:text-14_20 ${className} text-white`} onClick={onClick}>
            {text}
        </button>
    )
}

export const ButtonPrimaryLink = ({ className, text, link = '#', target }) => {
    return (
        <Link to={link} target={target} className={`bg-pink hover:bg-lightPink flex items-center text-center duration-300 lg:rounded-12 rounded-8 lg:py-2.5 lg:px-4 py-2 px-4 max-lg:text-14_20 ${className} text-white`}>
            {text}
        </Link>
    )
}
