import React, { useEffect, useRef, useState } from 'react'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import RadioForm from '../../components/RadioForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_PASSWORD_CONFIRM, ERROR_PASWORD_FORMAT, ERROR_PHONE_FORMAT, ERROR_REQUIRED } from '../../utils/error'
import { useDispatch, useSelector } from 'react-redux'
import { getGenders, register } from '../../reduxs/actions/auth.action'
import { Link, useNavigate } from 'react-router-dom'
import { REGEX_BIRTHDAY_NUMBER, REGEX_PASWORD, REGEX_PHONE_NUMBER } from '../../utils/regex'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { useBirthdayFormik, usePhoneNumberFormik } from '../../hooks/formatNumber'
import { renderError } from '../../utils/renderText'

export default function RegisterPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            name: '',
            phone_number: '',
            dob: '',
            gender: '',
            isPolicy: '',
            isPersonal: '',
            isService: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(ERROR_REQUIRED),
            password: Yup.string().matches(REGEX_PASWORD, ERROR_PASWORD_FORMAT).required(ERROR_REQUIRED),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], ERROR_PASSWORD_CONFIRM).required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            phone_number: Yup.string().matches(REGEX_PHONE_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED),
            dob: Yup.string().matches(REGEX_BIRTHDAY_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED),
            isPolicy: Yup.string().required(ERROR_REQUIRED),
            isPersonal: Yup.string().required(ERROR_REQUIRED),
            isService: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(register(values))

            const res = result.payload

            if (res.status) {
                navigate('/auth')
            } else {
                const error = renderError(res.error)

                if ((res.error === "e_phone") || (res.error === "blocked_phone") || (res.error === "accphone_blaclist")) {
                    formik.setFieldError('phone_number', error)
                }
                else if (res.error === "f_expire") {
                    formik.setFieldError('phone_number', `이 전화번호은 ${res.data}일 후에 사용할 수 있습니다`)
                }

                else if ((res.error === 'e_email') || (res.error === 'blocked_email')) {
                    formik.setFieldError('email', error)
                }
            }
        }
    })

    const handleChooseAll = () => {
        formik.setValues({
            ...formik.values,
            isPersonal: 1,
            isPolicy: 1,
            isService: 1
        })
    }

    const [phoneNumber, handlePhoneNumberChange] = usePhoneNumberFormik(formik, 'phone_number')
    const [dob, handleDob] = useBirthdayFormik(formik, 'dob')

    useEffect(() => {
        dispatch(getGenders())
    }, [])

    return (
        <form className='w-full flex flex-col items-center gap-10' onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 w-full'>
                <div>
                    <LabelForm label='이메일(ex: abcd@naver.com)' />
                    <InputForm
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placehoder='사용중인 이메일 주소를 입력 하세요'
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>

                <div>
                    <LabelForm label='비밀번호' />
                    <InputForm
                        name='password'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        type='password'
                        placehoder='영문+숫자 6~20자리'
                        error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                    />
                </div>

                <div>
                    <LabelForm label='비밀번호 확인' />
                    <InputForm
                        name='confirmPassword'
                        type='password'
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        placehoder='비밀번호 확인을 위해 재입력'
                        error={(formik.touched.confirmPassword && formik.errors.confirmPassword) && formik.errors.confirmPassword}
                    />
                </div>

                <div>
                    <LabelForm label='이름' />
                    <InputForm
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placehoder='이름을 입력 하세요'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>

                <div>
                    <LabelForm label='휴대폰번호' />
                    <InputForm
                        name='phone_number'
                        onChange={handlePhoneNumberChange}
                        value={phoneNumber}
                        placehoder='숫자만입력 (예: 01012345678)'
                        error={(formik.touched.phone_number && formik.errors.phone_number) && formik.errors.phone_number}
                    />
                </div>

                <div>
                    <LabelForm label='생년월일' />

                    <div className='flex w-full gap-5'>
                        <InputForm
                            name='dob'
                            onChange={handleDob}
                            value={dob}
                            placehoder='예) 19901225' classNameAll='w-2/3'
                            error={(formik.touched.dob && formik.errors.dob) && formik.errors.dob}
                        />
                        <DropdownGender formik={formik} />
                    </div>
                </div>

                <div className='grid lg:gap-5 gap-4 pt-5 mt-1 border-t border-strock'>
                    <RadioForm
                        content='이용약관에 모두 동의합니다'
                        name='isPolicy'
                        value={1}
                        onChange={handleChooseAll}
                        isCheck={formik.values.isPolicy}
                    />
                    <RadioForm
                        content={<><span className='text-blue'>(필수)</span> 서비스 이용약관 <Link to='/terms_of_use' target='_blank' className='text-blue font-bold'>(내용보기)</Link></>}
                        name='isService'
                        onChange={formik.handleChange}
                        value={1}
                        isCheck={formik.values.isService}
                    />
                    <RadioForm
                        content={<><span className='text-blue'>(필수)</span> 개인정보 수집/이용약관 <Link to='/privacy_policy' target='_blank' className='text-blue font-bold'>(내용보기)</Link></>}
                        name='isPersonal'
                        onChange={formik.handleChange}
                        value={1}
                        isCheck={formik.values.isPersonal}
                    />
                </div>

                {
                    (formik.errors.isPersonal || formik.errors.isService)
                    &&
                    <p className='text-center text-12 text-red'>필수로 약관에 동의해야 합니다</p>
                }
            </div>

            <ButtonPrimary text='가입완료' className='w-[200px]' type='submit' onClick={formik.handleSubmit} />
        </form>
    )
}

const DropdownGender = ({ formik }) => {
    const contentRef = useRef()

    const { dataGender } = useSelector(state => state.auth)

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const handleChooseGender = (gender) => {
        formik.setFieldValue('gender', gender)
        handleShow()
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div className='relative w-1/3' ref={contentRef}>
            <button
                type='button'
                onClick={handleShow}
                className={`shadow-input rounded-12 w-full lg:py-2 lg:px-4 py-1.5 px-3 max-lg:text-14_20 ${formik.values.gender ? 'text-black' : 'text-deactive'} h-full flex justify-between items-center`}
            >
                <span>{formik.values.gender ? dataGender?.find(item => item.id === formik.values.gender).name : '성별'}</span>
                {
                    !isShow
                        ?
                        <ArrowDown2 size={20} />
                        :
                        <ArrowUp2 size={20} />
                }
            </button>

            {
                isShow
                &&
                <div className='absolute top-full mt-1 right-0 grid w-full bg-white shadow-dropdown rounded-b-20'>
                    {
                        dataGender.map(item =>
                            <button
                                key={item.id}
                                type='button'
                                className={`text-center py-2.5 hover:text-logoColor duration-200 ${formik.values.gender === item.id ? 'text-logoColor' : ''}`}
                                onClick={() => handleChooseGender(item.id)}
                            >
                                {item.name}
                            </button>
                        )
                    }
                </div>
            }
        </div>
    )
}
