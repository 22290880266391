import React from 'react'
import TitlePage from '../../components/user/TitlePage'

export default function PolicyPage() {
    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='구매조건 및 취소/환불 규정' />

            <div className='whitespace-pre-wrap'>
                <h2 className='font-bold'>1. 구매조건</h2>
                <br />

                편지발송
                <br />
                작성된 편지지 종류, 편지 장수와 첨부한 PDF / 자체 제작 자료에 따라 가격이 상이 해 지고
                <br />
                메이드바이미 우편 요금은 일반우편 1000원, 준등기 2400원, 일반등기 2950원, 익일특급 3950원 입니다.
                <br />
                <br />
                ※우편요금은 이벤트등 할인 또는 우체국 우편물 가격조정으로 인해 변경 될 수 있습니다
                우편에 대해서 수신지 주소 또는 수신자의 이름과 수용번호를 잘못 입력 하셨을 경우 발신지로 반송이 될 수 있는데, 이때 반송 요금은 접수자가 부담 합니다.
                <br />
                <br />
                *편지는 영업일 기준 오후 3시00까지 신청하신 접수건에 대해서는 당일 4-5시 이후 우체국 접수 처리 되고, 3시 01분 이후에 신청하신 건은 다음날 우체국 접수 처리 됩니다(우체국 접수 시간은 사정에 따라 변동 될 수 있습니다. 메이드바이미의 계약 우체국은 대구우체국입니다 당일발송 5:30)
                제주도 및 섬 지역은 배송 기간이 +2일 정도 더 소요될 수 있습니다.
                <br />
                <br />
                *신도시 (화성, 동탄) / 해남 등 외진 지역은 우체국 사정에 따라 +1일 정도
                소요 될 수 있습니다.
                <br />
                <br />
                메이드바이미의 모든 우편 시스템은 우체국과 연계하기에 우체국 시스템과 동일합니다.
                <br />
                <br />

                <h2 className='font-bold'>2. 취소 및 환불 규정</h2>

                <br />
                편지
                <br />
                - 실시간 계좌이체로 접수시에 접수는 되었으나 미입금인 상태라면 3:00이후 자동 취소 됩니다.<br />
                - 주문 확정이 되었으나 단순 변심 (편지 내용 수정/추가 등) 으로 인한 사유로는 취소 또는 환불이 불가합니다 (단, 편지 폐기 처리 및 미발송은 가능)<br />
                - 사람이 제작 하는 것이 아니라 고객님께서 선택하신 편지지 위에 작성하실 때 즉시 메이드바이미 사이트 내 자체 제작 시스템이 동 시간대 제작 하는 것이므로 결제 즉시 제작이 들어가는 시스템으로 바뀌었기에 취소/ 환불은 불가합니다.<br />
                <br />
                <br />
                PDF파일 접수 / 자체제작 자료 상품 구매<br />
                - 파일 접수는 상품 상태가 [접수확인]일 때만 취소 가능합니다.<br /><br />


                취소 환불 안내<br />
                <br />
                신용/체크카드 : 취소 시 승인이 즉시 취소됩니다.<br />
                <br />
                실시간 계좌이체: 메이드바이미 고객센터로 접수된 고객님의 환불계좌로 입금해드립니다.<br />
                신용카드 결제의 경우 일반적으로 당사의 취소처리가 완료되고 4~5일 후 카드사의 취소가 확인됩니다.(체크카드도 동일, 단 당일 접수 당일 취소는 카드사에 따라 당일 환불 될 수 있습니다)<br />
                <br />

                기타 안내<br />
                <br />
                실시간 계좌이체 취소 입금 계좌 번호 : 취소 신청시 즉시 카카오톡 CS 상담<br />
                문의로 환불 받으실 입금 계좌를 남겨주시거나 또는 적립금으로 적립 될 수 있습니다.<br />
                <br />
                주의사항 : 각 구매 건에 대한 입금계좌명, 접수자명을 확인 부탁드립니다.<br />
                <br />

                1.구매 금액과 입금금액이 일치하지 않을 경우<br />
                2.입금계좌명과 접수자명이 다를경우
                <br />
                <br />
                확인이 불가하여 발송이 지연 될 수 있습니다.
                <br />
                또한 보이스피싱 등에 의한 문제로 인해 입금하신 분의 성함과 동일한 계좌를 입력 하셔야 합니다. 반드시 확인 부탁드리며 이에 따른 발송지연으로 인한 책임은 메이드바이미가 지지 않습니다.<br />
                입금 확인은 영업시간(오전 9시 ~ 오후 18시)에 확인 처리가 가능 합니다(영업일 기준)
            </div>
        </main>
    )
}

