import React, { useEffect, useRef, useState } from 'react'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecon from '../ButtonSecon'
import { Trash } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserRecipient, getUserRecipient } from '../../reduxs/actions/user.action'

export default function SelectInforUser({ titleButton, title, typeId = 1, formUpload, setFormUpload }) {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <>
            <ButtonPrimary text={titleButton} className='text-14_20' onClick={handleShow} />

            {
                isShow
                &&
                <PopupInforAddress
                    contentRef={contentRef}
                    handleShow={handleShow}
                    title={title}
                    typeId={typeId}
                    formUpload={formUpload}
                    setFormUpload={setFormUpload}
                />
            }
        </>
    )
}

const PopupInforAddress = ({ contentRef, title, handleShow, typeId, formUpload, setFormUpload }) => {
    const dispatch = useDispatch()

    const { loadingUserRicipient, dataUserRecipient } = useSelector(state => state.user)

    const [isUser, setIsUser] = useState()

    const sender_id = formUpload?.sender_address_id
    const receider_id = formUpload?.recipient_address_id

    const handleChooseUser = (user) => {
        setIsUser(user)
    }

    const handleConfirm = () => {
        if (typeId === 1 && isUser) {                       // sender
            setFormUpload({
                ...formUpload,
                sender_address_id: isUser.id,
                sender_name: isUser.name,
                sender_phone: isUser.phone,
                sender_address: isUser.address,
                sender_zip_code: isUser.zip_code
            })
        }
        else if (typeId === 2 && isUser) {                  // receider
            setFormUpload({
                ...formUpload,
                recipient_address_id: isUser.id,
                recipient_name: isUser.name,
                recipient_phone: isUser.phone,
                recipient_address: isUser.address,
                recipient_zip_code: isUser.zip_code
            })
        }

        handleShow()
    }

    useEffect(() => {
        dispatch(getUserRecipient(typeId))
    }, [])

    return (
        <>
            <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                {
                    !(loadingUserRicipient || !dataUserRecipient)
                    &&
                    <div ref={contentRef} className='p-5 rounded-20 bg-white w-[600px] max-h-[600px] overflow-auto hiddenScroll flex flex-col justify-between gap-5 mx-6'>
                        <h4 className='text-center lg:text-24-30 lg:font-bold font-semibold'>{title}</h4>

                        <div className='grid gap-2.5'>
                            {
                                dataUserRecipient.map(item =>
                                    <InforUserItem
                                        key={item.id}
                                        data={item}
                                        handleChooseUser={handleChooseUser}
                                        isCheck={(item.id === (isUser?.id || (typeId === 1 ? sender_id : receider_id)))}
                                    />)
                            }
                        </div>

                        <div className='flex gap-2.5 w-full justify-center'>
                            <ButtonSecon title='이전' className='w-[160px] justify-center h-fit' onClick={handleShow} />
                            <ButtonPrimary text='확인' className='w-[160px] justify-center h-fit' onClick={handleConfirm} />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const InforUserItem = ({ data, handleChooseUser, isCheck }) => {
    const dispatch = useDispatch()

    const { address, name, phone, id } = data

    const handleDelete = () => {
        dispatch(deleteUserRecipient(id))
    }

    return (
        <div className={`flex flex-col gap-2.5 border ${isCheck ? 'border-logoColor lg:border-[3px] border-[1px]' : 'border-strock'} lg:rounded-20 rounded-12 lg:p-5 p-4 cursor-pointer`}>
            <div className='flex justify-between items-center max-lg:text-14_20'>
                <p onClick={() => handleChooseUser(data)}>{name} <span>|</span> {phone}</p>
                <button type='button' className='max-lg:text-xl text-deactive hover:text-logoColor duration-200' onClick={handleDelete}><Trash size={20} /></button>
            </div>

            <p className='max-lg:text-14_20' onClick={() => handleChooseUser(data)}>{address}</p>
        </div>
    )
}
