import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import ListPointSection from '../../sections/admin/points/ListPointSection'
import CreateNewpoint from '../../sections/admin/points/CreateNewpoint'

export default function PointPage() {
    return (
        <>
            <section className='flex justify-between items-center'>
                <TitleSection title='상품권' mt={false} />

                <CreateNewpoint />
            </section>

            <ListPointSection />
        </>
    )
}
