import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

export default function PostSection({ dataPost }) {
    return (
        <section>
            <TitleSection title='필독/ 공지사항' more='posts' />

            <div className='border-strock px-5 border rounded-20'>
                <table className='text-center w-full'>
                    <thead>
                        <tr>
                            <th className='w-2/5 py-5'>제목</th>
                            <th className='w-2/5 py-5'>작성자</th>
                            <th className='w-1/5 py-5'>작성일</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataPost.map(item =>
                                <RowPostItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    title={item.title}
                                    createdAt={item.craeted_at}
                                />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </section>
    )
}

const RowPostItem = ({ id, title, name, createdAt }) => {
    return (
        <tr className='border-t border-strock'>
            <td className='py-5'>
                <Link to={`/posts/${id}`} className='line-clamp-1 hover:text-logoColor duration-300 max-lg:text-14_20'>{title}</Link>
            </td>
            <td className='py-5 max-lg:text-14_20'>{name}</td>
            <td className='py-5 max-lg:text-14_20'>{moment(createdAt).format('YYYY.MM.DD')}</td>
        </tr>
    )
}
