import { useSearchParams } from "react-router-dom";
import circle1 from '../../assets/images/circle1.png'
import circle2 from '../../assets/images/circle2.png'
import fail from '../../assets/images/fail.png'

export function FailPage() {
    const [searchParams] = useSearchParams();

    return (
        <div className="result wrapper">
            <div className="relative w-full h-screen">
                <img src={circle2} alt="" className="absolute top-[10%] left-[25%] w-[550px] aspect-square" />
                <img src={circle1} alt="" className="absolute bottom-[5%] right-[25%] w-[720px] aspect-square" />
            </div>

            <div className="box_section text-logoColor fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white max-w-[600px] w-full aspect-[6/4] flex flex-col gap-2.5 items-center justify-center p-10 rounded-20 shadow-payment">
                <img src={fail} alt="fail payment" className="w-[100px]" />

                <p className="text-24-30">결제 실패</p>
                <p>{`실패 사유: ${searchParams.get("message")}`}</p>
            </div>
        </div>
    );
}