import React, { useEffect, useRef, useState } from 'react'
import ButtonSecon from '../ButtonSecon'
import ButtonPrimary from '../ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import { getCounponOrder } from '../../reduxs/actions/order.action'
import moment from 'moment/moment'
import { formatMoney } from '../../utils/renderText'

export default function SelectCouponPopup({ formUpload, setFormUpload }) {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <>
            <ButtonSecon title='쿠폰 선택' onClick={handleShow} />

            {
                isShow
                &&
                <PopupCoupoun contentRef={contentRef} handleShow={handleShow} formUpload={formUpload} setFormUpload={setFormUpload} />
            }
        </>
    )
}

const PopupCoupoun = ({ contentRef, handleShow, formUpload, setFormUpload }) => {
    const dispatch = useDispatch()

    const { dataCoupon, loadingGetCoupon } = useSelector(state => state.order)

    const [couponSelected, setCouponSelected] = useState()

    const handleChooseCoupon = (coupon) => {
        setCouponSelected(coupon)
    }

    const handleConfirm = () => {
        const couponType = couponSelected?.mark_type_id

        if (couponSelected) {
            setFormUpload({
                ...formUpload,
                coupon_point: couponType === 2 ? couponSelected.mark : '',
                coupon_percent: couponType === 1 ? couponSelected.mark : '',
                coupon_id: couponSelected.id
            })
        }

        handleShow()
    }

    useEffect(() => {
        dispatch(getCounponOrder())
    }, [])

    useEffect(() => {
        if (formUpload.pay_method_id === 1) {
            setCouponSelected()
        }
    }, [formUpload.pay_method_id])

    if (loadingGetCoupon || !dataCoupon) {
        return
    }

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
            <div ref={contentRef} className='p-5 rounded-20 bg-white w-[600px] max-lg:h-[400px] overflow-y-auto hiddenScroll grid gap-5'>
                <h4 className='text-center lg:text-24-30 lg:font-bold font-semibold'>쿠폰</h4>

                <div className='grid lg:grid-cols-2 gap-2.5'>
                    {
                        dataCoupon.map(item =>
                            <CouponItem
                                key={item.id}
                                data={item}
                                handleChooseCoupon={handleChooseCoupon}
                                isCheck={item.id === (couponSelected?.id || formUpload.coupon_id)}
                            />)
                    }
                </div>

                <div className='flex gap-2.5 w-full justify-center'>
                    <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleShow} />
                    <ButtonPrimary text='확인' className='w-[160px] justify-center' onClick={handleConfirm} />
                </div>
            </div>
        </div>
    )
}

const CouponItem = (props) => {
    const { handleChooseCoupon, data, isCheck } = props
    const { name, mark, mark_type_id, expire_at } = data

    return (
        <div className={`border-[3px] ${isCheck ? 'border-red' : 'border-strock'} rounded-20 p-5 flex flex-col gap-2.5 cursor-pointer`} onClick={() => handleChooseCoupon(data)}>
            <p className='font-semibold max-lg:text-14_20'>{name}</p>

            <div className='flex justify-between items-end'>
                <span className='lg:text-30 font-bold text-logoColor lg:leading-[30px] text-24-30'>{mark_type_id === 1 ? `${mark}%` : `${formatMoney(mark)}원`}</span>
                <span className='lg:text-14_20 text-12'>{moment(expire_at).format('YYYY/MM/DD')}</span>
            </div>
        </div>
    )
}
