import React, { useEffect } from 'react'
import ProductInforSection from '../../sections/user/ProductDetailPage/ProductInforSection'
import OrderProduct from '../../sections/user/ProductDetailPage/OrderProduct'
import LetterDetail from '../../sections/user/ProductDetailPage/LetterDetail'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProductDetail } from '../../reduxs/actions/product.action'
import LoadingUser from './LoadingUser'
import PolicyShippingSection from '../../sections/user/PostDetailPage/PolicyShippingSection'
import CommentSectionProduct from '../../sections/user/ProductDetailPage/CommentSectionProduct'

export default function ProductDetailPage() {
    const dispatch = useDispatch()

    const { id } = useParams()

    const { loadingGetDetailProduct, detailProduct } = useSelector(state => state.product)

    useEffect(() => {
        dispatch(getProductDetail(id))
    }, [id])

    if (loadingGetDetailProduct || !detailProduct) {
        return <LoadingUser />
    }

    const { sameProduct, normalPicture, desPicture, productType, policy } = detailProduct

    return (
        <main className='flex-grow lg:py-10 pt-2 pb-6 max-lg:px-6 flex flex-col lg:gap-10 gap-6 container'>
            <ProductInforSection
                detail={detailProduct}
                images={normalPicture}
                productType={productType}
            />

            {
                productType === 1 && <OrderProduct data={sameProduct} />
            }

            <LetterDetail data={desPicture} />
            <CommentSectionProduct />
            <PolicyShippingSection data={policy} />
        </main>
    )
}
