import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get all faq user
export const getAllFaqUser = createAsyncThunk(
    'faq/getall',
    async (page = 1) => {
        const response = await get(`home/getallfaq/?page=${page}`)

        return response
    }
)

// get all faq admin
export const getAllFaq = createAsyncThunk(
    'faq/getalladmin',
    async (page) => {
        const response = await get(`faq?page=${page}`)

        return response
    }
)

// create faq
export const createFaq = createAsyncThunk(
    'faq/create',
    async (data) => {
        const response = await post(`faq`, data)

        return response
    }
)

// get detail faq
export const getDetailFaq = createAsyncThunk(
    'faq/getdetail',
    async (faqId) => {
        const response = await get(`faq/detail/${faqId}`)

        return response
    }
)

// update detail faq
export const updateDetailFaq = createAsyncThunk(
    'faq/updatedetail',
    async (data) => {
        const { faqId, body } = data

        const response = await post(`faq/${faqId}`, body)

        return response
    }
)

// delete faq
export const deleteFaq = createAsyncThunk(
    'faq/deleteFaq',
    async (data) => {
        const { faqId, page } = data

        const response = await del(`faq/${faqId}?page=${page}`)

        return response
    }
)