import React from 'react'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecon from '../ButtonSecon'

export default function PopupShowNotifyBanking({ handleShow, handleConfirm }) {


    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
            <div className='p-5 bg-white rounded-20 flex flex-col items-center gap-5 max-w-[600px] w-full'>
                <h3 className='text-24-30 font-semibold'>계좌이체 주의사항</h3>

                <p className='max-w-[384px] w-full text-center'>접수자명과 입금자명이 일치해야 접수확인이 가능합니다.
                    꼭 확인 후 입금해주세요.</p>

                <div className='flex gap-2.5'>
                    <ButtonSecon title='취소' className='w-[160px]' onClick={handleShow} />
                    <ButtonPrimary link='/my-page' text='확인' className='w-[160px]' onClick={handleConfirm} />
                </div>
            </div>
        </div>
    )
}
