import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdvertHomepage } from '../../reduxs/actions/adverts.action'
import { URL_SERVER } from '../../utils/apis'
import { renderPosition } from '../../utils/renderText'

export default function PopupSection() {
    const dispatch = useDispatch()

    const { loadingGetAddAdvertHome, dataAdvertHome } = useSelector(state => state.advert)

    const [dataAdvertLocal, setDataAdvertLocal] = useState([])

    const handleClosePopup = (advertId) => {
        const updateAdvert = dataAdvertLocal.filter(item => item.id !== advertId)

        setDataAdvertLocal(updateAdvert)
    }

    useEffect(() => {
        dispatch(getAdvertHomepage())
    }, [])

    useEffect(() => {
        if (dataAdvertHome) {
            setDataAdvertLocal(dataAdvertHome)
        }
    }, [dataAdvertHome])

    if (!dataAdvertHome || loadingGetAddAdvertHome) {
        return
    }

    if (dataAdvertHome.length <= 0 || dataAdvertLocal.length <= 0) {
        return
    }

    return (
        <>
            <div className='fixed inset-0 bg-popup z-50'>
                {
                    dataAdvertLocal.map(item => <AdvertItem key={item.id} id={item.id} filename={item.filename} name={item.name} position={item.position} handleCloseAdvert={handleClosePopup} />)
                }
            </div>
        </>
    )
}

const AdvertItem = ({ position, name, filename, handleCloseAdvert, id }) => {
    return (
        <>
            <div className={`fixed ${renderPosition(position)} max-w-[400px] bg-white z-50 shadow-setting`}>
                <img src={`${URL_SERVER}${filename}`} alt={`${name}`} className='w-full object-contain' />
                <button onClick={() => handleCloseAdvert(id)} className='absolute bottom-2.5 right-2.5 z-10 hover:text-logoColor duration-200'>닫기</button>
            </div>
        </>
    )
}
