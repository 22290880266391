import { createSlice } from "@reduxjs/toolkit";
import { commentPost, getDetailpost, getPostComment, getPosts } from "../actions/posts.action";

const postSlice = createSlice({
    name: 'post',
    initialState: {
        loadingGetAllPost: false,
        dataPosts: undefined,

        loadingGetDetailPost: false,
        dataDetailPost: undefined,

        loadingGetCommentPost: false,
        dataCommentPost: undefined,

        loadingPostComment: false,
        loadingPostCommentreply: false,
    },
    extraReducers: builder => {
        builder.addCase(getPosts.pending, (state, action) => {
            state.loadingGetAllPost = true
        })
        builder.addCase(getPosts.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPosts = res.data
            }

            state.loadingGetAllPost = false
        })

        // detail post
        builder.addCase(getDetailpost.pending, (state, action) => {
            state.loadingGetDetailPost = true
        })
        builder.addCase(getDetailpost.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDetailPost = res.data
            }

            state.loadingGetDetailPost = false
        })

        // detail post comment
        builder.addCase(getPostComment.pending, (state, action) => {
            state.loadingGetCommentPost = true
        })
        builder.addCase(getPostComment.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCommentPost = res.data
            }

            state.loadingGetCommentPost = false
        })

        // comment post
        builder.addCase(commentPost.pending, (state, action) => {
            state.loadingPostComment = true
        })
        builder.addCase(commentPost.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                if (res.data.comment_id) {
                    const updateReplyComment = state.dataCommentPost.map(item => {
                        if (item.id === res.data.comment_id) {
                            return {
                                ...item,
                                commentReply: [
                                    res.data,
                                    ...item.commentReply
                                ]
                            }
                        }

                        return item
                    })

                    state.dataCommentPost = updateReplyComment

                    return state
                }

                const updateComment = [
                    res.data,
                    ...state.dataCommentPost,
                ]

                state.dataCommentPost = updateComment
            }

            state.loadingPostComment = false
        })
    }
})

// export const {} = postSlice.actions
export default postSlice.reducer
