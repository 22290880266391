import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import LettersItem from '../../components/user/LettersItem'
import LoadingUser from './LoadingUser'
import { getAllCategories } from '../../reduxs/actions/category.action'
import { getAllProducts, getProductsByCategory } from '../../reduxs/actions/product.action'
import { useDispatch, useSelector } from 'react-redux'

export default function ProductEvent() {
    const dispatch = useDispatch()

    const { loadinggetAllProduct, dataAllProduct, loadingProductByCategory } = useSelector(state => state.product)
    const { loadingGetAllCategories, dataCategories } = useSelector(state => state.categories)

    const [currentCate, setCurrentCate] = useState()

    const handleGetProductByCategory = (categoryId) => {
        setCurrentCate(categoryId)

        const data = {
            productId: 2,
            categoryId: categoryId,
            page: ''
        }
        dispatch(getProductsByCategory(data))
    }

    useEffect(() => {
        dispatch(getAllCategories(2))
    }, [])

    useEffect(() => {
        if (dataCategories) {
            const data = {
                productId: 2,
                categoryId: dataCategories[0].id,
                page: ''
            }

            setCurrentCate(dataCategories[0].id)
            dispatch(getAllProducts(data))
        }
    }, [dataCategories])

    if (loadinggetAllProduct || !dataAllProduct || loadingGetAllCategories || !dataCategories) {
        return <LoadingUser />
    }

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='공구 (공동구매 상품)' />

            <div className='flex justify-center font-semibold max-lg:text-14_20'>
                {
                    dataCategories.map(item => <button key={item.id} className={`lg:px-6 px-2 max-lg:text-14_20 relative linecategory last:after:hidden ${currentCate === item.id && 'text-logoColor'}`} onClick={() => handleGetProductByCategory(item.id)}>{item.name}</button>)
                }
            </div>

            {
                loadingProductByCategory
                    ?
                    <p>Loading...</p>
                    :
                    (
                        dataAllProduct.length
                            ?
                            <section className='grid xl:grid-cols-6 lg:grid-cols-5 grid-cols-3 lg:gap-5 gap-4'>
                                {
                                    dataAllProduct.map(item =>
                                        <LettersItem
                                            key={item.id}
                                            id={item.id}
                                            name={item.name}
                                            price={item.price}
                                            frontThumbnail={item.frontThumbnail}
                                            sold_out={item.sold_out}
                                        />)
                                }
                            </section>
                            :
                            <p className='text-center'>현재 상품이 없습니다.</p>
                    )
            }
        </main>
    )
}
