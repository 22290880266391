import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get recipient
export const getUserRecipient = createAsyncThunk(
    'user/getRecipient',
    async (typeId) => {
        const response = await get(`usermanage/user-address/${typeId}`)

        return response
    }
)

// delete recipient
export const deleteUserRecipient = createAsyncThunk(
    'user/deleteRecipient',
    async (userId) => {
        const response = await del(`usermanage/user-addressinfor/${userId}`)

        return response
    }
)

// get user normal
export const getUserNormal = createAsyncThunk(
    'user/getUserNormal',
    async (page) => {
        const response = await get(`usermanage/normal?page=${page}`)

        return response
    }
)

// search user normal
export const searchUserNormal = createAsyncThunk(
    'user/searchUserNormal',
    async (data) => {
        const { page, option, search } = data

        const response = await get(`usermanage/normal/search?page=${page}&search=${search}&option=${option}`)

        return response
    }
)

// change to blacklist
export const changeToBlacklist = createAsyncThunk(
    'user/changeToBlacklist',
    async (data) => {
        const response = await post(`usermanage/toblacklist`, data)

        return response
    }
)

// get user blacklist
export const getUserBlacklist = createAsyncThunk(
    'user/getUserBlacklist',
    async (page) => {
        const response = await get(`usermanage/blacklist?page=${page}`)

        return response
    }
)

// search user blacklist
export const searchUserBlacklist = createAsyncThunk(
    'user/searchUserBlacklist',
    async (data) => {
        const { page, option, search } = data

        const response = await get(`usermanage/blacklist/search?page=${page}&search=${search}&option=${option}`)

        return response
    }
)

// add user to normal
export const changeUserToNormal = createAsyncThunk(
    'user/changeUserToNormal',
    async (data) => {
        const response = await post(`usermanage/tonormal`, data)

        return response
    }
)

// get phone user blacklist
export const getPhoneBlacklist = createAsyncThunk(
    'user/getPhoneBlacklist',
    async (page) => {
        const response = await get(`usermanage/phoneblacklist?page=${page}`)

        return response
    }
)

// delete phone user blacklist
export const deletePhoneBlacklist = createAsyncThunk(
    'user/deletePhoneBlacklist',
    async (phoneId) => {
        const response = await del(`usermanage/phone-blacklist/${phoneId}`)

        return response
    }
)

// get email user blacklist
export const getEmailBlacklist = createAsyncThunk(
    'user/getEmailBlacklist',
    async (page) => {
        const response = await get(`usermanage/emailblacklist?page=${page}`)

        return response
    }
)

// delete email user blacklist
export const deleteEmailBlacklist = createAsyncThunk(
    'user/deleteEmailBlacklist',
    async (emailId) => {
        const response = await del(`usermanage/email-blacklist/${emailId}`)

        return response
    }
)

// add email user blacklist
export const addEmailBlacklist = createAsyncThunk(
    'user/addEmailBlacklist',
    async (data) => {
        const response = await post(`usermanage/addemail-blacklist`, data)

        return response
    }
)

// add phone to blacklist
export const addPhoneToBlacklist = createAsyncThunk(
    'user/addPhoneToBlacklist',
    async (data) => {
        const response = await post(`usermanage/addphone-blacklist`, data)

        return response
    }
)

// get point user
export const getPointUser = createAsyncThunk(
    'user/getPointUser',
    async (page) => {
        const response = await get(`usermanage/point/?page=${page}`)

        return response
    }
)

// search point user
export const searchPointUser = createAsyncThunk(
    'user/searchPointUser',
    async (data) => {
        const { condition, page, text } = data

        const response = await get(`usermanage/point/search?text=${text}&condition=${condition}&page=${page}`)

        return response
    }
)

// update review point user
export const updateReviewPointUser = createAsyncThunk(
    'user/updateReviewPointUser',
    async (data) => {
        const response = await post(`usermanage/reviewpoint/1`, data)

        return response
    }
)

// update percent purchase user
export const updatePercelPurchaseUser = createAsyncThunk(
    'user/updatePercelPurchaseUser',
    async (data) => {
        const response = await post(`usermanage/percentpurchase/1`, data)

        return response
    }
)

// update point user
export const updatePointUser = createAsyncThunk(
    'user/updatePointUser',
    async (data) => {
        const response = await post(`usermanage/userpoint`, data)

        return response
    }
)

// get premedium point user
export const getPremiumPointUser = createAsyncThunk(
    'user/getPremiumPointUser',
    async (data) => {
        const response = await get(`usermanage/premium`, data)

        return response
    }
)

// delete user account
export const deleteUserAccount = createAsyncThunk(
    'user/deleteUserAccount',
    async (data) => {
        const { userId, page } = data

        const response = await del(`usermanage/user/${userId}?page=${page}`)

        return response
    }
)
