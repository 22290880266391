import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBannerHomepage } from '../../../reduxs/actions/banner.action'
import { URL_SERVER } from '../../../utils/apis'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Autoplay, EffectFade } from 'swiper/modules';

export default function HeroSection() {
    const dispatch = useDispatch()

    const { loadingGetBannerHomePage, dataBannerHome } = useSelector(state => state.banner)

    useEffect(() => {
        dispatch(getBannerHomepage())
    }, [])

    if (loadingGetBannerHomePage || !dataBannerHome) {
        return <p className='text-center'>Loading...</p>
    }

    return (
        <section className='w-full aspect-video lg:h-screen'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                effect={'fade'}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectFade]}
                className='h-full'
                loop
            >
                {
                    dataBannerHome.map(item => <SwiperSlide key={item.id} >
                        <img loading='lazy' src={`${URL_SERVER}${item.filename}`} alt='make by me banner' className='w-full h-full aspect-video object-cover' />
                    </SwiperSlide>)
                }
            </Swiper>
        </section>
    )
}
