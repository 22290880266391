import axios from 'axios'
import { clearLocalStorage, getLocalStorage } from './storage'

export const URL_SERVER = process.env.REACT_APP_SERVER_API
export const URL_FILE = process.env.REACT_APP_SERVER_API

const instaince = axios.create({
    baseURL: URL_SERVER,
    // withCredentials: true,
})

instaince.interceptors.request.use(config => {
    const token = getLocalStorage('token')

    config.headers["Authorization"] = `Bearer ${token}`

    return config
}, (err) => {
    return Promise.reject(err)
})

instaince.interceptors.response.use(res => {
    return res
}, async err => {
    // const originalRequest = err.config;
    const statusError = err.response.status

    if (statusError === 403 || statusError === 401 || statusError === 402) {  // handle when status err 403
        clearLocalStorage()
        window.location.href = '/auth'
    }
    else if (statusError === 500 || statusError === 502) {  // handle when status err 500
        alert('Server error 500 !!!!')
        // window.location.href = '/auth'
    }
})

export const get = async (url) => {
    try {
        const result = await instaince.get(url)

        return result.data
    } catch (err) {
        handleError(err)
    }
}

export const post = async (url, data) => {
    try {
        const result = await instaince.post(url, data)

        return result.data
    } catch (err) {
        handleError(err)
    }
}

export const del = async (url, data) => {
    try {
        const result = await instaince.delete(url)

        return result.data
    } catch (err) {
        handleError(err)
    }
}

const handleError = (err) => {
    // alert(err)
    // return window.location.reload()
    console.log(err)
}