import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth } from '../../reduxs/reducers/auth.reducer'
import { getInforUser } from '../../reduxs/actions/auth.action'

export default function ProtectRouterUser({ children }) {
    const dispatch = useDispatch()

    const { isAuthentication, loading } = useSelector(state => state.auth)

    useEffect(() => {
        if (isAuthentication) {
            dispatch(getInforUser())
        }
    }, [isAuthentication])

    useEffect(() => {
        dispatch(checkAuth())
    }, [])

    if (loading) {
        return <div className='h-screen flex items-center justify-center'>Loading...</div>
    }

    return children
}
