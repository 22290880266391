import React, { useCallback, useEffect, useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { useDispatch, useSelector } from 'react-redux'
import { getPointsUser } from '../../../reduxs/actions/point.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { SwiperSlide, Swiper } from 'swiper/react'

import 'swiper/css';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react'
import VoucherItem from '../../../components/user/VoucherItem'

export default function ListVoucher() {
    const sliderRef = useRef(null);
    const dispatch = useDispatch()

    const { loadingGetPointsUser, dataPoistsUser } = useSelector(state => state.point)

    useEffect(() => {
        dispatch(getPointsUser())
    }, [])

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    if (loadingGetPointsUser || !dataPoistsUser) {
        return <LoadingUser />
    }

    return (
        <section>
            <TitleSection title='상품권 구매하기' small />

            <div className='relative'>
                <Swiper
                    className="w-full"
                    spaceBetween={20}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        865: {
                            slidesPerView: 3
                        },
                        1000: {
                            slidesPerView: 4
                        },
                        1500: {
                            slidesPerView: 5
                        },
                    }}
                    ref={sliderRef}
                >
                    {
                        dataPoistsUser.map(item =>
                            <SwiperSlide key={item.id}>
                                <VoucherItem
                                    id={item.id}
                                    name={item.name}
                                    image={item.filename}
                                    sold_out={item.sold_out}
                                    price={item.price}
                                />
                            </SwiperSlide>
                        )
                    }
                </Swiper>

                {
                    dataPoistsUser.length > 5
                    &&
                    <>
                        <div
                            className="shadow-setting cursor-pointer absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2 bg-white w-8 aspect-square rounded-full flex justify-center items-center z-10"
                            onClick={handlePrev}
                        >
                            <ArrowLeft2 size={24} color='#FA0071' />
                        </div>

                        <div
                            className="shadow-setting cursor-pointer absolute top-1/2 right-0 translate-x-1/2 -translate-y-1/2 bg-white w-8 aspect-square rounded-full flex justify-center items-center z-10"
                            onClick={handleNext}
                        >
                            <ArrowRight2 size={24} color='#FA0071' />
                        </div>
                    </>
                }
            </div>
        </section>
    )
}
