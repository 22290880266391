import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonPrimary from '../../components/ButtonPrimary'
import UploadImage from '../../sections/admin/createproduct/UploadImage'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import FormUploadEvent from '../../sections/admin/createproduct-event/FormUploadEvent'
import * as  Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { createProductEvent } from '../../reduxs/actions/product.action'
import { clearOrderLetter } from '../../reduxs/reducers/order-letter.reducer'

export default function CreateProductEvent() {
    const dispatch = useDispatch()

    const [frontImage, setFrontImage] = useState()
    const [endImage, setEndImage] = useState()

    const [descriptionImage, setDescriptionImage] = useState([])

    const [normalImage, setNarmalImage] = useState([])
    const [normalImageFirst, setNarmalImageFirst] = useState()

    const { dataOrderLetter } = useSelector(state => state.orderLetter)

    const formik = useFormik({
        initialValues: {
            frontThumbnail: '',
            endThumbnail: '',
            normals: '',
            normalFirst: '',
            descriptions: '',
            name: '',
            explres: '',
            price: '',
            discount: 0,
            quantity: '',
            category_id: '',
        },
        validationSchema: Yup.object({
            // frontThumbnail: Yup.string().required(ERROR_REQUIRED),
            // endThumbnail: Yup.string().required(ERROR_REQUIRED),
            // normals: Yup.string().required(ERROR_REQUIRED),
            // normalFirst: Yup.string().required(ERROR_REQUIRED),
            // descriptions: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            explres: Yup.string().required(ERROR_REQUIRED),
            price: Yup.string().required(ERROR_REQUIRED),
            discount: 0,
            quantity: Yup.string().required(ERROR_REQUIRED),
            category_id: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('frontThumbnail', values.frontThumbnail)
            formData.append('endThumbnail', values.endThumbnail)

            for (let i = 0; i < values.descriptions.length; i++) {
                const element = values.descriptions[i].file;

                formData.append('descriptions', element)
            }

            formData.append('normals', values.normalFirst)

            for (let i = 0; i < values.normals.length; i++) {
                const element = values.normals[i].file;

                formData.append('normals', element)
            }

            formData.append('name', values.name)
            formData.append('price', values.price)
            formData.append('expires', values.explres.getTime())
            formData.append('discount', values.discount)
            formData.append('quantity', values.quantity)
            formData.append('category_id', values.category_id)

            for (let i = 0; i < dataOrderLetter.length; i++) {
                formData.append(`envelopes[${i}][name]`, dataOrderLetter[i].name)
                formData.append(`envelopes[${i}][price]`, dataOrderLetter[i].price)
                formData.append(`envelopes[${i}][quantity]`, dataOrderLetter[i].quantity)
            }

            const upload = await dispatch(createProductEvent(formData))

            const res = upload.payload

            if (res.status) {
                formik.resetForm()

                setDescriptionImage()
                setEndImage()
                setFrontImage()
                setNarmalImage()
                setNarmalImageFirst()

                dispatch(clearOrderLetter())
            }
        }
    })

    return (
        <>
            <div className='flex justify-between'>
                <TitleSection title='상품 추가하기' mt={false} />
                <ButtonPrimary text='상품 추가하기' onClick={formik.handleSubmit} />
            </div>

            <form className='flex gap-5' onSubmit={formik.handleSubmit}>
                <UploadImage
                    formik={formik}
                    frontImage={frontImage}
                    setFrontImage={setFrontImage}
                    endImage={endImage}
                    setEndImage={setEndImage}
                    normalImage={normalImage}
                    setNormalImage={setNarmalImage}
                    normalImageFirst={normalImageFirst}
                    setNormalImageFirst={setNarmalImageFirst}
                />

                <FormUploadEvent formik={formik} descriptionImage={descriptionImage} setDescriptionImage={setDescriptionImage} />
            </form>
        </>
    )
}


