import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import RadioForm from '../../../components/RadioForm'
import InputFormThin from '../../../components/InputFormThin'
import Dropdown from '../../../components/admin/Dropdown'
import { filterEditPoint } from '../../../utils/filterArray'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { searchCouponAdmin } from '../../../reduxs/actions/coupon.action'

export default function FilterCoupon({ formik, page }) {
    const dispatch = useDispatch()

    const handleChangeType = (e) => {
        formik.setFieldValue('mark_type_id', Number(e.target.value))
    }

    // const [searchParams, setSearchParams] = useSearchParams()

    const searchFormik = useFormik({
        initialValues: {
            text: '',
            condition: '',
            page: page
        },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(searchCouponAdmin(values))
        }
    })

    const handleSetCondition = (e) => {
        searchFormik.setFieldValue('condition', e)
    }

    return (
        <section className='flex flex-col gap-[30px]'>
            <div>
                <TitleSection small title='쿠폰' />

                <div className='flex items-center gap-5'>
                    <div className='flex items-center gap-2.5'>
                        <RadioForm
                            value={1}
                            onChange={handleChangeType}
                            name='mark_type_id'
                            isCheck={formik.values.mark_type_id === 1}
                        />
                        <InputFormThin
                            classNameAll='w-[100px]'
                            className='text-center'
                            placehoder={0}
                            onChange={formik.handleChange}
                            name='mark1'
                            value={formik.values.mark1}
                            type='number'
                        />
                        <span>% 쿠폰 발행</span>
                    </div>

                    <div className='flex items-center gap-2.5'>
                        <RadioForm
                            value={2}
                            onChange={handleChangeType}
                            name='mark_type_id'
                            isCheck={formik.values.mark_type_id === 2}
                        />
                        <InputFormThin
                            classNameAll='w-[100px]'
                            className='text-center'
                            placehoder={0}
                            onChange={formik.handleChange}
                            name='mark2'
                            value={formik.values.mark2}
                            type='number'
                        />
                        <span>원 쿠폰 발행</span>
                    </div>

                    <div className='flex items-center gap-2.5'>
                        <span>만료일</span>
                        <input
                            type='date'
                            className='border py-2 px-4 rounded-12 w-[300px]'
                            onChange={formik.handleChange}
                            name='expire_at'
                            value={formik.values.expire_at}
                        />
                    </div>

                    <div className='flex items-center gap-2.5'>
                        <span>쿠폰 이름</span>
                        <InputFormThin
                            placehoder='검색어를 입력하세요'
                            classNameAll='w-[300px]'
                            onChange={formik.handleChange}
                            name='name'
                            value={formik.values.name}
                        />
                    </div>
                </div>
            </div>

            <div>
                <TitleSection small title='쿠폰을 지급할 사용자를 입력하세요.' />

                <form className='flex gap-5 items-stretch' onSubmit={searchFormik.handleSubmit}>
                    <Dropdown
                        data={filterEditPoint}
                        title='검색'
                        className='w-[160px] justify-between'
                        name='name'
                        handleChoose={handleSetCondition}
                        isChoose={searchFormik.values.condition}
                    />

                    <div className='flex gap-2 h-full flex-grow max-w-[396px] w-full'>
                        <InputFormThin
                            placehoder='검색어를 입력하세요'
                            classNameAll='w-full'
                            className='h-full'
                            name='text'
                            value={searchFormik.values.text}
                            onChange={searchFormik.handleChange}
                        />

                        <ButtonPrimary text='검색' className='flex-shrink-0' type='button' onClick={searchFormik.handleSubmit} />
                    </div>
                </form>
            </div>
        </section>
    )
}
