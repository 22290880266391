import { Add, ArrowDown2, CloseSquare, Minus, NotificationBing, TruckFast } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowAltCircleRight } from "react-icons/fa";
import ButtonPrimary, { ButtonPrimaryLink } from '../../../components/ButtonPrimary';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrderLetterSelected, deleteOrderSelectedItem, handleQuantityOrderSelect, selectOrderLetter } from '../../../reduxs/reducers/order-letter.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { formatMoney } from '../../../utils/renderText';
import ButtonDeactive from '../../../components/ButtonDeactive';
import { setIsShowNotifyNotLogin } from '../../../reduxs/reducers/auth.reducer';

export default function ProductInforEvent({ detail }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { dataOrderSelected, isLetterIdSelected } = useSelector(state => state.orderLetter)
    const { isAuthentication } = useSelector(state => state.auth)

    const { evelopes, productDetail } = detail
    const { name, price, feeShip, sold_out } = productDetail

    const { id } = useParams()

    const handleChooseOrderLetter = (order) => {
        dispatch(selectOrderLetter({ ...order, letterId: id }))
    }

    const handleSubmit = () => {
        if (isAuthentication) {
            navigate(`/checkout/event/${id}`)
        } else {
            dispatch(setIsShowNotifyNotLogin())
        }
    }

    useEffect(() => {
        if (!(Number(id) === Number(isLetterIdSelected))) {
            dispatch(clearOrderLetterSelected())
        }
    }, [id, isLetterIdSelected])

    return (
        <section className='flex flex-col lg:gap-5 gap-4 items-center' >
            <h4 className='lg:text-24-30 font-semibold w-full text-start'>{name}</h4>

            <div className='flex flex-col gap-5 w-full'>
                <div className='flex justify-between items-center'>
                    <div className='flex gap-5 font-bold items-center'>
                        <p>판매가격</p>
                        <p className='text-logoColor'>{formatMoney(price)} 원</p>
                    </div>

                    <div className='flex gap-5 font-bold items-center'>
                        <div className='flex items-center gap-2'>
                            <TruckFast size={20} variant='Bold' />
                            <span>배송비</span>
                        </div>

                        <p className='text-logoColor'>{formatMoney(feeShip)} 원</p>
                    </div>
                </div>

                <div className='flex gap-2 items-center text-blue font-bold'>
                    <NotificationBing size={24} />
                    <span className='text-14_20'>이 공구 상품은 제작 상품으로 판매자에 의해 취소/반품/교환이 제한 될 수 있습니다.</span>
                </div>

                <p className='flex items-center gap-2'>자료구매옵션 <FaArrowAltCircleRight /></p>

                <DropdownOption
                    evelopes={evelopes}
                    name='name'
                    handleChoose={handleChooseOrderLetter}
                />

                <div className='flex flex-col gap-5'>
                    {
                        dataOrderSelected.map(item =>
                            <OrderLetterSelected
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                price={item.price}
                                quantity={item.quantity}
                                totalQuantity={item.totalQuantity}
                            />)
                    }
                </div>
            </div>

            {
                sold_out
                    ?
                    <ButtonDeactive text='구매하기' />
                    :
                    <ButtonPrimary onClick={handleSubmit} text='구매하기' className='w-[160px]' type='submit' />
            }
        </section>
    )
}

const DropdownOption = ({ evelopes, isCheck, name, handleChoose }) => {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);
    return (
        <>
            <div className={`relative w-full`} ref={contentRef}>
                <button
                    type='button'
                    className={`w-full text-deactive flex gap-2.5 items-center justify-between bg-white py-2 px-4 rounded-12 border-strock border relative z-20`}
                    onClick={handleShow}
                >
                    <span>주문하실 봉투종류</span>

                    <ArrowDown2 size={20} className={`${isShow ? 'rotate-180' : ''} duration-100`} />
                </button>

                {
                    isShow
                    &&
                    <div className='absolute top-full mt-1 right-0 inset-x-0 bg-white border-strock border pt-2.5 -translate-y-3 z-10 rounded-b-12'>
                        <ul>
                            {
                                evelopes.map(item =>
                                    <li
                                        key={item.id}
                                        className={`py-2.5 px-4 w-full flex gap-2.5 items-center cursor-pointer last:rounded-b-12 hover:text-logoColor duration-300`}
                                        onClick={() => {
                                            handleChoose(item)
                                            handleShow()
                                        }}
                                    >
                                        <span className={`translate-y-[2px] ${isCheck && 'text-red'} block text-center w-full`}>{item[name]}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                }
            </div>
        </>
    )
}

const OrderLetterSelected = ({ id, name, price, quantity, totalQuantity }) => {
    const dispatch = useDispatch()

    const handleQuantity = (method) => {
        if (method === 'plus' && (totalQuantity < Number(quantity))) {
            dispatch(handleQuantityOrderSelect({ id, quantity: totalQuantity + 1 }))
        }

        if (method === 'minus' && (totalQuantity > 1)) {
            dispatch(handleQuantityOrderSelect({ id, quantity: totalQuantity - 1 }))
        }
    }

    const handleDelete = (id) => {
        dispatch(deleteOrderSelectedItem(id))
    }

    return (
        <div className='flex flex-col gap-2.5'>
            <p>{name}</p>

            <div className='flex gap-5 justify-between items-center'>
                <div className='grid grid-cols-3 items-center w-fit border rounded-8 border-strock'>
                    <button type='button' onClick={() => handleQuantity('minus')} className='flex justify-center items-center py-1 px-1.5 w-10'><Minus size={16} /></button>
                    <span className='text-14_20 py-1 px-1.5 bg-pink text-white font-bold text-center'>{totalQuantity}</span>
                    <button type='button' onClick={() => handleQuantity('plus')} className='flex justify-center items-center py-1 px-1.5'><Add size={16} /></button>
                </div>

                <div className='flex gap-5 items-center'>
                    <span className='font-bold text-logoColor leading-[24px]'>{formatMoney(price * totalQuantity)} 원</span>

                    <button type='button' className='strock1' onClick={() => handleDelete(id)}><CloseSquare size={20} /></button>
                </div>
            </div>
        </div>
    )
}