import React, { useEffect, useState } from 'react'
import ToggleStatus from '../../../components/admin/ToggleStatus'
import { useDispatch, useSelector } from 'react-redux'
import { changeToBlacklist, deleteUserAccount, getUserNormal, searchUserNormal } from '../../../reduxs/actions/user.action'
import Pagination from '../../../components/Pagination'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import { formatMoney } from '../../../utils/renderText'
import DropdownTable from '../../../components/admin/DropdownTable'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useSearchParams } from 'react-router-dom'

export default function ListUser() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const { loadingGetUserNormal, dataUserNormal, countUserNormal } = useSelector(state => state.user)

    const page = searchParams.get('page') || 1
    const search = searchParams.get('search') || ''
    const option = searchParams.get('option') || ''

    const handlePage = (method) => {
        const currentParams = Object.fromEntries([...searchParams]);
        const numberPage = Number(page)

        if (method === 'next') {
            const maxPage = Math.ceil(countUserNormal / 10)
            setSearchParams({ ...currentParams, page: numberPage < maxPage ? numberPage + 1 : numberPage })
        }
        else if (method === 'pre') {
            setSearchParams({ ...currentParams, page: numberPage <= 1 ? numberPage : numberPage - 1 })
        }
        else if (typeof method === 'number') {
            setSearchParams({ ...currentParams, page: method })
        }
    }

    useEffect(() => {
        if (!search) {
            dispatch(getUserNormal(page))
        }
    }, [page])

    useEffect(() => {
        if (search) {
            const dataSearch = { page: page, option, search }

            dispatch(searchUserNormal(dataSearch))
        }
    }, [])

    if (loadingGetUserNormal || !dataUserNormal) {
        return <LoadingUser />
    }

    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[300px]'>이메일</th>
                        <th className='py-5 px-2 w-[200px]'>성함</th>
                        <th className='py-5 px-2'>주소</th>
                        <th className='py-5 px-2 w-[100px]'>포인트</th>
                        <th className='py-5 px-2 w-[150px]'>연락처</th>
                        <th className='py-5 px-2 w-[150px]'>회원가입일</th>
                        <th className='py-5 px-2 w-[150px]'>마지막 로그인</th>
                        <th className='py-5 px-2 w-[150px]'>블랙리스트</th>
                        <th className='py-5 px-2 w-[20px]'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataUserNormal.map(item =>
                            <RowUserItem
                                key={item.account_id}
                                id={item.account_id}
                                email={item.email}
                                name={item.name}
                                phone={item.phone_number}
                                address={item.address}
                                created_at={item.created_at}
                                last_login={item.last_login}
                                point={item.point_total}
                                status={true}
                                page={Number(page)}
                            />)
                    }
                </tbody>
            </table>

            <Pagination totalCount={countUserNormal} currentPage={Number(page)} handlePage={handlePage} />
        </section>
    )
}

const RowUserItem = ({ email, name, phone, address, last_login, point, created_at, id, status, page }) => {
    const dispatch = useDispatch()

    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isNotify, setIsNotify] = useState(false)

    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleChangeStatusUser = () => {
        dispatch(changeToBlacklist({ account_id: id, page }))
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(deleteUserAccount({ userId: id, page: page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>{email}</td>
            <td className='py-5'>{name}</td>
            <td className='py-5'>{address}</td>
            <td className='py-5'>{formatMoney(point)}</td>
            <td className='py-5'>{phone}</td>
            <td className='py-5'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5'>{moment(last_login).format('YYYY.MM.DD')}</td>
            <td className='py-5'><ToggleStatus status={status} onClick={handleChangeStatusUser} /></td>
            <td className='py-5'>
                <DropdownTable onclickDelete={handleShowDelete} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 사용자 계정을 삭제하시겠습니까?</p>
                                <p>삭제된 사용자 계정은 복구할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowDelete} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleConfirmDelete} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}

