import { EyeSlash } from 'iconsax-react'
import React, { useState } from 'react'

export default function InputFormThin({ type = 'text', className, classNameAll, name, onChange, value, placehoder, error, defaultValue, onlyView = false, height = true, indexTab }) {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <div className={` ${classNameAll}`}>
            <div className={`w-full ${height && 'h-full'} relative`}>
                <input
                    type={isShow ? 'text' : type}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    placeholder={placehoder}
                    tabIndex={indexTab}
                    className={`${className} rounded-12 w-full border ${error ? 'border-red' : 'border-strock'} lg:py-2 lg:px-4 py-1.5 px-3 max-lg:text-14_20 disabled:bg-transparent placeholder:text-deactive ${type === 'password' && 'pr-[52px]'}`}
                    disabled={onlyView}
                />


                {
                    type === 'password'
                    &&
                    <button type='button' onClick={handleShow} className='absolute top-1/2 -translate-y-1/2 right-4 flex-shrink-0 w-fit'>
                        <EyeSlash size={16} color='#bababa' />
                    </button>
                }
            </div>

            {
                error
                &&
                <span className='text-12 text-red mt-2.5 block'>{error}</span>
            }
        </div>
    )
}
