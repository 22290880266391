import { createSlice } from "@reduxjs/toolkit";
import { changeStatusOrderVoucher, checkoutPointUser, checkPointsUser, createPointsAdmin, deleteOrderVoucher, deletePointsAdmin, getAllVoucher, getPointsAdmin, getPointsUser, searchVoucher } from "../actions/point.action";

const pointSlice = createSlice({
    name: 'point',
    initialState: {
        loadingGetPoints: false,
        dataPoists: undefined,
        pointCount: 0,

        loadingGetPointsUser: false,
        dataPoistsUser: undefined,

        loadingCreatePoint: false,

        loadingDeletePoint: false,

        loadingGetCheckPoint: false,
        dataCheck: undefined,

        loadingCheckoutPoinyt: false,

        loadingChangeStatusOrderVoucher: false,

        loadingDeleteOrderVoucher: false,

        dataAllVoucherAdmin: undefined,
        loadingGetAllVoucherAdmin: false,
        countVoucher: 0
    },
    extraReducers: builder => {
        // get list points admin
        builder.addCase(getPointsAdmin.pending, (state, action) => {
            state.loadingGetPoints = true
        })
        builder.addCase(getPointsAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPoists = res.data.voucherpoint
                state.pointCount = res.data.voucherpointCount
            }

            state.loadingGetPoints = false
        })

        // get list points user
        builder.addCase(getPointsUser.pending, (state, action) => {
            state.loadingGetPointsUser = true
        })
        builder.addCase(getPointsUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPoistsUser = res.data.voucherpoint
            }

            state.loadingGetPointsUser = false
        })

        // create point
        builder.addCase(createPointsAdmin.pending, (state, action) => {
            state.loadingCreatePoint = true
        })
        builder.addCase(createPointsAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPoists = [
                    res.data,
                    ...state.dataPoists
                ]

                state.pointCount += 1
            }

            state.loadingCreatePoint = false
        })

        // create point
        builder.addCase(deletePointsAdmin.pending, (state, action) => {
            state.loadingDeletePoint = true
        })
        builder.addCase(deletePointsAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataPoists.filter(item => Number(item.id) !== Number(res.data))

                state.dataPoists = updateData
                state.pointCount -= 1
            }

            state.loadingDeletePoint = false
        })

        // check point
        builder.addCase(checkPointsUser.pending, (state, action) => {
            state.loadingGetCheckPoint = true
        })
        builder.addCase(checkPointsUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheck = res.data
            }

            state.loadingGetCheckPoint = false
        })

        // checkout point
        builder.addCase(checkoutPointUser.pending, (state, action) => {
            state.loadingCheckoutPoinyt = true
        })
        builder.addCase(checkoutPointUser.fulfilled, (state, action) => {
            state.loadingCheckoutPoinyt = false
        })

        // get all voucher admin
        builder.addCase(getAllVoucher.pending, (state, action) => {
            state.loadingGetAllVoucherAdmin = true
        })
        builder.addCase(getAllVoucher.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAllVoucherAdmin = res.data.orders
                state.countVoucher = res.data.orderCount
            }

            state.loadingGetAllVoucherAdmin = false
        })

        // get all voucher admin
        builder.addCase(searchVoucher.pending, (state, action) => {
            state.loadingSearchVoucherOrder = true
        })
        builder.addCase(searchVoucher.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAllVoucherAdmin = res.data.orders
                state.countVoucher = res.data.orderCount
            }

            state.loadingSearchVoucherOrder = false
        })

        // delete order voucher admin
        builder.addCase(deleteOrderVoucher.pending, (state, action) => {
            state.loadingDeleteOrderVoucher = true
        })
        builder.addCase(deleteOrderVoucher.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataAllVoucherAdmin.filter(item => item.id !== Number(res.data.order_id))

                if (res.data.replaceOrder) {
                    state.dataAllVoucherAdmin = [
                        ...updateData,
                        res.data.replaceOrder
                    ]

                    return
                }

                state.dataAllVoucherAdmin = updateData
            }

            state.countVoucher -= 1
            state.loadingDeleteOrderVoucher = false
        })


        // change status order voucher
        builder.addCase(changeStatusOrderVoucher.pending, (state, action) => {
            state.loadingChangeStatusOrderVoucher = true
        })
        builder.addCase(changeStatusOrderVoucher.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataAllVoucherAdmin.map(item => {
                    if (item.id === Number(res.data.order_id)) {
                        return {
                            ...item,
                            order_status_id: res.data.status_id
                        }
                    }

                    return item
                })

                state.dataAllVoucherAdmin = updateData
            }

            state.loadingChangeStatusOrderVoucher = false
        })
    }
})

export default pointSlice.reducer
