import React, { useEffect, useState } from 'react'
import htmlToPdfmake from 'html-to-pdfmake';

import pdfMake from 'pdfmake/build/pdfmake';

import vfs from '../../assets/fonts/vfs_fonts'

import pdfFonts from 'pdfmake/build/vfs_fonts';
import { URL_SERVER } from '../../utils/apis';
import { loadImageAsBase64 } from '../../utils/renderText';

pdfMake.vfs = vfs
pdfMake.fonts = pdfFonts.pdfMake

pdfMake.fonts = {
    'Gowunbatang': {
        normal: 'https://fonts.cdnfonts.com/s/93241/GowunBatang-Regular.woff',
        bold: 'https://fonts.cdnfonts.com/s/93241/GowunBatang-Bold.woff'
    },
    'Handwriting': {
        normal: 'https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_05@1.0/Gomsin.woff',
        bold: 'https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_05@1.0/Gomsin.woff',
    },
    'Ongeulip': {
        normal: `${URL_SERVER}/font/ongliponz.woff`, // ongliponz
        bold: `${URL_SERVER}/font/ongliponz.woff`
    },
    'Chamomile': {
        normal: `${URL_SERVER}/font/onlykemomile.woff`, // onlykemomile
        bold: `${URL_SERVER}/font/onlykemomile.woff`
    },
}

const resultLineHeight = (size, font) => {
    // switch (size, font) {
    // case 10:               /// Lating
    //     return 2.69
    // case 11:
    //     return 2.44
    // case 12:
    //     return 2.24
    // default:
    //     return 2.69
    if (size === 10 && font === 'Gowunbatang') {          // Gowunbatang
        return 2.44
    } else if (size === 11 && font === 'Gowunbatang') {
        return 2.2
    } else if (size === 12 && font === 'Gowunbatang') {
        return 2.04
    } else if (size === 15 && font === 'Gowunbatang') {
        return 1.61
    } else if (size === 10 && font === 'Handwriting') {   // Handwriting
        return 3.1
    } else if (size === 11 && font === 'Handwriting') {
        return 2.72
    } else if (size === 12 && font === 'Handwriting') {
        return 2.57
    } else if (size === 15 && font === 'Handwriting') {
        return 2.04
    } else if (size === 10 && font === 'Ongeulip') {      // Ongeulip
        return 3.31
    } else if (size === 11 && font === 'Ongeulip') {
        return 3.06
    } else if (size === 12 && font === 'Ongeulip') {
        return 2.82
    } else if (size === 15 && font === 'Ongeulip') {
        return 2.27
    } else if (size === 10 && font === 'Chamomile') {      // Chamomile
        return 3.05
    } else if (size === 11 && font === 'Chamomile') {
        return 2.72
    } else if (size === 12 && font === 'Chamomile') {
        return 2.526
    } else if (size === 15 && font === 'Chamomile') {
        return 2.03
    } else {
        return 2.51
    }
}

export default function DownloadQuillLetter({ fileName, data }) {
    const [contentEditor, setContentEditor] = useState()
    const [isLoading, setIsLoading] = useState(false);

    const content = data?.content

    // add line height to content pdfmake
    const addLineHeightToCopy = (data) => {
        return data.map(contain => {
            if (!contain.text && contain.nodeName === 'OL') {
                const updateItem = contain.ol.map(liItem => {
                    const updateText = liItem.text.map(item => {
                        return {
                            ...item,
                            lineHeight: resultLineHeight(item.fontSize || 10, item.font),
                            fontSize: item.fontSize ? item.fontSize / 1.5 : (10 / 1.5),
                            margin: [0, 0, 0, 0]
                        }
                    })

                    return {
                        ...liItem,
                        text: updateText
                    }
                })

                return updateItem
            }

            if (!contain.text && contain.nodeName === 'UL') {
                const updateItem = contain.ul.map(liItem => {
                    const updateText = liItem.text.map(item => {
                        return {
                            ...item,
                            lineHeight: resultLineHeight(item.fontSize || 10, item.font),
                            fontSize: item.fontSize ? item.fontSize / 1.5 : (10 / 1.5),
                            margin: [0, 0, 0, 0]
                        }
                    })

                    return {
                        ...liItem,
                        text: updateText
                    }
                })

                return updateItem
            }

            else if (typeof contain.text === 'string') return {
                ...contain,
                lineHeight: resultLineHeight(contain.fontSize || 10, contain.font),
                fontSize: contain.fontSize ? contain.fontSize / 1.5 : (10 / 1.5),
                margin: [0, 0, 0, 0]
            }

            const updateItem = contain.text.map(item => {
                return {
                    ...item,
                    lineHeight: resultLineHeight(item.fontSize || 10, item.font),
                    fontSize: item.fontSize ? item.fontSize / 1.5 : (10 / 1.5),
                    margin: [0, 0, 0, 0]
                }
            })

            return { ...contain, text: updateItem, margin: [0, 0, 0, 0] }
        })
    };

    // custom data download
    const listPdfContent = () => {
        const pdf = []

        contentEditor.forEach((item, index) => {
            const pdfContent = addLineHeightToCopy(htmlToPdfmake(item.editorState, {
                defaultStyles: {
                    margin: [0, 0, 0, 0]
                }
            }))

            pdf.push(
                { text: '', pageBreak: 'before' },
                [
                    ...pdfContent,
                    {
                        text: index + 1,
                        fontSize: 8,
                        absolutePosition: {
                            y: 580,
                            x: 210
                        }
                    }

                ]
            )

            if (index < contentEditor.length - 1) {
                pdf.push(
                    { text: '', pageBreak: 'after' }
                );
            }
        })

        return pdf
    }

    useEffect(() => {
        if (content) {
            const contentParse = JSON.parse(content)

            setContentEditor(contentParse)
        }
    }, [content])

    // handle download file Pdf
    const downloadFile = async () => {
        setIsLoading(true)

        const base64ImageFront = await loadImageAsBase64(`${URL_SERVER}${data.frontThumbnail}`)
        const base64ImageEnd = await loadImageAsBase64(`${URL_SERVER}${data.endThumbnail}`)

        const docDefinition = {
            pageSize: 'A5',
            background: (page) => {
                if (page % 2 !== 0) {
                    return [
                        {
                            image: `data:image/png;base64,${base64ImageFront}`,
                            width: 420,
                            height: 600
                        }
                    ]
                } else {
                    return [
                        {
                            image: `data:image/png;base64,${base64ImageEnd}`,
                            width: 420,
                            height: 600
                        }
                    ]
                }
            },
            content: listPdfContent(),
            pageMargins: [36, 69, 36, 0],
            defaultStyle: {
                font: 'Gowunbatang'
            }
        };

        // // Tạo và tải file PDF
        try {
            const pdfDoc = pdfMake.createPdf(docDefinition)

            await new Promise((resolve, reject) => {
                pdfDoc.getBlob((blob) => {
                    if (blob) {
                        resolve(blob)
                    } else {
                        reject('Failed to generate PDF')
                    }
                })
            })

            pdfDoc.download(`${fileName}.pdf`)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }

        // console.log(listPdfContent())
    }

    return (
        <>
            <button onClick={downloadFile} className={`bg-blue text-white py-1.5 px-3 min-w-[65px] text-center rounded-8 text-12 font-semibold`} type='button'>
                {isLoading ? '다운로드 중...' : '인쇄'}
            </button>
        </>
    )
}
