import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import { TruckFast } from 'iconsax-react'
import RadioForm from '../../components/RadioForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkoutPointUser, checkPointsUser } from '../../reduxs/actions/point.action'
import ButtonSecon from '../../components/ButtonSecon'
import { loadTossPayments } from '@tosspayments/tosspayments-sdk'
import { formatMoney } from '../../utils/renderText'
import PopupShowTimeNotify from '../../components/user/PopupShowTimeNotify'
import PopupShowNotifyBanking from '../../components/user/PopupShowNotifyBanking'
import CopyButton from '../../components/user/CopyButton'
import { addSaveDataUpload } from '../../reduxs/reducers/order-letter.reducer'

export default function PaymentVoucher() {
    const dispatch = useDispatch()

    const { dataCheck } = useSelector(state => state.point)

    const visapay_id = dataCheck?.visapay_id
    const totalPayment = dataCheck?.price

    const [typePayment, setTypePayment] = useState(2)

    const [widgets, setWidgets] = useState(null)
    const [paymentMethodWidget, setPaymentMethodWidget] = useState(null);
    const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false)

    const [isShowTimeNotify, setIsShowTimeNotify] = useState(false)
    const [isShowBankingNotify, setIsShowBankingNotify] = useState(false)

    const [isShowNotify, setIsShowNotify] = useState(false)

    const { voucher } = useParams()

    const ship_fee = 0

    const handleChooseTypePayment = (e) => {
        const type = e.target.value

        setTypePayment(Number(type))
    }

    const handlePaymentBanking = () => {
        setIsShowBankingNotify(true)
    }

    const confirmPaymentBanking = async () => {
        setIsShowBankingNotify(false)

        const formUpload = {
            id: voucher,
            pay_method_id: typePayment,
        }

        const result = await dispatch(checkoutPointUser(formUpload))

        const res = result.payload

        if (res.status) {
            setIsShowTimeNotify(true)
        } else {
            setIsShowNotify(res.message)
        }

        setTimeout(() => {
            setIsShowNotify(false)
        }, 3000);
    }

    const handlePaymentByCart = async () => {
        const formUpload = {
            id: voucher,
            pay_method_id: typePayment,
        }

        dispatch(addSaveDataUpload(formUpload))

        try {
            if (paymentMethodWidget && typeof paymentMethodWidget.destroy === "function") {
                await paymentMethodWidget.destroy();
                setPaymentMethodWidget(null);  // Xóa widget khỏi trạng thái
            }

            setIsShowPaymentMethod(true);

            // Đặt số tiền thanh toán
            await widgets.setAmount({
                currency: "KRW",
                value: dataCheck?.price, // dataCheck?.price
            });

            // Render widget phương thức thanh toán
            const paymentMethod = await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: "DEFAULT",
            });

            setPaymentMethodWidget(paymentMethod);  // Lưu widget vào state
        } catch (error) {
            console.error("Lỗi trong quá trình xử lý thanh toán:", error);
            await paymentMethodWidget.destroy();
            setPaymentMethodWidget(null);
        }
    }

    // confirm payment card
    const confirmPaymentCard = async () => {
        try {
            await widgets.requestPayment({
                orderId: visapay_id,
                orderName: 'voucher',
                successUrl: window.location.origin + "/successPoint",
                failUrl: window.location.origin + "/fail",
                customerEmail: 'email@gmail.com',
                customerName: 'name',
                customerMobilePhone: '0123456789',
            });
        } catch (error) {
            console.log(error)
        }
    }

    // config widgets
    useEffect(() => {
        const initializeToss = async () => {
            const clientKey = process.env.REACT_APP_CLIENT_KEY_PAYMENT;
            const tossPayments = await loadTossPayments(clientKey);
            const customerKey = process.env.REACT_APP_CUSTOMER_KEY_PAYMENT;

            const widgetsInstance = tossPayments.widgets({
                customerKey,
            });
            setWidgets(widgetsInstance);
        };

        initializeToss();
    }, [])

    useEffect(() => {
        dispatch(checkPointsUser(voucher))
    }, [voucher])

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col items-center lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='결제방법' />

            <section className='w-full'>
                <div className='flex justify-between items-start w-full max-lg:flex-col gap-4 max-lg:text-14_20'>
                    <div className='flex gap-2 items-center'>
                        <TruckFast variant="Bold" size={20} />
                        <p>배송비:</p>
                        <span className='text-logoColor font-bold'>{formatMoney(ship_fee)}장</span>
                    </div>

                    <div className='flex flex-col gap-2.5'>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>총액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(totalPayment)}원</span>
                        </div>

                        <div className='flex gap-2 items-center justify-between'>
                            <p className='font-bold min-w-20'>총 구매금액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(totalPayment)}원</span>
                        </div>
                    </div>
                </div>
            </section>

            <section className='w-full'>
                <div className='rounded-20 border-strock border'>
                    <div className='flex'>
                        <div className='flex justify-center items-center p-5 border-r border-strock max-lg:w-1/3 max-lg:text-14_20 w-[140px]'>결제방법</div>

                        <div className='p-5 flex flex-col gap-2.5 max-lg:w-2/3'>
                            <div className='flex lg:gap-5 gap-2.5 max-sm:flex-col'>
                                <RadioForm type='checkbox' isCheck={typePayment === 2} content='계좌이체' onChange={handleChooseTypePayment} value={2} />
                                <RadioForm type='checkbox' isCheck={typePayment === 3} content='신용/체크카드' onChange={handleChooseTypePayment} value={3} />
                            </div>

                            <CopyButton title='Kakao Bank 3333-28-8248474' value='3333288248474' />
                        </div>
                    </div>
                </div>
            </section>

            <ButtonPrimary text='구매하기' className='w-[160px] justify-center' onClick={typePayment === 3 ? handlePaymentByCart : handlePaymentBanking} />

            {
                isShowPaymentMethod
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='w-[600px] bg-white rounded-20 p-5'>
                        <div className='flex flex-col gap-5 items-center justify-center'>
                            <div id='payment-method' className='w-full'></div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='이전' onClick={() => setIsShowPaymentMethod(false)} className='w-[160px]' />
                                <ButtonPrimary text='확인' onClick={confirmPaymentCard} className='w-[160px]' />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                isShowBankingNotify
                    ?
                    <PopupShowNotifyBanking handleShow={() => setIsShowBankingNotify(false)} handleConfirm={confirmPaymentBanking} />
                    :
                    ''
            }

            {
                isShowTimeNotify
                    ?
                    <PopupShowTimeNotify />
                    :
                    ''
            }

            <div className={`${isShowNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isShowNotify}
            </div>
        </main>
    )
}
