import React from 'react'
import { Link } from 'react-router-dom'

export default function ButtonSecon({ title, className, onClick }) {
    return (
        <button type='button' onClick={onClick} className={`flex gap-2 lg:rounded-12 rounded-8 lg:py-2.5 lg:px-4 py-2 px-[14px] max-lg:text-14_20 bg-blue text-white items-center justify-center ${className}`}>
            {title}
        </button>
    )
}

export const ButtonLinkSecon = ({ title, className, link }) => {
    return (
        <Link to={link} className={`flex gap-2 lg:rounded-12 rounded-8 lg:py-2.5 lg:px-4 py-2 px-[14px] max-lg:text-14_20 bg-blue text-white items-center ${className}`}>
            {title}
        </Link>
    )
}
