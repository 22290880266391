import { createSlice } from "@reduxjs/toolkit";
import { addEventOrderVisapay, addNormalOrderVisapay, addOfficeOrder, addPdfOrderVisapay, addPointOrderVisapay, checkoutOrder, checkoutOrderEvent, checkoutOrderhandmake, checkoutPdfOrder, checkPaymentOrderEvent, checkPaymentOrderHandmake, checkPaymentPdf, deleteOrderAdmin, getCounponOrder, getOrderCheckInfor, getOrderCompletedMypage, getOrderDetail, getOrderFree, getOrderMypage, getOrderPdfMypage, getOrderStamp, getPaymentCheckOrder, getSavedPdfMaterialOrder, savedPdfMaterialOrder, updatePdfMaterialOrder } from "../actions/order.action";

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loadingGetFee: false,
        dataOrderFee: undefined,

        loadingGetStamp: false,
        dataOrderStamp: undefined,

        loadingCheckInforOrder: false,
        dataCheckInforOrder: undefined,

        loadingGetCoupon: false,
        dataCoupon: undefined,

        loadingGetCheckPayment: false,
        dataCheckPayment: undefined,

        loadingGetSavedPdfMaterial: false,
        dataSavedPdfMaterial: undefined,

        loadingSavedPdfMaterial: false,
        loadingUpdatePdfMaterial: false,

        loadingCheckoutOrder: false,

        loadingCheckoutOrderByVisa: false,

        loadingCheckoutPdfOrder: false,

        dataCheckPaymentPdf: undefined,
        loadingCheckPaymentPdf: false,

        loadingCheckPaymentEventLt: false,
        dataCheckPaymentEventLlt: undefined,

        loadingCheckoutOrderEvent: false,

        loadingGetCheckOrderHandmake: false,
        dataCheckOrderHandmake: undefined,

        loadingCheckouthandmake: false,

        loadingGetOrderMypage: false,
        dataOrderMypage: undefined,
        countOrders: 0,

        loadingGetOrderPdfMypage: false,
        dataOrderPdfMypage: undefined,
        countOrderPdf: 0,

        loadingGetOrderCompletedMypage: false,
        dataOrderCompletedMypage: undefined,
        countOrderCompleted: 0,

        loadingGetOrderDetail: false,
        dataOrderDetail: undefined,
        isError: true,

        loadingDeleteOrder: false
    },
    extraReducers: builder => {
        // get fee
        builder.addCase(getOrderFree.pending, (state, action) => {
            state.loadingGetFee = true
        })
        builder.addCase(getOrderFree.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderFee = res.data
            }

            state.loadingGetFee = false
        })

        // get stamp
        builder.addCase(getOrderStamp.pending, (state, action) => {
            state.loadingGetStamp = true
        })
        builder.addCase(getOrderStamp.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderStamp = res.data
            }

            state.loadingGetStamp = false
        })

        // get order my page
        builder.addCase(getOrderMypage.pending, (state, action) => {
            state.loadingGetOrderMypage = true
        })
        builder.addCase(getOrderMypage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderMypage = res.data.orders
                state.countOrders = res.data.orderCount
            }

            state.loadingGetOrderMypage = false
        })

        // get order my page
        builder.addCase(getOrderPdfMypage.pending, (state, action) => {
            state.loadingGetOrderPdfMypage = true
        })
        builder.addCase(getOrderPdfMypage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderPdfMypage = res.data.orders
                state.countOrderPdf = res.data.orderCount
            }

            state.loadingGetOrderPdfMypage = false
        })

        // get order my page
        builder.addCase(getOrderCompletedMypage.pending, (state, action) => {
            state.loadingGetOrderCompletedMypage = true
        })
        builder.addCase(getOrderCompletedMypage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderCompletedMypage = res.data.orders
                state.countOrderCompleted = res.data.orderCount
            }

            state.loadingGetOrderCompletedMypage = false
        })

        // get order detail
        builder.addCase(getOrderDetail.pending, (state, action) => {
            state.loadingGetOrderDetail = true
            state.isError = false
        })
        builder.addCase(getOrderDetail.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataOrderDetail = res.data
            } else {
                state.isError = true
            }

            state.loadingGetOrderDetail = false
        })

        // get chcek infor
        builder.addCase(getOrderCheckInfor.pending, (state, action) => {
            state.loadingCheckInforOrder = true
        })
        builder.addCase(getOrderCheckInfor.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheckInforOrder = res.data
            }

            state.loadingCheckInforOrder = false
        })

        // get coupon
        builder.addCase(getCounponOrder.pending, (state, action) => {
            state.loadingGetCoupon = true
        })
        builder.addCase(getCounponOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCoupon = res.data
            }

            state.loadingGetCoupon = false
        })

        // get check payment
        builder.addCase(getPaymentCheckOrder.pending, (state, action) => {
            state.loadingGetCheckPayment = true
        })
        builder.addCase(getPaymentCheckOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheckPayment = res.data
            }

            state.loadingGetCheckPayment = false
        })

        // get saved pdf material
        builder.addCase(getSavedPdfMaterialOrder.pending, (state, action) => {
            state.loadingGetSavedPdfMaterial = true
        })
        builder.addCase(getSavedPdfMaterialOrder.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataSavedPdfMaterial = res.data
            }

            state.loadingGetSavedPdfMaterial = false
        })

        // saved pdf material order
        builder.addCase(savedPdfMaterialOrder.pending, (state, action) => {
            state.loadingSavedPdfMaterial = true
        })
        builder.addCase(savedPdfMaterialOrder.fulfilled, (state, action) => {
            // const res = action.payload

            // if (res.status) {
            //     state.dataSavedPdfMaterial = res.data
            // }

            state.loadingSavedPdfMaterial = false
        })

        // update pdf material order
        builder.addCase(updatePdfMaterialOrder.pending, (state, action) => {
            state.loadingUpdatePdfMaterial = true
        })
        builder.addCase(updatePdfMaterialOrder.fulfilled, (state, action) => {
            // const res = action.payload

            // if (res.status) {
            //     state.dataSavedPdfMaterial = res.data
            // }

            state.loadingUpdatePdfMaterial = false
        })

        // saved pdf material
        builder.addCase(checkoutOrder.pending, (state, action) => {
            state.loadingCheckoutOrder = true
        })
        builder.addCase(checkoutOrder.fulfilled, (state, action) => {

            state.loadingCheckoutOrder = false
        })

        // add office order
        builder.addCase(addOfficeOrder.pending, (state, action) => {
            state.loadingCheckoutOrderByVisa = true
        })
        builder.addCase(addOfficeOrder.fulfilled, (state, action) => {

            state.loadingCheckoutOrderByVisa = false
        })

        // add pdf order
        builder.addCase(addPdfOrderVisapay.pending, (state, action) => {
            state.loadingCheckoutOrderByVisa = true
        })
        builder.addCase(addPdfOrderVisapay.fulfilled, (state, action) => {

            state.loadingCheckoutOrderByVisa = false
        })

        // add event letter order
        builder.addCase(addEventOrderVisapay.pending, (state, action) => {
            state.loadingCheckoutOrderByVisa = true
        })
        builder.addCase(addEventOrderVisapay.fulfilled, (state, action) => {

            state.loadingCheckoutOrderByVisa = false
        })

        // add normal letter order
        builder.addCase(addNormalOrderVisapay.pending, (state, action) => {
            state.loadingCheckoutOrderByVisa = true
        })
        builder.addCase(addNormalOrderVisapay.fulfilled, (state, action) => {

            state.loadingCheckoutOrderByVisa = false
        })

        // add point order
        builder.addCase(addPointOrderVisapay.pending, (state, action) => {
            state.loadingCheckoutOrderByVisa = true
        })
        builder.addCase(addPointOrderVisapay.fulfilled, (state, action) => {

            state.loadingCheckoutOrderByVisa = false
        })

        // checkout pdf order
        builder.addCase(checkoutPdfOrder.pending, (state, action) => {
            state.loadingCheckoutPdfOrder = true
        })
        builder.addCase(checkoutPdfOrder.fulfilled, (state, action) => {

            state.loadingCheckoutPdfOrder = false
        })

        // check payment pdf
        builder.addCase(checkPaymentPdf.pending, (state, action) => {
            state.loadingCheckPaymentPdf = true
        })
        builder.addCase(checkPaymentPdf.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheckPaymentPdf = res.data
            }

            state.loadingCheckPaymentPdf = false
        })

        // check payment event letter
        builder.addCase(checkPaymentOrderEvent.pending, (state, action) => {
            state.loadingCheckPaymentEventLt = true
        })
        builder.addCase(checkPaymentOrderEvent.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheckPaymentEventLlt = res.data
            }

            state.loadingCheckPaymentEventLt = false
        })

        // checkout order event product
        builder.addCase(checkoutOrderEvent.pending, (state, action) => {
            state.loadingCheckoutOrderEvent = true
        })
        builder.addCase(checkoutOrderEvent.fulfilled, (state, action) => {
            state.loadingCheckoutOrderEvent = false
        })

        // get checkout handlemake product
        builder.addCase(checkPaymentOrderHandmake.pending, (state, action) => {
            state.loadingGetCheckOrderHandmake = true
        })
        builder.addCase(checkPaymentOrderHandmake.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCheckOrderHandmake = res.data
            }

            state.loadingGetCheckOrderHandmake = false
        })

        // checkout handlemake product
        builder.addCase(checkoutOrderhandmake.pending, (state, action) => {
            state.loadingCheckouthandmake = true
        })
        builder.addCase(checkoutOrderhandmake.fulfilled, (state, action) => {
            state.loadingCheckouthandmake = false
        })
    }
})

export const { } = orderSlice.actions
export default orderSlice.reducer
