import { Calendar } from 'iconsax-react'
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react'
import * as Calen from 'react-calendar'

import 'react-calendar/dist/Calendar.css';

const today = new Date()

export default function DateInputForm({ date, handleSelectDay }) {
    const contentRef = useRef()

    const [isShowCalendar, setIsShowCalendar] = useState(false)
    const [value, setValue] = useState(today)

    // handle show calendar
    const handleShowCalendar = () => {
        setIsShowCalendar(!isShowCalendar)
    }

    // click outsize
    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShowCalendar()
            }
        }

        if (isShowCalendar) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShowCalendar, isShowCalendar]);

    return (
        <div className='relative' ref={contentRef}>
            <button onClick={handleShowCalendar} type='button' className='flex justify-between items-center text-deactive py-2 px-4 rounded-12 border border-strock w-full'>
                <span className={`${date && 'text-black'}`}>{date ? moment(date).format('YYYY/MM/DD') : 'YYYY/MM/DD'}</span>

                <Calendar size={20} />
            </button>

            {
                isShowCalendar
                &&
                <div className='absolute top-full left-0 mt-1 text-black shadow-shadow-sidebar p-5 bg-white rounded-8 shadow-setting react-calendar'>
                    <Calen.Calendar
                        onChange={(e) => {
                            handleSelectDay(e)
                            setValue(e)
                        }}
                        value={value}
                        className='!border-none max-sm:!w-full'
                        locale='ko-KO'
                        onClickDay={handleSelectDay}
                        calendarType='iso8601'
                        formatDay={(locale, date) => date.getDate()}
                    // maxDate={new Date()}
                    // selectRange={true}
                    />
                </div>
            }
        </div>
    )
}
