import { createBrowserRouter } from "react-router-dom";
import Auth from "../pages/auth/Auth";
import User from "../pages/user/User";
import RegisterPage from "../pages/auth/RegisterPage";
import ForgotPassword from "../pages/auth/ForgotPassword";
import PostDetailPage from "../pages/user/PostDetailPage";
import PostPage from "../pages/user/PostPage";
import FQAPage from "../pages/user/FQAPage";
import ProductsPage from "../pages/user/ProductsPage";
import ProductDetailPage from "../pages/user/ProductDetailPage";
import WritingLetter from "../pages/user/WritingLetter";
import TestPage from "../pages/TestPage";
import ProductEvent from "../pages/user/ProductEvent";
import ProductHandmade from "../pages/user/ProductHandmade";
import MyAccount from "../pages/user/MyAccount";
import PdfuploadPage from "../pages/user/PdfuploadPage";
import ConfirmNewPassword from "../pages/auth/ConfirmNewPassword";
import AdminPage from "../pages/admin/AdminPage";
import OrderStatisticsAdmin from "../pages/admin/OrderStatisticsAdmin";
import ProductsAdmin from "../pages/admin/ProductsAdmin";
import CategoriesAdmin from "../pages/admin/CategoriesAdmin";
import ReviewProductAdmin from "../pages/admin/ReviewProductAdmin";
import ListUserAdmin from "../pages/admin/ListUserAdmin";
import BlacklistuserAdmin from "../pages/admin/BlacklistuserAdmin";
import OrdersAdmin from "../pages/admin/OrdersAdmin";
import EditPointsAdmin from "../pages/admin/EditPointsAdmin";
import DeliveryOption from "../pages/admin/DeliveryOption";
import FaqAdmin from "../pages/admin/FaqAdmin";
import NoticeAdmin from "../pages/admin/NoticeAdmin";
import PopupAdmin from "../pages/admin/PopupAdmin";
import Createproduct from "../pages/admin/Createproduct";
import ProductEventAdmin from "../pages/admin/ProductEventAdmin";
import CreateProductEvent from "../pages/admin/CreateProductEvent";
import CreateNotice from "../pages/admin/CreateNotice";
import EditNotice from "../pages/admin/EditNotice";
import CreateFaq from "../pages/admin/CreateFaq";
import ManagementImage from "../pages/admin/ManagementImage";
import ProductHandmadeAdmin from "../pages/admin/ProductHandmadeAdmin";
import CreateProductHandmade from "../pages/admin/CreateProductHandmade";
import SavedPage from "../pages/user/SavedPage";
import { SuccessPage } from "../pages/user/SuccessPayment";
import { FailPage } from "../pages/user/FailPayment";
import ProtectRouterUser from "../components/user/ProtectRouterUser";
import CheckoutLetterEvent from "../pages/user/CheckoutLetterEvent";
import CheckoutLetterHandmade from "../pages/user/CheckoutLetterHandmade";
import CommentPage from "../pages/user/CommentPage";
import TermsOfUse from "../pages/user/TermsOfUse";
import PrivacyPolicyPage from "../pages/user/PrivacyPolicyPage";
import OrderdetailPage from "../pages/user/OrderdetailPage";
import NotFoundPage from "../pages/404";
import PointPage from "../pages/admin/PointPage";
import CouponAdminPage from "../pages/admin/CouponAdminPage";
import EditFaq from "../pages/admin/EditFaq";
import OrderPdfAdmin from "../pages/admin/OrderPdfAdmin";
import OrderEventAdmin from "../pages/admin/OrderEventAdmin";
import OrderHandmakeAdmin from "../pages/admin/OrderHandmakeAdmin";
import PaymentVoucher from "../pages/user/PaymentVoucher";
import OrderVoucher from "../pages/admin/OrderVoucher";
import PolicyPage from "../pages/user/PolicyPage";
import ProtectRouterAdmin from "../components/admin/ProtectRouterAdmin";
import EditProductAdmin from "../pages/admin/EditProductAdmin";
import EditEventProduct from "../pages/admin/EditEventProduct";
import EditHanmakeProduct from "../pages/admin/EditHanmakeProduct";
import ErrorPage from "../pages/ErrorPage";
import { SuccessPagePdf } from "../pages/user/SuccessPaymentPdf";
import { SuccessPageEvent } from "../pages/user/SuccessPaymentEvent";
import { SuccessPageNormal } from "../pages/user/SuccessPaymentNormal";
import { SuccessPagePoint } from "../pages/user/SuccessPaymentPoint";

export const router = createBrowserRouter([
    {
        path: '/',
        element:
            <ProtectRouterUser>
                <User />
            </ProtectRouterUser>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'posts',
                element: <PostPage />
            },
            {
                path: 'posts/:post',
                element: <PostDetailPage />
            },
            {
                path: 'fqa',
                element: <FQAPage />
            },
            {
                path: 'products',
                element: <ProductsPage />
            },
            {
                path: 'products/:id',
                element: <ProductDetailPage />
            },
            {
                path: 'writing/:letter',
                element: <WritingLetter />
            },
            {
                path: 'product-event',
                element: <ProductEvent />
            },
            {
                path: 'product-handmade',
                element: <ProductHandmade />
            },
            {
                path: 'my-page',
                element: <MyAccount />
            },
            {
                path: 'pdf',
                element: <PdfuploadPage />
            },
            {
                path: 'saved-letter/:letter?',
                element: <SavedPage />
            },
            {
                path: 'checkout/event/:letterId',
                element: <CheckoutLetterEvent />
            },
            {
                path: 'checkout/handmake/:letterId',
                element: <CheckoutLetterHandmade />
            },
            {
                path: 'comment/:orderId',
                element: <CommentPage />
            },
            {
                path: 'order/:orderId',
                element: <OrderdetailPage />
            },
            {
                path: 'voucher-payment/:voucher',
                element: <PaymentVoucher />
            },
            {
                path: 'policy',
                element: <PolicyPage />
            },
        ]
    },
    {
        path: '/auth',
        element: <Auth />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'register',
                element: <RegisterPage />
            },
            {
                path: 'forgot',
                element: <ForgotPassword />
            },
            {
                path: 'new-password/otp',
                element: <ConfirmNewPassword />
            },
        ]
    },
    {
        path: 'terms_of_use',
        element: <TermsOfUse />
    },
    {
        path: 'privacy_policy',
        element: <PrivacyPolicyPage />
    },
    {
        path: '/admin',
        element:
            <ProtectRouterAdmin>
                <AdminPage />
            </ProtectRouterAdmin>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'orders',
                element: <OrdersAdmin />
            },
            {
                path: 'order-statistics',
                element: <OrderStatisticsAdmin />
            },
            {
                path: 'order-pdf',
                element: <OrderPdfAdmin />
            },
            {
                path: 'order-event',
                element: <OrderEventAdmin />
            },
            {
                path: 'order-handmake',
                element: <OrderHandmakeAdmin />
            },
            {
                path: 'products',
                element: <ProductsAdmin />
            },
            {
                path: 'products/add',
                element: <Createproduct />
            },
            {
                path: 'products/edit/:id',
                element: <EditProductAdmin />
            },
            {
                path: 'products-event',
                element: <ProductEventAdmin />
            },
            {
                path: 'products-event/edit/:id',
                element: <EditEventProduct />
            },
            {
                path: 'products-event/add',
                element: <CreateProductEvent />
            },
            {
                path: 'products-handmade',
                element: <ProductHandmadeAdmin />
            },
            {
                path: 'products-handmade/edit/:id',
                element: <EditHanmakeProduct />
            },
            {
                path: 'products-handmade/add',
                element: <CreateProductHandmade />
            },
            {
                path: 'categories',
                element: <CategoriesAdmin />
            },
            {
                path: 'reviews',
                element: <ReviewProductAdmin />
            },
            {
                path: 'users',
                element: <ListUserAdmin />
            },
            {
                path: 'blacklist-user',
                element: <BlacklistuserAdmin />
            },
            {
                path: 'edit-point',
                element: <EditPointsAdmin />
            },
            {
                path: 'delivery',
                element: <DeliveryOption />
            },
            {
                path: 'faq-admin',
                element: <FaqAdmin />
            },
            {
                path: 'faq-admin/add',
                element: <CreateFaq />
            },
            {
                path: 'faq-admin/edit/:id',
                element: <EditFaq />
            },
            {
                path: 'notice',
                element: <NoticeAdmin />
            },
            {
                path: 'notice/add',
                element: <CreateNotice />
            },
            {
                path: 'notice/edit/:id',
                element: <EditNotice />
            },
            {
                path: 'popup',
                element: <PopupAdmin />
            },
            {
                path: 'management',
                element: <ManagementImage />
            },
            {
                path: 'points',
                element: <PointPage />
            },
            {
                path: 'coupons',
                element: <CouponAdminPage />
            },
            {
                path: 'order-voucher',
                element: <OrderVoucher />
            },
        ]
    },
    {
        path: 'test',
        element: <TestPage />
    },
    {
        path: 'success',
        element: <SuccessPage />
    },
    {
        path: 'successPdf',
        element: <SuccessPagePdf />
    },
    {
        path: 'successEvent',
        element: <SuccessPageEvent />
    },
    {
        path: 'successNormal',
        element: <SuccessPageNormal />
    },
    {
        path: 'successPoint',
        element: <SuccessPagePoint />
    },
    {
        path: 'fail',
        element: <FailPage />
    },
    {
        path: '*',
        element: <NotFoundPage />
    },
])