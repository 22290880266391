import { ArrowDown2 } from "iconsax-react"
import { useEffect, useRef, useState } from "react"

export const DropdownQuill = ({ title, className, data, name, isChoose, classnameAll, onChange, style }) => {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div className={`relative flex-shrink-0 ${classnameAll}`} ref={contentRef}>
            <button
                type='button'
                className={`!flex gap-2.5 items-center justify-between !h-full !bg-white hover:!text-logoColor focus:!text-logoColor duration-200 !py-2 !px-4 rounded-12 ${className} !border-strock !border-[1px] !border-solid relative !z-20 !min-w-[180px] !float-none`}
                onClick={handleShow}
            >
                <span className={`!line-clamp-1`}>
                    {isChoose ? isChoose : title}
                </span>

                <ArrowDown2 size={20} className={`${isShow ? 'rotate-180' : ''} duration-100`} />
            </button>

            {
                isShow
                &&
                <div className='absolute top-full mt-1 right-0 !w-full bg-white border-strock border pt-2.5 -translate-y-3 z-10 rounded-b-12'>
                    <ul>
                        {
                            data.map(item =>
                                <li
                                    key={item.id}
                                    className={`!p-0 w-full text-center cursor-pointer last:rounded-b-12 hover:text-logoColor duration-300 ${(isChoose === item.name) && 'text-logoColor'}`}
                                >
                                    <button
                                        onClick={() => {
                                            onChange(item)
                                            handleShow()
                                        }}
                                        className={`ql-${style}-${item.value} !py-2 !w-full !h-auto hover:!text-logoColor duration-200 !float-none`}
                                    >
                                        {item[name]}
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    )
}