import React from 'react'
import StatisticalRepost from '../../sections/admin/dashboard/StatisticalRepost'
import HistoryOrder from '../../sections/admin/dashboard/HistoryOrder'

export default function OrderStatisticsAdmin() {
    return (
        <>
            <StatisticalRepost title='일일 통계 현황' />
            <StatisticalRepost title='월간 통계 현황' />
            <StatisticalRepost title='연간 통계 현황' />
            <HistoryOrder />
        </>
    )
}
