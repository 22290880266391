import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import LoginPage from './LoginPage'
import logo from '../../assets/images/logo.png'
import NotifyChangePassword from '../../components/NotifyChangePassword'

const renderTitle = (page) => {
    switch (page) {
        case '/auth':
            return '로그인'
        case '/auth/register':
            return '계정 만들기'
        case '/auth/forgot':
            return '비밀번호를 잊으셨나요?'
        case '/auth/new-password/otp':
            return '인증번호 입력'
        default:
            return 'Not Found !!!'
    }
}

export default function Auth() {
    const { pathname } = useLocation()

    return (
        <>
            <NotifyChangePassword />

            <main className='bg-auth h-screen w-full bg-cover bg-center bg-no-repeat flex justify-center items-center'>
                <section className='hiddenScroll w-popup mx-6 bg-white lg:rounded-24 rounded-20 lg:p-10 p-[30px] flex flex-col items-center lg:gap-10 gap-[30px] shadow-shadow max-h-[760px] overflow-y-auto' >
                    <div className='flex flex-col gap-2.5 items-center'>
                        <img src={logo} alt='' className='lg:w-[100px] w-[70px] aspect-square object-contain' />

                        <h2 className='lg:text-30 lg:leading-[30px] text-24-30 font-semibold'>{renderTitle(pathname)}</h2>
                    </div>

                    {
                        pathname === '/auth' ? <LoginPage /> : <Outlet />
                    }
                </section>
            </main>
        </>
    )
}
