import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

// get banner home page
export const getBannerHomepage = createAsyncThunk(
    'banner/gethomepage',
    async () => {
        const response = await get(`/home/getbanner`)

        return response
    }
)

// get banner admin
export const getBannerAdmin = createAsyncThunk(
    'banner/getadmin',
    async () => {
        const response = await get(`/banner`)

        return response
    }
)

// delete banner admin
export const deleteBannerAdmin = createAsyncThunk(
    'banner/delete',
    async (bannerId) => {
        const response = await del(`/banner/${bannerId}`)

        return response
    }
)

// add banner admin
export const addBannerAdmin = createAsyncThunk(
    'banner/add',
    async (data) => {
        const response = await post(`/banner`, data)

        return response
    }
)