import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBannerAdmin, deleteBannerAdmin, getBannerAdmin } from '../../../reduxs/actions/banner.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { ImageUploadItem } from '../createproduct/FormUpload'
import { URL_SERVER } from '../../../utils/apis'
import ButtonSecon from '../../../components/ButtonSecon'

export default function BannerImage() {
    const dispatch = useDispatch()
    const imageRef = useRef()

    const { loadingGetBannerAdmin, dataBannerAdmin } = useSelector(state => state.banner)

    const [postImage, setPostImage] = useState([])
    const [isShowDeletePopup, setIsShowDeletePopup] = useState()
    const [isNotify, setIsNotify] = useState(false)

    const [isImageDelete, setIsImageDelete] = useState()

    const handleClickImage = () => {
        imageRef.current.click()
    }

    const handleShowPopupDelete = (bannerId) => {
        setIsShowDeletePopup(!isShowDeletePopup)

        if (isShowDeletePopup) setIsImageDelete()
        else setIsImageDelete(bannerId)
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(deleteBannerAdmin(isImageDelete))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')

            handleDeletepostImage(isImageDelete)
            setIsImageDelete()
            setIsShowDeletePopup()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    const handleChooseImage = async (e) => {
        const files = e.currentTarget.files
        const fileArray = Array.from(files)

        const formData = new FormData()

        formData.append('banners', files[0])

        const addBanner = await dispatch(addBannerAdmin(formData))

        const res = addBanner.payload

        if (res.status) {
            const newImages = fileArray.map((item, index) => {
                return {
                    id: new Date().getTime() + index,
                    file: URL.createObjectURL(item)
                };
            });

            setPostImage(pre => [...pre, ...newImages])

            setIsNotify('이미지를 추가했습니다.')
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);

        e.target.value = null
    }

    const handleDeletepostImage = (file) => {
        const handleFiles = postImage.filter(item => item.id !== file)

        setPostImage(handleFiles)
    }

    useEffect(() => {
        dispatch(getBannerAdmin())
    }, [])

    useEffect(() => {
        if (dataBannerAdmin) {
            setPostImage(dataBannerAdmin)
        }
    }, [dataBannerAdmin])

    if (loadingGetBannerAdmin || !dataBannerAdmin) {
        return <LoadingUser />
    }

    return (
        <section className='flex flex-col gap-5'>
            <h3 className='font-semibold'>배너</h3>

            <div className='grid grid-cols-4 gap-5'>
                <input type='file' accept='image/jpg, image/jpeg' hidden ref={imageRef} onChange={handleChooseImage} />

                {
                    (postImage && (postImage.length > 0)) ?
                        <>
                            {
                                postImage.map(item =>
                                    <ImageUploadItem
                                        key={item.id}
                                        id={item.id}
                                        image={item.file ? item.file : `${URL_SERVER}${item.filename}`}
                                        handleDelete={() => handleShowPopupDelete(item.id)}
                                    />)
                            }

                            {postImage.length < 4 &&
                                Array(4 - postImage.length).fill(null).map((_, index) => (
                                    <ImageUploadItem
                                        key={`empty-${index}`}
                                        handleSelect={handleClickImage}
                                    />
                                ))
                            }
                        </>
                        :
                        <>
                            <ImageUploadItem handleSelect={handleClickImage} />
                            <ImageUploadItem handleSelect={handleClickImage} />
                            <ImageUploadItem handleSelect={handleClickImage} />
                            <ImageUploadItem handleSelect={handleClickImage} />
                        </>
                }
            </div>

            {
                isShowDeletePopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-10'>
                    <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                        <div className='text-center'>
                            <p>이미지를 삭제하시겠습니까?</p>
                            <p>삭제된 이미지는 복구할 수 없습니다.</p>
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleShowPopupDelete} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' onClick={handleConfirmDelete} />
                        </div>
                    </div>
                </div>
            }

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </section>
    )
}
