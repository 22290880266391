import React, { useEffect, useState } from 'react'
import PhoneItem from '../../../components/admin/PhoneItem'
import { useDispatch, useSelector } from 'react-redux'
import { getPhoneBlacklist } from '../../../reduxs/actions/user.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import Pagination from '../../../components/Pagination'

export default function ListPhone() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetPhoneBlacklist, dataPhoneBlacklist, countPhoneBlacklist } = useSelector(state => state.user)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countPhoneBlacklist / 15)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getPhoneBlacklist(page))
    }, [page])

    if (loadingGetPhoneBlacklist || !dataPhoneBlacklist) {
        return <LoadingUser />
    }

    return (
        <div className='grid grid-cols-3 gap-5 rounded-24 p-5 bg-white'>
            {
                dataPhoneBlacklist.map(item =>
                    <PhoneItem
                        key={item.id}
                        id={item.id}
                        phone={item.phone_number}
                    />
                )
            }

            <Pagination totalCount={countPhoneBlacklist} numberInPage={15} currentPage={page} handlePage={handlePage} />
        </div>
    )
}
