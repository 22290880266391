import React, { useEffect, useState } from 'react'
import Dropdown from '../../../components/admin/Dropdown'
import InputFormThin from '../../../components/InputFormThin'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getStatusOrder } from '../../../reduxs/actions/admin.action'
import { searchVoucher } from '../../../reduxs/actions/point.action'
import { filterByUserPdf } from '../../../utils/filterArray'
import LoadingUser from '../../../pages/user/LoadingUser'

export default function SearchVoucher() {
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const [dataText, setDataText] = useState()

    const page = searchParams.get('page')
    const status_id = searchParams.get('status_id')
    const condition = searchParams.get('condition')

    const { dataAllVoucherAdmin } = useSelector(state => state.point)
    const { dataStatusOrder } = useSelector(state => state.admin)

    const handleChooseStatus = (statusId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, status_id: statusId })
    }

    const handleChooseCondition = (conditionId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, condition: conditionId })
    }

    const handleChangeInput = (e) => {
        const value = e.target.value

        setDataText(value)
    }

    const handleSearch = (e) => {
        e.preventDefault()

        const dataSearch = {
            page,
            status_id,
            condition,
            text: dataText,
        }

        dispatch(searchVoucher(dataSearch))
    }

    useEffect(() => {
        dispatch(getStatusOrder())
    }, [])

    useEffect(() => {
        if (dataAllVoucherAdmin && (page || status_id || condition)) {
            const dataSearch = {
                page,
                status_id,
                condition,
                text: dataText,
            }

            dispatch(searchVoucher(dataSearch))
        }
    }, [dataStatusOrder])

    if (!dataStatusOrder || !dataAllVoucherAdmin) {
        return <LoadingUser />
    }

    return (
        <div>
            <div className='flex gap-5 items-center'>
                <p>총 10개</p>

                <Dropdown
                    data={filterByUserPdf}
                    handleChoose={handleChooseCondition}
                    title='검색'
                    className='w-[160px] justify-between'
                    name='name'
                    isChoose={Number(condition)}
                />

                <form className='flex gap-2 h-full flex-grow max-w-[396px] w-full items-stretch'>
                    <InputFormThin placehoder='검색어를 입력하세요' classNameAll='w-full' className='h-full' onChange={handleChangeInput} />

                    <ButtonPrimary text='검색' className='flex-shrink-0' type='submit' onClick={handleSearch} />
                </form>

                <Dropdown
                    data={dataStatusOrder}
                    handleChoose={handleChooseStatus}
                    title='상태'
                    className='w-[160px] justify-between'
                    name='name'
                    isChoose={Number(status_id)}
                />

                {/* <ButtonSecon title='주문 내역 추가하기' /> */}
            </div>
        </div>
    )
}
