import React from 'react'
import kakao from '../../assets/images/kakaoLogo.png'
import naver from '../../assets/images/naverLogo.png'
import { Link } from 'react-router-dom'

export default function FooterUser() {
    return (
        <footer className='shadow-footer'>
            <div className='px-layout py-10 max-lg:px-6 flex flex-col lg:gap-10 gap-[30px]'>
                <div className='flex max-lg:flex-col lg:items-end items-start gap-4 justify-between'>
                    <div className='grid lg:gap-5 gap-4 max-lg:text-14_20'>
                        <p>사업자번호 : 749-36-01141</p>
                        <p>카카오뱅크 3333-28-8248474</p>
                        {/* <p>전화번호 : 010-2916-2018</p> */}
                        <p>예금주 : 메이드바이미</p>
                        <p>대표자명 : 박성희</p>
                        <p>사업자 주소 : 대구광역시 동성로 6길 12-13 7층 메이드바이미</p>
                        <p>Business hour: AM 11 : 00 - PM 16 : 00 (금요일은 15:00까지)</p>
                    </div>

                    <div className='flex justify-center gap-5'>
                        <Link to='https://pf.kakao.com/_iFxaXG' target='_blank'>
                            <img src={kakao} alt='kakaotalk' className='lg:w-[60px] w-10 aspect-square rounded-full object-contain' />
                        </Link>
                    </div>
                </div>

                <div className='grid gap-5'>
                    <p className='max-lg:text-14_20'>메이드바이미는 윈도우를 기반으로 개발 된 사이트입니다. Mac OS 이용시에는 기능적인 부분에 이슈가 있을 수 있습니다.</p>
                    <p className='max-lg:text-14_20'>Copyright ⓒ 2023 메이드바이미 All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
