import React, { useEffect } from 'react'
import Dropdown from '../../../components/admin/Dropdown'
import InputFormThin from '../../../components/InputFormThin'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { filterByAccount } from '../../../utils/filterArray'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getPremiumPointUser, searchPointUser, updatePercelPurchaseUser, updateReviewPointUser } from '../../../reduxs/actions/user.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { formatMoney } from '../../../utils/renderText'

export default function FilterEditPoint() {
    const dispatch = useDispatch()

    const { percentPurchase, reviewPoint, loadingGetMediumPoint } = useSelector(state => state.user)

    const searchFormik = useFormik({
        initialValues: {
            text: '',
            condition: '',
            page: 1
        },
        onSubmit: values => {
            dispatch(searchPointUser(values))
        }
    })

    const updatePointFormik = useFormik({
        initialValues: {
            mark: reviewPoint.mark || ''
        },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(updateReviewPointUser(values))
        }
    })

    const updatePercentFormik = useFormik({
        initialValues: {
            percent: percentPurchase.percent || ''
        },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(updatePercelPurchaseUser(values))
        }
    })

    const handleChooseCondition = (e) => {
        searchFormik.setFieldValue('condition', e)
    }

    useEffect(() => {
        dispatch(getPremiumPointUser())
    }, [])

    if (loadingGetMediumPoint || !percentPurchase || !reviewPoint) {
        return <LoadingUser />
    }

    return (
        <div className='grid gap-5'>
            <div className='flex gap-5 items-stretch'>
                <Dropdown data={filterByAccount} name='name' title='검색' className='w-[160px] justify-between' handleChoose={handleChooseCondition} isChoose={searchFormik.values.condition} />

                <form className='flex gap-2 flex-grow max-w-[396px] w-full items-stretch' onSubmit={searchFormik.handleSubmit}>
                    <InputFormThin
                        placehoder='검색어를 입력하세요'
                        classNameAll='w-full h-full flex-grow'
                        className='h-full'
                        name='text'
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.text}
                    />

                    <ButtonPrimary text='검색' className='flex-shrink-0' type='submit' />
                </form>
            </div>

            <div className='flex gap-5' >
                <form className='flex gap-1 items-center' onSubmit={updatePointFormik.handleSubmit}>
                    <p>후기 작성 시</p>
                    <InputFormThin
                        classNameAll='w-[100px]'
                        name='mark'
                        value={formatMoney(updatePointFormik.values.mark)}
                        onChange={updatePointFormik.handleChange}
                        className='text-center !py-1 !px-3 !text-14_20 !rounded-8'
                    />
                    <p>원 적립</p>
                    <ButtonPrimary text='저장' className='!py-1 !px-3 !text-14_20 !rounded-8' onClick={updatePointFormik.handleSubmit} />
                </form>

                <form className='flex gap-1 items-center' onSubmit={updatePercentFormik.handleSubmit}>
                    <InputFormThin
                        classNameAll='w-[100px]'
                        type='number'
                        name='percent'
                        value={updatePercentFormik.values.percent}
                        onChange={updatePercentFormik.handleChange}
                        className='text-center !py-1 !px-3 !text-14_20 !rounded-8'
                    />
                    <p>% 적립</p>
                    <ButtonPrimary text='저장' className='!py-1 !px-3 !text-14_20 !rounded-8' onClick={updatePercentFormik.handleSubmit} />
                </form>
            </div>
        </div>
    )
}
