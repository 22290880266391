import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { Table } from '../WritingLetter.js/PaymentStep'
import { TruckFast } from 'iconsax-react'
import { UploadPdfContext } from '../../../contexts/UploadPdfContext'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import PopupFailPayment from '../../../pages/user/PopupFailPayment'
import { useDispatch, useSelector } from 'react-redux'
import { checkoutPdfOrder, checkPaymentPdf } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { loadTossPayments } from '@tosspayments/tosspayments-sdk'
import { addSaveDataUpload } from '../../../reduxs/reducers/order-letter.reducer'
import PopupShowTimeNotify from '../../../components/user/PopupShowTimeNotify'
import PopupShowNotifyBanking from '../../../components/user/PopupShowNotifyBanking'
import { encodeFilename } from '../../../utils/renderText'
import { useNavigate } from 'react-router-dom'

export default function CheckoutPdfStep() {
    const paymentRef = useRef()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loadingCheckPaymentPdf, dataCheckPaymentPdf } = useSelector(state => state.order)

    const { handleNavigateStep, formUpload, setFormUpload } = useContext(UploadPdfContext)
    const { ship_fee, pdf } = formUpload

    const [typePayment, setTypePayment] = useState(0)

    const [widgets, setWidgets] = useState(null)
    const [paymentMethodWidget, setPaymentMethodWidget] = useState(null);
    const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false)

    const [totalProduct, setTotalProduct] = useState(0)
    const [coupon, setCoupon] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const [isShowNotifyPayByPoint, setIsShowNotifyPayBypoint] = useState(false)

    const [showNotifycationPayment, setShowNotifycationPayment] = useState(false)

    const [isShowTimeNotify, setIsShowTimeNotify] = useState(false)
    const [isShowBankingNotify, setIsShowBankingNotify] = useState(false)

    const handleChooseTypePayment = (e) => {
        const type = Number(e.target.value)

        if (type === 1) {
            if (userPoint < 1000) return setIsShowNotifyPayBypoint('현재 점수가 1000점 이하이므로 사용할 수 없습니다.')

            setCoupon(userPoint)

            setFormUpload({
                ...formUpload,
                pay_method_id: 1
            })
        } else {
            setCoupon(0)

            setFormUpload({
                ...formUpload,
                pay_method_id: type
            })
        }
        setTypePayment(type)
    }

    const handlePaymentByPoint = async () => {
        if (coupon < totalPayment || coupon < 1000) {
            return setIsShowNotifyPayBypoint(true)
        }

        const formData = new FormData()

        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            product_total: 2,
            money_total: totalProduct,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
        }

        const pdfs = customDataUpload.pdf

        for (let i = 0; i < pdfs.length; i++) {
            const pdf = pdfs[i];
            const encodedFilename = encodeFilename(pdf.pdf_name.name)

            const file = new File([pdf.pdf_name], encodedFilename, { type: pdf.pdf_name.type })

            formData.append(`pdf[${i}][type]`, pdf.type)
            formData.append(`pdf[${i}][price]`, pdf.price)
            formData.append(`pdf[${i}][pdf_name]`, file)
            formData.append(`pdf[${i}][pdf_page]`, pdf.pdf_page)
        }

        for (const data in customDataUpload) {
            if (!(data === 'pdf')) {
                formData.append(`${data}`, customDataUpload[data])
            }
        }

        const result = await dispatch(checkoutPdfOrder(formData))

        const res = result.payload

        if (res.status) {
            setIsShowTimeNotify(true)

            setTimeout(() => {
                navigate('/my-page')
            }, 3000);
        } else {
            setShowNotifycationPayment(res.message)
        }

        setTimeout(() => {
            setShowNotifycationPayment(false)
        }, 3000);
    }

    const handlePaymentByBanking = async () => {
        setIsShowBankingNotify(false)

        const formData = new FormData()

        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            product_total: 2,
            money_total: totalProduct,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
            save_sender: formUpload.save_sender ? 1 : 0,
        }

        const pdfs = customDataUpload.pdf

        for (let i = 0; i < pdfs.length; i++) {
            const pdf = pdfs[i];

            const encodedFilename = encodeFilename(pdf.pdf_name.name)

            const file = new File([pdf.pdf_name], encodedFilename, { type: pdf.pdf_name.type })

            formData.append(`pdf[${i}][type]`, pdf.type)
            formData.append(`pdf[${i}][price]`, pdf.price)
            formData.append(`pdf[${i}][pdf_name]`, file)
            formData.append(`pdf[${i}][pdf_page]`, pdf.pdf_page)
        }

        for (const data in customDataUpload) {
            if (data !== 'pdf') {
                formData.append(`${data}`, customDataUpload[data])
            }
        }

        const result = await dispatch(checkoutPdfOrder(formData))

        const res = result.payload

        if (res.status) return setIsShowTimeNotify(true)
        else setShowNotifycationPayment(res.message)

        setTimeout(() => {
            setShowNotifycationPayment(false)
        }, 3000);
    }

    const handleBanking = () => {
        setIsShowBankingNotify(true)
    }

    const handlePaymentByCard = async () => {
        const formData = new FormData()

        const customDataUpload = {
            ...formUpload,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            product_total: 2,
            money_total: totalProduct,
            money_pay: totalPayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
        }

        const pdfs = customDataUpload.pdf

        for (let i = 0; i < pdfs.length; i++) {
            const pdf = pdfs[i];

            const encodedFilename = encodeFilename(pdf.pdf_name.name)

            const file = new File([pdf.pdf_name], encodedFilename, { type: pdf.pdf_name.type })

            formData.append(`pdf[${i}][type]`, pdf.type)
            formData.append(`pdf[${i}][price]`, pdf.price)
            formData.append(`pdf[${i}][pdf_name]`, file)
            formData.append(`pdf[${i}][pdf_page]`, pdf.pdf_page)
        }

        for (const data in customDataUpload) {
            if (data !== 'pdf') {
                formData.append(`${data}`, customDataUpload[data])
            }
        }

        const result = await dispatch(checkoutPdfOrder(formData))

        const res = result.payload

        if (!res.status) {
            setShowNotifycationPayment(res.message)

            setTimeout(() => {
                setShowNotifycationPayment(false)
            }, 3000);
        }

        dispatch(addSaveDataUpload({ tempOrderId: res.data }))

        try {
            if (paymentMethodWidget && typeof paymentMethodWidget.destroy === "function") {
                await paymentMethodWidget.destroy();
                setPaymentMethodWidget(null);  // Xóa widget khỏi trạng thái
            }

            setIsShowPaymentMethod(true);

            // Đặt số tiền thanh toán
            await widgets.setAmount({
                currency: "KRW",
                value: totalPayment, // totalPayment
            });

            // Render widget phương thức thanh toán
            const paymentMethod = await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: "DEFAULT",
            });

            setPaymentMethodWidget(paymentMethod);  // Lưu widget vào state
        } catch (error) {
            console.error("Lỗi trong quá trình xử lý thanh toán:", error);
            await paymentMethodWidget.destroy();
            setPaymentMethodWidget(null);
        }
    }

    // confirm payment card
    const confirmPaymentCard = async () => {
        try {
            await widgets.requestPayment({
                orderId: visapay_id,
                orderName: 'PDF Download',
                successUrl: window.location.origin + "/successPdf",
                failUrl: window.location.origin + "/fail",
                customerEmail: email,
                customerName: name,
                customerMobilePhone: phone_number,
            });
        } catch (error) {
            console.log(error)
        }
    }

    // config widgets
    useEffect(() => {
        const initializeToss = async () => {
            const clientKey = process.env.REACT_APP_CLIENT_KEY_PAYMENT;
            const tossPayments = await loadTossPayments(clientKey);
            const customerKey = process.env.REACT_APP_CUSTOMER_KEY_PAYMENT;

            const widgetsInstance = tossPayments.widgets({
                customerKey,
            });
            setWidgets(widgetsInstance);
        };

        initializeToss();
    }, [])

    useEffect(() => {
        dispatch(checkPaymentPdf())
    }, [])

    useEffect(() => {
        if (pdf) {
            const totalPrice = pdf.reduce((total, item) => total + (item.price * item.pdf_page), 0)

            setTotalProduct(totalPrice + ship_fee)
        }
    }, [pdf])

    useEffect(() => {
        const totalPayments = formUpload?.coupon_percent ? totalProduct - ((totalProduct * formUpload?.coupon_percent) / 100) : totalProduct - (formUpload?.coupon_point || 0)
        setTotalPayment(totalPayments)
    }, [formUpload, coupon, totalProduct, typePayment])

    // handle show
    useEffect(() => {
        async function handleClickOutside(event) {
            if (paymentRef.current && !paymentRef.current.contains(event.target)) {
                setIsShowPaymentMethod(false)
            }
        }

        if (isShowPaymentMethod) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [paymentRef, isShowPaymentMethod]);

    if (loadingCheckPaymentPdf || !dataCheckPaymentPdf) {
        return <LoadingUser />
    }

    const { bonusPercent, userPoint, email, name, phone_number, visapay_id } = dataCheckPaymentPdf

    return (
        <>
            <TitleSection title='결제방법' />

            <section className='flex flex-col lg:gap-5 gap-4 w-full'>
                <div className='flex flex-col gap-2.5 max-lg:text-14_20'>
                    <TitleSection title='결제방법' small mt={false} />

                    {/* <div className='flex gap-1 text-blue items-center'>
                        <SmsTracking />
                        <span>내일 우체국에 접수됩니다.</span>
                    </div> */}
                </div>

                <div className='flex justify-between items-start w-full max-lg:flex-col gap-4 max-lg:text-14_20'>
                    <div className='flex gap-2 items-center'>
                        <TruckFast variant="Bold" size={20} />
                        <p>등기/우편 비용:</p>
                        <span className='text-logoColor font-bold'>{ship_fee}원</span>
                    </div>

                    <div className='flex flex-col gap-2.5 max-lg:w-full'>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>총액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{totalProduct}원</span>
                        </div>

                        {
                            coupon
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>사용 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{coupon}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            (coupon && (totalPayment < coupon))
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>남은 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{coupon - totalPayment}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            (formUpload.coupon_percent || formUpload.coupon_point)
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>쿠폰:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{formUpload.coupon_percent && `${formUpload.coupon_percent}%`} {formUpload.coupon_point && `${formUpload.coupon_point}원`}</span>
                                </div>
                                :
                                ''
                        }

                        <div className='flex gap-2 items-center justify-between'>
                            <p className='font-bold min-w-20'>총 구매금액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{(coupon - totalPayment) > 0 ? 0 : totalPayment}원</span>
                        </div>
                    </div>
                </div>

                <Table
                    handleChooseTypePayment={handleChooseTypePayment}
                    typePayment={typePayment}
                    bonusPercent={bonusPercent}
                    userPoint={userPoint}
                    isCheck={!!coupon}
                    totalPayment={totalPayment}
                    formUpload={formUpload}
                    setFormUpload={setFormUpload}
                />

                <div className='flex gap-2.5 w-full justify-center'>
                    <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleNavigateStep} />
                    <ButtonPrimary text='결제하기' className='w-[160px] justify-center' onClick={typePayment === 1 ? handlePaymentByPoint : typePayment === 2 ? handleBanking : handlePaymentByCard} />
                </div>
            </section>

            {
                isShowNotifyPayByPoint
                &&
                <PopupFailPayment content={isShowNotifyPayByPoint} handleShow={() => setIsShowNotifyPayBypoint(false)} />
            }

            {
                isShowPaymentMethod
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='w-[600px] bg-white rounded-20 p-5' ref={paymentRef}>
                        <div className='flex flex-col gap-5 items-center justify-center'>
                            <div id='payment-method' className='w-full'></div>
                            <ButtonSecon title='확인' onClick={confirmPaymentCard} />
                        </div>
                    </div>
                </div>
            }

            {
                isShowTimeNotify
                    ?
                    <PopupShowTimeNotify handleShow={() => setIsShowTimeNotify(false)} />
                    :
                    ''
            }

            {
                isShowBankingNotify
                    ?
                    <PopupShowNotifyBanking handleShow={() => setIsShowBankingNotify(false)} handleConfirm={handlePaymentByBanking} />
                    :
                    ''
            }

            <div className={`${showNotifycationPayment ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {showNotifycationPayment}
            </div>
        </>
    )
}
