import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/apis";

// get gaoladdress
export const getGaoladdress = createAsyncThunk(
    'gaol/getall',
    async (type = 1) => {
        const response = await get(`address/type/${type}`)

        return response
    }
)

// search gaol address
export const searchGaolAddress = createAsyncThunk(
    'gao/search',
    async (data) => {
        const { type, text } = data

        const response = await get(`address/search/type/${type}?text=${text}`)

        return response
    }
)
