import React, { useContext } from 'react'
import TitlePage from '../../../components/user/TitlePage'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ButtonSecon from '../../../components/ButtonSecon'
import { UploadPdfContext } from '../../../contexts/UploadPdfContext'

export default function CheckPdfFileStep() {
    const { handleNavigateStep, formUpload } = useContext(UploadPdfContext)

    const pdfFiles = formUpload?.pdf

    return (
        <>
            <TitlePage title='확인' />

            <div className='w-full grid gap-5'>
                {
                    pdfFiles
                    &&
                    pdfFiles.map(item => <FilePdfTotalItem key={item.id} file={item.pdf_name} page_count={item.pdf_page} />)
                }
            </div>

            <div className='flex gap-2.5 w-full justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleNavigateStep} />
                <ButtonPrimary text='결제하기' className='w-[160px] justify-center' onClick={() => handleNavigateStep('next')} />
            </div>
        </>
    )
}

const FilePdfTotalItem = ({ file, page_count }) => {
    const { name } = file

    return (
        <div className='max-lg:text-14_20'>
            <p className='brak-all line-clamp-1'>{name}</p>
            <span className='text-logoColor'>(PDF: {page_count}장 컬러)</span>
        </div>
    )
}

