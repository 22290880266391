import { createContext, useState } from "react";

export const UploadLetterContext = createContext()

const UploadLetterProvider = ({ children }) => {
    const [stepUpload, setStepUpload] = useState(1)
    const [formUpload, setFormUpload] = useState({
        sender_address_id: '',
        recipient_address_id: '',
        pay_method_id: ''
    })

    const handleNavigateStep = (navigate) => {
        switch (navigate) {
            case 'next':
                return stepUpload <= 5 && setStepUpload(stepUpload + 1)

            default:
                return (stepUpload > 1) && setStepUpload(stepUpload - 1)
        }
    }

    return (
        <UploadLetterContext.Provider
            value={{
                formUpload,
                setFormUpload,
                stepUpload,
                handleNavigateStep,
            }}
        >
            {children}
        </UploadLetterContext.Provider>
    )
}

export default UploadLetterProvider
