import React from 'react'
import { URL_SERVER } from '../../utils/apis'
import RatingReview from '../admin/RatingReview'

export default function EvaluateItem({ rating, content, frontThumbnail }) {
    return (
        <div className='flex gap-5 max-lg:flex-col h-full items-center'>
            <img src={`${URL_SERVER}${frontThumbnail}`} alt={`${content}`} className='lg:w-1/3 lg:max-w-[200px] object-cover rounded-20 aspect-[7.08/10]' />

            <div className='lg:w-2/3 flex flex-col text-center lg:gap-2.5 gap-2 lg:text-14_20 text-12'>
                <RatingReview defaultStar={rating} />

                <h5 className='text-logoColor'>[사용하기 아주 편리함]</h5>

                <p className='whitespace-pre-line'>{content}</p>
            </div>
        </div>
    )
}
