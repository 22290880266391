import React from 'react'
import ReviewImage from './ReviewImage'
import ProductInforNormal from './ProductInforNormal'
import ProductInforHandmade from './ProductInforHandmade'
import ProductInforEvent from './ProductInforEvent'

export default function ProductInforSection({ detail, images, productType }) {
    return (
        <div className='grid lg:grid-cols-2 lg:gap-10 gap-4'>
            <ReviewImage data={images} />

            {
                productType === 1 && <ProductInforNormal detail={detail.productDetail} />
            }

            {
                productType === 2 && <ProductInforEvent detail={detail} />
            }

            {
                productType === 3 && <ProductInforHandmade detail={detail} />
            }

        </div>
    )
}
