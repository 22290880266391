import React, { useRef, useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import LabelFormThin from '../../components/LabelFormThin'
import InputFormThin from '../../components/InputFormThin'
import { useFormik } from 'formik'
import ContentForm from '../../components/admin/ContentForm'
import { ImageUploadItem } from '../../sections/admin/createproduct/FormUpload'
import ButtonPrimary from '../../components/ButtonPrimary'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { convertToRaw, EditorState } from 'draft-js'
import { useDispatch } from 'react-redux'
import { createNotice } from '../../reduxs/actions/notice.action'
import { useNavigate } from 'react-router-dom'

export default function CreateNotice() {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const imageRef = useRef()

    const [postImage, setPostImage] = useState([])
    const [isNotify, setIsNotify] = useState(false)

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        formik.setFieldError('content', '')
    };


    const handleChooseImage = (e) => {
        const files = e.currentTarget.files
        const fileArray = Array.from(files)

        const newImages = fileArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const newImagesForm = fileArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: item
            };
        });

        const customImage = [
            ...postImage,
            ...newImages
        ].slice(0, 4)

        const customImageForm = [
            ...formik.values.pictures,
            ...newImagesForm
        ].slice(0, 4)

        setPostImage(customImage);
        formik.setFieldValue('pictures', customImageForm)

        e.target.value = null
    }

    const handleDeletepostImage = (file) => {
        const handleFile = postImage.filter(item => item.id !== file)
        const handleFileForm = formik.values.pictures.filter(item => item.id !== file)

        setPostImage(handleFile)
        formik.setFieldValue('pictures', handleFileForm)
    }

    const handleClickImage = () => {
        imageRef.current.click()
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            pictures: '',
            content: '',
        },
        validate: values => {
            const errors = {}

            const isEditorState = !editorState.getCurrentContent().hasText()

            if (isEditorState) {
                errors.content = ERROR_REQUIRED
            }

            return errors
        },
        validationSchema: Yup.object({
            title: Yup.string().required(ERROR_REQUIRED),
            pictures: Yup.array().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            const contentState = editorState.getCurrentContent();
            const rawContent = convertToRaw(contentState);

            formData.append('title', values.title)
            formData.append('content', JSON.stringify(rawContent))

            for (let i = 0; i < values.pictures.length; i++) {
                const file = values.pictures[i].file;

                formData.append('posts', file)
            }

            const result = await dispatch(createNotice(formData))

            const res = result.payload

            if (res.status) {
                // navigate('/admin/notice')
                setIsNotify('')
                formik.resetForm()
            } else {
                setIsNotify(res.message)
            }

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }
    })

    return (
        <>
            <TitleSection title='Notice 추가하기' mt={false} />

            <form className='grid gap-5 p-5 rounded-24 bg-white justify-items-center' onSubmit={formik.handleSubmit}>
                <div className='w-full'>
                    <LabelFormThin label='제목' className='font-bold' />
                    <InputFormThin
                        name='title'
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        placehoder='제목을 입력하세요'
                        error={(formik.touched.title && formik.errors.title) && formik.errors.title}
                    />
                </div>

                <div className='w-full'>
                    <LabelFormThin label='내용' className='font-bold' />

                    <div>
                        <ContentForm editorState={editorState} setEditorState={onEditorStateChange} />

                        {
                            (formik.errors.content)
                            &&
                            <span className='text-12 text-red'>{formik.errors.content}</span>
                        }
                    </div>
                </div>

                <div className='w-full'>
                    <div className='flex justify-between items-center gap-5'>
                        <LabelFormThin label='첨부파일 (.jpg)' />
                        <span>{postImage.length}/4 사진</span>
                    </div>

                    <div className='grid grid-cols-4 gap-5'>
                        <input type='file' accept='image/jpg, image/jpeg' multiple hidden ref={imageRef} onChange={handleChooseImage} />

                        {
                            (postImage && (postImage.length > 0)) ?
                                <>
                                    {
                                        postImage.map(item => <ImageUploadItem key={item.id} id={item.id} image={item.file} handleDelete={handleDeletepostImage} />)
                                    }

                                    {postImage.length < 4 &&
                                        Array(4 - postImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickImage}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} />
                                    <ImageUploadItem handleSelect={handleClickImage} />
                                </>
                        }
                    </div>
                </div>

                <ButtonPrimary type='button' text='저장' className='w-[160px]' onClick={formik.handleSubmit} />
            </form>

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}
