import React from 'react'

export default function StepLetters({ stepUpload }) {
    return (
        <div className='grid grid-cols-5'>
            <StepItem title='편지지에 편지작성' step={1 <= stepUpload} isStep={stepUpload === 1} />
            <StepItem title='PDF 업로드 / 등기자료 추가' step={2 <= stepUpload} isStep={stepUpload === 2} />
            <StepItem title='주소 및 우편 선택' step={3 <= stepUpload} isStep={stepUpload === 3} />
            <StepItem title='작성된 편지 확인' step={4 <= stepUpload} isStep={stepUpload === 4} />
            <StepItem title='발송방법 선택 / 발송완료' step={5 <= stepUpload} isStep={stepUpload === 5} />
        </div>
    )
}

export const StepItem = ({ title, step, isStep }) => {
    return (
        <div className='flex flex-col items-center gap-5'>
            <div className={`relative h-[1px] w-full ${step ? (isStep ? 'bg-lightPink' : 'bg-logoColor') : 'bg-deactive'} 
            after:h-4 after:w-4 after:rounded-full ${step ? isStep ? 'after:bg-lightPink' : 'after:bg-logoColor' : 'after:bg-deactive'} after:block after:absolute 
            after:top-1/2 after:-translate-y-1/2 after:left-1/2 after:-translate-x-1/2`}
            ></div>

            <p className={`${step ? (isStep ? 'text-lightPink' : 'text-logoColor') : 'text-deactive'} px-2 text-center max-lg:text-12`}>{title}</p>
        </div>
    )
}
