import React from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterOrders from '../../sections/admin/orders/FilterOrders'
import ListOrder from '../../sections/admin/orders/ListOrder'

export default function OrdersAdmin() {
    return (
        <>
            <TitleSection title='주문내역' mt={false} />

            <FilterOrders />

            <ListOrder />
        </>
    )
}
