import React from 'react'
import { ButtonPrimaryLink } from '../ButtonPrimary'
import moment from 'moment/moment';

export default function PopupShowTimeNotify() {
    const now = moment();
    const day = now.day(); // 0 = Chủ Nhật, 1 = Thứ Hai, ..., 5 = Thứ Sáu, 6 = Thứ Bảy
    const hour = now.hour();

    let message;

    if ((day === 5 && hour >= 15) || day === 6 || (day === 0 && hour < 24)) {
        message = "월요일에 발송됩니다.";
    } else if (hour >= 15 && hour < 24) {
        // Từ 15:00 đến 24:00 các ngày trong tuần
        message = "내일 발송됩니다.";
    } else {
        // Từ 00:00 đến 15:00 các ngày trong tuần
        message = "오늘 발송됩니다.";
    }

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
            <div className='p-5 bg-white rounded-20 flex flex-col items-center gap-5 max-w-[600px] w-full'>
                <h3 className='text-24-30 font-semibold'>알림</h3>

                <p>{message}</p>

                <ButtonPrimaryLink link='/my-page' text='확인' className='w-[160px] justify-center' />
            </div>
        </div>
    )
}
