import React, { useState } from 'react'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { useDispatch } from 'react-redux'
import { sendOtp } from '../../reduxs/actions/auth.action'
import { renderError } from '../../utils/renderText'
import { useNavigate } from 'react-router-dom'
import { setAccountId } from '../../reduxs/reducers/auth.reducer'

export default function ForgotPassword() {
    const navigate = useNavigate('')
    const dispatch = useDispatch()

    const [isError, setIsError] = useState()

    const formik = useFormik({
        initialValues: {
            email: '',
            name: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(sendOtp(values))

            const res = result.payload

            if (!res.status) {
                setIsError(res.error)
            } else {
                dispatch(setAccountId({ accountId: res.data.account_id, ...values }))
                navigate('/auth/new-password/otp')
            }
        }
    })

    return (
        <form className='w-full flex flex-col items-center gap-10' onSubmit={formik.handleSubmit}>
            <div className='grid gap-4 w-full'>
                <div>
                    <LabelForm label='이메일' />
                    <InputForm
                        placehoder='이메일 주소를 입력하세요'
                        name='email'
                        onChange={(e) => {
                            formik.handleChange(e)
                            setIsError()
                        }}
                        value={formik.values.email}
                        error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                    />
                </div>

                <div>
                    <LabelForm label='이름' />
                    <InputForm
                        placehoder='이름을 입력 하세요'
                        name='name'
                        onChange={(e) => {
                            formik.handleChange(e)
                            setIsError()
                        }}
                        value={formik.values.name}
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>
            </div>

            {
                isError
                &&
                <p className='text-12 text-red'>{renderError(isError)}</p>
            }

            <ButtonPrimary text='비밀번호 찾기' className='w-[200px]' type='submit' onClick={formik.handleSubmit} />
        </form>
    )
}
