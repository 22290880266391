import React, { useContext, useEffect } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import SelectInforUser from '../../../components/user/SelectInforUser'
import InputFormThin from '../../../components/InputFormThin'
import LabelFormThin from '../../../components/LabelFormThin'
import RadioForm from '../../../components/RadioForm'
import { uploadLetterEventContext } from '../../../contexts/uploadLetterEventContext'
import SelectAddressUser from '../../../components/user/SelectAddressUser'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { useNavigate, useParams } from 'react-router-dom'

export default function AddressStep() {
    const navigate = useNavigate()

    const { formUpload, setFormUpload, handleStepOrder } = useContext(uploadLetterEventContext)

    const { letterId } = useParams()

    const formik = useFormik({
        initialValues: {
            recipient_name: '',
            recipient_phone: '',
            recipient_address: '',
            recipient_zip_code: '',
            note: ''
        },
        validationSchema: Yup.object({
            recipient_name: Yup.string().required(ERROR_REQUIRED),
            recipient_phone: Yup.string().required(ERROR_REQUIRED),
            recipient_address: Yup.string().required(ERROR_REQUIRED),
            recipient_zip_code: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: values => {
            setFormUpload({
                ...formUpload,
                recipient_name: values.recipient_name,
                recipient_phone: values.recipient_phone,
                recipient_address: values.recipient_address,
                recipient_zip_code: values.recipient_zip_code,
                note: values.note || ''
            })

            handleStepOrder('next')
        }
    })

    const handleChangeReceiver = (e) => {
        formik.handleChange(e)
        setFormUpload({
            ...formUpload,
            recipient_address_id: '',
        })
    }

    const handleSaveReceiver = () => {
        setFormUpload({
            ...formUpload,
            save_recipient: !(formUpload?.save_recipient),
            recipient_address_id: ''
        })
    }

    const handlePreStep = () => {
        navigate(`/products/${letterId}`)
    }

    useEffect(() => {
        if (formUpload.recipient_address_id) {
            formik.setValues({
                recipient_name: formUpload.recipient_name,
                recipient_phone: formUpload.recipient_phone,
                recipient_address: formUpload.recipient_address,
                recipient_zip_code: formUpload.recipient_zip_code
            })
        } else if (formUpload.recipient_name) {
            formik.setValues({
                recipient_name: formUpload.recipient_name,
                recipient_phone: formUpload.recipient_phone,
                recipient_address: formUpload.recipient_address,
                recipient_zip_code: formUpload.recipient_zip_code
            })
        }
    }, [formUpload.recipient_address_id])

    useEffect(() => {
        if (formUpload.note) {
            formik.setFieldValue(formUpload.note)
        }
    }, [formUpload.note])

    return (
        <>
            <TitleSection title='주소 입력' mt={false} />

            <section className='p-5 rounded-20 border border-strock flex flex-col items-center gap-5 w-full'>
                <div className='flex justify-end items-center w-full'>
                    <SelectInforUser
                        titleButton='저장된 수신자 주소'
                        title='저장된 수신자 주소'
                        typeId={2}
                        formUpload={formUpload}
                        setFormUpload={setFormUpload}
                    />
                </div>

                <div className='grid gap-5 w-full'>
                    <div>
                        <LabelFormThin label='받는 사람 이름' />
                        <InputFormThin
                            placehoder='내용을 입력하세요'
                            name='recipient_name'
                            value={formik.values.recipient_name}
                            onChange={handleChangeReceiver}
                            error={(formik.touched.recipient_name && formik.errors.recipient_name) && formik.errors.recipient_name}
                        />
                    </div>
                    <div>
                        <LabelFormThin label='휴대전화번호' />
                        <InputFormThin
                            placehoder='내용을 입력하세요'
                            name='recipient_phone'
                            value={formik.values.recipient_phone}
                            onChange={handleChangeReceiver}
                            error={(formik.touched.recipient_phone && formik.errors.recipient_phone) && formik.errors.recipient_phone}
                        />
                    </div>
                    <div>
                        <LabelFormThin label='받는 사람 주소' />

                        <div className='flex gap-2.5 items-start'>
                            <InputFormThin
                                placehoder='내용을 입력하세요'
                                name='recipient_address'
                                value={formik.values.recipient_address}
                                onChange={handleChangeReceiver}
                                classNameAll='w-full'
                                error={(formik.touched.recipient_address && formik.errors.recipient_address) && formik.errors.recipient_address}
                            />
                            <SelectAddressUser buttonTitle='일반주소검색' formik={formik} target='recipient_address' zipcode='recipient_zip_code' />
                        </div>
                    </div>
                    <div>
                        <LabelFormThin label='우편번호' />
                        <InputFormThin
                            placehoder='내용을 입력하세요'
                            name='recipient_zip_code'
                            value={formik.values.recipient_zip_code}
                            onChange={handleChangeReceiver}
                            error={(formik.touched.recipient_zip_code && formik.errors.recipient_zip_code) && formik.errors.recipient_zip_code}
                        />
                    </div>
                </div>

                <RadioForm
                    content='저장 [저장시 자동으로 주소를 불러옴]'
                    onChange={handleSaveReceiver}
                    isCheck={formUpload?.save_recipient}
                    type='checkbox'
                />
            </section>
            {/* 
            <section className='w-full'>
                <TitleSection title='고객 메모 (필수 없음)' small />

                <TextareaForm name='note' value={formik.values.note} onChange={formik.handleChange} placeholder='메모를 입력해 주세요....' />
            </section> */}

            <div className='flex gap-2.5 w-full justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handlePreStep} />
                <ButtonPrimary text='결제하기' className='w-[160px] justify-center' onClick={formik.handleSubmit} />
            </div>
        </>
    )
}
