import { createSlice } from "@reduxjs/toolkit";
import { deleteAccount, getGenders, getInforUser, getInforUserUpdate, getMypageInfor, login, register, resetPassword, sendOtp, updateInforUser } from "../actions/auth.action";
import { clearLocalStorage, getLocalStorage } from "../../utils/storage";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accountId: '',
        name: '',
        email: '',

        isAuthentication: false,
        loading: true,
        showNotifyNotLogin: false,

        loadingLogin: false,

        loadingRegister: false,

        loadingSendOtp: false,

        loadingGetGender: false,
        dataGender: undefined,

        loadingGetInforUser: true,
        dataGetAccount: undefined,

        loadingGetMypage: false,
        dataGetMypage: undefined,

        loadingResetPassword: false,

        loadingGetInforUserUpdate: false,
        dataInforUserUpdate: undefined,

        loadingUpdateInforUser: false
    },
    reducers: {
        checkAuth: (state, action) => {
            const token = getLocalStorage('token')

            state.isAuthentication = token ? true : false
            state.loading = false
        },
        logoutAuth: (state, action) => {
            clearLocalStorage()
            state.isAuthentication = false
        },
        setAccountId: (state, action) => {
            const { accountId, email, name } = action.payload

            state.accountId = accountId
            state.name = name
            state.email = email
        },
        clearAccountId: (state, action) => {
            state.accountId = ''
        },
        setIsShowNotifyNotLogin: (state, action) => {
            state.showNotifyNotLogin = !state.showNotifyNotLogin
        },
    },
    extraReducers: builder => {
        // login
        builder.addCase(login.pending, (state, action) => {
            state.loadingLogin = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.isAuthentication = true
            }

            state.loadingLogin = false
        })

        // register
        builder.addCase(register.pending, (state, action) => {
            state.loadingRegister = true
        })
        builder.addCase(register.fulfilled, (state, action) => {
            state.loadingRegister = false
        })

        // send otp
        builder.addCase(sendOtp.pending, (state, action) => {
            state.loadingSendOtp = true
        })
        builder.addCase(sendOtp.fulfilled, (state, action) => {
            state.loadingSendOtp = false
        })

        // reset password
        builder.addCase(resetPassword.pending, (state, action) => {
            state.loadingResetPassword = true
        })
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.loadingResetPassword = false
        })

        // get gender
        builder.addCase(getGenders.pending, (state, action) => {
            state.loadingGetGender = true
        })
        builder.addCase(getGenders.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataGender = res.data
            }

            state.loadingGetGender = false
        })

        // get infor user
        builder.addCase(getInforUser.pending, (state, action) => {
            state.loadingGetInforUser = true
        })
        builder.addCase(getInforUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataGetAccount = res.data
            }

            state.loadingGetInforUser = false
        })

        // get infor user update
        builder.addCase(getInforUserUpdate.pending, (state, action) => {
            state.loadingGetInforUserUpdate = true
        })
        builder.addCase(getInforUserUpdate.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataInforUserUpdate = res.data
            }

            state.loadingGetInforUserUpdate = false
        })

        // update infor user
        builder.addCase(updateInforUser.pending, (state, action) => {
            state.loadingUpdateInforUser = true
        })
        builder.addCase(updateInforUser.fulfilled, (state, action) => {
            // const res = action.payload

            // if (res.status) {
            //     console.log(res)
            // }

            state.loadingUpdateInforUser = false
        })

        // get my page
        builder.addCase(getMypageInfor.pending, (state, action) => {
            state.loadingGetMypage = true
        })
        builder.addCase(getMypageInfor.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataGetMypage = res.data
            }

            state.loadingGetMypage = false
        })

        // get my page
        builder.addCase(deleteAccount.pending, (state, action) => {
            state.loadingDeleteAccount = true
        })
        builder.addCase(deleteAccount.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.isAuthentication = false
            }

            state.loadingDeleteAccount = false
        })
    }
})

export const { checkAuth, logoutAuth, setAccountId, clearAccountId, setIsShowNotifyNotLogin } = authSlice.actions
export default authSlice.reducer
