import React, { useEffect, useRef, useState } from 'react'
import ButtonSecon from '../ButtonSecon'
import ButtonPrimary from '../ButtonPrimary'
import { SearchNormal1 } from 'iconsax-react'
import Dropdown from '../admin/Dropdown'
import RadioForm from '../RadioForm'
import { useDispatch, useSelector } from 'react-redux'
import { getGaoladdress, searchGaolAddress } from '../../reduxs/actions/gaoIaddress.action'

const listSearch = [
    {
        id: 1,
        name: '지방/고등법원'
    },
    {
        id: 2,
        name: '구치소/교도소'
    },
]

export default function SearchOrganization({ formUpload, setFormUpload }) {
    const dispatch = useDispatch()

    const { dataGaol } = useSelector(state => state.gaol)

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)
    const [selected, setSelected] = useState()

    const [typeId, setTypeId] = useState(1)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const handleSearch = (e) => {
        e.preventDefault()
        const content = e.target.value

        dispatch(searchGaolAddress({ type: typeId, text: content }))
    }

    const handleChooseFilter = (values) => {
        setTypeId(values)
    }

    const handleSelect = (values) => {
        setSelected(values)
    }

    /// set gaol infor thieuuuu
    const handleConfirm = () => {
        handleShow()

        setFormUpload({
            ...formUpload,
            receiver_name: selected.name,
            receiver_phone: '',
            receiver_address: selected.address,
            receiver_zip: selected.zip_code,
            organization: selected.id
        })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    useEffect(() => {
        dispatch(getGaoladdress(typeId))
    }, [])

    return (
        <>
            <ButtonSecon title='기관주소검색' className='flex-shrink-0' onClick={handleShow} />

            {
                isShow
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div ref={contentRef} className='p-5 rounded-20 bg-white w-[600px] h-[600px] overflow-y-auto hiddenScroll flex flex-col gap-5 mx-5'>
                        <h4 className='text-center text-24-30 font-bold'>기관주소검색</h4>

                        <div className='flex gap-2.5'>
                            <Dropdown
                                title='선택'
                                classnameAll='flex-shrink-0 h-full'
                                className='h-full w-[160px]'
                                data={listSearch}
                                name='name'
                                handleChoose={handleChooseFilter}
                                isChoose={typeId}
                            />

                            <form className='relative w-full' onSubmit={handleSearch}>
                                <input
                                    type='text'
                                    className='w-full rounded-[10px] border border-strock py-2.5 px-4'
                                    placeholder='주소 입력'
                                    onChange={handleSearch}
                                />
                                <button type='button' onClick={handleSearch} className='absolute top-1/2 -translate-y-1/2 right-4 text-deactive'><SearchNormal1 size={16} /></button>
                            </form>
                        </div>

                        <table className='w-full text-14_20 text-center h-full'>
                            <thead>
                                <tr className='border-b border-strock'>
                                    <th className='lg:pb-5 pb-2.5 w-6'></th>
                                    <th className='lg:pb-5 pb-2.5 w-[226px]'>법원 이름</th>
                                    <th className='lg:pb-5 pb-2.5 w-[260px]'>주소</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataGaol?.length
                                        ?
                                        <>
                                            {
                                                dataGaol.map(item =>
                                                    <SearchAddressItem
                                                        key={item.id}
                                                        id={item.id}
                                                        item={item}
                                                        name={item.name}
                                                        address={item.address}
                                                        handleSelect={handleSelect}
                                                        isCheck={item.id === Number(selected?.id)}
                                                    />)
                                            }
                                        </>
                                        : ''
                                }
                            </tbody>
                        </table>

                        <div className='flex gap-2.5 w-full justify-center'>
                            <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={handleShow} />
                            <ButtonPrimary text='확인' className='w-[160px] justify-center' onClick={handleConfirm} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const SearchAddressItem = ({ id, name, address, handleSelect, isCheck, item }) => {
    return (
        <tr className='border-b border-strock last:border-none'>
            <td className='py-5'>
                <RadioForm onChange={() => handleSelect(item)} name='gaol' isCheck={isCheck} />
            </td>
            <td className='py-5'>
                <p>{name}</p>
            </td>
            <td className='py-5'>
                <p className='line-clamp-1 '>{address}</p>
            </td>
        </tr>
    )
}
