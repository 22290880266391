import React, { useRef } from 'react'

export default function RadioForm({ content, name, value, onChange, isCheck = false, type = 'radio' }) {
    const inputRef = useRef()

    const handleClick = () => {
        inputRef.current.click()
    }

    return (
        <div className='flex items-center gap-1 cursor-pointer' onClick={handleClick}>
            <input type={type} hidden name={name} onChange={onChange} value={value} ref={inputRef} />

            <div className={`w-4 h-4 p-0.5 rounded-full border ${isCheck ? 'border-logoColor' : 'border-black'}`}>
                {
                    isCheck
                    &&
                    <div className='w-full h-full rounded-full bg-logoColor'></div>
                }
            </div>

            <p className='max-lg:text-14_20'>{content}</p>
        </div>
    )
}
