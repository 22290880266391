import { More } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

export default function DropdownTable({ linkEdit, onclickDelete }) {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div className='relative' ref={contentRef}>
            <button type='button' className='rotate-90 flex items-center justify-center' onClick={handleShow}><More size={16} /></button>

            {
                isShow
                &&
                <div className='absolute top-full right-0 mt-1 bg-white shadow-setting w-[160px] rounded-12 z-10'>
                    <ul>
                        {
                            linkEdit
                            &&
                            <li>
                                <Link to={linkEdit} className='py-2.5 px-4 block hover:text-logoColor duration-200'>수정</Link>
                            </li>
                        }

                        <li>
                            <button className='py-2.5 px-4 hover:text-logoColor duration-200' onClick={onclickDelete}>삭제</button>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}
