import React from 'react'

export default function ErrorPage() {
    return (
        <div className='text-center '>
            <h1>Oops! Đã xảy ra lỗi.</h1>
            <p>Chúng tôi không thể tải trang. Vui lòng thử lại sau.</p>
        </div>
    )
}
