import React, { useEffect, useState } from 'react'
import Pagination from '../../../components/Pagination'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { deletePointsAdmin, getPointsAdmin } from '../../../reduxs/actions/point.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import DropdownTable from '../../../components/admin/DropdownTable'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import EmptySection from '../EmptySection'
import { formatMoney } from '../../../utils/renderText'

export default function ListPointSection() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetPoints, dataPoists, pointCount } = useSelector(state => state.point)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(pointCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getPointsAdmin(page))
    }, [page])

    if (loadingGetPoints || !dataPoists) {
        return <LoadingUser />
    }

    return (
        <>

            {
                dataPoists.length
                    ?
                    <section className='bg-white rounded-24 px-5 '>
                        <table className='w-full text-center'>
                            <thead>
                                <tr className='border-b border-strock'>
                                    <th className='py-5 px-2 w-[300px]'>상품명</th>
                                    <th className='py-5 px-2 w-[300px]'>전철기</th>
                                    <th className='py-5 px-2 w-[300px]'>판매수량</th>
                                    <th className='py-5 px-2 w-[300px]'>추가된 날짜</th>
                                    <th className='py-5 px-2 w-[300px]'>가격</th>
                                    <th className='py-5 px-2 w-5'></th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    dataPoists?.map(item =>
                                        <RowPointItem
                                            key={item.id}
                                            id={item.id}
                                            name={item.name}
                                            point={item.point}
                                            created_at={item.created_at}
                                            price={item.price}
                                            quantity={item.quantity}
                                        />)
                                }
                            </tbody>
                        </table>

                        <Pagination totalCount={pointCount} currentPage={page} handlePage={handlePage} />
                    </section>
                    :
                    <EmptySection />
            }
        </>
    )
}

const RowPointItem = ({ id, created_at, name, quantity, price, point }) => {
    const dispatch = useDispatch()

    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isNotify, setIsNotify] = useState(false)

    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(deletePointsAdmin(id))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <p>{name}</p>
            </td>
            <td className='py-5'>{point}포인트</td>
            <td className='py-5'>{quantity}</td>
            <td className='py-5'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5'>₩{formatMoney(price)}원</td>
            <td className='py-5'>
                <DropdownTable onclickDelete={handleShowDelete} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 상품을 삭제하시겠습니까?</p>
                                <p>삭제된 이 상품은 복구할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowDelete} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleConfirmDelete} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}
