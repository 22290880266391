import React, { useEffect, useRef, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import RatingReview from '../../components/admin/RatingReview'
import { AttachSquare, CloseSquare } from 'iconsax-react'
import TextareaForm from '../../components/TextareaForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { getCommentByOrder, postCommentOrder } from '../../reduxs/actions/comment.action'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUser from './LoadingUser'
import { URL_SERVER } from '../../utils/apis'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import { formatMoney } from '../../utils/renderText'

export default function CommentPage() {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const inputRef = useRef()

    const { orderId } = useParams()

    const { loadingGetComment, dataComment } = useSelector(state => state.comment)

    const [fileUpload, setFileUpload] = useState([])
    const [isNotifycation, setIsNotifycation] = useState()

    const handleClickInput = () => {
        inputRef.current.click()
    }

    const formik = useFormik({
        initialValues: {
            content: '',
            files: '',
            mark: 1
        },
        validationSchema: Yup.object({
            content: Yup.string().required(ERROR_REQUIRED),
            // files: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const formData = new FormData()

            for (let i = 0; i < values.files.length; i++) {
                const file = values.files[i];
                formData.append(`evaluate_picture`, file.file)
            }

            formData.append('content', values.content)
            formData.append('order_id', orderId)
            formData.append('mark', values.mark)

            const result = await dispatch(postCommentOrder(formData))

            const res = result.payload

            if (res.status) {
                setIsNotifycation('평가가 확인되었습니다')

                setTimeout(() => {
                    navigate('/my-page')
                }, 3000);
            }
            else {
                setIsNotifycation('실패한 평가')

                setTimeout(() => {
                    setIsNotifycation()
                }, 3000);
            }
        }
    })

    const handleChangeMark = (mark) => {
        formik.setFieldValue('mark', mark)
    }

    const handleChange = (e) => {
        const files = e.target.files

        const filesArray = Array.from(files)

        const handleFiles = filesArray.map((item, index) => {
            return {
                id: index,
                file: URL.createObjectURL(item)
            }
        })

        const filesNormal = filesArray.map((item, index) => {
            return {
                id: index,
                file: item
            }
        })

        setFileUpload(handleFiles)

        formik.setFieldValue('files', filesNormal)

        e.target.value = null
    }

    const handleDeleteFile = (fileId) => {
        const handleDeleteFile = formik.values.files.filter(item => item.id !== fileId)
        const handleFileNormal = fileUpload.filter(item => item.id !== fileId)

        formik.setFieldValue('files', handleDeleteFile)
        setFileUpload(handleFileNormal)
    }

    useEffect(() => {
        dispatch(getCommentByOrder(orderId))
    }, [orderId])

    if (loadingGetComment || !dataComment) {
        return <LoadingUser />
    }

    const { bonuspoint, filename, product_name } = dataComment

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col items-center lg:gap-10 gap-6 container max-lg:px-6'>
            <TitlePage title='리뷰작성하기' />

            <div>
                <img src={`${URL_SERVER}${filename}`} className='aspect-[7.08/10] object-cover lg:w-[300px] w-[100px]' />
            </div>

            <section className='w-full flex flex-col gap-2.5'>
                <div className='flex flex-col gap-2.5'>
                    <div className='flex justify-between'>
                        <p className='font-bold'>제품명: <span className='font-normal'>{product_name}</span></p>

                        <div className='flex gap-2 items-center justify-between'>
                            <p className='w-20'>총액:</p>
                            <span className='text-logoColor font-bold'>{formatMoney(bonuspoint)}원</span>
                        </div>
                    </div>

                    <div className='flex justify-between gap-2.5'>
                        <div className='flex items-center gap-2.5'>
                            <p>제품 품질</p>

                            <RatingReview numberChoose={formik.values.mark} handleChoose={handleChangeMark} onlyView={false} />
                        </div>
                        <div className='cursor-pointer' onClick={handleClickInput}>
                            <input name='files' type='file' accept="image/*" multiple hidden ref={inputRef} onChange={handleChange} />
                            <AttachSquare className='strock1' size={24} />
                        </div>
                    </div>
                </div>

                <div>
                    <TextareaForm
                        placeholder='판매수량'
                        onChange={formik.handleChange}
                        name='content'
                        value={formik.values.content}
                        error={(formik.touched.content && formik.errors.content) && formik.errors.content}
                    />

                    <div className='grid lg:grid-cols-8 grid-cols-3 gap-2.5'>
                        {
                            fileUpload.map(item =>
                                <ImageUploadItem
                                    key={item.id}
                                    id={item.id}
                                    image={item.file}
                                    handleDeleteFile={handleDeleteFile}
                                />)
                        }
                    </div>
                </div>
            </section>

            <ButtonPrimary text='확인' className='w-[160px]' onClick={formik.handleSubmit} />

            <div className={`${isNotifycation ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotifycation}
            </div>
        </main>
    )
}

const ImageUploadItem = ({ image, id, handleDeleteFile }) => {
    return (
        <div className='relative'>
            <img src={image} alt='' className='aspect-square rounded-20 object-cover' />

            <button className='absolute top-0 right-0 p-2.5 text-white hover:text-logoColor duration-300' type='button' onClick={() => handleDeleteFile(id)}><CloseSquare className='' size={16} /></button>
        </div>
    )
}
