import React, { useEffect, useState } from 'react'
import LettersItemPopup from '../../../components/user/LettersItemPopup'
import { useDispatch, useSelector } from 'react-redux'
import { getProductsByType } from '../../../reduxs/actions/product.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { formatMoney } from '../../../utils/renderText'

export default function ListMaterial(props) {
    const { oldMaterial, materialData, setMaterialdata, setMaterialDataDelete, materialDataDelete, materialDataUpdate, setMaterialDataUpdate } = props

    const dispatch = useDispatch()

    const { loadingProductByType, dataProductByType } = useSelector(state => state.product)

    const [pageCount, setPageCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0)

    const handleChooseLetter = (value) => {
        const checkOldMaterial = oldMaterial.find(check => value.id === check.product_id)
        const checkMaterialUpdate = materialDataUpdate.find(check => value.id === check.id)
        const checkMaterialChoose = materialData.find(check => value.id === check.id)

        if (checkOldMaterial) {   // Nếu có sẽ delete
            const checkDeleteId = materialDataDelete.find(check => value.id === check)

            if (checkDeleteId) {
                const deleteMaterialId = materialDataDelete.filter(item => item !== value.id)

                setMaterialDataDelete(deleteMaterialId)
            } else {
                setMaterialDataDelete(pre => [...pre, value.id])
            }
        } else {                  // Nếu ko có sẽ update
            if (!checkMaterialUpdate && !checkMaterialChoose.isCheck) {
                setMaterialDataUpdate(pre => [...pre, value])
            } else {
                const handleMaterial = materialDataUpdate.filter(pre => pre.id !== value.id)
                setMaterialDataUpdate(handleMaterial)
            }
        }

        const updateLetterUpload = materialData.map(item => {
            if (item.id === value.id) {
                return {
                    ...item,
                    isCheck: !item.isCheck
                }
            }

            return item
        })

        setMaterialdata(updateLetterUpload)
    }

    useEffect(() => {
        if (!dataProductByType) {
            dispatch(getProductsByType(3))
        }
    }, [])

    useEffect(() => {
        if (dataProductByType && !materialData.some(obj => obj.hasOwnProperty('isCheck'))) {
            const handleData = dataProductByType.map(item => {
                const isCheck = oldMaterial.find(material => item.id === material.product_id)

                return {
                    ...item,
                    isCheck: !!isCheck
                }
            })

            setMaterialdata(handleData)
        }
    }, [dataProductByType])

    useEffect(() => {
        if (materialData) {
            let totalprice = 0
            let totalPage = 0

            materialData.map(item => {
                if (item.isCheck) {
                    totalprice += item.price
                    totalPage += 1
                }
            })

            setPageCount(totalPage)
            setTotalPrice(totalprice)
        }
    }, [materialData])

    if (loadingProductByType || !dataProductByType) {
        return <LoadingUser />
    }

    return (
        <>
            <div className='grid lg:grid-cols-4 grid-cols-3 gap-5'>
                {
                    materialData
                    &&
                    materialData.map(item =>
                        <LettersItemPopup
                            key={item.id}
                            id={item.id}
                            item={item}
                            frontThumbnail={item.frontThumbnail}
                            name={item.name}
                            price={item.price}
                            handleChoose={handleChooseLetter}
                            isCheck={item.isCheck}
                        />)
                }
            </div>

            {/* <button className='flex items-center gap-1 font-semibold max-lg:text-14_20'>
                더 보기
                <ArrowDown2 size={16} />
            </button> */}

            <div className='flex gap-5 justify-end w-full text-logoColor max-lg:text-14_20'>
                <p>총 {pageCount}페이지</p>
                <p>금액: <span className='font-semibold'>{formatMoney(totalPrice)}원</span></p>
            </div>
        </>
    )
}
