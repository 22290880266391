import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import FilterBlacklist from '../../sections/admin/users/FilterBlacklist'
import ListBlacklistUser from '../../sections/admin/users/ListBlacklistUser'
import { Add } from 'iconsax-react'
import ListPhone from '../../sections/admin/users/ListPhone'
import LabelFormThin from '../../components/LabelFormThin'
import InputFormThin from '../../components/InputFormThin'
import ButtonPrimary from '../../components/ButtonPrimary'
import ButtonSecon from '../../components/ButtonSecon'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_PHONE_FORMAT, ERROR_REQUIRED } from '../../utils/error'
import { usePhoneNumberFormik } from '../../hooks/formatNumber'
import { REGEX_PHONE_NUMBER } from '../../utils/regex'
import { useDispatch } from 'react-redux'
import { addPhoneToBlacklist } from '../../reduxs/actions/user.action'
import ListEmail from '../../sections/admin/users/ListEmail'


export default function BlacklistuserAdmin() {
    const dispatch = useDispatch()

    const [isShowAddPhone, setIsShowAddPhone] = useState(false)
    const [isNotifycation, setIsNotifycation] = useState(false)

    const handleShowPopup = () => {
        setIsShowAddPhone(!isShowAddPhone)
    }

    const formik = useFormik({
        initialValues: {
            phone_number: ''
        },
        validationSchema: Yup.object({
            phone_number: Yup.string().matches(REGEX_PHONE_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(addPhoneToBlacklist(values))

            const res = result.payload

            if (res.status) {
                setIsNotifycation('추가된 전화번호')

                setTimeout(() => {
                    setIsNotifycation()
                }, 3000);

                handleShowPopup()
            } else {
                if (res.error === 'e_phone') formik.setFieldError('phone_number', '전화번호가 존재했습니다')
            }
        }
    })

    const [phoneNumber, handlePhoneNumberChange] = usePhoneNumberFormik(formik, 'phone_number')

    return (
        <>
            <TitleSection title='블랙리스트' mt={false} />

            <section className='grid gap-5'>
                <FilterBlacklist />
                <ListBlacklistUser />
            </section>

            <section>
                <div className='flex justify-between items-center'>
                    <TitleSection title='차단된 연락처' small />

                    <button className='flex items-center gap-1' onClick={handleShowPopup}><Add size={20} /> <span>연락처 추가</span></button>
                </div>

                <ListPhone />

                {
                    isShowAddPhone
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <form onSubmit={formik.handleSubmit} className='w-[600px] bg-white rounded-20 p-5 flex flex-col gap-5 items-center'>
                            <h3 className='text-24-30 font-semibold'>연락처 추가</h3>

                            <div className='w-full'>
                                <LabelFormThin label='연락처' />
                                <InputFormThin
                                    name='phone_number'
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    placehoder='000-0000-0000'
                                    error={(formik.touched.phone_number && formik.errors.phone_number) && formik.errors.phone_number}
                                />
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' className='w-[160px]' onClick={handleShowPopup} />
                                <ButtonPrimary text='확인' className='w-[160px]' type='sumbit' onClick={formik.handleSubmit} />
                            </div>
                        </form>
                    </div>
                }

                <div className={`${isNotifycation ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotifycation}
                </div>
            </section>

            <ListEmail />
        </>
    )
}
