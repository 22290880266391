import React, { useState } from 'react'
import ButtonSecon from '../../../components/ButtonSecon'
import TitleSection from '../../../components/user/TitleSection'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import { useFormik } from 'formik'
import PopupImage from '../../../components/admin/PopupImage'
import ButtonPrimary from '../../../components/ButtonPrimary'
import * as Yup from 'yup'
import { ERROR_FORMAT_IMAGE, ERROR_MAX_IMAGE, ERROR_REQUIRED } from '../../../utils/error'
import { useDispatch } from 'react-redux'
import { createAdvert } from '../../../reduxs/actions/adverts.action'
import { encodeFilename } from '../../../utils/renderText'
import Dropdown from '../../../components/admin/Dropdown'

export const positions = [
    {
        id: 1,
        position: '왼쪽 위'
    },
    {
        id: 2,
        position: '오른쪽 위'
    },
    {
        id: 3,
        position: '가운데 위'
    },
    {
        id: 4,
        position: '가운데'
    },
    {
        id: 5,
        position: '오른쪽 아래'
    },
    {
        id: 6,
        position: '가운데 아래'
    },
]

export default function CreatePopup() {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <div>
            <ButtonSecon title='팝업 추가하기' className='w-fit' onClick={handleShow} />

            {
                isShow
                &&
                <PopupCreateAdvert handleShow={handleShow} />
            }
        </div>
    )
}

const PopupCreateAdvert = (props, ref) => {
    const { handleShow } = props

    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)

    const handleChangeFile = (file) => {
        formik.setFieldValue('advert_picture', file)
    }

    const handleDeleteImage = () => {
        formik.setFieldValue('advert_picture', '')
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            advert_picture: '',
            position: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(ERROR_REQUIRED),
            position: Yup.string().required(ERROR_REQUIRED),
            advert_picture: Yup.mixed().required(ERROR_REQUIRED).test('fileFormat', ERROR_FORMAT_IMAGE, value => {
                if (value) {
                    const supportedFormats = ['jpg', 'png'];
                    return supportedFormats.includes(value.name.split('.').pop());
                }

                return true
            }).test('fileSize', ERROR_MAX_IMAGE, value => {
                if (value) {
                    return value.size <= 10485760
                }

                return true
            }),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            const positionName = positions.find(item => item.id === values.position).position

            const encodedFilename = encodeFilename(values.advert_picture.name)
            const file = new File([values.advert_picture], encodedFilename, { type: values.advert_picture.type })

            formData.append('name', values.name)
            formData.append('advert_picture', file)
            formData.append('position', positionName)

            const result = await dispatch(createAdvert(formData))

            const res = result.payload

            if (res.status) {
                setIsNotify('콘텐츠가 삭제되었습니다.')
                formik.resetForm()
            } else {
                setIsNotify(res.message)
            }

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }
    })

    const handleShoosePosition = (positionId) => {
        formik.setFieldValue('position', positionId)
    }

    return (
        <div className='fixed inset-0 bg-popup flex justify-center items-center'>
            <div className='w-popup p-5 rounded-24 bg-white flex flex-col items-center gap-5'>
                <TitleSection title='팝업 추가하기' mt={false} />

                <div className='w-full'>
                    <LabelFormThin label='팝업명' className='font-bold' />
                    <InputFormThin
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placehoder='제목을 입력하세요'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>

                <div className='w-full'>
                    <LabelFormThin label='팝업명' className='font-bold' />
                    <Dropdown
                        title='표시 위치'
                        classnameAll='w-full'
                        className='w-full'
                        data={positions}
                        name='position'
                        handleChoose={handleShoosePosition}
                        isChoose={formik.values.position}
                    />

                    {
                        (formik.touched.position && formik.errors.position)
                        &&
                        <span className='text-red text-12'>{formik.errors.position}</span>
                    }
                </div>

                <div className='w-full'>
                    <LabelFormThin label='팝업 이미지' className='font-bold' />
                    <PopupImage
                        onChange={handleChangeFile}
                        image={formik.values.advert_picture}
                        handleDelete={handleDeleteImage}
                        error={(formik.touched.advert_picture && formik.errors.advert_picture) && formik.errors.advert_picture}
                    />
                </div>

                <div className='flex gap-2.5'>
                    <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShow} />
                    <ButtonPrimary text='확인' className='w-[160px]' type='submit' onClick={formik.handleSubmit} />
                </div>
            </div>

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </div>
    )
}
