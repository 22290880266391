import React, { useState } from 'react'
import ShippingItem from '../../../components/admin/ShippingItem'
import { useFormik } from 'formik'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { useDispatch } from 'react-redux'
import { updateLimitPdfAdmin } from '../../../reduxs/actions/management.action'

export default function PdfPageOption({ data }) {
    const dispatch = useDispatch()
    const [isShowPopup, setIsShowPopup] = useState(false)

    const formik = useFormik({
        initialValues: {
            limited: '',
            pdfId: ''
        },
        validationSchema: Yup.object({
            limited: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(updateLimitPdfAdmin(values))

            const res = result.payload

            if (res.status) {
                handleShowPopup()
            } else {
                formik.setFieldError('price', res.message)
            }
        }
    })

    const handleShowPopup = (id) => {
        if (isShowPopup) formik.resetForm()

        setIsShowPopup(!isShowPopup)
        formik.setFieldValue('pdfId', formik.values.pdfId ? '' : id)
    }

    return (
        <>
            {
                data.map(item =>
                    <ShippingItem
                        key={item.id}
                        name={item.name}
                        title='PDF 장수제한 변경'
                        price={item.limited}
                        onClick={() => handleShowPopup(item.id)}
                        page
                    />)
            }

            {
                (isShowPopup && formik.values.pdfId)
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                    <form onSubmit={formik.handleSubmit} className='w-[600px] bg-white rounded-20 flex flex-col items-center gap-5 p-5'>
                        <h3 className='text-24-30 font-semibold'>편집</h3>

                        <div className='w-full'>
                            <LabelFormThin label='장수' />
                            <InputFormThin
                                type='number'
                                name='limited'
                                value={formik.values.limited}
                                onChange={formik.handleChange}
                                placehoder='가격을 입력하세요'
                                error={(formik.touched.limited && formik.errors.limited) && formik.errors.limited}
                            />
                        </div>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }
        </>
    )
}
