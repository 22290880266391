import React from 'react'
import InputFormThin from '../../components/InputFormThin'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import { updateShipmentCode } from '../../reduxs/actions/admin.action'
import { useDispatch } from 'react-redux'

export default function UpdateDeliveriOrder({ orderId, currentCode }) {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            shipment_code: currentCode,
            order_id: orderId
        },
        enableReinitialize: true,
        onSubmit: values => {
            dispatch(updateShipmentCode(values))
        }
    })

    return (
        <form className='flex gap-5 items-center' onSubmit={formik.handleSubmit}>
            <p className='w-[130px] flex-shrink-0 text-start font-semibold'>송장번호:</p>
            <div className='flex w-full gap-2.5'>
                <InputFormThin
                    name='shipment_code'
                    value={formik.values.shipment_code}
                    onChange={formik.handleChange}
                    placehoder='송장번호를 입력하세요'
                    classNameAll='w-9/12'
                />
                <ButtonPrimary text='저장' className='w-3/12' type='submit' />
            </div>
        </form>
    )
}
