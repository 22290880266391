import React from 'react'
import { ButtonPrimaryLink } from '../ButtonPrimary'
import { URL_SERVER } from '../../utils/apis'
import { formatMoney } from '../../utils/renderText'

export default function VoucherItem({ id, name, price, image }) {
    return (
        <div className='p-5 border border-strock rounded-20'>
            <p className='mb-1 font-semibold max-lg:text-14_20'>상품권</p>

            <div className='relative mb-5 flex flex-col items-center'>
                <img src={`${URL_SERVER}${image}`} alt={`${name}`} className='w-full max-lg:w-[200px] aspect-square object-cover' />
                <p className='text-logoColor absolute bottom-0 left-1/2 -translate-x-1/2 whitespace-nowrap'>{name}</p>
            </div>

            <div className='w-full flex justify-between items-center'>
                <p className='font-semibold text-logoColor max-lg:text-14_20'>₩{formatMoney(price)}</p>

                <ButtonPrimaryLink link={`/voucher-payment/${id}`} text='결제하기' className='!py-1 !px-3 bg-pink text-14_20' />
            </div>
        </div>
    )
}
