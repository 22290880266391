export const renderError = (error) => {
    switch (error) {
        case 'e_email':
            return '계정이 존재했습니다'
        case 'blocked_email':
            return '이메일이 차단되었습니다'
        case 'e_phone':
            return '전화번호가 존재했습니다'
        case 'blocked_phone':
            return '전화번호가 차단되었습니다'
        case 'accphone_blaclist':
            return '이 전화번호는 블랙리스트에 추가되어 있는 계좌에 있습니다'
        case 'f_expire':
            return '이 전화번호은 69일 후에 사용할 수 있습니다'
        case '404_email':
            return '계정이 존재하지 않습니다'
        case 'f_pw':
            return '계정 정보가 올바르지 않습니다'
        case '404_acc':
            return '계정을 찾을 수 없습니다'
        case 'e_otp':
            return 'OTP가 이미 존재합니다'
        case '404_otp':
            return 'OTP를 찾을 수 없습니다'
        case 'f_otp':
            return 'OTP가 유효하지 않습니다'
        case 'expired_otp':
            return 'OPT가 만료되었습니다. 다시 OTP를 보내주시기 바랍니다'
        case 'required_data':
            return '입력 데이터가 누락되었습니다.'
        default:
            break;
    }
}

export const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US').format(amount);
};

// encode Filename
export const encodeFilename = (filename) => {
    return encodeURIComponent(filename);
};


export const renderPosition = (position) => {
    switch (position) {
        case '왼쪽 위':
            return 'top-0 left-0'
        case '오른쪽 위':
            return 'top-0 right-0'
        case '가운데 위':
            return 'top-0 right-1/2 translate-x-1/2'
        case '가운데':
            return 'top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2'
        case '오른쪽 아래':
            return 'bottom-0 right-0'
        case '가운데 아래':
            return 'bottom-0 left-0'
        default:
            break;
    }
}


export const loadImageAsBase64 = async (url) => {
    const response = await fetch(url)
    const blob = await response.blob()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]
            resolve(base64String)
        };
        reader.onerror = reject
        reader.readAsDataURL(blob)
    });
};