import React, { useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { ArrowDown2, AttachSquare, Send } from 'iconsax-react'
import RadioForm from '../../../components/RadioForm'
import CommentItem from '../../../components/user/CommentItem'
import { useSelector } from 'react-redux'

export default function CommentSection({ onlyView = true, image = false, title = '리뷰', data, formik, name, handleReply, onChange }) {
    const inputRef = useRef()

    const { isAuthentication } = useSelector(state => state.auth)

    const handleClickReply = (data) => {
        handleReply(data)

        inputRef.current.focus()
    }

    return (
        <section className='flex flex-col'>
            <TitleSection title={title} small />

            {
                !onlyView
                &&
                <div className='w-full flex flex-col gap-2.5'>
                    {
                        isAuthentication
                            ?
                            <form className='flex justify-between rounded-20 border-strock border py-2.5 px-4 gap-5' onSubmit={formik.handleSubmit}>
                                <textarea
                                    className='w-full resize-none textarea-sizing min-h-[50px]'
                                    placeholder='Comment ...'
                                    name={name}
                                    onChange={onChange}
                                    value={formik.values[name]}
                                    ref={inputRef}
                                />

                                <div className='grid h-fit gap-5'>
                                    <button type='submit' onClick={formik.handleSubmit}><Send size={16} /></button>
                                    {/* <button type='button'><AttachSquare size={16} /></button> */}
                                </div>
                            </form>
                            :
                            <p>댓글을 달려면 로그인하세요</p>
                    }

                    {
                        (formik.touched[name] && formik.errors[name])
                        &&
                        <p className='text-10 text-red'>{formik.errors[name]}</p>
                    }
                </div>
            }

            {
                image
                &&
                <div className='flex gap-5 lg:mb-5 mb-4'>
                    <RadioForm content='모든 댓글' />
                    <RadioForm content='이미지' />
                </div>
            }

            <div>
                {
                    data.map(item =>
                        <CommentItem
                            key={item.id}
                            commentId={item.id}
                            name={item.name}
                            content={item.content}
                            createdAt={item.created_at}
                            reply={item.commentReply}
                            handleReply={() => handleClickReply({ commentId: item.id, nameuser: item.name })}
                        />)
                }
            </div>

            {/* <button type='button' className='lg:mt-5 mt-4 max-lg:text-14_20 flex justify-center items-center gap-2 font-bold'>더 보기 <ArrowDown2 size="16" /></button> */}
        </section>
    )
}
