import { createSlice } from "@reduxjs/toolkit";
import { getManagementAdmin, updateFeeletterAdmin, updateFeeprintAdmin, updateLimitPdfAdmin, updateStampAdmin } from "../actions/management.action";

const managementSlice = createSlice({
    name: 'management',
    initialState: {
        loadingGetManagement: false,
        dataManagement: undefined,

        loadingUpdateStamp: false,

        loadingUpdateFeeprint: false
    },
    extraReducers: builder => {
        builder.addCase(getManagementAdmin.pending, (state, action) => {
            state.loadingGetManagement = true
        })
        builder.addCase(getManagementAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataManagement = res.data
            }

            state.loadingGetManagement = false
        })

        // update feePrint
        builder.addCase(updateFeeprintAdmin.pending, (state, action) => {
            state.loadingUpdateFeeprint = true
        })
        builder.addCase(updateFeeprintAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataManagement.feePrint.map(item => {
                    if (Number(item.id) === Number(res.data.id)) {
                        return res.data
                    }

                    return item
                })

                state.dataManagement = {
                    ...state.dataManagement,
                    feePrint: updateData
                }
            }

            state.loadingUpdateFeeprint = false
        })

        // update stamps
        builder.addCase(updateStampAdmin.pending, (state, action) => {
            state.loadingUpdateStamp = true
        })
        builder.addCase(updateStampAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataManagement.stamps.map(item => {
                    if (Number(item.id) === Number(res.data.id)) {
                        return res.data
                    }

                    return item
                })

                state.dataManagement = {
                    ...state.dataManagement,
                    stamps: updateData
                }
            }

            state.loadingUpdateStamp = false
        })

        // update limitPdf
        builder.addCase(updateLimitPdfAdmin.pending, (state, action) => {
            state.loadingUpdateStamp = true
        })
        builder.addCase(updateLimitPdfAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataManagement.limitPdf.map(item => {
                    if (Number(item.id) === Number(res.data.id)) {
                        return res.data
                    }

                    return item
                })

                state.dataManagement = {
                    ...state.dataManagement,
                    limitPdf: updateData
                }
            }

            state.loadingUpdateStamp = false
        })

        // update limitPdf
        builder.addCase(updateFeeletterAdmin.pending, (state, action) => {
            state.loadingUpdateStamp = true
        })
        builder.addCase(updateFeeletterAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataManagement.feeLetter.map(item => {
                    if (Number(item.id) === Number(res.data.id)) {
                        return res.data
                    }

                    return item
                })

                state.dataManagement = {
                    ...state.dataManagement,
                    feeLetter: updateData
                }
            }

            state.loadingUpdateStamp = false
        })
    }
})

export default managementSlice.reducer
