import React, { useEffect } from 'react'
import StatisticalRepost from '../../sections/admin/dashboard/StatisticalRepost'
import HistoryOrder from '../../sections/admin/dashboard/HistoryOrder'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardAdmin } from '../../reduxs/actions/admin.action'
import LoadingUser from '../user/LoadingUser'

export default function DashboardAdmin() {
    const dispatch = useDispatch()

    const { loadingGetDashboard, dataDashboard } = useSelector(state => state.admin)

    useEffect(() => {
        dispatch(getDashboardAdmin())
    }, [])

    if (loadingGetDashboard || !dataDashboard) {
        return <LoadingUser />
    }

    const { analystDay, analystMonth, analystYear } = dataDashboard

    return (
        <>
            <StatisticalRepost title='일일 통계 현황' data={analystDay} />
            <StatisticalRepost title='월간 통계 현황' data={analystMonth} />
            <StatisticalRepost title='연간 통계 현황' data={analystYear} />
            <HistoryOrder />
        </>
    )
}
