import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeStatusOrderVoucher, deleteOrderVoucher, getAllVoucher } from '../../../reduxs/actions/point.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import Pagination from '../../../components/Pagination'
import { StatusOrderSelect } from '../../../components/user/StatusOrder'
import moment from 'moment/moment'
import { getStatusOrderVoucher } from '../../../reduxs/actions/admin.action'
import { formatMoney } from '../../../utils/renderText'
import DropdownTable from '../../../components/admin/DropdownTable'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'

export default function ListVoucherOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { dataAllVoucherAdmin, loadingGetAllVoucherAdmin, countVoucher } = useSelector(state => state.point)
    const { dataStatusOrderVoucher } = useSelector(state => state.admin)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countVoucher / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getAllVoucher())
        dispatch(getStatusOrderVoucher())
    }, [])

    if (loadingGetAllVoucherAdmin || !dataAllVoucherAdmin || !dataStatusOrderVoucher) {
        return <LoadingUser />
    }

    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[300px]'>접수일</th>
                        <th className='py-5 px-2 w-[300px]'>받는분</th>
                        <th className='py-5 px-2 w-[300px]'>상품명</th>
                        <th className='py-5 px-2 w-[300px]'>받은 포인트</th>
                        <th className='py-5 px-2 w-[200px]'>결제방법</th>
                        <th className='py-5 px-2 w-[300px]'>상태</th>
                        <th className='py-5 px-2 w-[160px]'>결제금액</th>
                        <th className='py-5 px-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataAllVoucherAdmin.map(item =>
                            <RowOrderItem
                                key={item.id}
                                id={item.id}
                                receiver={item.recipient_name}
                                status={item.order_status_id}
                                created_at={item.created_at}
                                listStatus={dataStatusOrderVoucher}
                                methodName={item.pay_method_name}
                                money_pay={item.money_pay}
                                name_voucher={item.voucher_name}
                                point={item.point}
                                page={page}
                            />
                        )
                    }
                </tbody>
            </table>

            <Pagination totalCount={countVoucher} currentPage={page} handlePage={handlePage} />
        </section>
    )
}

const RowOrderItem = ({ id, money_pay, methodName, status, point, receiver, name_voucher, created_at, listStatus, page }) => {
    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)
    const [isShowDelete, setIsShowDelete] = useState(false)

    const handleChangeStatus = (statusId) => {
        dispatch(changeStatusOrderVoucher({ order_id: id, status_id: statusId }))
    }

    const handleShowpopup = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleDeleteOrder = async () => {
        const result = await dispatch(deleteOrderVoucher({ orderId: id, page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
            handleShowpopup()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{moment(created_at).format('YYYY.MM.DD hh:mm')}</td>
            <td className='py-5 px-2'>{receiver}</td>
            <td className='py-5 px-2'>{name_voucher}</td>
            <td className='py-5 px-2'>{formatMoney(point)}포인트</td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    {methodName}
                </div>
            </td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    <StatusOrderSelect listStatus={listStatus} status={status} handleChange={handleChangeStatus} />
                </div>
            </td>
            <td className='py-5 px-2'>₩{formatMoney(money_pay)}원</td>
            <td>
                <DropdownTable onclickDelete={handleShowpopup} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 주문을 삭제하시겠습니까?</p>
                                <p>이 주문이 삭제되면 복원할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowpopup} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleDeleteOrder} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}

