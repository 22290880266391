import React, { useState } from 'react'
import logo from '../../assets/images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import { IoGridOutline } from "react-icons/io5";
import { ArrowDown2, Bag2, Box, Profile2User, Setting2 } from 'iconsax-react';

const listNavlink = [
    {
        id: 1,
        icon: <Box />,
        title: '주문',
        data: [
            {
                id: 1,
                link: 'orders',
                title: '편지지'
            },
            {
                id: 2,
                link: 'order-pdf',
                title: 'PDF'
            },
            {
                id: 3,
                link: 'order-event',
                title: '공구'
            },
            {
                id: 4,
                link: 'order-handmake',
                title: '자체제작 상품'
            },
            {
                id: 5,
                link: 'order-voucher',
                title: '상품권'
            },
            {
                id: 6,
                link: 'order-statistics',
                title: '주문통계'
            },
        ]
    },
    {
        id: 2,
        icon: <Bag2 />,
        title: '상품',
        data: [
            {
                id: 1,
                link: 'products',
                title: '편지지 상품'
            },
            {
                id: 2,
                link: 'products-event',
                title: '공구 상품'
            },
            {
                id: 3,
                link: 'products-handmade',
                title: '자체제작 상품'
            },
            {
                id: 4,
                link: 'categories',
                title: '카테고리'
            },
            {
                id: 5,
                link: 'reviews',
                title: '리뷰내역'
            },
            {
                id: 6,
                link: 'points',
                title: '상품권'
            },
        ]
    },
    {
        id: 3,
        icon: <Profile2User />,
        title: '사용자',
        data: [
            {
                id: 1,
                link: 'users',
                title: '사용자 목록'
            },
            {
                id: 2,
                link: 'blacklist-user',
                title: '블랙리스트'
            },
            {
                id: 3,
                link: 'edit-point',
                title: '적립금'
            },
        ]
    },
    {
        id: 4,
        icon: <Setting2 />,
        title: '설정',
        data: [
            {
                id: 1,
                link: 'delivery',
                title: '고정값 변경'
            },
            {
                id: 2,
                link: 'faq-admin',
                title: 'FAQ'
            },
            {
                id: 3,
                link: 'notice',
                title: 'Notice'
            },
            {
                id: 4,
                link: 'popup',
                title: '팝업 관리'
            },
            {
                id: 5,
                link: 'management',
                title: '배너/Calendar'
            },
            {
                id: 6,
                link: 'coupons',
                title: '쿠폰 관리'
            },
        ]
    },
]

export default function SidebarAdmin() {
    const defaultStyle = 'flex gap-2 items-center w-full justify-start py-2 px-4 rounded-12 hover:bg-logoColor hover:text-white duration-300'

    const [isShow, setIsShow] = useState(0)

    const handleChooseList = (list) => {
        // if (list === isShow) setIsShow(0)
        setIsShow(list)
    }

    return (
        <aside className='w-2/12 p-[30px] flex flex-col gap-5 items-center flex-shrink-0 h-full overflow-y-auto hiddenScroll'>
            <Link to='/admin'>
                <img src={logo} alt='made by me' className='w-20 aspect-square object-contain' />
            </Link>

            <nav className='w-full'>
                <ul className='flex flex-col gap-5 items-start'>
                    <li className='w-full'>
                        <NavLink
                            to='/admin'
                            className={({ isActive, isPending }) =>
                                isActive ? `${defaultStyle} bg-logoColor text-white` : `${defaultStyle}`
                            }
                            onClick={() => handleChooseList(0)}
                            end
                        >
                            <IoGridOutline className='text-xl' />
                            <span className='font-semibold translate-y-[1.5px]'>대시보드</span>
                        </NavLink>
                    </li>

                    {listNavlink.map(item =>
                        <DropdownNavbar
                            key={item.id}
                            id={item.id}
                            icon={item.icon}
                            title={item.title}
                            data={item.data}
                            handleChooseList={handleChooseList}
                            isShow={isShow === item.id}
                        />)}
                </ul>
            </nav>
        </aside>
    )
}

const DropdownNavbar = ({ id, icon, title, data, handleChooseList, isShow }) => {
    const defaultStyle = 'duration-300 hover:text-logoColor'

    return (
        <li className='w-full'>
            <button
                className={`${isShow && 'bg-logoColor text-white'} flex gap-2 items-center justify-between w-full py-2 px-4 rounded-12 hover:bg-logoColor hover:text-white duration-300 mb-2`}
                type='button'
                onClick={() => handleChooseList(id)}
            >
                <div className='flex gap-2'>
                    {icon}

                    <span>{title}</span>
                </div>

                <ArrowDown2 size={20} className={`${isShow && 'rotate-180'} duration-100`} />
            </button>

            {
                isShow
                &&
                <div className='flex flex-col pl-11 gap-2.5'>
                    {
                        data.map(item => <NavLink key={item.id} to={item.link} className={({ isActive, isPending }) =>
                            isActive ? `${defaultStyle} text-logoColor` : `${defaultStyle}`
                        }>{item.title}</NavLink>)
                    }
                </div>
            }
        </li >
    )
}