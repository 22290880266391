import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

export default function Pagination({ totalCount, numberInPage = 10, currentPage = 1, handlePage }) {
    if (totalCount <= numberInPage) {
        return null;
    }

    const totalPages = Math.ceil(totalCount / numberInPage);
    const paginationNumbers = [];

    if (totalPages <= 10) {
        for (let i = 1; i <= totalPages; i++) {
            paginationNumbers.push(i);
        }
    } else {
        paginationNumbers.push(1);
        if (totalPages > 1) paginationNumbers.push(2);

        if (currentPage > 5) {
            paginationNumbers.push('...');
        }

        const startMiddle = Math.max(3, currentPage - 1);
        const endMiddle = Math.min(totalPages - 2, currentPage + 1);

        for (let i = startMiddle; i <= endMiddle; i++) {
            if (!paginationNumbers.includes(i)) {
                paginationNumbers.push(i);
            }
        }

        if (currentPage < totalPages - 4) {
            paginationNumbers.push('...');
        }

        if (!paginationNumbers.includes(totalPages - 1)) {
            paginationNumbers.push(totalPages - 1);
        }
        if (!paginationNumbers.includes(totalPages)) {
            paginationNumbers.push(totalPages);
        }
    }

    return (
        <div className='flex justify-center items-center gap-5 lg:py-5 py-4 max-lg:text-14_20'>
            <button
                type='button'
                className={`${currentPage <= 1 ? 'text-deactive' : ''}`}
                onClick={() => handlePage('pre')}
                disabled={currentPage <= 1}
            >
                <ArrowLeft2 size={16} />
            </button>

            {paginationNumbers.map((pageNumber, index) =>
                pageNumber === '...' ? (
                    <span key={`dots-${index}`}>...</span>
                ) : (
                    <button
                        key={pageNumber}
                        onClick={() => handlePage(pageNumber)}
                        className={`${currentPage === pageNumber ? 'text-logoColor' : ''}`}
                    >
                        {pageNumber}
                    </button>
                )
            )}

            <button
                type='button'
                onClick={() => handlePage('next')}
                className={`${currentPage >= totalPages ? 'text-deactive' : ''}`}
                disabled={currentPage >= totalPages}
            >
                <ArrowRight2 size={16} />
            </button>
        </div>
    );
}