import { createSlice } from "@reduxjs/toolkit";
import { createCategoriesAdmin, deleteCategoriesAdmin, getAllCategories, getAllCategoriesAdmin, getEmojis, updateCategoriesAdmin } from "../actions/category.action";

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        loadingGetAllCategories: false,
        dataCategories: undefined,

        loadingGetAllCategoriesAdmin: false,
        dataCategoriesAdmin: undefined,

        loadingCreateCategory: false,

        loadingUpdateCategory: false,

        loadingDeleteCategory: false,

        loadingGetEmoji: false,
        dataEmojis: undefined
    },
    extraReducers: builder => {
        builder.addCase(getAllCategories.pending, (state, action) => {
            state.loadingGetAllCategories = true
        })
        builder.addCase(getAllCategories.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCategories = res.data
            }

            state.loadingGetAllCategories = false
        })

        // get all category admin
        builder.addCase(getAllCategoriesAdmin.pending, (state, action) => {
            state.loadingGetAllCategoriesAdmin = true
        })
        builder.addCase(getAllCategoriesAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCategoriesAdmin = res.data
            }

            state.loadingGetAllCategoriesAdmin = false
        })

        // create category admin
        builder.addCase(createCategoriesAdmin.pending, (state, action) => {
            state.loadingCreateCategory = true
        })
        builder.addCase(createCategoriesAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updatedata = state.dataCategoriesAdmin.map(item => {
                    if (item.product_type_id === res.data.product_type_id) {
                        return {
                            ...item,
                            category: [
                                res.data,
                                ...item.category,
                            ]
                        }
                    }

                    return item
                })

                state.dataCategoriesAdmin = updatedata
            }

            state.loadingCreateCategory = false
        })

        // update category admin
        builder.addCase(updateCategoriesAdmin.pending, (state, action) => {
            state.loadingUpdateCategory = true
        })
        builder.addCase(updateCategoriesAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const findCategoryType = state.dataCategoriesAdmin.find(item => item.product_type_id === res.data.product_type_id)

                const updateCategory = findCategoryType.category.map(item => {
                    if (item.id === res.data.id) {
                        return {
                            ...item,
                            name: res.data.name
                        }
                    }

                    return item
                })

                const updatedata = state.dataCategoriesAdmin.map(item => {
                    if (item.product_type_id === res.data.product_type_id) {
                        return {
                            ...item,
                            category: updateCategory
                        }
                    }

                    return item
                })

                state.dataCategoriesAdmin = updatedata
            }

            state.loadingUpdateCategory = false
        })

        // delete category admin
        builder.addCase(deleteCategoriesAdmin.pending, (state, action) => {
            state.loadingDeleteCategory = true
        })
        builder.addCase(deleteCategoriesAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const findCategoryType = state.dataCategoriesAdmin.find(item => item.product_type_id === res.data.product_type_id)

                const deleteArray = findCategoryType.category.filter(item => Number(item.id) !== Number(res.data.id))

                const updateData = state.dataCategoriesAdmin.map(item => {
                    if (item.product_type_id === res.data.product_type_id) {
                        return {
                            ...item,
                            category: deleteArray
                        }
                    }

                    return item
                })

                state.dataCategoriesAdmin = updateData
            }

            state.loadingDeleteCategory = false
        })

        builder.addCase(getEmojis.pending, (state, action) => {
            state.loadingGetEmoji = true
        })
        builder.addCase(getEmojis.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataEmojis = res.data
            }

            state.loadingGetEmoji = true
        })
    }
})

export const { } = categoriesSlice.actions
export default categoriesSlice.reducer
