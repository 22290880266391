import React, { useContext } from 'react'
import UploadLetterEventProvider, { uploadLetterEventContext } from '../../contexts/uploadLetterEventContext'
import AddressStep from '../../sections/user/CheckoutEventLetter/AddressStep'
import CheckoutStep from '../../sections/user/CheckoutEventLetter/CheckoutStep'

export default function CheckoutLetterEvent() {
  return (
    <UploadLetterEventProvider>
      <CheckoutUploadContent />
    </UploadLetterEventProvider>
  )
}

const CheckoutUploadContent = () => {
  const { stepOrder } = useContext(uploadLetterEventContext)

  return (
    <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col items-center lg:gap-10 gap-6 container max-lg:px-6'>
      {
        stepOrder === 1 && <AddressStep />
      }
      {
        stepOrder === 2 && <CheckoutStep />
      }
    </main>
  )
}