import React, { useRef, useState } from 'react'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'
import { AttachSquare } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { createPointsAdmin } from '../../../reduxs/actions/point.action'

export default function CreateNewpoint() {
    const dispatch = useDispatch()
    const inputRef = useRef()

    const [isShowPopup, setIsShowPopup] = useState(false)

    const handleShow = () => {
        setIsShowPopup(!isShowPopup)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            point: '',
            price: '',
            quantity: '',
            voucher: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRED),
            point: Yup.string().required(ERROR_REQUIRED),
            price: Yup.string().required(ERROR_REQUIRED),
            quantity: Yup.string().required(ERROR_REQUIRED),
            voucher: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('point', values.point)
            formData.append('price', values.price)
            formData.append('quantity', values.quantity)
            formData.append('voucher', values.voucher)

            const result = await dispatch(createPointsAdmin(formData))

            const res = result.payload

            if (res.status) {
                handleShow()
            }
        }
    })

    const handleClickImage = () => {
        inputRef.current.click()
    }

    return (
        <div>
            <ButtonSecon title='상품권 추가하기' onClick={handleShow} />

            {
                isShowPopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center'>
                    <form className='p-5 w-[600px] max-h-[600px] bg-white rounded-20 hiddenScroll flex flex-col gap-5 items-center' onSubmit={formik.handleSubmit}>
                        <h3 className='text-24-30 font-semibold'>사용자 추가하기</h3>

                        <div className='w-full grid gap-4'>
                            <div>
                                <LabelFormThin label='상품명' />
                                <InputFormThin
                                    name='name'
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    placehoder='텍스트 입력'
                                    error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='전철기' />
                                <InputFormThin
                                    name='point'
                                    onChange={formik.handleChange}
                                    value={formik.values.point}
                                    placehoder='텍스트 입력'
                                    error={(formik.touched.point && formik.errors.point) && formik.errors.point}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='가격' />
                                <InputFormThin
                                    name='price'
                                    onChange={formik.handleChange}
                                    value={formik.values.price}
                                    placehoder='텍스트 입력'
                                    error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='판매수량' />
                                <InputFormThin
                                    name='quantity'
                                    onChange={formik.handleChange}
                                    value={formik.values.quantity}
                                    placehoder='텍스트 입력'
                                    error={(formik.touched.quantity && formik.errors.quantity) && formik.errors.quantity}
                                />
                            </div>
                            {
                                <div>
                                    <div className='flex justify-between items-center'>
                                        <LabelFormThin label='첨부파일 (.jpg)' />
                                        <button type='button' onClick={handleClickImage}><AttachSquare size={20} className='strock1' /></button>
                                    </div>

                                    <input type='file' accept='/image' name='picture' hidden ref={inputRef} onChange={(e) => formik.setFieldValue('voucher', e.currentTarget.files[0])} />
                                    <InputFormThin
                                        name='voucher'
                                        value={formik.values.voucher?.name || ''}
                                        onChange={formik.handleChange}
                                        placehoder='첨부파일'
                                        error={(formik.touched.voucher && formik.errors.voucher) && formik.errors.voucher}
                                        onlyView
                                    />
                                </div>
                            }
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleShow} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

