import { createSlice } from "@reduxjs/toolkit";
import { createCouponAdmin, deleteCouponByUser, getAllCouponAdmin, getCouponByUser, searchCouponAdmin } from "../actions/coupon.action";

const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        loadingGetCouponAdmin: false,
        dataCouponAdmin: undefined,
        couponCount: 0,

        loadingCreateCoupon: false,

        loadingGetCouponUser: false,
        dataCouponUser: undefined,

        loadingDeleteCouponAdmin: false
    },
    extraReducers: builder => {
        //
        builder.addCase(getAllCouponAdmin.pending, (state, action) => {
            state.loadingGetCouponAdmin = true
        })
        builder.addCase(getAllCouponAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCouponAdmin = res.data.userCoupon
                state.couponCount = res.data.userCouponCount
            }

            state.loadingGetCouponAdmin = false
        })

        //
        builder.addCase(createCouponAdmin.pending, (state, action) => {
            state.loadingCreateCoupon = true
        })
        builder.addCase(createCouponAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                console.log(res)
            }

            state.loadingCreateCoupon = false
        })

        //
        builder.addCase(getCouponByUser.pending, (state, action) => {
            state.loadingGetCouponUser = true
        })
        builder.addCase(getCouponByUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCouponUser = res.data
            }

            state.loadingGetCouponUser = false
        })

        //
        builder.addCase(searchCouponAdmin.pending, (state, action) => {
            state.loadingGetCouponAdmin = true
        })
        builder.addCase(searchCouponAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataCouponAdmin = res.data.userCoupon
                state.couponCount = res.data.userCouponCount
            }

            state.loadingGetCouponAdmin = false
        })

        //
        builder.addCase(deleteCouponByUser.pending, (state, action) => {
            state.loadingDeleteCouponAdmin = true
        })
        builder.addCase(deleteCouponByUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataCouponUser.filter(item => item.id !== Number(res.data))

                state.dataCouponUser = updateData
            }

            state.loadingDeleteCouponAdmin = false
        })
    }
})

export default couponSlice.reducer
