import React, { useEffect, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { Add, Trash } from 'iconsax-react'
import Pagination from '../../../components/Pagination'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { addEmailBlacklist, deleteEmailBlacklist, getEmailBlacklist } from '../../../reduxs/actions/user.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error'

export default function ListEmail() {
    const dispatch = useDispatch()

    const [isShowAddPhone, setIsShowAddPhone] = useState(false)
    const [isNotifycation, setIsNotifycation] = useState(false)

    const [page, setPage] = useState(1)

    const { loadingGetEmailBlacklist, dataEmailBlacklist, countEmailBlacklist } = useSelector(state => state.user)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countEmailBlacklist / 15)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    const handleShowPopup = () => {
        setIsShowAddPhone(!isShowAddPhone)
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const result = await dispatch(addEmailBlacklist(values))

            const res = result.payload

            if (res.status) {
                setIsNotifycation('추가된 전화번호')

                setTimeout(() => {
                    setIsNotifycation()
                }, 3000);

                handleShowPopup()
            } else {
                if (res.error === 'e_email') formik.setFieldError('email', '이메일이 이미 존재합니다')
            }
        }
    })

    useEffect(() => {
        dispatch(getEmailBlacklist(page))
    }, [page])

    if (loadingGetEmailBlacklist || !dataEmailBlacklist) {
        return <LoadingUser />
    }

    return (
        <section>
            <div className='flex justify-between items-center'>
                <TitleSection title='이메일이 차단되었습니다' small />

                <button className='flex items-center gap-1' onClick={handleShowPopup}><Add size={20} /> <span>연락처 추가</span></button>
            </div>

            <div className='grid grid-cols-3 gap-5 rounded-24 p-5 bg-white'>
                {
                    dataEmailBlacklist.map(item =>
                        <EmailItem
                            key={item.id}
                            id={item.id}
                            email={item.email}
                        />
                    )
                }

                <Pagination totalCount={countEmailBlacklist} numberInPage={15} currentPage={page} handlePage={handlePage} />
            </div>

            {
                isShowAddPhone
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                    <form onSubmit={formik.handleSubmit} className='w-[600px] bg-white rounded-20 p-5 flex flex-col gap-5 items-center'>
                        <h3 className='text-24-30 font-semibold'>이메일 추가</h3>

                        <div className='w-full'>
                            <LabelFormThin label='이메일' />
                            <InputFormThin
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                placehoder='텍스트 입력'
                                error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                            />
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px]' onClick={handleShowPopup} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='sumbit' onClick={formik.handleSubmit} />
                        </div>
                    </form>
                </div>
            }

            <div className={`${isNotifycation ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotifycation}
            </div>
        </section>
    )
}

const EmailItem = ({ id, email }) => {
    const dispatch = useDispatch()

    const handleDelete = () => {
        dispatch(deleteEmailBlacklist(id))
    }

    return (
        <div className='p-5 rounded-12 border border-strock flex justify-between gap-4 items-center'>
            <p className='line-clamp-1'>{email}</p>

            <button onClick={handleDelete}><Trash size={20} color='#BABABA' /></button>
        </div>
    )
}
