import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { checkPaymentOrderEvent } from "../reduxs/actions/order.action";

export const uploadLetterEventContext = createContext()

export default function UploadLetterEventProvider({ children }) {
    const dispatch = useDispatch()

    const [stepOrder, setStepOrder] = useState(1)
    const [formUpload, setFormUpload] = useState({
        note: ''
    })

    const { dataOrderSelected } = useSelector(state => state.orderLetter)
    const { dataCheckPaymentEventLlt } = useSelector(state => state.order)

    const { letterId } = useParams()

    const handleStepOrder = (value) => {
        switch (value) {
            case 'next':
                return setStepOrder(pre => pre + 1)

            default:
                return setStepOrder(pre => pre - 1)
        }
    }

    // useEffect(() => {
    //     if (!!!dataOrderSelected.length) navigate(`/products/${letterId}`)
    // }, [dataOrderSelected.length, letterId])

    useEffect(() => {
        if (stepOrder === 2) {
            const dataUpload = {
                product_id: letterId,
                envelopes: dataOrderSelected.map(item => {
                    return {
                        id: item.id,
                        quantity: item.totalQuantity
                    }
                })
            }

            dispatch(checkPaymentOrderEvent(dataUpload))
        }
    }, [stepOrder])

    useEffect(() => {
        if (dataCheckPaymentEventLlt) {
            setFormUpload({
                ...formUpload,
                product_id: dataCheckPaymentEventLlt.product_id,
                envelopes: dataCheckPaymentEventLlt.envelopes,
                ship_fee: dataCheckPaymentEventLlt.ship_fee,
                product_total: dataCheckPaymentEventLlt.product_total,
                money_total: dataCheckPaymentEventLlt.money_total
            })
        }
    }, [dataCheckPaymentEventLlt])

    return (
        <uploadLetterEventContext.Provider value={{
            stepOrder,
            handleStepOrder,
            formUpload,
            setFormUpload
        }}>
            {children}
        </uploadLetterEventContext.Provider>
    )
}
