import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { URL_SERVER } from '../../../utils/apis'

export default function CalendarSection({ dataEvent }) {
    return (
        <section className='flex flex-col'>
            <TitleSection title='MADEBYME CALENDAR' />

            {
                dataEvent
                    ?
                    <img src={`${URL_SERVER}${dataEvent.filename}`} alt='make by me event' className='aspect-video object-cover rounded-20' />
                    :
                    ''
            }
        </section>
    )
}
