import { createSlice } from "@reduxjs/toolkit";
import { getAllFont } from "../actions/fontfamily.action";

const fontSlice = createSlice({
    name: 'font',
    initialState: {
        loadingGetFont: false,
        dataFont: undefined,
    },
    extraReducers: builder => {
        builder.addCase(getAllFont.pending, (state, action) => {
            state.loadingGetFont = true
        })
        builder.addCase(getAllFont.fulfilled, (state, action) => {
            const res = action.payload

            if (res?.status) {
                state.dataFont = res.data
            }

            state.loadingGetFont = false
        })
    }
})

export const { } = fontSlice.actions
export default fontSlice.reducer
