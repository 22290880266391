import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { formatMoney } from '../../../utils/renderText'

export default function StatisticalRepost({ title, data }) {
    return (
        <section>
            <TitleSection title={title} mb={true} small />

            <div className='flex w-full bg-white rounded-24'>
                <div className='py-2.5 w-full'>
                    <table className='w-full text-center' >
                        <thead>
                            <tr>
                                <th className='px-5 py-2.5 border-r border-logoColor'>주문건수</th>
                                <th className='px-5 py-2.5 border-r border-logoColor'>결제완료</th>
                                <th className='px-5 py-2.5 border-r border-logoColor'>배송중</th>
                                <th className='px-5 py-2.5'>배송완료</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='px-5 pt-2.5 border-r border-logoColor'>{formatMoney(data?.order_total)}</td>
                                <td className='px-5 pt-2.5 border-r border-logoColor'>{formatMoney(data?.order_completedpay)}</td>
                                <td className='px-5 pt-2.5 border-r border-logoColor'>{formatMoney(data?.shipping)}</td>
                                <td className='px-5 pt-2.5'>{formatMoney(data?.completed_shipping)}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className='flex flex-col w-1/5 items-center rounded-24 bg-logoColor text-white font-bold shadow-repost'>
                    <p className='p-5'>일일 매출</p>
                    <p className='p-5 pt-0'>₩{formatMoney(data?.money_revenue)}</p>
                </div>
            </div>
        </section>
    )
}
