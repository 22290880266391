import React, { useRef } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { AddCircle, CloseSquare } from 'iconsax-react'
import { ImageUploadItem } from './FormUpload'

export default function UploadImage({
    formik,
    frontImage,
    setFrontImage,
    endImage,
    setEndImage,
    normalImageFirst,
    setNormalImageFirst,
    normalImage,
    setNormalImage,
}) {
    const frontThumbnail = useRef()
    const endThumbnail = useRef()
    const normalThumbnail = useRef()
    const normalThumbnailFirst = useRef()

    // ========== front thumbnail ========== //
    const handleClickFrontThumbnail = () => {
        frontThumbnail.current.click()
    }

    const handleChooseFrontThumbnail = (e) => {
        const file = e.currentTarget.files

        if (file.length) {
            setFrontImage(URL.createObjectURL(file[0]))
            formik.setFieldValue('frontThumbnail', file[0])
        }

        e.target.value = null;
    }

    const deleteFrontImage = () => {
        setFrontImage()
        formik.setFieldValue('frontThumbnail', '')
    }

    // ========== end thumbnail ========== //
    const handleClickEndThumbnail = () => {
        endThumbnail.current.click()
    }

    const handleChooseEndThumbnail = (e) => {
        const file = e.currentTarget.files

        if (file.length) {
            setEndImage(URL.createObjectURL(file[0]))
            formik.setFieldValue('endThumbnail', file[0])
        }

        e.target.value = null;
    }

    const deleteEndImage = () => {
        setEndImage()
        formik.setFieldValue('endThumbnail', '')
    }

    // ========== normal thumbnail first ========== //
    const handleClickNormalThumbnailFirst = () => {
        normalThumbnailFirst.current.click()
    }

    const handleChooseNormalThumbnailFirst = (e) => {
        const file = e.currentTarget.files

        if (file.length) {
            setNormalImageFirst(URL.createObjectURL(file[0]))
            formik.setFieldValue('normalFirst', file[0])
        }

        e.target.value = null;
    }

    const deleteNormalThumbnailFirstImage = () => {
        setNormalImageFirst()
        formik.setFieldValue('normalFirst', '')
    }

    // ========== normal thumbnail ========== //
    const handleClickNormalThumbnail = () => {
        normalThumbnail.current.click()
    }

    const handleChooseNormalThumbnail = (e) => {
        const fileArray = Array.from(e.currentTarget.files)

        const handleFile = fileArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item)
            };
        });

        const files = fileArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: item
            };
        });

        const customImage = [
            ...normalImage,
            ...handleFile
        ].slice(0, 4)

        const customImageForm = [
            ...formik.values.normals,
            ...files
        ].slice(0, 4)

        if (handleFile.length > 0) {
            setNormalImage(customImage)
        } else {
            setNormalImage()
        }

        formik.setFieldValue('normals', customImageForm)

        e.target.value = null
    }

    const deleteNormalImage = (file) => {
        const handleDelete = normalImage.filter(item => item.id !== file)
        const handleDeleteFormik = formik.values.normals.filter(item => item.id !== file)

        setNormalImage(handleDelete.length > 0 ? handleDelete : [])
        formik.setFieldValue('normals', handleDeleteFormik)
    }

    return (
        <section className='p-5 rounded-20 bg-white w-5/12 grid gap-[30px]'>
            <div className='grid gap-5'>
                <TitleSection title='상품 썸네일' small mt={false} />

                <div className='grid gap-2.5'>
                    {/* /// front thumbnail */}
                    <div className={`aspect-[7.08/10] border-dashed ${frontImage ? '' : 'border-[2px]'} rounded-20 relative overflow-hidden`}>
                        <input
                            type='file'
                            accept="image/*"
                            ref={frontThumbnail}
                            onChange={(e) => handleChooseFrontThumbnail(e)}
                            hidden
                        />

                        {
                            frontImage
                                ?
                                <>
                                    <button type='button' className='absolute top-5 right-5 text-deactive' onClick={deleteFrontImage}><CloseSquare size={20} /></button>
                                    <img src={frontImage} alt='front thumbnail' className='w-full h-full object-contain' />
                                </>
                                :
                                <div className='flex flex-col gap-2.5 items-center justify-center w-full h-full cursor-pointer' onClick={handleClickFrontThumbnail}>
                                    <AddCircle size={40} variant="Outline" />

                                    <div className='text-center'>
                                        <p>이미지 비율 3x4</p>
                                        <p>앞 (최대 5MB)</p>
                                    </div>
                                </div>
                        }
                    </div>


                    {/* /// end thumbnail */}
                    <div className={`w-1/2 aspect-[7.08/10] border-dashed ${endImage ? '' : 'border-[2px]'} rounded-20 relative overflow-hidden`}>
                        <input
                            type='file'
                            accept="image/*"
                            hidden
                            ref={endThumbnail}
                            onChange={(e) => handleChooseEndThumbnail(e)}
                        />

                        {
                            endImage
                                ?
                                <>
                                    <button type='button' className='absolute top-2.5 right-2.5 text-deactive' onClick={deleteEndImage}><CloseSquare size={20} /></button>
                                    <div className='w-full h-full bg-no-repeat bg-center bg-contain' style={{ backgroundImage: `url(${endImage})` }}>
                                        <div className='paper w-full h-full'></div>
                                    </div>
                                </>
                                :
                                <div className='flex flex-col gap-2.5 items-center justify-center h-full w-full cursor-pointer' onClick={handleClickEndThumbnail}>
                                    <AddCircle size={40} variant="Outline" />

                                    <div className='text-center'>
                                        <p>이미지 비율 3x4</p>
                                        <p>앞 (최대 5MB)</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className='grid gap-5'>
                <div className='flex justify-between items-center'>
                    <TitleSection title='상품 상세 이미지' small mt={false} />
                    <p>{(formik.values.normalFirst ? 1 : 0) + (formik.values.normals.length)}/4 사진</p>
                </div>

                <div className='grid gap-2.5'>
                    { /* description thumbnail first */}
                    <div className='aspect-video border-dashed border-[2px] rounded-20 relative overflow-hidden'>
                        <input type='file' hidden accept="image/*" multiple ref={normalThumbnailFirst} onChange={handleChooseNormalThumbnailFirst} />

                        {
                            normalImageFirst
                                ?
                                <>
                                    <button type='button' className='absolute top-5 right-5' onClick={() => deleteNormalThumbnailFirstImage(normalImageFirst.id)}>
                                        <CloseSquare size={20} className=' hover:text-logoColor duration-300' />
                                    </button>

                                    <img src={normalImageFirst} alt='front thumbnail' className='w-full h-full object-cover' />
                                </>
                                :
                                <div className='flex flex-col gap-2.5 items-center justify-center h-full cursor-pointer' onClick={handleClickNormalThumbnailFirst}>
                                    <AddCircle size={40} variant="Outline" />

                                    <div className='text-center'>
                                        <p>이미지 비율 16x9</p>
                                        <p>앞 (최대 5MB)</p>
                                    </div>
                                </div>
                        }
                    </div>

                    { /* normal thumbnail */}
                    <div className='grid grid-cols-3 gap-2.5'>
                        <input ref={normalThumbnail} accept="image/*" hidden multiple type='file' onChange={handleChooseNormalThumbnail} />
                        {
                            normalImage
                                ?
                                <>
                                    {
                                        normalImage.map(item => <ImageUploadItem key={item.id} id={item.id} image={item.file} handleDelete={deleteNormalImage} />)
                                    }

                                    {normalImage.length < 3 &&
                                        Array(3 - normalImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickNormalThumbnail}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                    <ImageUploadItem handleSelect={handleClickNormalThumbnail} />
                                </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
