import React, { useEffect } from 'react'
import PostSection from './PostSection'
import BestCommentSection from './BestCommentSection'
import FAQSection from './FAQSection'
import CalendarSection from './CalendarSection'
import { useDispatch, useSelector } from 'react-redux'
import { getInforDataHomePage } from '../../../reduxs/actions/home.action'

export default function DataDetailSection() {
    const dispatch = useDispatch()

    const { loadingGetInforHomePage, dataInforHomePage } = useSelector(state => state.home)
    // const { isAuthentication } = useSelector(state => state.auth)

    useEffect(() => {
        // if (isAuthentication) {
        dispatch(getInforDataHomePage())
        // }
    }, [])

    if (loadingGetInforHomePage || !dataInforHomePage) {
        return <p>Loading...</p>
    }

    const { posts, faq, product_evaluate, event } = dataInforHomePage

    return (
        <>
            <div className='lg:grid lg:grid-cols-2 lg:gap-10 flex flex-col gap-6 w-full'>
                <PostSection dataPost={posts} />
                <BestCommentSection dataComment={product_evaluate} />
            </div>

            <div className='grid lg:grid-cols-2 lg:gap-10 gap-6'>
                <FAQSection dataFaq={faq} />
                <CalendarSection dataEvent={event} />
            </div>
        </>
    )
}
