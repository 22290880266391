import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { URL_SERVER } from '../../../utils/apis'

export default function PolicyShippingSection({ data }) {
    return (
        <section>
            <TitleSection title='거래조건 정보 (환불/반품/취소)' small />

            <div className='w-full'>
                <img src={`${URL_SERVER}${data}`} alt='policy' className='w-full object-contain aspect-video' />
            </div>
        </section>
    )
}
