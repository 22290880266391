import { InfoCircle } from 'iconsax-react'
import React from 'react'
import ButtonSecon from '../../components/ButtonSecon'

export default function PopupFailPayment({ handleShow, content = '결제금액보다 사용포인트가 적어 결제에 실패했습니다.' }) {
    return (
        <div className='fixed inset-0 bg-popup z-50 flex justify-center items-center'>
            <div className='bg-white p-5 flex flex-col gap-5 rounded-20 w-[600px] items-center overflow-y-auto hiddenScroll'>
                <div className='grid gap-2.5 justify-items-center'>
                    <InfoCircle color='#FA0071' size={60} />

                    <p>{content}</p>
                </div>

                <ButtonSecon title='취소' className='w-[160px]' onClick={handleShow} />
            </div>
        </div>
    )
}
