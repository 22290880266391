import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/apis";

export const getDashboardAdmin = createAsyncThunk(
    'admin/dashboard',
    async () => {
        const response = await get(`ordermanagement/dashboard-infor`)

        return response
    }
)

export const getDashboardOrder = createAsyncThunk(
    'admin/dashboardOrder',
    async (page) => {
        const response = await get(`ordermanagement/dashboard-order?page=${page}`)

        return response
    }
)

// get list post office order
export const getPostOfficeOrder = createAsyncThunk(
    'admin/postOffice',
    async (page) => {
        const response = await get(`ordermanagement/postoffice-order/?page=${page}`)

        return response
    }
)

// download letter post office order
export const downloadPostOfficeOrder = createAsyncThunk(
    'admin/downloadPostOffice',
    async (orderId) => {
        const response = await get(`ordermanagement/postoffice-order/pdf/download/${orderId}`)

        return response
    }
)

// get list pdf order
export const getPdfOrder = createAsyncThunk(
    'admin/dpfOrder',
    async (page) => {
        const response = await get(`ordermanagement/pdf-order/?page=${page}`)

        return response
    }
)

// get list event order order
export const getEventOrder = createAsyncThunk(
    'admin/eventorder',
    async (page) => {
        const response = await get(`ordermanagement/makebyme-event-order/?page=${page}`)

        return response
    }
)

// get list handmake order order
export const getHandmakeOrder = createAsyncThunk(
    'admin/handmakeorder',
    async (page) => {
        const response = await get(`ordermanagement/makebyme-normal-order/?page=${page}`)

        return response
    }
)

// get detail post office
export const getDetailPostOffice = createAsyncThunk(
    'admin/detailPostOffice',
    async (orderId) => {
        const response = await get(`ordermanagement/detail-order/${orderId}`)

        return response
    }
)

// get detail post office update
export const getDetailPostOfficeUpdate = createAsyncThunk(
    'admin/detailPostOfficeUpdate',
    async (orderId) => {
        const response = await get(`productmanage/productinforupdate/${orderId}`)

        return response
    }
)

// update detail post office update
export const updateDetailPostOfficeUpdate = createAsyncThunk(
    'admin/updatePostoffice',
    async (data) => {
        const { formData, productId } = data

        const response = await post(`productmanage/update-postofficeproduct/${productId}`, formData)

        return response
    }
)

// update detail product event
export const updateDetailProductEvent = createAsyncThunk(
    'admin/updateDetailProductEvent',
    async (data) => {
        const { formData, productId } = data

        const response = await post(`productmanage/update-eventproduct/${productId}`, formData)

        return response
    }
)

// update detail product handmake
export const updateDetailProductHandmake = createAsyncThunk(
    'admin/updateDetailProductHandmake',
    async (data) => {
        const { formData, productId } = data

        const response = await post(`productmanage/update-makebymeproduct/${productId}`, formData)

        return response
    }
)

export const searchPostOffice = createAsyncThunk(
    'admin/searchPostOffice',
    async (data) => {
        const { page, status_id, stamp_id, condition, text, type } = data
        const response = await get(`ordermanagement/${type || 'postoffice-order'}/search/?page=${page || ''}&status_id=${status_id || ''}&stamp_id=${stamp_id || ''}&condition=${condition || ''}&text=${text || ''}`)

        return response
    }
)

// get status
export const getStatusOrder = createAsyncThunk(
    'admin/statusOrder',
    async () => {
        const response = await get(`ordermanagement/getstatus`)

        return response
    }
)

// get status voucher
export const getStatusOrderVoucher = createAsyncThunk(
    'admin/statusOrderVoucher',
    async () => {
        const response = await get(`ordermanagement/getstatus/voucher-order`)

        return response
    }
)

// change status order 
export const changeStatusOrder = createAsyncThunk(
    'order/changeStatus',
    async (data) => {
        const response = await post(`ordermanagement/update-orderstatus`, data)

        return response
    }
)

// update shipment code
export const updateShipmentCode = createAsyncThunk(
    'order/updateShipmentCode',
    async (data) => {
        const response = await post(`ordermanagement/update-ordershipmentcode`, data)

        return response
    }
)
