import React, { useEffect, useRef, useState } from 'react'
import { URL_SERVER } from '../../utils/apis';

import "react-quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';

export default function LetterQuillRead({ editorState, endThumbnail, widthElm }) {
    const ref = useRef(null);

    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const adjustContainerSize = () => {
            const scaleFactor = widthElm / 800;
            setContainerSize({
                width: 800 * scaleFactor,
                height: (800 * scaleFactor) * (10 / 7.08)
            });
        };

        adjustContainerSize();
        window.addEventListener('resize', adjustContainerSize);

        return () => {
            window.removeEventListener('resize', adjustContainerSize);
        };
    }, [widthElm]);

    return (
        <div
            style={{
                height: `${containerSize.height}px`,
                width: `${containerSize.width}px`
            }}
            ref={ref}
        >
            <div className='w-[800px] pt-[115px] pb-[122px] px-16 h-[1130px] bg-no-repeat bg-center bg-contain origin-top-left break-all letteritem'
                style={{
                    backgroundImage: `url('${URL_SERVER}${endThumbnail}')`,
                    transform: `scale(${widthElm / 800})`,
                }}
            >
                <ReactQuill
                    modules={{ toolbar: false }}
                    value={editorState}
                    readOnly={true}
                    style={{ width: "100%", height: '100%' }}
                />
            </div>
        </div>
    )
}
