import React, { useEffect, useState } from 'react'
import MaterialItem from '../../../components/user/MaterialItem'
import { formatMoney } from '../../../utils/renderText';

export default function TotalFileMasterial({ materials }) {
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        if (materials.length > 0) {
            const totalMaterial = materials.reduce((total, item) => total + item.price, 0);

            setTotalPrice(totalMaterial);
        }
    }, [materials])

    return (
        <section className='w-full flex flex-col items-center gap-5'>
            <div className='flex justify-between items-center w-full max-lg:text-14_20'>
                <div className='flex gap-2 items-center'>
                    <p>자체제작 자료:</p>
                    <span className='text-logoColor font-bold'>{materials.length}장</span>
                </div>

                <div className='flex gap-2 items-center'>
                    <p>총액:</p>
                    <span className='text-logoColor font-bold'>{formatMoney(totalPrice)} 원</span>
                </div>
            </div>

            <div className='grid lg:grid-cols-4 grid-cols-3 gap-5'>
                {
                    materials.map(item => <MaterialItem key={item.id} image={item.frontThumbnail} name={item.frontThumbnail} />)
                }
            </div>
        </section>
    )
}
