import React from 'react'
import TitleSection from '../../../components/user/TitleSection'
import LettersItem from '../../../components/user/LettersItem'

export default function OrderProduct({ data }) {
    return (
        <section>
            <TitleSection title='다른 상품들도 있어요!' small />

            <div className='grid xl:grid-cols-6 lg:grid-cols-5 grid-cols-3 gap-5'>
                {
                    data.map(item => <LettersItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        price={item.price}
                        frontThumbnail={item.frontThumbnail}
                        endThumbnail={item.endThumbnail}
                        sold_out={item.sold_out}
                    />)
                }
            </div>
        </section>
    )
}
