import React, { useState } from 'react'
import ButtonBlack from '../ButtonBlack'
import ButtonSecon from '../ButtonSecon'
import ButtonPrimary from '../ButtonPrimary'
import { useDispatch } from 'react-redux'
import { deleteAccount } from '../../reduxs/actions/auth.action'
import { useNavigate } from 'react-router-dom'
import { clearLocalStorage } from '../../utils/storage'

export default function PopupShowWarningUser() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isNotifycation, setIsNotifycation] = useState(false)

    const handleShow = () => {
        setIsShowPopup(!isShowPopup)
    }

    const handleConfirm = async () => {
        const result = await dispatch(deleteAccount())

        const res = result.payload

        if (res.status) {
            setIsNotifycation('회원 자격을 종료했습니다')

            setTimeout(() => {
                clearLocalStorage()
                navigate('/')
            }, 3000);
        }
    }

    return (
        <>
            <ButtonBlack text='탈퇴' onclick={handleShow} />

            {
                isShowPopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50 text-black'>
                    <div className='p-5 rounded-20 bg-white max-w-[370px] w-full overscroll-y-auto hiddenScroll flex flex-col items-center gap-5 text-center'>
                        <p>
                            90일 이후 재가입이 가능합니다.<br />
                            그래도 탈퇴하시겠습니까?
                        </p>

                        <div className='flex gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px] flex justify-center' onClick={handleShow} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='button' onClick={handleConfirm} />
                        </div>
                    </div>
                </div>
            }

            <div className={`${isNotifycation ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotifycation}
            </div>
        </>
    )
}
