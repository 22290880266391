import React from 'react'
import { Edit2 } from 'iconsax-react'
import { URL_SERVER } from '../../utils/apis'
import { formatMoney } from '../../utils/renderText'

export default function ShippingItem({ title, price, image, name, onClick, page = false }) {
    return (
        <div className='bg-white p-5 rounded-20'>
            <h4 className='mb-2.5 font-semibold'>{title}</h4>

            <div className='flex justify-between items-center'>
                <h5>{name}</h5>

                <button className='p-2.5' type='button' onClick={onClick}><Edit2 size={20} color='#BABABA' /></button>
            </div>

            <div className='rounded-20 border border-strock w-full flex flex-col gap-2.5 items-center p-5 relative'>
                {image && <img src={`${URL_SERVER}${image}`} alt='' className='w-[100px] aspect-square object-cover' />}

                <p className='text-logoColor'>{page ? `${price} 장까지` : `₩ ${formatMoney(price)} 원`}</p>
            </div>
        </div>
    )
}
