import { createSlice } from "@reduxjs/toolkit";
import { autoSaveLetter, createProductEvent, createProductHandmade, createProductNormal, deleteProductAdmin, deleteSavedLetter, getAllProductAdmin, getAllProducts, getDeleteLetter, getDetailMaterial, getFormLetter, getNewProduct, getProductDetail, getProductsByCategory, getProductsByType, getSavedLetter, getSavedLetterById, manualSaveLetter } from "../actions/product.action";

const productSlice = createSlice({
    name: 'product',
    initialState: {
        loadingGetNewProduct: false,
        dataNewProduct: undefined,

        loadingGetAllProduct: false,
        dataAllProduct: undefined,
        countAllproduct: undefined,

        loadingProductByCategory: false,

        detailProduct: undefined,
        loadingGetDetailProduct: false,

        loadingCreateProductNormal: false,
        loadingCreateProductEvent: false,
        loadingCreateProductHand: false,

        loadingGetAllProductAdmin: false,
        dataAllProductAdmin: undefined,
        countProductAdmin: 0,

        loadingGetFormLetter: false,
        dataFormLetter: undefined,

        loadingAutosaveLetter: false,
        saveId: null,
        updatedLetterAt: 0,

        loadingManualSaveLetter: false,

        loadingGetSavedLetter: false,
        dataSavedLetter: undefined,

        loadingGetSavedLetterById: false,
        dataSavedLetterById: undefined,

        loadingProductByType: false,
        dataProductByType: undefined,

        loadingGetDetailMaterial: false,
        dataDetailMaterial: undefined,

        loadingDeleteSavedLetter: false,
        loadingDeleteProductAdmin: false,
    },
    reducers: {
        clearSaveId: (state, action) => {
            state.saveId = null
        },
        clearSaveLetterId: (state, action) => {
            state.dataSavedLetterById = null
        },
    },
    extraReducers: builder => {
        // ================================== user ================================== //

        // get all product
        builder.addCase(getAllProducts.pending, (state, action) => {
            state.loadingGetAllProduct = true
        })
        builder.addCase(getAllProducts.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAllProduct = res.data.products
                state.countAllproduct = res.data.productCount
            }

            state.loadingGetAllProduct = false
        })

        // get new product
        builder.addCase(getNewProduct.pending, (state, action) => {
            state.loadingGetNewProduct = true
        })
        builder.addCase(getNewProduct.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataNewProduct = res.data
            }

            state.loadingGetNewProduct = false
        })

        // get product by category id
        builder.addCase(getProductsByCategory.pending, (state, action) => {
            state.loadingProductByCategory = true
        })
        builder.addCase(getProductsByCategory.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAllProduct = res.data.products
                state.countAllproduct = res.data.productCount
            }

            state.loadingProductByCategory = false
        })

        // get product by product id
        builder.addCase(getProductsByType.pending, (state, action) => {
            state.loadingProductByType = true
        })
        builder.addCase(getProductsByType.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataProductByType = res.data
            }

            state.loadingProductByType = false
        })

        // get product detail
        builder.addCase(getProductDetail.pending, (state, action) => {
            state.loadingGetDetailProduct = true
        })
        builder.addCase(getProductDetail.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.detailProduct = res.data
            }

            state.loadingGetDetailProduct = false
        })

        // get form letter
        builder.addCase(getFormLetter.pending, (state, action) => {
            state.loadingGetFormLetter = true
        })
        builder.addCase(getFormLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataFormLetter = res.data
            }

            state.loadingGetFormLetter = false
        })

        // auto save letter
        builder.addCase(autoSaveLetter.pending, (state, action) => {
            state.loadingAutosaveLetter = true
        })
        builder.addCase(autoSaveLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.saveId = res.data.save_id
                state.updatedLetterAt = res.data.updated_at
            }

            state.loadingAutosaveLetter = false
        })

        // manual save letter
        builder.addCase(manualSaveLetter.pending, (state, action) => {
            state.loadingManualSaveLetter = true
        })
        builder.addCase(manualSaveLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.saveId = res.data.save_id
                state.updatedLetterAt = res.data.updated_at
            }

            state.loadingManualSaveLetter = false
        })

        // manual save letter
        builder.addCase(getSavedLetter.pending, (state, action) => {
            state.loadingGetSavedLetter = true
        })
        builder.addCase(getSavedLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataSavedLetter = res.data
            }

            state.loadingGetSavedLetter = false
        })

        // manual delete letter
        builder.addCase(getDeleteLetter.pending, (state, action) => {
            state.loadingGetSavedLetter = true
        })
        builder.addCase(getDeleteLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataSavedLetter = res.data
            }

            state.loadingGetSavedLetter = false
        })

        // get saved letter by id
        builder.addCase(getSavedLetterById.pending, (state, action) => {
            state.loadingGetSavedLetterById = true
        })
        builder.addCase(getSavedLetterById.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataSavedLetterById = res.data
            }

            state.loadingGetSavedLetterById = false
        })

        // delete saved letter by id
        builder.addCase(deleteSavedLetter.pending, (state, action) => {
            state.loadingDeleteSavedLetter = true
        })
        builder.addCase(deleteSavedLetter.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const type = res.data.type_id

                if (Number(type) === 1) {
                    const updateData = state.dataSavedLetter.autoSaveLetter.filter(item => item.save_id !== Number(res.data.save_id))

                    state.dataSavedLetter = {
                        ...state.dataSavedLetter,
                        autoSaveLetter: updateData
                    }
                } else {
                    const updateData = state.dataSavedLetter.manualSaveLetter.filter(item => item.save_id !== Number(res.data.save_id))

                    state.dataSavedLetter = {
                        ...state.dataSavedLetter,
                        manualSaveLetter: updateData
                    }
                }
            }

            state.loadingDeleteSavedLetter = false
        })

        // get saved letter by id
        builder.addCase(getDetailMaterial.pending, (state, action) => {
            state.loadingGetDetailMaterial = true
        })
        builder.addCase(getDetailMaterial.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataDetailMaterial = res.data
            }

            state.loadingGetDetailMaterial = false
        })

        // ================================== admin ================================== //

        // create product normal
        builder.addCase(createProductNormal.pending, (state, action) => {
            state.loadingCreateProductNormal = true
        })
        builder.addCase(createProductNormal.fulfilled, (state, action) => {
            // const res = action.payload

            // if (res.status) {
            //     state.detailProduct = res.data
            // }

            state.loadingCreateProductNormal = false
        })

        // create product event
        builder.addCase(createProductEvent.pending, (state, action) => {
            state.loadingCreateProductEvent = true
        })
        builder.addCase(createProductEvent.fulfilled, (state, action) => {
            // const res = action.payload

            state.loadingCreateProductEvent = false
        })

        // create product handmade
        builder.addCase(createProductHandmade.pending, (state, action) => {
            state.loadingCreateProductHand = true
        })
        builder.addCase(createProductHandmade.fulfilled, (state, action) => {
            // const res = action.payload

            state.loadingCreateProductHand = false
        })

        // get all product admin
        builder.addCase(getAllProductAdmin.pending, (state, action) => {
            state.loadingGetAllProductAdmin = true
        })
        builder.addCase(getAllProductAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAllProductAdmin = res.data.products
                state.countProductAdmin = res.data.countProduct
            }

            state.loadingGetAllProductAdmin = false
        })

        // delete product admin
        builder.addCase(deleteProductAdmin.pending, (state, action) => {
            state.loadingDeleteProductAdmin = true
        })
        builder.addCase(deleteProductAdmin.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataAllProductAdmin.filter(item => item.id !== Number(res.data.id))

                if (res.data.replaceProduct) {
                    state.dataAllProductAdmin = [
                        ...updateData,
                        res.data.replaceProduct
                    ]

                    return
                }

                state.dataAllProductAdmin = updateData
                state.countProductAdmin -= 1
            }

            state.loadingDeleteProductAdmin = false
        })
    }
})

export const { clearSaveId, clearSaveLetterId } = productSlice.actions
export default productSlice.reducer
