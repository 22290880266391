import React, { useEffect, useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import Dropdown from '../../components/admin/Dropdown'
import { ButtonLinkSecon } from '../../components/ButtonSecon'
import ListProduct from '../../sections/admin/products/ListProduct'
import { getAllProductAdmin } from '../../reduxs/actions/product.action'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategories } from '../../reduxs/actions/category.action'

export default function ProductHandmadeAdmin() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const [categoryId, setCategoryId] = useState()

    const { loadingGetAllProductAdmin, dataAllProductAdmin, countProductAdmin } = useSelector(state => state.product)
    const { dataCategories } = useSelector(state => state.categories)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countProductAdmin / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    const handleChangeCategory = (category) => {
        setCategoryId(category)
    }

    useEffect(() => {
        const data = {
            productType: 3,
            page: page,
            category: categoryId
        }

        dispatch(getAllProductAdmin(data))
    }, [page, dispatch, categoryId])

    useEffect(() => {
        dispatch(getAllCategories(3))
    }, [dispatch])

    return (
        <>
            <section className='flex justify-between items-center'>
                <TitleSection title='자체제작 상품' mt={false} />

                <div className='flex items-center gap-5'>
                    <Dropdown
                        data={dataCategories}
                        name='name'
                        title='모든 카테고리'
                        className='w-[210px]'
                        handleChoose={handleChangeCategory}
                        isChoose={categoryId}
                    />

                    <ButtonLinkSecon title='상품 추가하기' className='w-fit' link='add' />
                </div>
            </section>

            {
                (loadingGetAllProductAdmin || !dataAllProductAdmin)
                    ?
                    <p>Loading</p>
                    :
                    <ListProduct
                        data={dataAllProductAdmin}
                        countProductAdmin={countProductAdmin}
                        page={page}
                        handlePage={handlePage}
                    />
            }
        </>
    )
}

