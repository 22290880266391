import React, { useEffect, useRef, useState } from 'react'
import ButtonPrimary, { ButtonPrimaryLink } from '../../../components/ButtonPrimary'
import { CloudAdd } from 'iconsax-react'
import CountdownTimer from '../../../components/user/CountdownTimer'
import { useDispatch, useSelector } from 'react-redux';
import { autoSaveLetter, manualSaveLetter } from '../../../reduxs/actions/product.action';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ButtonSecon from '../../../components/ButtonSecon';
import InputFormThin from '../../../components/InputFormThin';
import TitleSection from '../../../components/user/TitleSection';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../../utils/error';
import NotifySaveLetter from '../../../components/user/NotifySaveLetter';

export default function SaveLetterSection({ editors }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    const { saveId } = useSelector(state => state.product)

    const { letter } = useParams()

    const letterSaved = searchParams.get('savedLetter')

    const [showNotify, setShowNotify] = useState(false)

    const formik = useFormik({
        initialValues: {
            filename: ''
        },
        validationSchema: Yup.object({
            filename: Yup.string().required(ERROR_REQUIRED)
        }),
        onSubmit: async values => {
            const dataSubmit = {
                save_id: letterSaved || saveId,
                filename: values.filename,
                product_id: letter,
                content: JSON.stringify(editors),
                page_total: editors.length
            }

            const result = await dispatch(manualSaveLetter(dataSubmit))

            const res = result.payload

            if (res.status) {
                setShowNotify(true)

                navigate(`/writing/${letter}?savedLetter=${res.data.save_id}`)

                setTimeout(() => {
                    setShowNotify(false)
                }, 3000);
            }
        }
    })

    // auto save
    const saveAllEditors = async (savedId) => {
        const dataUpload = {
            save_id: letterSaved || savedId,
            product_id: letter,
            content: JSON.stringify(editors),
            page_total: editors.length
        }

        const result = await dispatch(autoSaveLetter(dataUpload))

        const res = result.payload

        if (res.status) {
            setShowNotify(true)
            navigate(`/writing/${letter}?savedLetter=${res.data.save_id}`)

            setTimeout(() => {
                setShowNotify(false)
            }, 3000);
        }
    };

    // manual save 
    const handleManualSave = async (savedId) => {
        const dataSubmit = {
            save_id: letterSaved || savedId,
            filename: formik.values.filename,
            product_id: letter,
            content: JSON.stringify(editors)
        }

        const result = await dispatch(manualSaveLetter(dataSubmit))

        const res = result.payload

        if (res.status) {
            setShowNotify(true)
            navigate(`/writing/${letter}?savedLetter=${res.data.save_id}`)

            setTimeout(() => {
                setShowNotify(false)
            }, 3000);
        }
    }

    useEffect(() => {
        const autoSaveInterval = setInterval(() => {
            saveAllEditors(saveId);
        }, 3000000);

        return () => clearInterval(autoSaveInterval);
    }, [saveId]);

    return (
        <>
            <NotifySaveLetter isShow={showNotify} content='편지지가 저장되었습니다.' />

            <div className='flex justify-between items-center w-full max-w-[800px]'>
                <CountdownTimer detail={false} />

                <div className='flex gap-2.5'>
                    <ButtonManualSave formik={formik} isFirstSave={(!!formik.values.filename || !!letterSaved)} saveId={saveId} handleManualSave={handleManualSave} />

                    <ButtonPrimaryLink text='저장된편지지' link={`/saved-letter/${letter}`} />
                </div>
            </div>
        </>
    )
}

const ButtonManualSave = ({ formik, isFirstSave, saveId, handleManualSave }) => {
    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div>
            <button
                type='button'
                className='flex gap-2 rounded-12 lg:py-2.5 lg:px-4 py-2 px-[14px] max-lg:text-14_20 bg-blue text-white items-center'
                onClick={() => {
                    isFirstSave ? handleManualSave(saveId) : handleShow()
                }}
            >
                <CloudAdd /> 저장
            </button>

            {
                (isShow)
                &&
                <div onSubmit={formik.handleSubmit} className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <form className='bg-white rounded-20 p-5 w-[600px] flex flex-col gap-5' ref={contentRef}>
                        <TitleSection title='파일 이름' mt={false} className='text-center w-full' />

                        <InputFormThin
                            name='filename'
                            onChange={formik.handleChange}
                            value={formik.values.filename}
                            error={(formik.touched.filename && formik.errors.filename) && formik.errors.filename}
                            placehoder='텍스트 입력'
                        />

                        <div className='flex justify-center gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px]' onClick={handleShow} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='button' onClick={formik.handleSubmit} />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
