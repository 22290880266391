import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import SaveLetterSection from './SaveLetterSection'
import ButtonSecon from '../../../components/ButtonSecon'
import { AddCircle } from 'iconsax-react'
import { getFormLetter, getSavedLetterById, manualSaveLetter } from '../../../reduxs/actions/product.action'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LoadingUser from '../../../pages/user/LoadingUser'
import { clearSaveId } from '../../../reduxs/reducers/product.reducer'
import moment from 'moment/moment'
import { useFormik } from 'formik'
import { ERROR_REQUIRED } from '../../../utils/error'
import TitleSection from '../../../components/user/TitleSection'
import InputFormThin from '../../../components/InputFormThin'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { UploadLetterContext } from '../../../contexts/uploadLetterContext'
import * as Yup from 'yup'
import ButtonDeactive from '../../../components/ButtonDeactive'
import LetterQuill from '../../../components/user/LetterQuill'

export default function WritingStep() {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const ref = useRef()

    const [widthElm, setWidthElm] = useState(0)

    const [searchParams] = useSearchParams()

    const savedLetter = searchParams.get('savedLetter')

    const { loadingGetFormLetter, dataFormLetter, updatedLetterAt, dataSavedLetterById } = useSelector(state => state.product)

    const { letter } = useParams()

    const [editors, setEditors] = useState([
        {
            id: 1,
            editorState: '',
        }
    ]);

    // update editor state
    const updateEditorState = (id, content) => {
        const currentEditor = editors.find(editor => editor.id === id);

        if (!currentEditor) {
            return;
        }

        setEditors(prevEditors =>
            prevEditors.map(editorItem =>
                editorItem.id === id ? { ...editorItem, editorState: content } : editorItem
            )
        );

        return
    };

    // create letter item
    const createLetterItem = () => {
        if (editors.length < 10) {
            setEditors([
                ...editors,
                {
                    id: editors.length + 1 + Date.now(),
                    editorState: '',
                }
            ])
        }
    }

    // delete letter item
    const handleDeleteEditor = (id) => {
        const handle = editors.filter(editor => editor.id !== id)

        setEditors(handle)
    }

    useEffect(() => {
        setWidthElm(ref.current ? ref.current.offsetWidth : 0);
    }, [dataFormLetter])

    // clear save id
    useLayoutEffect(() => {
        dispatch(clearSaveId())
    }, [])

    // get form letter
    useEffect(() => {
        dispatch(getFormLetter(letter))
    }, [letter, dispatch])

    // check param 
    useEffect(() => {
        if (savedLetter) {
            const fetchSaveLetter = async () => {
                const result = await dispatch(getSavedLetterById(savedLetter))

                const res = result.payload

                if (!res.status) {
                    setEditors([{ id: 1, editorState: '' }])
                    navigate(`/writing/${letter}`)
                }
            }

            fetchSaveLetter()
        } else {
            setEditors([{ id: 1, editorState: '' }])
        }
    }, [savedLetter])

    // gan content
    useEffect(() => {
        if (dataSavedLetterById) {
            const { content } = dataSavedLetterById

            const contentParse = JSON.parse(content)

            const restoredEditors = contentParse.map(editor => {
                return {
                    id: editor.id,
                    editorState: editor.editorState
                };
            });

            setEditors(restoredEditors)
        }
    }, [dataSavedLetterById])

    if (loadingGetFormLetter || !dataFormLetter) {
        return <LoadingUser />
    }

    const { endThumbnail, number_character, sold_out } = dataFormLetter
    const letter_name = dataSavedLetterById?.letter_name

    return (
        <>
            <section className='flex flex-col gap-5 items-center justify-center w-full'>
                <SaveLetterSection editors={editors} />

                <div className='grid gap-10 w-full justify-items-center max-w-[800px]' ref={ref}>
                    {
                        editors.map((item, index) =>
                            <LetterQuill
                                key={item.id}
                                page={index + 1}
                                id={item.id}
                                number_line={20}
                                number_character={Number(number_character)}
                                endThumbnail={endThumbnail}
                                editorState={item.editorState}
                                setEditorState={updateEditorState}
                                deleteEditor={() => handleDeleteEditor(item.id)}
                                widthElm={widthElm}
                            />
                        )
                    }
                </div>

                {
                    editors.length < 10 && <button type='button' onClick={createLetterItem} className='strock1'><AddCircle size={30} /></button>
                }

                <div className='flex justify-between lg:items-end items-center max-lg:flex-col gap-4 w-full max-w-[800px]'>
                    <div className='flex gap-5 max-lg:text-12'>
                        <p>{editors.length} / 10 페이지</p>

                        {
                            updatedLetterAt ? <p>최신 업데이트 시간 {moment(updatedLetterAt).format('hh:mm')}</p> : ''
                        }
                    </div>

                    <div className='flex gap-2.5'>
                        {
                            sold_out
                                ?
                                <ButtonDeactive text='다음' />
                                :
                                <ButtonNextStep editors={editors} letter_name={letter_name} />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const ButtonNextStep = ({ editors, letter_name }) => {
    const dispatch = useDispatch()

    const { handleNavigateStep } = useContext(UploadLetterContext)

    const { saveId } = useSelector(state => state.product)
    const [searchParams, setSearchParams] = useSearchParams();

    const savedLetter = searchParams.get('savedLetter')

    const { letter } = useParams()

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const formik = useFormik({
        initialValues: {
            filename: ''
        },
        validate: values => {
            const errors = {}

            if (!savedLetter && !values.filename) {
                errors.filename = ERROR_REQUIRED
            }

            return errors
        },
        validationSchema: Yup.object({
            filename: Yup.string()
        }),
        onSubmit: async values => {
            const dataSubmit = {
                save_id: saveId || savedLetter,
                filename: values.filename || letter_name,
                product_id: letter,
                content: JSON.stringify(editors),
                page_total: editors.length
            }

            const result = await dispatch(manualSaveLetter(dataSubmit))

            const res = result.payload

            if (res.status) {
                setSearchParams(params => {
                    params.set('savedLetter', res.data.save_id)
                    return params;
                })
                handleNavigateStep('next')
            }
        }
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <div>
            <ButtonSecon title='다음' className='w-[160px] justify-center' onClick={(letter_name || formik.values.filename && savedLetter) ? formik.handleSubmit : handleShow} />

            {
                (isShow)
                &&
                <div onSubmit={formik.handleSubmit} className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <form className='bg-white rounded-20 p-5 w-[600px] flex flex-col gap-5 mx-5' ref={contentRef}>
                        <TitleSection title='파일 이름' mt={false} className='text-center w-full' />

                        <InputFormThin
                            name='filename'
                            onChange={formik.handleChange}
                            value={formik.values.filename}
                            error={(formik.touched.filename && formik.errors.filename) && formik.errors.filename}
                            placehoder='편지지 저장 제목을 입력해주세요'
                        />

                        <div className='flex justify-center gap-2.5'>
                            <ButtonSecon title='취소' className='w-[160px]' onClick={handleShow} />
                            <ButtonPrimary text='확인' className='w-[160px]' type='button' onClick={formik.handleSubmit} />
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
