import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react'

export default function CountdownTimer({ detail }) {
    const calculateTimeLeft = () => {
        const nowInKorea = moment.tz(moment.now(), 'Asia/Seoul');

        const targetInKorea = new Date();

        // Đặt mục tiêu là 3 giờ chiều hôm nay
        targetInKorea.setHours(15, 0, 0, 0);

        // Nếu hiện tại đã qua 3 giờ chiều, chuyển sang 3 giờ chiều ngày hôm sau
        if (nowInKorea > targetInKorea) {
            targetInKorea.setDate(targetInKorea.getDate() + 1);
        }

        // Tính toán thời gian còn lại bằng milisecond
        const difference = targetInKorea - nowInKorea;

        // Chuyển từ milliseconds sang giây
        return Math.floor(difference / 1000);
    };

    // Đặt thời gian đếm ngược là 3 giờ (10800 giây)
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Hàm để chuyển đổi từ giây sang giờ, phút, giây
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const formatTimeDetail = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `남은시간 ${hours.toString().padStart(2, '0')}시간 ${minutes.toString().padStart(2, '0')}분 ${secs.toString().padStart(2, '0')}초`;
    };

    return (
        <>
            {
                detail
                    ?
                    <p>{formatTimeDetail(timeLeft)}</p>
                    :
                    <p className='lg:text-24-30 font-semibold text-logoColor'>{formatTime(timeLeft)}</p>
            }
        </>
    )
}
