import { useState } from "react";

// phone number
export const usePhoneNumberFormik = (formik, fieldName) => {
    const [value, setValue] = useState(formik.values[fieldName]);

    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '');

        const match1 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (match1) {
            return `${match1[1]}-${match1[2]}-${match1[3]}`;
        }

        return input;
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const formattedValue = formatPhoneNumber(inputValue);
        setValue(formattedValue);
        formik.setFieldValue(fieldName, formattedValue);
    };

    return [value, handleChange];
}

// birthday
export const useBirthdayFormik = (formik, fieldName) => {
    const [value, setValue] = useState(formik.values[fieldName]);

    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '');

        const match1 = cleaned.match(/^(\d{4})(\d{2})(\d{2})$/);
        if (match1) {
            return `${match1[1]}-${match1[2]}-${match1[3]}`;
        }

        return input;
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const formattedValue = formatPhoneNumber(inputValue);
        setValue(formattedValue);
        formik.setFieldValue(fieldName, formattedValue);
    };

    return [value, handleChange];
}

