import React, { useEffect, useState } from 'react'
import Dropdown from '../../../components/admin/Dropdown'
import InputFormThin from '../../../components/InputFormThin'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { filterByUser, filterByUserPdf } from '../../../utils/filterArray'
import { useDispatch, useSelector } from 'react-redux'
import { getStatusOrder, searchPostOffice } from '../../../reduxs/actions/admin.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { getOrderStamp } from '../../../reduxs/actions/order.action'
import { useSearchParams } from 'react-router-dom'

export default function FilterOrders({ type }) {
    // dataPostOffice
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const [dataText, setDataText] = useState()

    const page = searchParams.get('page')
    const status_id = searchParams.get('status_id')
    const stamp_id = searchParams.get('stamp_id')
    const condition = searchParams.get('condition')

    const { loadingGetStamp, dataOrderStamp } = useSelector(state => state.order)
    const { loadingGetStatusOrder, dataStatusOrder, dataPostOffice } = useSelector(state => state.admin)

    const handleChooseStamp = (stampId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, stamp_id: stampId })
    }

    const handleChooseStatus = (statusId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, status_id: statusId })
    }

    const handleChooseCondition = (conditionId) => {
        const currentParams = Object.fromEntries([...searchParams]);

        setSearchParams({ ...currentParams, condition: conditionId })
    }

    const handleChangeInput = (e) => {
        const value = e.target.value

        setDataText(value)
    }

    const handleSearch = (e) => {
        e.preventDefault()

        const dataSearch = {
            page,
            status_id,
            stamp_id,
            condition,
            text: dataText,
            type
        }

        dispatch(searchPostOffice(dataSearch))
    }

    useEffect(() => {
        dispatch(getStatusOrder())
        dispatch(getOrderStamp(1))
    }, [])

    useEffect(() => {
        if (dataPostOffice && (page || status_id || stamp_id || condition)) {
            const dataSearch = {
                page,
                status_id,
                stamp_id,
                condition,
                text: dataText,
                type
            }

            dispatch(searchPostOffice(dataSearch))
        }
    }, [dataStatusOrder])

    if (loadingGetStatusOrder || !dataStatusOrder || loadingGetStamp || !dataOrderStamp) {
        return <LoadingUser />
    }

    return (
        <div className='grid gap-5'>
            <div className='flex justify-between items-center'>
                <div className='flex gap-5'>
                    {
                        dataOrderStamp.map(item =>
                            <button
                                key={item.id}
                                onClick={() => handleChooseStamp(item.id)}
                                className={`hover:text-logoColor duration-200 ${Number(stamp_id) === item.id && 'text-logoColor'}`}
                            >
                                {item.name}
                            </button>
                        )
                    }

                    <button className={`${!stamp_id && 'text-logoColor'} hover:text-logoColor duration-200`} onClick={() => handleChooseStamp('')}>전체</button>
                </div>

                {/* <div className='flex gap-6'>
                        <button className='flex gap-1'>
                            <ReceiveSquare size={20} />
                            <span>엑셀파일 내려받기</span>
                        </button>

                        <button className='flex gap-1'>
                            <ReceiveSquare size={20} />
                            <span>엑셀파일 내려받기</span>
                        </button>
                    </div> */}
            </div>

            <div className='flex gap-5 items-center'>
                <p>총 10개</p>

                <Dropdown
                    data={type === '' ? filterByUser : filterByUserPdf}
                    handleChoose={handleChooseCondition}
                    title='검색'
                    className='w-[160px] justify-between'
                    name='name'
                    isChoose={Number(condition)}
                />

                <form className='flex gap-2 h-full flex-grow max-w-[396px] w-full'>
                    <InputFormThin placehoder='검색어를 입력하세요' classNameAll='w-full h-full' className='h-full' onChange={handleChangeInput} />

                    <ButtonPrimary text='검색' className='flex-shrink-0' type='submit' onClick={handleSearch} />
                </form>

                <Dropdown
                    data={dataStatusOrder}
                    handleChoose={handleChooseStatus}
                    title='상태'
                    className='w-[160px] justify-between'
                    name='name'
                    isChoose={Number(status_id)}
                />

                {/* <ButtonSecon title='주문 내역 추가하기' /> */}
            </div>
        </div>
    )
}
