import React, { useEffect, useRef } from 'react'
import LabelFormThin from '../../../components/LabelFormThin'
import { ImageUploadItem } from '../createproduct/FormUpload'
import TitleSection from '../../../components/user/TitleSection'
import InputFormThin from '../../../components/InputFormThin'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategories } from '../../../reduxs/actions/category.action'
import Dropdown from '../../../components/admin/Dropdown'
import DateInputForm from '../../../components/admin/DateInputForm'
import OrderLetter from './OrderLetter'
import { URL_SERVER } from '../../../utils/apis'
import LoadingUser from '../../../pages/user/LoadingUser'

export default function FormUpdateEvent({ formik, descriptionImage }) {
    const dispatch = useDispatch()

    const descriptionImageRef = useRef()

    const { dataCategories } = useSelector(state => state.categories)

    const handleClickNormalImage = () => {
        descriptionImageRef.current.click()
    }

    const handleChooseDescriptionImage = (e) => {
        const filesArray = Array.from(e.currentTarget.files)

        const handleFile = filesArray.map((item, index) => {
            return {
                id: new Date().getTime() + index,
                file: URL.createObjectURL(item),
                currentFile: item
            }
        })

        formik.setFieldValue('descriptions', [...formik.values.descriptions, ...handleFile].splice(0, 4))
    }

    const handleDeleteDescription = (file) => {
        const updateFormik = descriptionImage.filter(item => item.id !== file.id)

        if (file.file) {
            formik.setFieldValue('descriptions', updateFormik)
        } else {
            const updateArray = [
                ...formik.values.delete_picture,
                {
                    type: 'descriptions',
                    filename: file.picture_name
                }
            ]

            formik.setValues({
                ...formik.values,
                delete_picture: updateArray,
                descriptions: updateFormik
            })
        }
    }

    // handle choose date create
    const handleChooseDateStart = (date) => {
        formik.setFieldValue('expires', date)
    }

    // handle choose category
    const handleChooseCategory = (categoryId) => {
        formik.setFieldValue('category_id', categoryId)
    }

    useEffect(() => {
        dispatch(getAllCategories(2))
    }, [])

    if (!dataCategories) {
        return <LoadingUser />
    }

    return (
        <section className='w-7/12 grid gap-5 h-fit'>
            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title='정보' small mt={false} />

                <div>
                    <LabelFormThin label='상품명 (필수)' />
                    <InputFormThin
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placehoder='텍스트입력'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>

                <div className='grid gap-2.5'>
                    <div className='flex justify-between items-center'>
                        <LabelFormThin label='편지지 세부 사항' mt={false} className='mb-0' />
                        <p>{descriptionImage.length}/4 사진</p>
                    </div>

                    <div className='grid grid-cols-4 gap-2.5'>
                        <input type='file' accept='image/*' multiple ref={descriptionImageRef} hidden onChange={handleChooseDescriptionImage} />

                        {
                            (descriptionImage && (descriptionImage.length > 0)) ?
                                <>
                                    {
                                        descriptionImage.map(item =>
                                            <ImageUploadItem
                                                key={item.id}
                                                id={item.id}
                                                image={item.file ? item.file : `${URL_SERVER}${item.filename}`}
                                                handleDelete={() => handleDeleteDescription(item)}
                                            />)
                                    }

                                    {descriptionImage.length < 4 &&
                                        Array(4 - descriptionImage.length).fill(null).map((_, index) => (
                                            <ImageUploadItem
                                                key={`empty-${index}`}
                                                handleSelect={handleClickNormalImage}
                                            />
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                    <ImageUploadItem handleSelect={handleClickNormalImage} />
                                </>
                        }
                    </div>
                </div>
            </div>

            <div className='p-5 rounded-[20px] bg-white grid gap-5'>
                <TitleSection title=' 가격 및 재고' small mt={false} />

                <div className='grid grid-cols-2 gap-5'>
                    <div>
                        <LabelFormThin label='가격 (필수)' />
                        <InputFormThin
                            placehoder='가격을 입력하세요'
                            name='price'
                            onChange={formik.handleChange}
                            value={formik.values.price}
                            error={(formik.touched.price && formik.errors.price) && formik.errors.price}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='할인' />

                        <div className='relative'>
                            <InputFormThin
                                placehoder='할인을 입력하세요'
                                name='discount'
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.discount}
                                error={(formik.touched.discount && formik.errors.discount) && formik.errors.discount}
                            />

                            <span className='absolute top-1/2 -translate-y-1/2 right-5'>%</span>
                        </div>
                    </div>

                    <div>
                        <LabelFormThin label='범주 (필수)' />
                        <Dropdown
                            title='편지지'
                            data={dataCategories}
                            name='name'
                            className='w-full'
                            handleChoose={handleChooseCategory}
                            isChoose={formik.values.category_id}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='재고 (필수)' />
                        <InputFormThin
                            name='quantity'
                            type='number'
                            onChange={formik.handleChange}
                            value={formik.values.quantity}
                            placehoder='100개'
                            error={(formik.touched.quantity && formik.errors.quantity) && formik.errors.quantity}
                        />
                    </div>

                    <div>
                        <LabelFormThin label='재고 (필수)' />
                        <DateInputForm date={formik.values.expires} handleSelectDay={handleChooseDateStart} />
                    </div>
                </div>
            </div>

            <OrderLetter />
        </section>
    )
}
