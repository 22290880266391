import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/user/TitlePage'
import CommentSection from '../../sections/user/PostDetailPage/CommentSection'
import ContentPostSection from '../../sections/user/PostDetailPage/ContentPostSection'
import { commentPost, getPostComment } from '../../reduxs/actions/posts.action'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUser from './LoadingUser'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'

export default function PostDetailPage() {
    const dispatch = useDispatch()

    const { post } = useParams()

    const { loadingGetCommentPost, dataCommentPost } = useSelector(state => state.post)

    const [replyTo, setReplyTo] = useState()

    const formik = useFormik({
        initialValues: {
            content: '',
            post_id: post,
            comment_id: undefined
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            content: Yup.string().required(ERROR_REQUIRED)
        }),
        enableReinitialize: true,
        onSubmit: async values => {
            const result = await dispatch(commentPost(values))

            const res = result.payload

            if (res.status) {
                formik.resetForm()
                setReplyTo('')
            }
        }
    })

    const handleClickReply = (comment) => {
        if (comment.commentId === formik.values.comment_id) {
            formik.setFieldValue('comment_id', '')
            formik.setFieldValue('content', ``)

            return
        }

        setReplyTo(comment.nameuser)
        formik.setFieldValue('comment_id', comment.commentId)
        formik.setFieldValue('content', `@${comment.nameuser} `)
    }

    const handleChangeComment = (e) => {
        const content = e.target.value

        if (content.trim() === '' || !content.startsWith(`@${replyTo}`)) {
            setReplyTo('');
            formik.setFieldValue('comment_id', '')
        }

        formik.handleChange(e)
    }

    useEffect(() => {
        dispatch(getPostComment(post))
    }, [post])

    if (loadingGetCommentPost || !dataCommentPost) {
        return <LoadingUser />
    }

    return (
        <main className='flex-grow lg:py-10 pt-1 pb-6 flex flex-col lg:gap-10 gap-6 container'>
            <TitlePage title='필독/ 공지사항' />

            <article className='flex flex-col lg:gap-10 gap-6 max-lg:px-6'>
                <ContentPostSection />
                <CommentSection
                    onlyView={false}
                    title='댓글'
                    data={dataCommentPost}
                    formik={formik}
                    name='content'
                    handleReply={handleClickReply}
                    onChange={handleChangeComment}
                />
            </article>
        </main>
    )
}
