import React, { useEffect, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardOrder } from '../../../reduxs/actions/admin.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import Pagination from '../../../components/Pagination'
import { formatMoney } from '../../../utils/renderText'

export default function HistoryOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetDashboardOrder, dataDashboardOrder, orderCount } = useSelector(state => state.admin)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(orderCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getDashboardOrder(page))
    }, [page])

    if (loadingGetDashboardOrder || !dataDashboardOrder) {
        return <LoadingUser />
    }

    return (
        <section>
            <TitleSection title='최근 주문 내역' small />

            <div className='w-full bg-white rounded-24 py-5'>
                <table className='w-full text-center'>
                    <thead>
                        <tr className='border-b border-strock'>
                            <th className='pb-5 px-2 max-w-[100px]'>주문번호</th>
                            <th className='pb-5 px-2 max-w-[150px]'>보내는분</th>
                            <th className='pb-5 px-2 max-w-[150px]'>받는분</th>
                            <th className='pb-5 px-2'>주소</th>
                            <th className='pb-5 px-2 max-w-[100px]'>상태</th>
                            <th className='pb-5 px-2 max-w-[100px]'>접수일</th>
                            <th className='pb-5 px-2 max-w-[150px]'>결제금액</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataDashboardOrder.map((item, index) =>
                                <OrderItem
                                    key={item.id}
                                    index={page > 1 ? page * 10 + (index + 1) : index + 1}
                                    sender={item.sender_name}
                                    receiver={item.recipient_name}
                                    address={item.recipient_address}
                                    status={item.status_name}
                                    created_at={item.created_at}
                                    money={item.money_pay}
                                />)
                        }
                    </tbody>
                </table>
                <Pagination totalCount={orderCount} currentPage={page} handlePage={handlePage} />
            </div>
        </section>
    )
}

const OrderItem = ({ index, sender, receiver, address, status, created_at, money }) => {
    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2'>{index}</td>
            <td className='py-5 px-2'>
                <p className='line-clamp-1'>{sender}</p>
            </td>
            <td className='py-5 px-2'>
                <p className='line-clamp-1'>{receiver}</p>
            </td>
            <td className='py-5 px-2'>
                <p className='text-center line-clamp-2'>{address}</p>
            </td>
            <td className='py-5 px-2'>{status}</td>
            <td className='py-5 px-2'>{moment(created_at).format('YYYY.MM.DD')}</td>
            <td className='py-5 px-2'>₩{formatMoney(money)}원</td>
        </tr>
    )
}
