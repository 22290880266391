import React, { useEffect } from 'react'
import NewProduct from './NewProduct'
import PopularProduct from '../../../pages/user/PopularProduct'
import { getNewProduct } from '../../../reduxs/actions/product.action'
import { useDispatch, useSelector } from 'react-redux'

export default function ProductsSection() {
    const dispatch = useDispatch()

    const { loadingGetNewProduct, dataNewProduct } = useSelector(state => state.product)

    useEffect(() => {
        dispatch(getNewProduct())
    }, [])

    if (loadingGetNewProduct || !dataNewProduct) {
        return <p className='text-center'>Loading...</p>
    }

    const { newProduct, popularProduct } = dataNewProduct

    return (
        <>
            <NewProduct dataNewProducts={newProduct} />
            <PopularProduct dataPopularProducts={popularProduct} />
        </>
    )
}
