import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth.reducer";
import productReducer from "./reducers/product.reducer";
import categoryReducer from "./reducers/category.reducer";
import noticeReducer from "./reducers/notice.reducer";
import advertsReducer from "./reducers/adverts.reducer";
import faqReducer from "./reducers/faq.reducer";
import orderLetterReducer from "./reducers/order-letter.reducer";
import fontfamilyReducer from "./reducers/fontfamily.reducer";
import colorReducer from "./reducers/color.reducer";
import orderReducer from "./reducers/order.reducer";
import userReducer from "./reducers/user.reducer";
import gaolReducer from "./reducers/gaoladdress.reducer";
import bannerReducer from "./reducers/banner.reducer";
import homeReducer from "./reducers/home.reducer";
import postReducer from "./reducers/posts.reducer";
import commentReducer from "./reducers/comment.reducer";
import adminReducer from "./reducers/admin.reducer";
import managementReducer from "./reducers/management.reducer";
import eventReducer from "./reducers/event.reducer";
import pointReducer from "./reducers/point.reducer";
import couponReducer from "./reducers/coupon.reducer";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import { thunk } from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage,
    // stateReconciler: autoMergeLevel2, 
    whitelist: ['orderLetter']
}

const rootReducer = combineReducers({
    auth: authReducer,
    product: productReducer,
    categories: categoryReducer,
    notice: noticeReducer,
    advert: advertsReducer,
    faq: faqReducer,
    orderLetter: orderLetterReducer,
    font: fontfamilyReducer,
    color: colorReducer,
    order: orderReducer,
    user: userReducer,
    gaol: gaolReducer,
    banner: bannerReducer,
    home: homeReducer,
    post: postReducer,
    comment: commentReducer,
    admin: adminReducer,
    management: managementReducer,
    event: eventReducer,
    point: pointReducer,
    coupon: couponReducer,
})

const pReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: pReducer,
    devTools: 'production',
    middleware: () => { return [thunk] }
});
export const persistor = persistStore(store);