import React, { useEffect, useRef, useState } from 'react'
import { AddCircle, CloseSquare } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { createEventAdmin, deleteEventAdmin, getEventAdmin } from '../../../reduxs/actions/event.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import { URL_SERVER } from '../../../utils/apis'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'

export default function EventAdmin() {
    const dispatch = useDispatch()
    const inputRef = useRef()

    const { loadingGetEventAdmin, dataEventAdmin } = useSelector(state => state.event)

    const [image, setImage] = useState()
    const [isShowDeletePopup, setIsShowDeletePopup] = useState()
    const [isNotify, setIsNotify] = useState(false)

    const [isImageDelete, setIsImageDelete] = useState()

    const handleDeleteImage = (eventId) => {
        setIsShowDeletePopup(!isShowDeletePopup)

        if (isShowDeletePopup) setIsImageDelete()
        else setIsImageDelete(eventId)
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(deleteEventAdmin(isImageDelete))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')

            setIsImageDelete()
            setIsShowDeletePopup()
            setImage()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    const handleClickInput = () => {
        inputRef.current.click()
    }

    const handleChangeImage = async (e) => {
        const files = e.currentTarget.files
        const fileArray = Array.from(files)

        const formData = new FormData()

        formData.append('event', files[0])

        const createEvent = await dispatch(createEventAdmin(formData))

        const res = createEvent.payload

        if (res.status) {
            const newImages = fileArray.map((item, index) => {
                return {
                    id: res.data,
                    file: URL.createObjectURL(item)
                };
            });

            setImage(newImages[0])

            setIsNotify('이미지를 추가했습니다.')
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    useEffect(() => {
        dispatch(getEventAdmin())
    }, [])

    useEffect(() => {
        if (dataEventAdmin) {
            setImage(dataEventAdmin)
        }
    }, [dataEventAdmin])

    if (loadingGetEventAdmin) {
        return <LoadingUser />
    }

    return (
        <section className='flex flex-col gap-5'>
            <h3 className='font-semibold'>Madebyme Calendar 내용 추가하기</h3>

            <div className='grid grid-cols-2 gap-5'>
                <div className={`aspect-[3/2] border-dashed ${image ? '' : 'border-[2px]'} rounded-20 relative overflow-hidden`}>
                    <input
                        type='file'
                        accept="image/*"
                        ref={inputRef}
                        onChange={(e) => handleChangeImage(e)}
                        hidden
                    />

                    {
                        image
                            ?
                            <>
                                <button type='button' className='absolute top-5 right-5 text-deactive' onClick={() => handleDeleteImage(image?.id)}><CloseSquare size={20} /></button>
                                <img src={`${image?.file ? image?.file : `${URL_SERVER}${image?.filename}`} `} alt='front thumbnail' className='w-full h-full object-cover' />
                            </>
                            :
                            <div className='flex flex-col gap-2.5 items-center justify-center w-full h-full cursor-pointer' onClick={handleClickInput}>
                                <AddCircle size={40} variant="Outline" className='strock1' />

                                <div className='text-center'>
                                    <p>이미지 비율 3x2</p>
                                    <p>(최대 10MB)</p>
                                </div>
                            </div>
                    }
                </div>
            </div>

            {
                isShowDeletePopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-10'>
                    <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                        <div className='text-center'>
                            <p>이미지를 삭제하시겠습니까?</p>
                            <p>삭제된 이미지는 복구할 수 없습니다.</p>
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleDeleteImage} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' onClick={handleConfirmDelete} />
                        </div>
                    </div>
                </div>
            }

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </section>
    )
}
