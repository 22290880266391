// get local storage
export const getLocalStorage = (key) => {
    return localStorage.getItem(key)
}

// set local storage
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

// clear local storage
export const clearLocalStorage = () => {
    localStorage.clear()
}

// set session storage
export const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value)
}

// get session storage
export const getSessionStorage = (key) => {
    try {
        return sessionStorage.getItem(key);
    } catch (error) {
        console.error('Error accessing sessionStorage', error);
        return null;
    }
}

// remove session storage
export const removeSessionStorage = (key) => {
    try {
        return sessionStorage.removeItem(key);
    } catch (error) {
        console.error('Error remove sessionStorage', error);
        return null;
    }
}