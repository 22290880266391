import React, { useEffect, useState } from 'react'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
import LabelFormThin from '../../../components/LabelFormThin'
import InputFormThin from '../../../components/InputFormThin'
import Dropdown from '../../../components/admin/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getGenders, register } from '../../../reduxs/actions/auth.action'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_PASSWORD_CONFIRM, ERROR_PASWORD_FORMAT, ERROR_PHONE_FORMAT, ERROR_REQUIRED } from '../../../utils/error'
import { REGEX_BIRTHDAY_NUMBER, REGEX_PASWORD, REGEX_PHONE_NUMBER } from '../../../utils/regex'
import { useBirthdayFormik, usePhoneNumberFormik } from '../../../hooks/formatNumber'

export default function CreateUserNormal() {
    const dispatch = useDispatch()

    const { dataGender } = useSelector(state => state.auth)

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isNotify, setIsNotify] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            name: '',
            phone_number: '',
            dob: '',
            gender: 1
        },
        validationSchema: Yup.object({
            email: Yup.string().required(ERROR_REQUIRED),
            password: Yup.string().matches(REGEX_PASWORD, ERROR_PASWORD_FORMAT).required(ERROR_REQUIRED),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], ERROR_PASSWORD_CONFIRM).required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            phone_number: Yup.string().matches(REGEX_PHONE_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED),
            dob: Yup.string().matches(REGEX_BIRTHDAY_NUMBER, ERROR_PHONE_FORMAT).required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const result = await dispatch(register(values))

            const res = result.payload

            if (res.status) {
                setIsNotify('새 사용자를 성공적으로 생성했습니다')

                setTimeout(() => {
                    setIsNotify()
                }, 3000);
            } else {
                setIsNotify(res.message)
            }
        }
    })

    const handleChooseGender = (genderId) => {
        formik.setFieldValue('gender', genderId)
    }

    const handleShowPopup = () => {
        setIsShowPopup(!isShowPopup)
    }

    const [phoneNumber, handlePhoneNumberChange] = usePhoneNumberFormik(formik, 'phone_number')
    const [dob, handleDob] = useBirthdayFormik(formik, 'dob')

    useEffect(() => {
        dispatch(getGenders())
    }, [])

    return (
        <>
            <ButtonSecon title='사용자 추가하기' onClick={handleShowPopup} className='w-fit' />

            {
                isShowPopup
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-30'>
                    <form onSubmit={formik.handleSubmit} className='w-[600px] max-h-[600px] overflow-y-auto hiddenScroll bg-white p-5 rounded-20 flex flex-col gap-5 items-center'>
                        <h3 className='text-24-30 font-semibold'>사용자 추가하기</h3>

                        <div className='w-full grid gap-5'>
                            <div>
                                <LabelFormThin label='이메일' />
                                <InputFormThin
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    placehoder='사용중인 이메일 주소를 입력 하세요'
                                    error={(formik.touched.email && formik.errors.email) && formik.errors.email}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='비밀번호' />
                                <InputFormThin
                                    name='password'
                                    type='password'
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    placehoder='영문+숫자 6~20자리'
                                    error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='비밀번호 확인' />
                                <InputFormThin
                                    name='confirmPassword'
                                    type='password'
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    placehoder='비밀번호 확인을 위해 재입력'
                                    error={(formik.touched.confirmPassword && formik.errors.confirmPassword) && formik.errors.confirmPassword}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='이름' />
                                <InputFormThin
                                    name='name'
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    placehoder='이름을 입력 하세요'
                                    error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                                />
                            </div>
                            <div>
                                <LabelFormThin label='휴대폰번호' />
                                <InputFormThin
                                    name='phone_number'
                                    onChange={handlePhoneNumberChange}
                                    value={phoneNumber}
                                    placehoder='숫자만입력 (예: 010-1234-5678)'
                                    error={(formik.touched.phone_number && formik.errors.phone_number) && formik.errors.phone_number}
                                />
                            </div>

                            <div>
                                <LabelFormThin label='생년월일(선택)' />

                                <div className='flex gap-5'>
                                    <InputFormThin
                                        name='dob'
                                        onChange={handleDob}
                                        value={dob}
                                        placehoder='예) 1990/12/25' classNameAll='w-2/3'
                                        error={(formik.touched.dob && formik.errors.dob) && formik.errors.dob}
                                    />
                                    <Dropdown
                                        data={dataGender}
                                        name='name'
                                        title='성별'
                                        classnameAll='w-1/3 h-fit'
                                        className='w-full'
                                        handleChoose={handleChooseGender}
                                        isChoose={formik.values.gender}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='grid grid-cols-2 gap-2.5'>
                            <ButtonSecon title='취소' onClick={handleShowPopup} className='w-[160px]' />
                            <ButtonPrimary text='확인' className='w-[160px]' type='submit' onClick={formik.handleSubmit} />
                        </div>
                    </form>
                </div>
            }

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}
