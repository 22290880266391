import { Trash } from 'iconsax-react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deletePhoneBlacklist } from '../../reduxs/actions/user.action'

export default function PhoneItem({ id, phone }) {
    const dispatch = useDispatch()

    const handleDelete = () => {
        dispatch(deletePhoneBlacklist(id))
    }

    return (
        <div className='p-5 rounded-12 border border-strock flex justify-between gap-4 items-center'>
            <p>{phone}</p>

            <button onClick={handleDelete}><Trash size={20} color='#BABABA' /></button>
        </div>
    )
}
