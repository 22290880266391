import React from 'react'
import SidebarAdmin from '../../components/admin/SidebarAdmin'
import { Outlet, useLocation } from 'react-router-dom'
import DashboardAdmin from './DashboardAdmin'

export default function AdminPage() {
    const { pathname } = useLocation()

    return (
        <div className='flex h-screen'>
            <SidebarAdmin />

            <main className='flex-grow h-full bg-admin rounded-l-[40px] p-[30px] duration-300'>
                <article className='flex flex-col gap-[30px] overflow-y-scroll h-full hiddenScroll'>
                    {
                        pathname === '/admin' ? <DashboardAdmin /> : <Outlet />
                    }
                </article>
            </main>
        </div>
    )
}
