import { createSlice } from "@reduxjs/toolkit";
import {
    addEmailBlacklist,
    addPhoneToBlacklist,
    changeToBlacklist,
    changeUserToNormal,
    deleteEmailBlacklist,
    deletePhoneBlacklist,
    deleteUserAccount,
    deleteUserRecipient,
    getEmailBlacklist,
    getPhoneBlacklist,
    getPointUser,
    getPremiumPointUser,
    getUserBlacklist,
    getUserNormal,
    getUserRecipient,
    searchPointUser,
    searchUserBlacklist,
    searchUserNormal,
    updatePercelPurchaseUser,
    updatePointUser,
    updateReviewPointUser
} from "../actions/user.action";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        loadingUserRicipient: false,
        dataUserRecipient: undefined,

        loadingGetUserNormal: false,
        dataUserNormal: undefined,
        countUserNormal: 0,

        loadingGetUserBlacklist: false,
        dataUserBlacklist: undefined,
        countUserBlacklist: 0,

        loadingGetPhoneBlacklist: false,
        dataPhoneBlacklist: undefined,
        countPhoneBlacklist: 0,

        loadingGetEmailBlacklist: false,
        dataEmailBlacklist: undefined,
        countEmailBlacklist: 0,

        loadingAddPhoneToBlackList: false,

        loadingChangeToBlacklist: false,
        loadingChangeToNormal: false,

        loadingGetPointUser: false,
        dataPointUser: undefined,
        countPointUser: 0,
        percentPurchase: 0,
        reviewPoint: 0,

        loadingGetMediumPoint: false,

        loadingUpdatePoint: false,

        loadingUpdatePointUser: false,

        loadingDeleteEmailBlacklist: false,

        loadingAddEmailBlacklist: false,
        loadingDeletePhoneBlacklist: false,

        loadingDeleteUserRicipient: false,

        loadingDeleteUser: false,
    },
    reducers: {
        updateDataUserNormal: (state, action) => {
            // const res = action.payload

            // const deleteLastItem = state.dataUserNormal.pop()
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserRecipient.pending, (state, action) => {
            state.loadingUserRicipient = true
        })
        builder.addCase(getUserRecipient.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataUserRecipient = res.data
            }

            state.loadingUserRicipient = false
        })

        // delete user recipient
        builder.addCase(deleteUserRecipient.pending, (state, action) => {
            state.loadingDeleteUserRicipient = true
        })
        builder.addCase(deleteUserRecipient.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataUserRecipient.filter(item => item.id !== Number(res.data))

                state.dataUserRecipient = updateData
            }

            state.loadingDeleteUserRicipient = false
        })

        // get user normal
        builder.addCase(getUserNormal.pending, (state, action) => {
            state.loadingGetUserNormal = true
        })
        builder.addCase(getUserNormal.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataUserNormal = res.data.allUserNormal
                state.countUserNormal = res.data.countUser
            }

            state.loadingGetUserNormal = false
        })

        // search user normal
        builder.addCase(searchUserNormal.pending, (state, action) => {
            state.loadingGetUserNormal = true
        })
        builder.addCase(searchUserNormal.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataUserNormal = res.data.allUserNormal
                state.countUserNormal = res.data.countUser
            }

            state.loadingGetUserNormal = false
        })

        // get user blacklist
        builder.addCase(getUserBlacklist.pending, (state, action) => {
            state.loadingGetUserBlacklist = true
        })
        builder.addCase(getUserBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataUserBlacklist = res.data.allUserBlackList
                state.countUserBlacklist = res.data.countUser
            }

            state.loadingGetUserBlacklist = false
        })

        // search user blacklist
        builder.addCase(searchUserBlacklist.pending, (state, action) => {
            state.loadingGetUserBlacklist = true
        })
        builder.addCase(searchUserBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataUserBlacklist = res.data.allUserBlackList
                state.countUserBlacklist = res.data.countUser
            }

            state.loadingGetUserBlacklist = false
        })

        // get phone blacklist
        builder.addCase(getPhoneBlacklist.pending, (state, action) => {
            state.loadingGetPhoneBlacklist = true
        })
        builder.addCase(getPhoneBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPhoneBlacklist = res.data.phoneBlackList
                state.countPhoneBlacklist = res.data.countPhone
            }

            state.loadingGetPhoneBlacklist = false
        })

        // delete phone blacklist
        builder.addCase(deletePhoneBlacklist.pending, (state, action) => {
            state.loadingDeletePhoneBlacklist = true
        })
        builder.addCase(deletePhoneBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataPhoneBlacklist.filter(item => item.id !== Number(res.data))
                state.dataPhoneBlacklist = updateData
                state.countPhoneBlacklist -= 1
            }

            state.loadingDeletePhoneBlacklist = false
        })

        // get email blacklist
        builder.addCase(getEmailBlacklist.pending, (state, action) => {
            state.loadingGetEmailBlacklist = true
        })
        builder.addCase(getEmailBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataEmailBlacklist = res.data.emailBlackList
                state.countEmailBlacklist = res.data.countEmail
            }

            state.loadingGetEmailBlacklist = false
        })

        // delete email blacklist
        builder.addCase(deleteEmailBlacklist.pending, (state, action) => {
            state.loadingDeleteEmailBlacklist = true
        })
        builder.addCase(deleteEmailBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataEmailBlacklist.filter(item => item.id !== Number(res.data))

                state.dataEmailBlacklist = updateData
                state.countEmailBlacklist -= 1
            }

            state.loadingDeleteEmailBlacklist = false
        })

        // add email blacklist
        builder.addCase(addEmailBlacklist.pending, (state, action) => {
            state.loadingAddEmailBlacklist = true
        })
        builder.addCase(addEmailBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataEmailBlacklist = [
                    res.data,
                    ...state.dataEmailBlacklist,
                ]

                state.countEmailBlacklist += 1
            }

            state.loadingAddEmailBlacklist = false
        })

        // add phone to blacklist
        builder.addCase(addPhoneToBlacklist.pending, (state, action) => {
            state.loadingAddPhoneToBlackList = true
        })
        builder.addCase(addPhoneToBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPhoneBlacklist = [
                    res.data,
                    ...state.dataPhoneBlacklist
                ]
            }

            state.loadingAddPhoneToBlackList = false
        })

        // change to blacklist
        builder.addCase(changeToBlacklist.pending, (state, action) => {
            state.loadingChangeToBlacklist = true
        })
        builder.addCase(changeToBlacklist.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataUserNormal.filter(item => item.account_id !== res.data.account_id)

                if (res.data.next_user) {
                    state.dataUserNormal = [
                        ...updateData,
                        res.data.next_user
                    ]

                    return
                }

                state.dataUserNormal = updateData
                state.countUserNormal -= 1
            }

            state.loadingChangeToBlacklist = false
        })

        // change to normal
        builder.addCase(changeUserToNormal.pending, (state, action) => {
            state.loadingChangeToNormal = true
        })
        builder.addCase(changeUserToNormal.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataUserBlacklist.filter(item => item.account_id !== res.data.account_id)

                if (res.data.next_user) {
                    state.dataUserBlacklist = [
                        ...updateData,
                        res.data.next_user
                    ]

                    return
                }

                state.dataUserBlacklist = updateData
                state.countUserBlacklist -= 1
            }

            state.loadingChangeToNormal = false
        })

        // get point user
        builder.addCase(getPointUser.pending, (state, action) => {
            state.loadingGetPointUser = true
        })
        builder.addCase(getPointUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPointUser = res.data.userPoint
                state.countPointUser = res.data.countUser
            }

            state.loadingGetPointUser = false
        })

        // search point user
        builder.addCase(searchPointUser.pending, (state, action) => {
            state.loadingGetPointUser = true
        })
        builder.addCase(searchPointUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataPointUser = res.data.userPoint
                state.countPointUser = res.data.countUser
            }

            state.loadingGetPointUser = false
        })

        // update review point
        builder.addCase(updateReviewPointUser.pending, (state, action) => {
            state.loadingUpdatePoint = true
        })
        builder.addCase(updateReviewPointUser.fulfilled, (state, action) => {
            state.loadingUpdatePoint = false
        })

        // update percent purchase
        builder.addCase(updatePercelPurchaseUser.pending, (state, action) => {
            state.loadingUpdatePoint = true
        })
        builder.addCase(updatePercelPurchaseUser.fulfilled, (state, action) => {
            state.loadingUpdatePoint = false
        })

        // update point user
        builder.addCase(updatePointUser.pending, (state, action) => {
            state.loadingUpdatePointUser = true
        })
        builder.addCase(updatePointUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const { user_id, point_total } = res.data

                const updateData = state.dataPointUser.map(item => {
                    if (item.user_id === Number(user_id)) {
                        return {
                            ...item,
                            point_total
                        }
                    }

                    return item
                })

                state.dataPointUser = updateData
            }

            state.loadingUpdatePointUser = false
        })

        // get premium point
        builder.addCase(getPremiumPointUser.pending, (state, action) => {
            state.loadingGetMediumPoint = true
        })
        builder.addCase(getPremiumPointUser.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.percentPurchase = res.data.percentPurchase
                state.reviewPoint = res.data.reviewPoint
            }

            state.loadingGetMediumPoint = false
        })

        // delete user account
        builder.addCase(deleteUserAccount.pending, (state, action) => {
            state.loadingDeleteUser = true
        })
        builder.addCase(deleteUserAccount.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                const updateData = state.dataUserNormal.filter(item => item.account_id !== Number(res.data.account_id))

                if (res.data.replaceUser) {
                    state.dataUserNormal = [
                        ...updateData,
                        res.data.replaceUser
                    ]

                    return
                }

                state.dataUserNormal = updateData
            }

            state.loadingDeleteUser = false
        })
    }
})

export const { updateDataUserNormal } = userSlice.actions
export default userSlice.reducer
