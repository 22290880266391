import { createSlice } from "@reduxjs/toolkit";
import { getInforDataHomePage } from "../actions/home.action";

const homePageSlice = createSlice({
    name: 'home',
    initialState: {
        loadingGetInforHomePage: false,
        dataInforHomePage: undefined
    },
    extraReducers: builder => {
        builder.addCase(getInforDataHomePage.pending, (state, action) => {
            state.loadingGetInforHomePage = true
        })
        builder.addCase(getInforDataHomePage.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataInforHomePage = res.data
            }

            state.loadingGetInforHomePage = false
        })
    }
})

export const { } = homePageSlice.actions
export default homePageSlice.reducer
